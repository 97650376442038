import React, { Fragment } from 'react'
import image1 from "./images/admin_scr/image1.png"
import image2 from "./images/admin_scr/image2.png"
import image423 from "./images/admin_scr/image423.png"
import image436 from "./images/admin_scr/image436.png"
import image437 from "./images/admin_scr/image437.png"
import image438 from "./images/admin_scr/image438.png"
import image439 from "./images/admin_scr/image439.png"

import image440 from "./images/admin_scr/image440.png"
import image443 from "./images/admin_scr/image443.png"
import image444 from "./images/admin_scr/image444.png"
import image445 from "./images/admin_scr/image445.png"

import image424 from "./images/admin_scr/image424.png"
import image425 from "./images/admin_scr/image425.png"
import image426 from "./images/admin_scr/image426.png"
import image427 from "./images/admin_scr/image427.png"
import image429 from "./images/admin_scr/image429.png"
import image430 from "./images/admin_scr/image430.png"
import image431 from "./images/admin_scr/image431.png"

import image432 from "./images/admin_scr/image432.png"
import image433 from "./images/admin_scr/image433.png"
import image434 from "./images/admin_scr/image434.png"
import image435 from "./images/admin_scr/image435.png"

import image446 from "./images/admin_scr/image446.png"
import image447 from "./images/admin_scr/image447.png"
import image450 from "./images/admin_scr/image450.png"
import image449 from "./images/admin_scr/image449.png"

import image451 from "./images/admin_scr/image451.png"
import image452 from "./images/admin_scr/image452.png"
import image488 from "./images/admin_scr/image488.png"
import image490 from "./images/admin_scr/image490.png"
import image491 from "./images/admin_scr/image491.png"
import image492 from "./images/admin_scr/image492.png"
import image478 from "./images/admin_scr/image478.png"

function Admin({ admin_val }) {
    return (
        <Fragment>
            {
                admin_val === 0 ?
                    <article>
                        <h3><b>Introduction to the Shamah</b></h3>
                        <p>
                            Shamah autism is a complete educational platform for clients, educators and academic professionals both in and out of the session.
                            This site provides all the functionality of an educational platform as well as enhanced and additional features such as:
                        </p>
                        <ul>
                            <li>Live sessions </li>
                            <li>Pre-recorded Video sessions</li>
                            <li>Live sessions recording</li>
                            <li>Slide sharing</li>
                            <li>Automatic Attendance records</li>
                            <li>Quizzes</li>
                            <li>Creation of multiple users</li>
                            <li>Chat </li>
                        </ul>

                        <p>
                            This manual explains  how to use this Site/Application in order to get productive sessions. For all further questions, please get in touch with the Admin.
                            <br /><br />
                            Brows <a href='https://app.shamah-autism.com.sa/' target="_blank">https://app.shamah-autism.com.sa/</a> or Install Shamah application in
                        </p>
                        <ul>
                            <li>For Ios <a href='https://apps.apple.com/in/app/shamah/id1573574939' target="_blank">https://apps.apple.com/in/app/shamah/id1573574939</a></li>
                            <li>For Android <a href='https://play.google.com/store/apps/details?id=com.shamautism' target="_blank">https://play.google.com/store/apps/details?id=com.shamautism</a></li>
                        </ul>

                        <p>
                            By visiting <a href='https://app.shamah-autism.com.sa/' target="_blank">https://app.shamah-autism.com.sa/</a> Admin can reach in to the shamah website, Type the credentials and login
                        </p>

                        <div class='image_div'>
                            <img src={image1} alt='' width='100%' height='100%' />
                        </div>
                    </article>
                    :

                    admin_val === 1 ?
                        <article>
                            <h5><b>Dashboard</b></h5>
                            <p>
                                Admin <strong>Dashboard/Home</strong> is the place where Admin/Staff see the overall session details
                            </p>

                            <div class='image_div'>
                                <img src={image2} alt='' width='100%' height='100%' />
                            </div>

                            <ul>
                                <li>Total Sessions- Number of scheduled sessions in each day</li>
                                <li>Live sessions- Number of ongoing sessions</li>
                                <li>Active therapists -Number of therapists who conduct the session</li>
                                <li>Inactive therapists -Number of therapist who is not conducted the session on time</li>
                                <li>Active clients - Number of clients who actively participate in the session</li>
                                <li>Inactive clients -Number of the clients who is inactive</li>
                            </ul>

                            <h5><b>Countdown of next session</b></h5>
                            <p>
                                Here the countdown of the next session assigned for the admin will be displayed. If any sessions are assigned for the admin, he can <strong>join/start</strong> the session till the timer gets zero.
                            </p>

                            <h5><b>Live Session</b></h5>
                            <p>
                                Here the live sessions are visible.If once the host starts the session, By clicking the Action icon, Admin can enter the session without the permission of the host and check the ongoing sessions.
                            </p>


                            <h5><b>Current Session</b></h5>
                            <p>Sessions which have scheduled for each days will be displayed here. </p>

                            <div class='image_div'>
                                <img src={image423} alt='' width='100%' height='100%' />
                            </div>

                            <p style={{ color: '#d37806' }}>
                                Note:The above overall class details will not be visible in Mobile applications. Admin/staff can be start or join the session from the dashboard through the app
                            </p>

                            <p>A chat icon in the top right corner where the new chat notifications are received.</p>

                            {/* <p class='text-danger'>crop image to be added</p> */}


                            <p>From the Home page, Admin/Staff can go through their allowed features named as Menu list.  Let us check the features..</p>

                            <ol>
                                <li>Home</li>
                                <li>Session</li>
                                <li>Attendance </li>
                                <li>Recording</li>
                                <li>Group</li>
                                <li>User</li>
                                <li>Chat</li>
                            </ol>

                            {/* <p class='text-danger'>image424 (not now)</p> */}

                            <p style={{ color: '#d37806' }}>Note: Admin can set the staff permission in the User menu.In Mobile application you only have Home,Session, and Chat menus</p>

                        </article>
                        :
                        admin_val === 2 ?
                            <article>

                                <h5><b>Create Group</b></h5>
                                <ul>
                                    <li>Creating multiple groups for assigning the Clients, Staffs, Therapists in <strong>User</strong> creation</li>
                                    <li>By entering the name to the group, admin can add many groups</li>

                                    <div class='image_div'>
                                        <img src={image436} alt='' width='100%' height='100%' />
                                    </div>

                                    <li>participants can be added to the group by clicking the <strong>Assign button</strong></li>
                                    <div class='image_div'>
                                        <img src={image437} alt='' width='100%' height='100%' />
                                    </div>
                                    <div class='image_div'>
                                        <img src={image438} alt='' width='100%' height='100%' />
                                    </div>
                                    <li>Added participants can be view in <strong>View participants</strong></li>

                                    <div class='image_div'>
                                        <img src={image439} alt='' width='100%' height='100%' />
                                    </div>
                                    <li>Groups can be <strong>Edit/Delete.</strong></li>
                                </ul>

                            </article>
                            :
                            admin_val === 3 ?
                                <article>
                                    <h5 id='create_user' style={{ marginLeft: '10px' }}><b>1. Create User</b></h5>

                                    <div class='image_div'>
                                        <img src={image440} alt='' width='100%' height='100%' />
                                    </div>
                                    {/* <p class='text-danger'>image442 not now</p> */}

                                    <p style={{ marginLeft: '20px' }}>
                                        Create a User is the first step to add the users such as <strong>Therapists, Clients,</strong> and <strong>Staff. </strong><br />There are two methods to add users to Shamah
                                    </p>

                                    <div style={{ marginLeft: '20px' }}>
                                        <span style={{ fontWeight: '600' }}>A. Single</span>
                                        <ul>
                                            <li>Here the users can be added by selecting the tab and select the role <strong>Therapist/Client/Staff</strong></li>
                                            <li>If the user role is Client, then select the group which you want to add the Participants and fill the required fields</li>
                                            <li>Select the Permissions assigned for the user according to each role (Student have the default permissions)</li>
                                            <li>Click <strong>Add user</strong> button</li>
                                        </ul>

                                        <div class='image_div'>
                                            <img src={image488} alt='' width='100%' height='100%' />
                                        </div>

                                        <span style={{ fontWeight: '600' }}>B. Bulk</span>
                                        <ul>
                                            <li>Download the <strong>Excel</strong> template according to the users to add</li>

                                            <div class='image_div'>
                                                <img src={image443} alt='' width='100%' height='100%' />
                                            </div>

                                            <li>Open the excel form and insert the data in each cell under the corresponding  fields</li>

                                            {/* <p class='text-danger'>not now</p> */}

                                            <li>Save the excel</li>
                                            <li>Select the role of user in bulk method</li>
                                            <li>Choose the corresponding excel file</li>
                                            <li>Assign the required Permissions</li>
                                            <li><strong>Click Add</strong> user button</li>
                                        </ul>
                                    </div>

                                    <h5 id='show_therapist' style={{ marginLeft: '10px' }}><b>2. Show Therapists</b></h5>
                                    <ul>
                                        <li>Added therapists are listed here </li>
                                        <li>Here you can Update the Permission</li>
                                        <li><strong>Edit/Delete</strong> the user details</li>

                                        <div class='image_div'>
                                            <img src={image444} alt='' width='100%' height='100%' />
                                        </div>

                                    </ul>

                                    <h5 id='show_clients' style={{ marginLeft: '10px' }}><b>3. Show Clients</b></h5>
                                    <ul>
                                        <li>Added clients are listed here </li>
                                        <li>You can view the clients Group here</li>

                                        <div class='image_div'>
                                            <img src={image490} alt='' width='100%' height='100%' />
                                        </div>

                                    </ul>

                                    <h5 id='show_staff' style={{ marginLeft: '10px' }}><b>4. Show Staff</b></h5>
                                    <p style={{ marginLeft: '20px' }}>
                                        Staff is the person who can manage the Admin features.You can Update the Staff Permissions here.
                                    </p>
                                    <div class='image_div'>
                                        <img src={image491} alt='' width='100%' height='100%' />
                                    </div>

                                </article>
                                :
                                admin_val === 4 ?
                                    <article>
                                        <div style={{ marginLeft: '20px' }}>
                                            <h5 id='create_session'><b>1. Create session</b></h5>
                                            <p>Admin/Staff can create live sessions for the Clients here. <br />
                                                Enter the session details you  want to create such  as
                                            </p>
                                            <ul>
                                                <li><strong>Session name</strong> (will be displayed in the countdown)
                                                    <div class='image_div'>
                                                        <img src={image424} alt='' width='100%' height='100%' />
                                                    </div>
                                                </li>
                                                <li>Select the <strong>Host</strong> (therapist/staff/shamah)</li>
                                                <li>Select <strong>clinic</strong></li>
                                                <li>Select <strong>From - To date</strong> of session</li>
                                                <li>You can create <strong>Day</strong> and <strong>time slots</strong> for the session.</li>
                                                <li>Create <strong>session</strong>
                                                    <div class='image_div'>
                                                        <img src={image425} alt='' width='100%' height='100%' />
                                                    </div>
                                                </li>
                                                <li>Created sessions are listed below and in the Show session</li>
                                            </ul>
                                            <h5 id='show_session'><b>2. Show Session</b></h5>
                                            <ul>
                                                <li>Created sessions are listed here</li>

                                                <div class='image_div'>
                                                    <img src={image426} alt='' width='100%' height='100%' />
                                                </div>

                                                <li>There is an option to view the <strong>Time schedule of the created sessions</strong></li>
                                                <li>Option to add the <strong>Groups</strong> in to the session</li>

                                                <div class='image_div'>
                                                    <img src={image492} alt='' width='100%' height='100%' />
                                                </div>

                                                <li>You can add the Individual participants to the session by clicking the <strong>Add participants button</strong></li>
                                                <li>Assigned participants  details are displayed in the <strong>View participants</strong></li>
                                                <li>Edit session: By clicking edit option, <strong>Admin/Staff</strong> can Update time slots</li>
                                                <li>You can create special session by ticking the <strong>Check Box</strong> in the right side and create special time slots</li>
                                                {/* <p class='text-danger'>to be added</p> */}
                                                <li>Update sesion</li>
                                            </ul>

                                            <p style={{ color: '#d37806' }}>
                                                NOTE : In Mobile application, Admin/Staff can be only see the created sessions, Edit can not be possible in App
                                            </p>

                                            <h5 id='special_session'><b>3. Special Sessions</b></h5>
                                            <p>
                                                If any Special sessions are created, it will be displayed here

                                                <div class='image_div'>
                                                    <img src={image427} alt='' width='100%' height='100%' />
                                                </div>
                                            </p>
                                            <h5 id='video_session'><b>4. Video Session</b></h5>
                                            <p>
                                                The Pre-recorded video sessions created by the therapists are access from here.

                                                <div class='image_div'>
                                                    <img src={image429} alt='' width='100%' height='100%' />
                                                </div>
                                                <div class='image_div'>
                                                    <img src={image430} alt='' width='100%' height='100%' />
                                                </div>
                                                <div class='image_div'>
                                                    <img src={image431} alt='' width='100%' height='100%' />
                                                </div>
                                            </p>
                                        </div>
                                    </article>
                                    :
                                    admin_val === 5 ?
                                        <article>
                                            <h5><b>Recording</b></h5>
                                            <p>
                                                The session recordings taken by the hosts are displayed here and you can Download,Play and Copy the video's link for sharing.
                                            </p>
                                            <p style={{ color: '#d37806' }}>Note: The recording files will be displayed only in the Therapist & Admin accounts.</p>

                                            <div class='image_div'>
                                                <img src={image435} alt='' width='100%' height='100%' />
                                            </div>

                                        </article>
                                        :
                                        admin_val === 6 ?
                                            <article>
                                                <h5><b>Chat</b></h5>
                                                <p>
                                                    Admin can start a conversation with any of the users such as Clients, Therapists and Staff. The Chat icon in the dashboard indicates the count of <strong>New messages notifications.</strong>
                                                </p>
                                                <div class='image_div'>
                                                    <img src={image478} alt='' width='100%' height='100%' />
                                                </div>
                                                <ul>
                                                    <li>
                                                        New Chat - You can start a new conversation by clicking the <strong>Add button</strong> from the top left corner. You can search the participants which you want to added to the group.
                                                    </li>


                                                    <div className='image_combined'>
                                                        <div className='image_combined1'>
                                                            <img src={image492} alt='' width='100%' height='373px' style={{ borderRadius: '10px', border: '.5px solid rgb(97, 95, 95)' }} />
                                                        </div>
                                                        <div className='image_combined2'>
                                                            <img src={image446} alt='' width='100%' height='373px' style={{ borderRadius: '10px', border: '.5px solid rgb(97, 95, 95)' }} />

                                                        </div>
                                                    </div>

                                                    <li>
                                                        Group Chat - By giving a Group name you can create Group with multiple users<br />

                                                        <div class='image_div'>
                                                            <img src={image447} alt='' width='100%' height='100%' />
                                                        </div>

                                                        By clicking the View details icon in the chat screen of group, you can view the user's details.<br />

                                                        <div class='image_div'>
                                                            <img src={image449} alt='' width='100%' height='100%' />
                                                        </div>

                                                        Users have the feature of Sharing Documents, Images, Videos, and audios from their device.

                                                        <div class='image_div'>
                                                            <img src={image450} alt='' width='100%' height='100%' />
                                                        </div>

                                                    </li>
                                                </ul>
                                            </article>
                                            :
                                            admin_val === 8 ?
                                                <article>
                                                    <h5><b>Attendance</b></h5>
                                                    <ul>
                                                        <li>Admin can check the individual Therapists/Clients Attendance</li>
                                                        <li>Select Therapist/Student tab you want to add,Select the Date range, Session and Select the Partipant name</li>

                                                        <div class='image_div'>
                                                            <img src={image432} alt='' width='100%' height='100%' />
                                                        </div>

                                                        <li>Click the <strong>Filter</strong> button</li>
                                                        <li>The Attendance status of selected Date range will be listed with time duration</li>

                                                        <div class='image_div'>
                                                            <img src={image433} alt='' width='100%' height='100%' />
                                                        </div>
                                                        <div class='image_div'>
                                                            <img src={image434} alt='' width='100%' height='100%' />
                                                        </div>

                                                    </ul>
                                                </article>
                                                :
                                                <article>
                                                    <h5><b>Change password</b></h5>
                                                    <p>
                                                        Users can reset their password here

                                                        <div class='image_div'>
                                                            <img src={image451} alt='' width='100%' height='100%' />
                                                        </div>

                                                    </p>

                                                    <h5><b>My Account</b></h5>
                                                    <p>
                                                        In Settings you can view the User account information such as Username, Email ID and Contact number.

                                                        <div class='image_div'>
                                                            <img src={image452} alt='' width='100%' height='100%' />
                                                        </div>

                                                    </p>
                                                </article>
            }

        </Fragment>
    )
}

export default Admin
