import axios, { CancelToken, isCancel } from "axios";
import { development } from '../../config.json'
// import { useHistory } from 'react-router-dom'     
import Swal from 'sweetalert2'
import teacheHomeReducer from "../Reducers/homeTeacher";
import { param } from "jquery";
//const cancelTokenSource = axios.CancelToken.source();

export function setNewActionData(name, value) {
    return {
        type: 'SET_NEWACTION_DATA',
        payload: { [name]: value },
    }
}

export const getClassStudentListUnderClassGroup = (token, group_d, class_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        group_id: group_d,
        class_id: class_id
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { load_stdent: true } })
    axios.post(`${development.baseUrl}getClassStudentListUnderClassGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                let details = res.data.details ? res.data.details : []
                let arr = []
                details.forEach(element => {
                    if (element.selected) {
                        arr.push(element.user_id)
                    }
                });
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        sourceGroupStudents: res.data.details,
                        checkedArray: arr,
                        load_stdent: false
                    }
                })
                cb(res.data.details)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { load_stdent: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { load_stdent: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}


export const addStudentsToClassRoomFromGroup = (token, class_id, arr1, arr2, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        class_id: class_id,
        registered_student: arr1,
        unregistered_student: arr2
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { load_submit_stdent: true } })
    axios.post(`${development.baseUrl}addStudentsToClassRoomFromGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        load_submit_stdent: false
                    }
                })
                cb()
            }
            else {
                dispatch({
                    type: 'NEWACTION_ALL_FAILED', payload: {
                        load_submit_stdent: false

                    }
                })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({
                type: 'NEWACTION_ALL_FAILED', payload: {
                    load_submit_stdent: false,
                }
            })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const recentChats = (token, page, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { recentChat_loader: true } })
    axios.get(`${development.chatBaseUrl}recentChats`, { headers: head })
        .then(res => {
            if (res.data.result) {
                let chat_data = res.data ? res.data.recent_chat ? res.data.recent_chat : [] : []
                let arr = []
                chat_data.map((d, i) => {
                    arr.push(d.unread)
                })
                const sum = arr.reduce((partial_sum, a) => partial_sum + a, 0)

                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        Recent_chat_data: chat_data,
                        Recent_chat_next_page: res.data.recent_chat ? res.data.recent_chat.next_page_url : '',
                        recentChat_loader: false,
                        admin_chat_id: res.data.admin_id,
                        MessageCount: sum
                    }
                })
                if (cb) {
                    cb(chat_data)
                }
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { recentChat_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { recentChat_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const createChat = (token, message, id, type) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {}
    if (type === 'group') {
        data.group_id = id
        data.message = message
    }
    else {
        data.receiver_id = id
        data.message = message
    }
    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: {} })
    axios.post(`${development.chatBaseUrl}createChat`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {

                    }
                })
                // cb()
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}
export const uploadMediaFiles = (token, data, cb, cancelFileUpload) => (dispatch) => {

    const options = {

        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'content-type': 'multipart/form-data'

        },
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            dispatch({ type: 'SET_NEWACTION_DATA', payload: { fileChatPercentage: percent } })
        },
        cancelToken: new CancelToken(
            cancel => (cancelFileUpload.current = cancel)
        )
    };

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { UploadButtonLoader: true } })
    axios.post(`${development.chatBaseUrl}uploadFileChat`, data, options)
        .then(res => {

            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        UploadButtonLoader: false,
                        fileChatPercentage: ''
                    }
                })
                cb(res.data.details)
            }
            else {
                dispatch({
                    type: 'NEWACTION_ALL_FAILED', payload: {
                        UploadButtonLoader: false,
                        fileChatPercentage: ''
                    }
                })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            cb() // remove this cb once pusher is working
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { UploadButtonLoader: false,fileChatPercentage: '' } })
        }
        )
}

export const createGroup = (token, group_name, users, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        group_name: group_name,
        users: users
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { group_loader: true } })
    axios.post(`${development.chatBaseUrl}createGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        group_loader: false
                    }
                })
                cb()
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { group_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { group_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}


export const teachersList = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { teachertList_loader: true } })
    axios.get(`${development.chatBaseUrl}teachersList`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        teachers_list: res.data.teachers_list ? res.data.teachers_list : [],
                        teachers_list_copy: res.data.teachers_list ? res.data.teachers_list : [],
                        teachertList_loader: false
                    }
                })
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { teachertList_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { teachertList_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const chatableUsers = (token, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { userList_loader: true } })
    axios.get(`${development.chatBaseUrl}chatableUsers`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        user_list: res.data.user_list ? res.data.user_list : [],
                        user_list_copy: res.data.user_list ? res.data.user_list : [],
                        userList_loader: false
                    }
                })
                cb(res.data.user_list)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { teachertList_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { teachertList_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const groupList = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { grouptList_loader: true } })
    axios.get(`${development.chatBaseUrl}groupList`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        group_list: res.data.group_list ? res.data.group_list : [],
                        group_list_copy: res.data.group_list ? res.data.group_list : [],
                        grouptList_loader: false
                    }
                })
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { grouptList_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { grouptList_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const uploadUserImage = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { emoji_loader: true } })
    axios.post(`${development.baseUrl}uploadUserImage`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        emoji_loader: false
                    }
                })
                cb()
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { emoji_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { emoji_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const chatDetails = (token, group_id, receiver_id, page, cb,) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { chat_details_loader: true } })
    if (group_id) {
        axios.get(`${development.chatBaseUrl}chatDetails?group_id=${group_id}&page=${page}`, { headers: head })
            .then(res => {
                if (res.data.result) {
                    let data = res.data.chat_details ? res.data.chat_details.data : []
                    let channel = res.data.channel_name
                    dispatch({
                        type: 'NEWACTION_ALL_SUCCESS', payload: {
                            chat_data: data,
                            group_members: res.data.group_members ? res.data.group_members : [],
                            chat_next_page: res.data.chat_details ? res.data.chat_details.next_page_url : '',
                            chat_data: res.data.chat_details ? res.data.chat_details.data : [],
                            chat_name: res.data.name,
                            chat_details_loader: false,
                            group_members_copy: res.data.group_members ? res.data.group_members : [],
                            current_user_id: res.data.user_id,
                            group_owner: res.data.owner ? res.data.owner : false,
                            channel_id: res.data.channel_id,
                            group_created_at: res.data.group_created_at ? res.data.group_created_at : ''
                        }
                    })
                    cb(data, channel, res.data)
                }
                else {
                    dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { chat_details_loader: false } })
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: res.data.message,

                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { chat_details_loader: false } })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }

                }

            }
            )
    }
    else {
        axios.get(`${development.chatBaseUrl}chatDetails?receiver_id=${receiver_id}&page=${page}`, { headers: head })
            .then(res => {
                if (res.data.result) {
                    let data = res.data.chat_details ? res.data.chat_details.data : []
                    let channel = res.data.channel_name
                    dispatch({
                        type: 'NEWACTION_ALL_SUCCESS', payload: {
                            chat_data: data,
                            group_members: res.data.group_members ? res.data.group_members : [],
                            chat_next_page: res.data.chat_details ? res.data.chat_details.next_page_url : '',
                            chat_data: res.data.chat_details ? res.data.chat_details.data : [],
                            chat_name: res.data.name,
                            chat_details_loader: false,
                            group_members_copy: res.data.group_members ? res.data.group_members : [],
                            current_user_id: res.data.user_id,
                            group_owner: res.data.owner ? res.data.owner : false,
                            channel_id: res.data.channel_id
                        }
                    })
                    cb(data, channel, res.data)
                }
                else {
                    dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { chat_details_loader: false } })
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: res.data.message,

                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { chat_details_loader: false } })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                               
                                window.location.reload()
                            }
                        }
                    }

                }

            }
            )
    }
}

export const batchesList = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: {} })
    axios.get(`${development.chatBaseUrl}batchesList`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        batch_data: res.data.batches_list,
                        batch_data_copy: res.data.batches_list
                    }
                })
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const studentList = (token, search, batch, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {}
    data.search_name = search ? search : undefined
    data.batches = batch.length > 0 ? batch : undefined

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { recentChat_loader: true } })

    axios.post(`${development.chatBaseUrl}studentList`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                let data = res.data.students_list ? res.data.students_list : []
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        student_chat_list: res.data.students_list ? res.data.students_list : [],
                        recentChat_loader: false
                    }
                })
                cb(data)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { recentChat_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { recentChat_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const removeFromGroup = (token, user_id, group_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        user_id: user_id,
        group_id: group_id
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: {} })
    axios.post(`${development.chatBaseUrl}removeFromGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                    }
                })
                cb(user_id, res.data.message)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const getGroupAssignedTeachers = (token, group_id, teacher_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        group_id: group_id,
        teacher_id: teacher_id,
    }
    dispatch({ type: 'POPULATE_REGISTERED_TEACHER_REQUEST' })

    axios.post(`${development.baseUrlEnte}getGroupAssignedTeachers`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_REGISTERED_TEACHER_SUCCESS', payload: res.data.details })

            }
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_REGISTERED_TEACHER_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }

        }
        )
}

export const getAssignedGroups = (token, class_id, cb) => (dispatch) => {
   
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        class_id: class_id,
       
        type: ''
    }
    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { teacher_group_loader: true } })
    axios.post(`${development.baseUrl}getAssignedGroups`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                let arr = []
                res.data.data.forEach((ele) => {
                    if (ele.selected) {
                        arr.push(ele)
                    }
                })
                cb(arr, res.data.data)
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        teacher_group_data: res.data.data ? res.data.data : [],
                        teacher_group_data_selected: arr ? arr : [],
                        teacher_group_data_selected_copy: arr ? arr : [],
                        teacher_group_loader: false
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,
                })
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { teacher_group_loader: false } })
            }
        })
        .catch(err => {
            console.log("log error",err)
            dispatch({
                type: 'NEWACTION_ALL_FAILED', payload: {
                    teacher_group_loader: false,
                }
            })
        }
        )
}

export const assignGroupParticipantsToClassRoom = (token, class_id, group_selected, group_unselected, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        class_id: class_id,
        group_selected:  group_selected ,
        group_unselected : group_unselected
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: {assign_loader : true} })
    axios.post(`${development.baseUrl}assignGroupParticipantsToClassRoom`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        assign_loader : false
                    }
                })
                cb()
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Updated Successfully',

                })
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {
                    assign_loader : false
                } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {
                assign_loader : false
            } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}
export const channelUserStatus = (token, channel_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        channel_id: channel_id,
        status: "offline"
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: {} })
    axios.post(`${development.chatBaseUrl}channelUserStatus`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                    }
                })
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}



export const unassignedteachersInGroup = (token, group_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        group_id: group_id,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: {} })
    axios.post(`${development.chatBaseUrl}unassignedteachersInGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        new_member_list: res.data.teachers_list
                    }
                })
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const addUsers = (token, group_id, users, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        group_id: group_id,
        users: users
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { add_user_loader: true } })
    axios.post(`${development.chatBaseUrl}addUsers`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        add_user_loader: false
                    }
                })
                cb(res.data.message, res.data.group_members)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { add_user_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { add_user_loader: true } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}


export const unassignedUsersInGroup = (token, group_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        group_id: group_id,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { loading_members: true } })
    axios.post(`${development.chatBaseUrl}unassignedUsersInGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        new_all_member_list: res.data.users_list ? res.data.users_list : [],
                        loading_members: false
                    }
                })
                cb(res.data.users_list)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { loading_members: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { loading_members: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }

            }

        }
        )
}

export const getAllUsersInClass = (token, class_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { userlist_loader: true } })
    axios.post(`${development.baseUrl}getAllUsersInClass`, { class_id }, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        userlist_loader: false,
                        fulluserList: res.data.users ? res.data.users : []
                    }
                })
                cb(res.data.users)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_loader: false } })
        }
        )
}

export const addUsersToClassRoom = (token, classId, selectedstudent, unselectedstudent, selectedothers, unselectedothers, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    let data = {
        class_id: classId,
        student_selected: selectedstudent,
        student_unselected: unselectedstudent,
        others_selected: selectedothers,
        others_unselected: unselectedothers,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { adding_loader: true } })
    axios.post(`${development.baseUrl}addUsersToClassRoom`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        adding_loader: false
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: res.data.message ? res.data.message : 'Users added successfully',
                })
                cb()
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { adding_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { adding_loader: false } })
        }
        )
}

export const getAllUsersInEnterprise = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { userlist_loader: true } })
    axios.get(`${development.baseUrl}getAllUsersInEnterprise `, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        userlist_loader: false,
                        userlist_for_createclass: res.data.users ? res.data.users : []
                    }
                })
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_loader: false } })
        }
        )
}

export const getAllUsersInVideoClass = (token, class_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { userlist_loader: true } })
    axios.post(`${development.baseUrl}getAllUsersInVideoClass`, { class_id }, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        userlist_loader: false,
                        fullclientList: res.data.users ? res.data.users : [],
                        fullclientListCopy: res.data.users ? res.data.users : []

                    }
                })
                cb(res.data.users)
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_loader: false } })
        }
        )
}

export const addUsersToVideoClassRoom = (token,class_id,selectedstudent,unselectedstudent,cb,selectedteacher,unselectedteacher ) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data={
        class_id: class_id,
        student_selected: selectedstudent,
        student_unselected: unselectedstudent,
        teacher_selected:selectedteacher,
        teacher_unselected:unselectedteacher
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { userlist_add_loader: true } })
    axios.post(`${development.baseUrl}addUsersToVideoClassRoom`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        userlist_add_loader: false,
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: res.data.message ? res.data.message : 'Users added successfully',
                })
                cb()
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_add_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_add_loader: false } })
        }
        )
}

export const addGroupToStudyClassRoom = (token,class_id,group_selected,group_unselected,cb ) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data={
        class_id: class_id,
        group_selected: group_selected,
        group_unselected: group_unselected,
    }

    dispatch({ type: 'NEWACTION_ALL_REQUEST', payload: { userlist_add_loader: true } })
    axios.post(`${development.baseUrl}addGroupToStudyClassRoom`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'NEWACTION_ALL_SUCCESS', payload: {
                        userlist_add_loader: false,
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Groups updated to video session successfully',
                })
                cb()
            }
            else {
                dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_add_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'NEWACTION_ALL_FAILED', payload: { userlist_add_loader: false } })
        }
        )
}