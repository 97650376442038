import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
// import Loader from 'react-loader'
// import classnames from 'classnames';

// import { emailValidator, requiredValidator, nameValid } from '../../utilities/Validator'
import { authRequest, authRequestEnterprice } from '../../redux/Actions/auth'
import { Link, } from "react-router-dom";
import Slider from "react-slick";
import bg1 from '../../assets/utils/images/originals/shamah3.jpg'
import bg2 from '../../assets/utils/images/originals/autism2.jpg';
import bg3 from '../../assets/utils/images/originals/autism3.jpg';
import './logingStyle.scss'
// import bg3 from '../../assets/utils/images/originals/f3b.jpg';
import logo from '../../assets/utils/images/logoShamah.png'

//  import logo from '../../assets/utils/images/logoMots.png'
import {
    Row, Col, Form, FormGroup, Input,
    Label,
} from 'reactstrap'
import moment from 'moment-timezone'
// import { useBeforeunload } from 'react-beforeunload';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import Header from "../LandingPage/Header/Header";
import { setNewActionData } from "../../redux/Actions/new-action";
const Login = (props) => {

    // useBeforeunload(() => "You'll lose your data!");
    const dispatch = useDispatch();

    const history = useHistory();
    useEffect(() => {

        const tokenData = JSON.parse(localStorage.getItem("authDetails"));
        dispatch(setNewActionData('tabindex',1))
        if (tokenData) {
            history.push('/dashboard')
        }

        //  window.addEventListener("beforeunload", onUnload);

        // return () => {
        //  console.log("close in unmount")
        //window.removeEventListener("beforeunload", onUnload);

        // }
    }, []);

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true

    };
    const AuthData = useSelector(({ auth }) => auth)
    // console.log("auth", AuthData)
    // const [errors, setErrors] = useState({});
    const [authFields, setAuthFields] = useState({
        enterprise_id: "shamah",
        device_type:"web",
        timezone:moment.tz.guess()
    })
    const handleChange = e => {
        const { name, value } = e.target;

        setAuthFields({ ...authFields, [name]: value })
    }



    const [state, setState] = useState({
        activeTab: '1',
        showMore: true,
        transform: true,
        showInkBar: true,
        selectedTabKey: 0,
        transformWidth: 400,
    })
    const toggle = (tab) => {
        dispatch({ type: 'RESET_VALIDATION' })
        setAuthFields({})
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }
    const cb = () => {
        props.history.push('/dashboard')
    }

    // console.log("object")
    const formSubmit = (e) => {

        e.preventDefault()

        dispatch(authRequestEnterprice(authFields, cb))


        // setErrors({})
        // const errorz = {
        //     email: emailValidator(authFields.email),
        //     password: nameValidator(authFields.password)
        // }
        // if (!errorz.email && !errorz.password) {
        //dispatch(authRequest(authFields, cb))
        // } else {
        //     setErrors(errorz)
        // }

    };
    // console.log(authFields)


    return (
        <Fragment>
            {/* <Header/> */}
            <>
                {/* {twakto()} */}
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="4" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                        <div className="slide-img-bg"
                                            style={{
                                                backgroundImage: 'url(' + bg1 + ')'
                                            }} />
                                        {/* <div className="slider-content">
                                        <h3>Perfect Balance</h3>
                                        <p>
                                            ArchitectUI is like a dream. Some think it's too good to be true! Extensive collection of unified React Boostrap Components and Elements.
                                            </p>
                                    </div> */}
                                    </div>
                                    {/* <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />
                                    <div className="slider-content">
                                        <h3>Scalable, Modular, Consistent</h3>
                                        <p>
                                            Easily exclude the components you don't require. Lightweight, consistent
                                            Bootstrap based styles across all elements and components
                                            </p>
                                    </div>
                                </div> */}
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                        <div className="slide-img-bg opacity-6"
                                            style={{
                                                backgroundImage: 'url(' + bg2 + ')'
                                            }} />
                                        {/* <div className="slider-content">
                                        <h3>Complex, but lightweight</h3>
                                        <p>
                                            We've included a lot of components that cover almost all use cases for
                                            any type of application.
                                            </p>
                                    </div> */}
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                        <div className="slide-img-bg opacity-6"
                                            style={{
                                                backgroundImage: 'url(' + bg3 + ')'
                                            }} />
                                        {/* <div className="slider-content">
                                        <h3>Complex, but lightweight</h3>
                                        <p>
                                            We've included a lot of components that cover almost all use cases for
                                            any type of application.
                                            </p>
                                    </div> */}
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">

                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">

                                {/* <div className="float-right">
                                <Link to="/home"><i class="fa fa-home" aria-hidden="true"></i></Link>
                            </div> */}
                                {/* <div className="app-logo" /> */}
                                <Row>
                                    <Col lg={12} sm={12}>
                                        <Link to="/">
                                            <img src={logo} width="120px" height="180px" style={{ paddingBottom: '20px' }} />
                                        </Link>

                                    </Col>
                                    <Col lg="8" md="10" sm="12" >
                                        <h4 className="mb-0">
                                            <div>Welcome back,</div>
                                            <span>Please sign in to your account.</span>
                                        </h4>

                                    </Col>
                                    {/* <img src={logo} width="200px" height="100px" /> */}
                                </Row>
                                {/* <Row className="divider" /> */}

                                <div>
                                    <Form onSubmit={(e) => formSubmit(e)}>
                                        <Row form>

                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">Email</Label>
                                                    <Input
                                                        type="email"
                                                        name="enterprise_email"
                                                        id="exampleEmail"
                                                        placeholder="Email here..."
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="examplePassword">Password</Label>
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        id="examplePassword"
                                                        placeholder="Password here..."
                                                        required
                                                        onChange={handleChange}

                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>


                                        {AuthData.currentUser.result === false ?
                                            <FormGroup >
                                                <Label style={{ color: 'red' }} >Invalid Credentials</Label>
                                            </FormGroup>
                                            : null}
                                        <FormGroup check>
                                            <Input type="checkbox" name="check" id="exampleCheck" />
                                            <Label for="exampleCheck" check>Keep me logged in</Label>
                                        </FormGroup>
                                        <Row className="divider" />
                                        <Row>
                                            <Col md={6}>
                                                {/* <h6 className="mt-3" style={{ fontSize: '13px', fontWeight: '500', marginTop: '11px' }}>
                                                    Try free for 30 days{' '}
                                                    <Link to={`/teacher-register`}>
                                                        <a
                                                            //onClick={(e) => e.preventDefault()} 
                                                            className="text-primary">Sign up now</a>

                                                    </Link>
                                                </h6> */}
                                                 <Link to="/forgot"
                                                    className="btn-lg btn btn-link">Recover
                                                    Password
                                                </Link>{' '}{' '}
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-flex align-items-center jms-float">
                                                    <div className="ml-auto">
                                                       
                                                        <LaddaButton className="mb-2 mr-2 btn btn-warning"
                                                            loading={AuthData.enterpriseLoader}
                                                            //  onClick={() => this.toggle('expZoomIn')}
                                                            type="submit"
                                                            data-style={ZOOM_IN}

                                                        >
                                                            Login
                                            </LaddaButton>




                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>


                                    </Form>
                                </div>


                                {/* <div>
                                    <Form onSubmit={formSubmit}>
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">Email</Label>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        id="exampleEmail"
                                                        placeholder="Email here..."
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="examplePassword">Password</Label>
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        id="examplePassword"
                                                        placeholder="Password here..."
                                                        required
                                                        onChange={handleChange}

                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        {AuthData.currentUser.result === false ?
                                            <FormGroup >
                                                <Label style={{ color: 'red' }} >Invalid Credentials</Label>
                                            </FormGroup>
                                            : null}
                                        <FormGroup check>
                                            <Input type="checkbox" name="check" id="exampleCheck" />
                                            <Label for="exampleCheck" check>Keep me logged in</Label>
                                        </FormGroup>
                                        <Row className="divider" />
                                        <Row>
                                            <Col md={6}>
                                                <h6 className="mt-3" style={{ fontSize: '13px', fontWeight: '500', marginTop: '11px' }}>
                                                    Try free for 30 days{' '}
                                                    <Link to={`/teacher-register`}>
                                                        <a
                                                            //onClick={(e) => e.preventDefault()} 
                                                            className="text-primary">Sign up now</a>

                                                    </Link>
                                                </h6>
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-flex align-items-center jms-float">
                                                    <div className="ml-auto">
                                                        <a href="/#/forgot"
                                                            className="btn-lg btn btn-link">Recover
                                                    Password</a>{' '}{' '}



                                                        <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                            loading={AuthData.loader}
                                                            //  onClick={() => this.toggle('expZoomIn')}
                                                            type="submit"
                                                            data-style={ZOOM_IN}

                                                        >
                                                            Login
                                            </LaddaButton>




                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>


                                    </Form>
                                </div> */}
                            </Col>

                        </Col>
                    </Row>
                </div>


            </ >
        </Fragment>
    );

}
export default Login;
