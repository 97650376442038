import axios from 'axios'
import { development } from '../../config.json'
import Swal from 'sweetalert2'


export const listRecordings = (token, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'GET_RECORDING' })
    axios.get(`${development.baseUrl}getMyRecordings`, { headers: head })
        .then(res => {
            if (res.data.result) {
                // console.log("recordd", res.data.recordings);
                dispatch({ type: 'LIST_RECORDINGS', payload: res.data.recordings })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'RECORD_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        
                        window.location.reload()
                    }

                }

            }
        })
}

export const downloadRecording = (token, session_id, cb) =>
    (dispatch) => {
        const data = {
            headers: { 'Authorization': `Bearer ${token}` },
        }
        dispatch({ type: 'POPULATE_DOWNLOAD_REQUEST' })
        axios.post(`${development.baseUrl}downloadMyRecordings`, { session_id }, data)

            .then(res => {
                // var json = JSON.stringify(res.data);
                var blob = new Blob([res.data], { type: "octet/stream" });
                var url = window.URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.download = 'test.mp4';
                link.href = url;
                link.click();
                // window.location.assign(url);
                // console.log("response==",url);

            })
            .catch(err => {
                dispatch({ type: 'POPULATE_DOWNLOAD_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }

                }
            })

    }

