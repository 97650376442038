import axios from 'axios'
import { development } from '../../config.json'
import Swal from 'sweetalert2';

export const getGradeList = (id, category_id) =>
    (dispatch) => {
        

        const data = {
            degree_id: id,
            category_id: category_id,
            enterprise:true
        }
        dispatch({ type: 'GRADE_REQUEST' })
        axios.post(`${development.baseUrl}filterGrade`, data)
            .then(res => {
                if (typeof id === 'undefined' || id == null)
                    dispatch({ type: 'GRADE_SUCCESS_SCHOOL', payload: res.data })
                else
                    dispatch({ type: 'GRADE_SUCCESS_COLLEGE', payload: res.data })

               
            })
            .catch(err => {
                dispatch({ type: 'GRADE_FAILED', payload: err })
            })
    }

export const getGradeListLifeLong = (id, category_id) =>
    (dispatch) => {

        const data = {
            degree_id: id,
            category_id: category_id
        }
        dispatch({ type: 'GRADE_SUCCESS_LIFE_LONG_REQUEST' })
        axios.post(`${development.baseUrl}filterGrade`, data)
            .then(res => {


                dispatch({ type: 'GRADE_SUCCESS_LIFE_LONG_SUCCESS', payload: res.data })

            })
            .catch(err => {
                dispatch({ type: 'GRADE_SUCCESS_LIFE_LONG_FAILED', payload: err })
            })
    }


export const populateDegree = () =>

    (dispatch) => {


        dispatch({ type: 'DEGREE_REQUEST' })
        axios.get(`${development.baseUrl}populateDegree`)
            .then(res => {
                dispatch({ type: 'DEGREE_SUCCESS', payload: res.data })
            })
            .catch(err => {
                dispatch({ type: 'DEGREE_FAILED', payload: err })
            })
    }
export const requestOtp = (mobile) =>

    (dispatch) => {
        const data = {
            mobile_number: mobile
        }

        dispatch({ type: 'OTP_REQUEST' })
        axios.post(`${development.baseUrl}requestOtp`, data)

            .then(res => {
                dispatch({ type: 'OTP_RECEIVED', payload: res.data })
                
            })
            .catch(err => {
                dispatch({ type: 'OTP_NOT_RECIEVED', payload: err })
            })
    }
export const verifyEMAIL = (mail) =>

    (dispatch) => {

        const data = {
            email: mail
        }
        dispatch({ type: 'EMAIL_VALIDATION_REQUEST' })
        axios.post(`${development.baseUrl}validateUserEmail`, data)

            .then(res => {
                dispatch({ type: 'EMAIL_VALIDATION_SUCCESS', payload: res.data })
                
            })
            .catch(err => {
                dispatch({ type: 'EMAIL_VALIDATION_FAILED', payload: err })
            })
    }




export const validatingOtp = (mobile, otp, cb) =>

    (dispatch) => {
        const data = {
            mobile: mobile,
            otp: otp
        }

        dispatch({ type: 'OTP_VALIDATE_REQUEST' })
        axios.post(`${development.baseUrl}validateOtp`, data)
            .then(res => {
                dispatch({ type: 'OTP_VALIDATION_SUCCESS', payload: res.data })

                if (res.data.result) {

                    dispatch({ type: 'OTP_VALIDATION_SUCCESS', payload: res.data })

                    cb()
                }
                else {
                    dispatch({ type: 'OTP_VALIDATION_FAILED' })
                }
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'OTP_VALIDATION_FAILED', payload: err })
            })
    }

export const mobileAvailability = (num, cb) =>

    (dispatch) => {
        const data = {
            mobile: num
        }

        dispatch({ type: 'STUDENT_NUMBER_VALIDATE_REQUEST' })
        axios.post(`${development.baseUrl}validateParentMobile`, data)
            .then(res => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_SUCCESS', payload: res.data })
                if (res.data.result) {
                    cb()
                }
            })
            .catch(err => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_FAILED', payload: err })
            })
    }

export const registerStudent = (data, cb) => (dispatch) => {
    dispatch({ type: 'REGISTER_STUDENT_REQUEST' })
    axios.post(`${development.baseUrl}register`, data)
        .then(res => {
            dispatch({ type: 'REGISTER_STUDENT_SUCCESS' })
            // const tok = {
            //     role: res.data.role,
            //     token: res.data.api_token,
            //     name: res.data.name
            // }
            const tok = {
                result: res.data.result,
                role: res.data.role,
                token: res.data.api_token,
                name: res.data.name,
                emailVerified: res.data.email_verified,
                privilege: res.data.privilege,
                enterpriseUser: res.data.enterprise_user,
                plan_id: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_id : '',
                plan_name: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_name : '',
                class_duration: res.data.plan_details.length > 0 ? res.data.plan_details[0].classe_duration : '',
                number_of_student: res.data.plan_details.length > 0 ? res.data.plan_details[0].number_of_student : '',
                allowed_classes: res.data.plan_details.length > 0 ? res.data.plan_details[0].allowed_classes : ''
            }
            localStorage.setItem('authDetails', JSON.stringify(tok));
            if (res.data.result === true) {
                cb();
            }
            
        })
        .catch(err => {
            dispatch({ type: 'REGISTER_STUDENT_FAILED', payload: err })
        })
}

export const validateTeacherMobile = (num, cb) => (dispatch) => {
    const data = {
        mobile: num
    }
    dispatch({ type: 'TEACHER_NUMBER_VALIDATE_REQUEST' })
    axios.post(`${development.baseUrl}validateTeacherMobile`, data)
        .then(res => {
            dispatch({ type: 'TEACHER_NUMBER_VALIDATE_SUCCESS', payload: res.data })
            
            if (res.data.result) {
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'TEACHER_NUMBER_VALIDATE_FAILED', payload: err })
        })
}
export const validateReferralCode = (ref, cb) => (dispatch) => {
    const data = {
        teacher_referral_code: ref
    }
    dispatch({ type: 'VALIDATE_REFFERAL_REQUEST' })
    axios.post(`${development.baseUrl}validateReferralCode`, data)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VALIDATE_REFFERAL_SUCCESS', payload: res.data })
                cb()
            }
            else {
                dispatch({ type: 'VALIDATE_REFFERAL_SUCCESS', payload: res.data })

            }

            
        })
        .catch(err => {
            dispatch({ type: 'VALIDATE_REFFERAL_FAILED' })
        })
}

export const enquirySubmission = (data, cb) => (dispatch) => {
    dispatch({ type: 'REGISTER_ENQUIRY' })
    axios.post(`${development.baseUrl}sendEnquiryMail`, data)
        .then(res => {
            dispatch({ type: 'ENQUIRY_SUCCESS', payload: res.data })
            if (res.data.result === true) {
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: res.data.message,

                })
                cb();
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: '',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'REGISTER_ENQUIRY_FAILED', payload: err })
        })
}

export const subscribeSite = (email, cb) => (dispatch) => {
    
    dispatch({ type: 'REGISTER_SUBSCRIPTION' })
    axios.post(`${development.baseUrl}addSubscription`, email)
        .then(res => {

            // if (res.data.result === true) {
            dispatch({ type: 'SUBSCRIPTION_SUCCESS', payload: res.data.result })
            // }
            // else{
            // dispatch({ type: 'SUBSCRIPTION_SUCCESS', payload: res.data.result })
            // }
        })
        .catch(err => {
            dispatch({ type: 'SUBSCRIPTION_FAILED', payload: err })
        })
}
