import React, { Fragment } from 'react'

import image1 from "./images/admin_scr/image1.png"
// import image455 from "./images/therapist_scr/image455.png"
import image456 from "./images/therapist_scr/image456.png"
import image457 from "./images/therapist_scr/image457.png"
import image458 from "./images/therapist_scr/image458.png"

import image459 from "./images/therapist_scr/image459.png"
import image460 from "./images/therapist_scr/image460.png"
import image462 from "./images/therapist_scr/image462.png"
import image463 from "./images/therapist_scr/image463.png"
import image465 from "./images/therapist_scr/image465.png"
import image466 from "./images/therapist_scr/image466.png"
import image467 from "./images/therapist_scr/image467.png"

import image468 from "./images/therapist_scr/image468.png"
import image469 from "./images/therapist_scr/image469.png"

// import image444 from "./images/admin_scr/image444.png"

import image471 from "./images/therapist_scr/image471.png"
import image472 from "./images/therapist_scr/image472.png"
import image473 from "./images/therapist_scr/image473.png"
import image475 from "./images/therapist_scr/image475.png"
import image476 from "./images/therapist_scr/image476.png"
import image495 from "./images/therapist_scr/image495.png"
import image496 from "./images/therapist_scr/image496.png"

import image446 from "./images/admin_scr/image446.png"
import image447 from "./images/admin_scr/image447.png"
import image449 from "./images/admin_scr/image449.png"
import image451 from "./images/admin_scr/image451.png"
import image452 from "./images/admin_scr/image452.png"
import image492 from "./images/admin_scr/image492.png"
import image478 from "./images/admin_scr/image478.png"
import image450 from "./images/admin_scr/image450.png"

import image499 from "./images/therapist_scr/image499.png"
import image497 from "./images/therapist_scr/image497.png"
import image500 from "./images/therapist_scr/image500.png"
import image501 from "./images/therapist_scr/image501.png"

function Therapist({ admin_val }) {
    return (
        <Fragment>
            {
                admin_val === 0 ?
                    <article>
                        <h3><b>Introduction to the Shamah</b></h3>
                        <p>
                            Shamah autism is a complete educational platform for clients, educators and academic professionals both in and out of the session.
                            This site provides all the functionality of an educational platform as well as enhanced and additional features such as:
                        </p>
                        <ul>
                            <li>Live sessions </li>
                            <li>Pre-recorded Video sessions</li>
                            <li>Live sessions recording</li>
                            <li>Slide sharing</li>
                            <li>Automatic Attendance records</li>
                            <li>Quizzes</li>
                            <li>Creation of multiple users</li>
                            <li>Chat </li>
                        </ul>

                        <p>
                            This manual explains  how to use this Site/Application in order to get productive sessions. For all further questions, please get in touch with the Admin.
                            <br /><br />
                            Brows <a href='https://app.shamah-autism.com.sa/' target="_blank">https://app.shamah-autism.com.sa/</a> or Install Shamah application in
                        </p>
                        <ul>
                            <li>For Ios <a href='https://apps.apple.com/in/app/shamah/id1573574939' target="_blank">https://apps.apple.com/in/app/shamah/id1573574939</a></li>
                            <li>For Android <a href='https://play.google.com/store/apps/details?id=com.shamautism' target="_blank">https://play.google.com/store/apps/details?id=com.shamautism</a></li>
                        </ul>

                        <p>
                            By visiting <a href='https://app.shamah-autism.com.sa/' target="_blank">https://app.shamah-autism.com.sa/</a> Therapist can reach in to the Shamah website, Type the credentials and login
                        </p>

                        <div class='image_div'>
                            <img src={image1} alt='' width='100%' height='100%' />
                        </div>

                    </article>
                    :
                    admin_val === 1 ?
                        <article>

                            <h5><b>Therapist Menu</b></h5>
                            <ol>
                                <li>Home/Dashboard</li>
                                <li>Sessions</li>
                                <li>Quizz</li>
                                <li>Attendance</li>
                                <li>Recording</li>
                                <li>Video session</li>
                                <li>Chat</li>
                            </ol>

                            {/* <div class='image_div'>
                                <img src={image455} alt='' width='100%' height='100%' />
                            </div> */}

                            <h5><b>Countdown of Next Session</b></h5>
                            <p>
                                Here the countdown of the <strong>Next Session</strong> assigned for the Therapist will be displayed. Therapists can not <strong>Start/Join</strong> the session until the Timer gets Zero. Once the therapist starts the session, Clients can join. If it is not started it shows “Host has not started the session yet”. If no sessions are assigned,You can contact the Shamah Admin/Staff.
                            </p>

                            <h5 class='text-info'>How can a Therapist start the Session ??</h5>
                            <p>
                                You can start the Session by clicking the <strong>Start session</strong> button.
                            </p>

                            <div class='image_div'>
                                <img src={image495} alt='' width='100%' height='100%' />
                            </div>

                            <p>
                                You will get a popup message “Allow clients to join the session” then click the <strong>Start</strong> button.Now the session will be online.
                            </p>

                            <div class='image_div'>
                                <img src={image496} alt='' width='100%' height='100%' />
                            </div>


                            <p>Now you are the moderator of the session, you can see the clients entered into the session. </p>

                            <div class='image_div'>
                                <img src={image497} alt='' width='100%' height='100%' />
                            </div>

                            <ul>
                                <li>Session scheduling & automatic alerts for the clients</li>
                                <li>Live chat on live session</li>
                                <li>Automatic and dynamic attendance tracking </li>
                                <li>Mic mute & Unmute option</li>
                                <li>Video enable/Disable</li>
                                <li>External PPT, PDF, application or any screen sharing from within the platform</li>
                                <li>Toggle tile view- Clients are displayed in Grid view</li>
                                <li>Settings icon:
                                    <ol>
                                        <li>View full screen mode</li>
                                        <li>Start recording/stop recording</li>
                                        <li>Mute everyone</li>
                                    </ol>
                                </li>
                                <li>Hangup button for session ending</li>
                                <li>Settings icon in Single client tile:


                                    <ol>
                                        <li>Mute/Unmute option of specific client</li>
                                        <li>Mute everyone else</li>
                                        <li>Send private message option</li>

                                        <div class='image_div'>
                                            <img src={image499} alt='' width='100%' height='100%' />
                                        </div>

                                    </ol>
                                </li>

                            </ul>

                            <h5><b>Upcoming Sessions</b></h5>
                            <p>
                                These are the sessions assigned for you on each day. You can check the session’s details and clients group here.
                            </p>

                            <h5><b>Quiz Notification</b></h5>
                            <p>
                                Here you can get the notification of quizzes submitted by the clients.
                            </p>

                            <h5><b>Timeline activities</b></h5>
                            <p>
                                This timeline indicates the activities you have conducted connected to the live sessions & ondemand sessions
                            </p>

                            <div class='image_div'>
                                <img src={image500} alt='' width='100%' height='100%' />
                            </div>


                        </article>
                        :
                        admin_val === 2 ?
                            <article>
                                <h5><b>Sessions</b></h5>
                                <p>
                                    Here the Sessions and special sessions assigned for you will be displayed. You can check each session’s details, time schedules, and clients assigned to it from here.
                                </p>

                                <div class='image_div'>
                                    <img src={image456} alt='' width='100%' height='100%' />
                                </div>
                                <div class='image_div'>
                                    <img src={image457} alt='' width='100%' height='100%' />
                                </div>
                                <div class='image_div'>
                                    <img src={image458} alt='' width='100%' height='100%' />
                                </div>

                            </article>
                            :
                            admin_val === 3 ?
                                <article>
                                    <h5><b>Quizzes</b></h5>
                                    <p>
                                        Here is the option for creating quizzes for the Clients. You can add multiple quizzes by entering the following details
                                    </p>

                                    <div class='image_div'>
                                        <img src={image459} alt='' width='100%' height='100%' />
                                    </div>


                                    <h5 class='text-info'>What are the steps to Add & Evaluate Quizzes ??</h5>

                                    <ul>
                                        <li>Give Quiz title</li>
                                        <li>Select Session</li>
                                        <li>Choose Last date & time</li>
                                        <li>Add Description</li>
                                        <li>Add Reference files for the clients</li>
                                        <li>Add Grade/Mark</li>
                                        <li>Create new Quiz</li>
                                    </ul>

                                    <p>
                                        Created quizzes are listed in the table below. </p>

                                    <div class='image_div'>
                                        <img src={image460} alt='' width='100%' height='100%' />
                                    </div>

                                    <p>In each Quizz you can, </p>
                                    <ul>
                                        <li>View Quizz details
                                            <ol>
                                                <li>Here you can view the Quiz details that you have entered</li>

                                                <div class='image_div'>
                                                    <img src={image462} alt='' width='100%' height='100%' />
                                                </div>
                                                <div class='image_div'>
                                                    <img src={image463} alt='' width='100%' height='100%' />
                                                </div>

                                                <li>List of clients - Each client’s submission report is displayed here. By clicking the details you can download the Quiz report and Add mark/Grade with reference.</li>

                                                <div class='image_div'>
                                                    <img src={image465} alt='' width='100%' height='100%' />
                                                </div>
                                                <div class='image_div'>
                                                    <img src={image466} alt='' width='100%' height='100%' />
                                                </div>
                                                <div class='image_div'>
                                                    <img src={image467} alt='' width='100%' height='100%' />
                                                </div>

                                                <li>Added Quiz result will be displayed in Client account</li>
                                            </ol>
                                        </li>
                                        <li>Download option for reference files</li>
                                        <li>Edit File</li>
                                        <li>Delete Quiz</li>
                                    </ul>


                                </article>
                                :
                                admin_val === 4 ?
                                    <article>
                                        <h5><b>Attendance</b></h5>
                                        <ul>
                                            <li>The automatic attendance reports are displayed here.</li>
                                            <li> You can filter the date ranges and the session you want to check the attendance.</li>
                                            <li>If any sessions are conducted the session detail will be displayed here</li>

                                            <div class='image_div'>
                                                <img src={image468} alt='' width='100%' height='100%' />
                                            </div>
                                            <div class='image_div'>
                                                <img src={image469} alt='' width='100%' height='100%' />
                                            </div>

                                            <li>You can select the listed sessions and the attendance report regarding Present/Absent clients with time duration will displayed</li>
                                        </ul>
                                    </article>
                                    :
                                    admin_val === 5 ?
                                        <article>
                                            <h5><b>Recording</b></h5>
                                            <p>
                                                The recorded live sessions are listed here. You can download the video as an mp4,Play and Copy the video's link for sharing. The recorded videos can be used for the Prerecorded video sessions or you can share the video/link to the Absent clients in the session.
                                            </p>
                                            {/* <div class='image_div'>
                                                <img src={image444} alt='' width='100%' height='100%' />
                                            </div> */}
                                            <div class='image_div'>
                                                <img src={image501} alt='' width='100%' height='100%' />
                                            </div>
                                        </article>
                                        :
                                        admin_val === 6 ?
                                            <article>
                                                <h5><b>Chat</b></h5>
                                                <p>
                                                    Therapist can start a conversation with Clients, Therapists , Admin and Staff. The Chat icon in the dashboard indicates the count of <strong>New messages notifications.</strong>
                                                </p>
                                                <div class='image_div'>
                                                    <img src={image478} alt='' width='100%' height='100%' />
                                                </div>
                                                <ul>
                                                    <li>
                                                        New Chat - You can start a new conversation by clicking the <strong>Add button</strong> from the top left corner. You can search the participants which you want to added to the group.
                                                    </li>


                                                    <div className='image_combined'>
                                                        <div className='image_combined1'>
                                                            <img src={image492} alt='' width='100%' height='373px' style={{ borderRadius: '10px', border: '.5px solid rgb(97, 95, 95)' }} />
                                                        </div>
                                                        <div className='image_combined2'>
                                                            <img src={image446} alt='' width='100%' height='373px' style={{ borderRadius: '10px', border: '.5px solid rgb(97, 95, 95)' }} />

                                                        </div>
                                                    </div>

                                                    <li>
                                                        Group Chat - By giving a Group name you can create Group with multiple users<br />

                                                        <div class='image_div'>
                                                            <img src={image447} alt='' width='100%' height='100%' />
                                                        </div>

                                                        By clicking the View details icon in the chat screen of group, you can view the user's details.<br />

                                                        <div class='image_div'>
                                                            <img src={image449} alt='' width='100%' height='100%' />
                                                        </div>

                                                        Users have the feature of Sharing Documents, Images, Videos, and audios from their device.

                                                        <div class='image_div'>
                                                            <img src={image450} alt='' width='100%' height='100%' />
                                                        </div>

                                                    </li>
                                                </ul>
                                            </article>
                                            :
                                            admin_val === 8 ?
                                                <article>
                                                    <h5><b>Video Sessions</b></h5>
                                                    <p>
                                                        These are the Pre-recorded / On Demand sessions. There are two procedures such as
                                                    </p>
                                                    <ol style={{ color: '#7a2121' }}>
                                                        <li>Playlist creation</li>
                                                        <li>Create session</li>
                                                    </ol>

                                                    <div style={{ marginLeft: '20px' }}>
                                                        <span style={{ fontWeight: '600' }}>1. Playlist creation</span>
                                                        <ul>
                                                            {/* <li>Multiple videos can be added to the playlist</li> */}
                                                            <li>Create multiple Playlist with name under the selected Clinic from the option list</li>
                                                            <li>The Playlists can be Edited/Deleted</li>

                                                            <div class='image_div'>
                                                                <img src={image471} alt='' width='100%' height='100%' />
                                                            </div>

                                                            <li>Click the Playlist and there is an option to upload the videos or choose video another playlist (If you have multiple playlists)</li>

                                                            <div class='image_div'>
                                                                <img src={image472} alt='' width='100%' height='100%' />
                                                            </div>

                                                            <div class='image_div'>
                                                                <img src={image473} alt='' width='100%' height='100%' />
                                                            </div>
                                                        </ul>
                                                        <span style={{ fontWeight: '600' }}>2. Create session</span>
                                                        <ul>
                                                            <li>Enter the name of video session & select a clinic</li>
                                                            <li>Created playlists are displayed below.</li>

                                                            <div class='image_div'>
                                                                <img src={image475} alt='' width='100%' height='100%' />
                                                            </div>

                                                            <li>You can tick the Check box and set a From-To date & Time for the video after completing the processing time</li>
                                                            <li>You can give order to the videos</li>
                                                            <li>Enter the <strong>Create Video session</strong> button</li>
                                                            <li>The video sessions are listed below, there you can Assign/Unassign Individual Clients & Group of clients</li>

                                                            <div class='image_div'>
                                                                <img src={image476} alt='' width='100%' height='100%' />
                                                            </div>

                                                            <li>You can Update the session for adding or removing videos </li>
                                                            <li>You can play the videos here</li>
                                                            <li>Enable/Disable the session <br />
                                                                Admin and Therapist can <strong>enable</strong> or <strong>disable</strong> the video sessions. It will disappear from the client account while disabling.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </article>
                                                :
                                                <article>
                                                    <h5><b>Change password</b></h5>
                                                    <p>
                                                        Users can reset their password here.
                                                    </p>
                                                    <div class='image_div'>
                                                        <img src={image451} alt='' width='100%' height='100%' />
                                                    </div>

                                                    {/* <p class='text-danger'>from admin image folder image451</p> */}

                                                    <h5><b>My Account</b></h5>
                                                    <p>
                                                    In Settings you can view the User account information such as Username, Email ID and Contact number.
                                                    </p>
                                                    <div class='image_div'>
                                                        <img src={image452} alt='' width='100%' height='100%' />
                                                    </div>

                                                </article>
            }

        </Fragment>
    )
}

export default Therapist
