import React from 'react'
import { Button, CardHeader, CardFooter, Table, Card } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
// import { useHistory, Link } from 'react-router-dom'

// import {
//     faArchive,
//     faAngleUp,
//     faAngleDown,
//     faCalendarAlt,
//     faCloudSunRain,
//     faEllipsisH,
//     faCheck,
//     faTrashAlt,
//     faBusinessTime,
//     faCog
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'react-loaders';

const index = (props) => {

    const { List_of_student, getDetails } = props
    return (


        <LoadingOverlay tag="div" active={false}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 0.5
                })
            }}
            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>

            {
                <Card className="main-card mb-3">
                    <CardHeader>
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            List of clients
                    </div>
                        <div className="btn-actions-pane-right">

                        </div>
                    </CardHeader>
                    <Table responsive borderless hover className="align-middle text-truncate mb-0">
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center"> Name</th>
                                <th className="text-center">Contact</th>
                                <th className="text-center">Mark</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                List_of_student.map((list, index) => {
                                    return (
                                        < tr >
                                            <td className="text-center text-muted" style={{ width: '80px' }}>{index + 1}</td>
                                            <td className="text-center" style={list.class_type === "special" ? { width: '80px', color: 'red' } : { width: '80px' }}>
                                                <strong>  {`${list.first_name}   ${list.last_name}`}</strong>
                                            </td>
                                            <td className="text-center">
                                                {list.mobile}
                                            </td>
                                            <td className="text-center">

                                                {list.mark === null ?
                                                    <div className='badge badge-pill badge-warning'>
                                                        Not evaluated
                                                </div> : <><strong>{list.mark}</strong><span style={{ color: 'gray', fontSize: '11px', fontWeight: '700' }}>{list.grading_type === "marks" ? `/${list.total}` : null}</span></>
                                                }
                                            </td>
                                            <td className="text-center">
                                                <div className={list.submitted ? 'badge badge-pill badge-success'
                                                    : 'badge badge-pill badge-danger'}>
                                                    {list.submitted ? 'Submitted' : 'Not submitted'}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <Button className="mb-2 mr-2 btn-icon btn-icon-only btn-pill" outline
                                                    onClick={() => getDetails(list.student_id, list.submitted, list.assesment_id)}

                                                    color="primary">

                                                    {/* <i className="pe-7s-clock btn-icon-wrapper"> </i> */}
                                                    Details
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </Table>
                    <CardFooter className="d-block p-4 text-center">
                        {/* <Link to="/dashboard/show-class">
                            <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                                <span className="mr-2 opacity-7">
                                    <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                                </span>
                                <span className="mr-1">
                                    View Full TimeTable
                            </span>
                            </Button>
                        </Link> */}
                    </CardFooter>
                </Card>

            }

        </LoadingOverlay>
    )
}

export default index
