import React from 'react'
import MaterialTable from 'material-table'
import './tabStyle.scss'
const AttendanceDataTable = props => {

    // import MuiTableCell from "@material-ui/core/TableCell";

    // const TableCell = withStyles({
    //     root: {
    //         borderBottom: "none"
    //     }
    // })(MuiTableCell);

    return (
        // 


        <MaterialTable
            // components={{
            //     Toolbar: props => (
            //         <div style={{ backgroundColor: '#e8eaf5' }}>
            //             'dfdfd'
            //         </div>
            //     )
            // }}
            isLoading={props.loader}
            title="Attendance"
            columns={props.columns}
            data={props.data}
            onRowClick={
                props.actions === 'attandance' && props.tab != 2 && props.role !== 'student' ?
                    ((evt, rowData) => props.handleSelectedRow(rowData.class_id, rowData.session))
                    : null

            }
            options={{
                search: true,
                sorting: true,
                // headerStyle: {
                //     backgroundColor: '#01579b',
                //     color: '#FFF'
                // },
                actionsColumnIndex: -1,
                // headerStyle: {
                //     backgroundColor: '#01579b',
                //     color: '#FFF'
                // }
                // fixedColumns: {
                //     left: 2,
                //     right: 2
                // }
                rowStyle: {
                    borderTop: '0px',
                    borderBottum: '0px'
                }

            }}
            // editable={{
            //     onRowUpdate: (newData) =>
            //         new Promise((resolve) => {
            //             props.onEdit(newData)
            //             resolve()
            //         }
            //         )
            // }}
            actions={
                props.actions ?
                    props.actions === "dashboard" ? [
                        {
                            icon: 'queue_play_next',
                            tooltip: props.role ? props.role === 'student' ? 'Join to Class' : props.role === 'teacher' ? 'Start Class' : null : null,
                            onClick: (event, rowData) => props.joinClass(rowData.weekly_class_id, rowData.class_id)
                        },
                    ]
                        :

                        props.actions === "assign_student" ? [
                            {
                                icon: 'schedule',
                                tooltip: 'Time Shedule',
                                onClick: (event, rowData) => props.hadleOpenModal(rowData.class_id)
                            },
                            {
                                icon: 'supervisor_account',
                                tooltip: 'View Students',
                                onClick: (event, rowData) => props.handleOpenStudentListModal(rowData.class_id, rowData.grade_id)
                            },
                            {
                                icon: 'add',
                                tooltip: 'Assign Students',
                                onClick: (event, rowData) => props.hadleOpenStudentModal(rowData.class_id, rowData.grade_id)
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit Class',
                                onClick: (event, rowData) => props.handleOpenEditModal(rowData)
                            },

                        ] :
                            props.actions === "student_class" ? [
                                {
                                    icon: 'schedule',
                                    tooltip: 'Time Shedule',
                                    onClick: (event, rowData) => props.hadleOpenModal(rowData.class_id)
                                },

                            ] :


                                null
                    : null
            }

        />
    )
}

export default AttendanceDataTable
AttendanceDataTable.defaultProps = {
    tab: null
}