import React, { useState } from 'react'
// import Tabs, { TabPane } from 'rc-tabs';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import SchoolSubject from './school'
import CollegeSubject from './college'
import LifeLongSubject from './lifeLong'

import classnames from 'classnames';

import {
    // Row, Col, Form, FormGroup, Input, InputGroupAddon,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody,
    CardHeader,
    // Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container
} from 'reactstrap'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PageTitle from '../HeaderTitle/PageTitle'
const Index = () => {




    const [state, setState] = useState({
        activeTab: '1',
        showMore: true,
        transform: true,
        showInkBar: true,

        selectedTabKey: 0,
        transformWidth: 400,
    })
    const toggle = (tab) => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }
    return (
        <>
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <PageTitle
                    style={{ display: 'none' }}
                    heading="Create Subject"
                    subheading="Create your own subject under the grade or course"
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                />

                {/* <Tabs
                    defaultActiveKey="1"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab='School' key="1"><CreateSchoolClass /></TabPane>
                    <TabPane tab='College' key="2"><CreateCollegeClass /></TabPane>
                </Tabs> */}



                <Card tabs={true} className="mb-3">
                    <CardHeader>
                        <Nav justified>
                            <NavItem>
                                <NavLink href="#"
                                    className={classnames({ active: state.activeTab === '1' })}
                                    onClick={() => {
                                        toggle('1');
                                    }}
                                >
                                    School
                                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                    className={classnames({ active: state.activeTab === '2' })}
                                    onClick={() => {
                                        toggle('2');
                                    }}
                                >
                                    College/University
                                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                    className={classnames({ active: state.activeTab === '3' })}
                                    onClick={() => {
                                        toggle('3');
                                    }}
                                >
                                    Life Long Course
                                                </NavLink>
                            </NavItem>

                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={state.activeTab}>
                            <TabPane tabId="1">
                                <SchoolSubject />
                            </TabPane>
                            <TabPane tabId="2">
                                <CollegeSubject />
                            </TabPane>
                            <TabPane tabId="3">
                                <LifeLongSubject />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>




            </CSSTransitionGroup>
        </>


    )
}

export default Index
