import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button } from "reactstrap";
import Countdown from "../../common/Timer/countdown";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import "./style.scss";
function SwitchQuestions(props) {
  const aStore = useSelector(({ answers }) => answers);

  useEffect(() => {
    // setTimeout(function () { setIsTimeToStart(true) }, 1000);
    // var element = document.getElementById("question55");
    // element ? element.scrollIntoView() : console.log("null");
    // element ? (element.scrollTop = element.scrollHeight) : console.log("null");
    // var element = document.getElementById("switchQuestion");
    // var element2 = document.getElementById("question55");
    // var target = document.getElementById("switchQuestion");
    // if (element && element2 && target) {
    //   document
    //     .getElementById("question66")
    //     .scrollIntoView({ behavior: "smooth" });
    //   //= document.getElementById("question55").offsetTop;
    //   //  var target = document.getElementById("question55");
    //   // target.parentNode.scrollTop = target.offsetTop;
    //   console.log("parent", target.parentNode);
    // }
  }, []);

  const {
    setCurrentQuestion,
    questionNumber,
    setQuestionNumber,
    questions,
  } = props;
  const examStore = useSelector(({ exam }) => exam);
  let a = 0;
  const history = useHistory();

  const questionChooser = (data) => {
    let question_id = data.Question_id;
    // alert(question_id)
    console.log("data from choose", data);
    console.log(document.getElementById("question_count"));
    const current = questions.filter(
      (question) => question.Question_id === question_id
    );
    const index = questions
      .map((data) => data.Question_id)
      .indexOf(question_id);
    setCurrentQuestion(current);
    setQuestionNumber(index);
    //    console.log({index});
  };

  const finish = () => {
    Swal.fire({
      icon: "error",
      title: "Exam Timeout",
      // text: res.data.message,
      confirmButtonText: "Okay",
    });
    history.push("/dashboard");
  };
  return (
    <div>
      <Row>
        <Col
          md={12}
          className="p-3"
          style={{
            color: "red",
            textAlign: "center",
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          <Countdown
            finish={finish}
            date={examStore.questionPaperDetails?.Duration}
            type={"examHall"}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={12}
          className="p-3"
          style={{
            color: "rgb(13 69 139)",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Total Questions
          <p style={{ textAlign: "center", paddingTop: 10 }}>
            {" "}
            <span
              style={{
                fontWeight: 800,
                fontSize: "50px",
                color: "#36be74",
                lineHeight: "0px",
              }}
            >
              .
            </span>
            Answered{" "}
            <span
              style={{
                fontWeight: 800,
                fontSize: "50px",
                color: "#e2e5e8",
                lineHeight: "0px",
              }}
            >
              {" "}
              .
            </span>
            Unanswered{" "}
          </p>
          <p style={{ textAlign: "center", paddingTop: 0 }}>
            {" "}
            <span
              style={{
                fontWeight: 800,
                fontSize: "50px",
                color: "#f36609",
                lineHeight: "0px",
              }}
            >
              .
            </span>
            Not saved
            <span
              style={{
                fontWeight: 800,
                fontSize: "50px",
                color: "#5bc0de",
                lineHeight: "0px",
              }}
            >
              .
            </span>
            Review
          </p>
        </Col>
      </Row>
      <div
        id="switchQuestion"
        style={{ height: 381, overflowX: "hidden", padding: "0px 18px" }}
      >
        {examStore.questionPaperDetails?.Sections.map((val, counter) => {
          return (
            <>
              <p style={{ paddingTop: 10, margin: 0 }}>{val.Section}</p>
              <hr />
              <Row className="p-2">
                {val.Questions.map((data, index) => {
                  a++;
                  console.log("the store", aStore);
                  console.log("the answer store", aStore.subAnswer[a]);
                  return (
                    <Col
                      id={"question" + a}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ textAlign: "center", padding: 3 }}
                    >
                      {/* <div className={`exam-question-chooser ${questionNumber === a - 1 ? "exam-selected" : ""} ${data.SavedAnswer? "exam-answered" : aStore.subAnswer[a]?.length>9? "exam-unsaved":""}`} onClick={() => questionChooser(data)}> */}
                      <div
                        className={`exam-question-chooser ${
                          questionNumber === a - 1 ? "exam-selected" : ""
                        } ${
                          aStore.subAnswer[a]?.remote
                            ? "exam-answered"
                            : data.SavedAnswer
                            ? "exam-answered"
                            : aStore.review_marked[a]
                            ? "exam-review"
                            : aStore.subAnswer[a]?.type === "objective"
                            ? aStore.subAnswer[a].answer.length === 1
                              ? "exam-unsaved"
                              : ""
                            : aStore.subAnswer[a]?.answer.length > 8
                            ? "exam-unsaved"
                            : ""
                        }`}
                        onClick={() => questionChooser(data)}
                      >
                        <span
                          style={{ textAlign: "center" }}
                          id="question_count"
                        >
                          {a}{" "}
                        </span>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default SwitchQuestions;
