import React, { Fragment } from 'react'

import image451 from "./images/admin_scr/image451.png"
import image452 from "./images/admin_scr/image452.png"
import image446 from "./images/admin_scr/image446.png"
import image449 from "./images/admin_scr/image449.png"
import image450 from "./images/admin_scr/image450.png"

import image479 from "./images/client_scr/image479.png"
import image480 from "./images/client_scr/image480.png"
import image481 from "./images/client_scr/image481.png"
import image482 from "./images/client_scr/image482.png"
import image483 from "./images/client_scr/image483.png"
import image486 from "./images/client_scr/image486.png"

import image503 from "./images/client_scr/image503.png"
import image502 from "./images/client_scr/image502.png"
import image504 from "./images/client_scr/image504.png"
import image505 from "./images/client_scr/image505.png"

// import image2 from "./images/admin_scr/image2.png"
import image492 from "./images/admin_scr/image492.png"
import image478 from "./images/admin_scr/image478.png"
// import image450 from "./images/admin_scr/image450.png"
import image447 from "./images/admin_scr/image447.png"
import image1 from "./images/admin_scr/image1.png"

function Client({ admin_val }) {
    return (
        <Fragment>
            {
                admin_val === 0 ?
                    <article>
                        <h3><b>Introduction to the Shamah</b></h3>
                        <p>
                            Shamah autism is a complete educational platform for clients, educators and academic professionals both in and out of the session.
                            This site provides all the functionality of an educational platform as well as enhanced and additional features such as:
                        </p>
                        <ul>
                            <li>Live sessions </li>
                            <li>Pre-recorded Video sessions</li>
                            <li>Live sessions recording</li>
                            <li>Slide sharing</li>
                            <li>Automatic Attendance records</li>
                            <li>Quizzes</li>
                            <li>Creation of multiple users</li>
                            <li>Chat </li>
                        </ul>

                        <p>
                            This manual explains  how to use this Site/Application in order to get productive sessions. For all further questions, please get in touch with the Admin.
                            <br /><br />
                            Brows <a href='https://app.shamah-autism.com.sa/' target="_blank">https://app.shamah-autism.com.sa/</a> or Install Shamah application in
                        </p>
                        <ul>
                            <li>For Ios <a href='https://apps.apple.com/in/app/shamah/id1573574939' target="_blank">https://apps.apple.com/in/app/shamah/id1573574939</a></li>
                            <li>For Android <a href='https://play.google.com/store/apps/details?id=com.shamautism' target="_blank">https://play.google.com/store/apps/details?id=com.shamautism</a></li>
                        </ul>

                        <p>
                            By visiting <a href='https://app.shamah-autism.com.sa/' target="_blank">https://app.shamah-autism.com.sa/</a> Client can reach in to the shamah website, Type the credentials and login
                        </p>

                        <div class='image_div'>
                            <img src={image1} alt='' width='100%' height='100%' />
                        </div>

                    </article>
                    :
                    admin_val === 1 ?
                        <article>

                            <h5><b>Client Menu</b></h5>
                            <ol>
                                <li>Home/Dashboard</li>
                                <li>Sessions</li>
                                <li>Quizz</li>
                                <li>Attendance</li>
                                <li>Recording</li>
                                <li>Video session</li>
                                <li>Chat</li>
                            </ol>


                            <h5><b>Home/Dashboard</b></h5>
                            <p>
                                Here all the sessions scheduled for you will be shown in the order of creation
                            </p>

                            <div class='image_div'>
                                <img src={image503} alt='' width='100%' height='100%' />
                            </div>
                            <h5><b>Next Session</b></h5>
                            <p>
                                You will see a count-down digital clock denoting the latest session thatʼs about to join.If the therapist has not yet started the session even after the Countdown  reaches ‘0ʼ, a message “Host has not started the session yet” will be shown. <br />
                                Once it reaches ‘0ʼ or when the host starts the session, you can click on the <strong>JOIN</strong> button. Now, you are in the session.
                            </p>

                            <div class='image_div'>
                                <img src={image502} alt='' width='100%' height='100%' />
                            </div>

                            <p>Here you can use the following features available as ICONS </p>
                            <ol>
                                <li>Mute/Unmute – By default this will be Muted. If you want to speak, you can click on this to unmute.</li>
                                <li>Video On/Off – By default this will be OFF. You can switch this ON/OFF throughout the sessions</li>
                                <li>Chat Box – This is a message board for all clients from the host. You can use this to answer any question a therapist may have asks.</li>
                                <li>Camera Flip – Like your camera, you can use this to switch between the front or back cameras on your phone (Application)</li>
                                <li>Raise Hand – If you want to ask your therapist a question, just like in a live session, raise your hand and your therapist will notice you and will permit you to talk. Then you can unmute and talk.</li>
                                <li>Hang Up – You can end your session by clicking on this.The  therapist will be notified that you have closed your session if you Hang up before the session is over.</li>
                                <li>Toggle tile view- Clients are displayed in Grid view</li>
                                <li>Settings -
                                    Enable low bandwidth mode - You can enable the low  bandwidth mode, if you have a weak network , so that it will receive the therapistʼs voice more clearly. Enabling this mode
                                    can only support audio, it can not support video.

                                </li>

                            </ol>

                            <h5><b>Upcoming Sessions</b></h5>
                            <p>
                                Here a list of all sessions scheduled during the day will be displayed one by one. You can view the first five sessions assigned for you from here.
                            </p>

                            <h5><b>Quiz Notification</b></h5>
                            <p>
                                You will get the notification of new quizzes created by the therapists here.
                            </p>

                            <div class='image_div'>
                                <img src={image504} alt='' width='100%' height='100%' />
                            </div>

                        </article>
                        :
                        admin_val === 2 ?
                            <article>
                                <h5><b>Sessions</b></h5>
                                <ul>
                                    <li>
                                        <b id='show_session'>Show session</b>
                                        <p>
                                            You can see all the live sessions assigned for you here. By clicking the <strong>Time schedule icon</strong> you can view the session timings
                                        </p>

                                        <div class='image_div'>
                                            <img src={image479} alt='' width='100%' height='100%' />
                                        </div>

                                    </li>
                                    <li>
                                        <b id='special_session'>Special session</b>
                                        <p>
                                            If the Admin has scheduled any special sessions for you, it can be viewed here
                                        </p>
                                        <div class='image_div'>
                                            <img src={image480} alt='' width='100%' height='100%' />
                                        </div>

                                    </li>
                                    <li>
                                        <b id='video_session'>Video session</b>
                                        <p>
                                            The PRE-RECORDED video sessions are displayed here. You can play each video by clicking the <strong>play icon</strong> within the scheduled time set by the therapist.
                                        </p>
                                        <div class='image_div'>
                                            <img src={image481} alt='' width='100%' height='100%' />
                                        </div>

                                        <div class='image_div'>
                                            <img src={image505} alt='' width='100%' height='100%' />
                                        </div>

                                    </li>
                                </ul>
                            </article>
                            :
                            admin_val === 3 ?
                                <article>
                                    <h5><b>Quizzes</b></h5>
                                    <p>
                                        All the Quizzes created by the therapist will be displayed here.
                                    </p>
                                    <div class='image_div'>
                                        <img src={image482} alt='' width='100%' height='100%' />
                                    </div>

                                    <p>You can click on a quizz and its details will be displayed</p>
                                    <ol>
                                        <li>Once the details are displayed, you can download the reference file  by clicking on the DOWNLOAD button.</li>

                                        <div class='image_div'>
                                            <img src={image483} alt='' width='100%' height='100%' />
                                        </div>

                                        <li>Once you have finished with the  quizz , you can submit it using the upload button.</li>
                                        <li>On clicking on the UPLOAD button, you will be prompted to select your quizz file</li>
                                        <li>You can upload your quizz either in the form of IMAGES/PDF</li>

                                        {/* <p class='text-danger'>image482</p> */}

                                        <li>You may also enter any Remarks you might have for the therapist regarding your quizz</li>
                                        <li>The STATUS of your submission will be shown as PENDING and once the upload is completed, the status will change into SUBMITTED</li>
                                    </ol>

                                </article>
                                :
                                admin_val === 4 ?
                                    <article>
                                        <h5><b>Attendance</b></h5>
                                        <ul>
                                            <li>The automatic Attendance reports are displayed here.</li>
                                            <li>You can filter the Attendance by selecting the Date ranges and the session that you have attended.</li>
                                            <li>You will get the duration  details below.</li>
                                        </ul>

                                        <div class='image_div'>
                                            <img src={image486} alt='' width='100%' height='100%' />
                                        </div>

                                    </article>
                                    :
                                    admin_val === 5 ?
                                        <article>
                                            <h5><b>Recording</b></h5>
                                            <p>
                                                The recorded live sessions are listed here. You can download the video as an mp4 copy of the link. The recorded videos can be used for the Prerecorded video sessions or you can share the video/link to the Absent clients in the session.
                                            </p>
                                        </article>
                                        :
                                        admin_val === 6 ?
                                            <article>
                                                <h5><b>Chat</b></h5>
                                                <p>
                                                    Client can start a conversation with other Clients, Therapists, Admin and Staff. The Chat icon in the dashboard indicates the count of <strong>New messages notifications.</strong>
                                                </p>
                                                <div class='image_div'>
                                                    <img src={image478} alt='' width='100%' height='100%' />
                                                </div>
                                                <ul>
                                                    <li>
                                                        New Chat - You can start a new conversation by clicking the <strong>Add button</strong> from the top left corner. You can search the participants which you want to added to the group.
                                                    </li>


                                                    <div className='image_combined'>
                                                        <div className='image_combined1'>
                                                            <img src={image492} alt='' width='100%' height='373px' style={{ borderRadius: '10px', border: '.5px solid rgb(97, 95, 95)' }} />
                                                        </div>
                                                        <div className='image_combined2'>
                                                            <img src={image446} alt='' width='100%' height='373px' style={{ borderRadius: '10px', border: '.5px solid rgb(97, 95, 95)' }} />

                                                        </div>
                                                    </div>

                                                    <li>
                                                        Group Chat - By giving a Group name you can create Group with multiple users<br />

                                                        <div class='image_div'>
                                                            <img src={image447} alt='' width='100%' height='100%' />
                                                        </div>

                                                        By clicking the View details icon in the chat screen of group, you can view the user's details.<br />

                                                        <div class='image_div'>
                                                            <img src={image449} alt='' width='100%' height='100%' />
                                                        </div>

                                                        Users have the feature of Sharing Documents, Images, Videos, and audios from their device.

                                                        <div class='image_div'>
                                                            <img src={image450} alt='' width='100%' height='100%' />
                                                        </div>

                                                    </li>
                                                </ul>
                                            </article>
                                            :
                                            admin_val === 8 ?
                                                <article>
                                                    <h5><b>Video Sessions</b></h5>
                                                    <p>
                                                        These are the Pre-recorded / On Demand sessions. There are two procedures as
                                                    </p>
                                                    <ol>
                                                        <li>Playlist creation</li>
                                                        <li>Create session</li>
                                                    </ol>

                                                    <div style={{ marginLeft: '20px' }}>
                                                        <span style={{ fontWeight: '600' }}>1. Playlist creation</span>
                                                        <ul>
                                                            <li>Multiple videos can be arranged as a playlist</li>
                                                            <li>By selecting categories, create playlists</li>
                                                            <li>The playlist can be edited/deleted</li>
                                                            <li>Click the playlist and there is an option to upload the videos or,</li>
                                                            <li>choose video another playlist (If the therapist is created multiple playlists)</li>
                                                        </ul>
                                                        <span style={{ fontWeight: '600' }}>2. Create session</span>
                                                        <ul>
                                                            <li>Enter the name of video session & select a clinic</li>
                                                            <li>Created playlists are displayed below.</li>
                                                            <li>You can tick the videos and set a from - to date & time for the video after completing the processing time</li>
                                                            <li>You can give order to the videos</li>
                                                            <li>Click Create session</li>
                                                            <li>The video sessions are listed below, there you can assign/unassign Individual clients & Group of clients</li>
                                                            <li>You can edit the session for adding or removing videos </li>
                                                            <li>You can play the videos here</li>
                                                            <li>Enable/disable the session <br />
                                                                Shamah admin or the therapist can enable or disable the video sessions. It will disappear from the student account while disabling.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </article>
                                                :
                                                <article>
                                                    <h5><b>Change password</b></h5>
                                                    <p>
                                                        Users can reset their password here
                                                    </p>
                                                    <div class='image_div'>
                                                        <img src={image451} alt='' width='100%' height='100%' />
                                                    </div>

                                                    <h5><b>My Account</b></h5>
                                                    <p>
                                                        In Settings you can view the User account information such as Username, Email ID and Contact number.
                                                    </p>

                                                    <div class='image_div'>
                                                        <img src={image452} alt='' width='100%' height='100%' />
                                                    </div>
                                                </article>
            }

        </Fragment>
    )
}

export default Client
