
// import axios from 'axios';
// import Dropzone from 'react-dropzone'
import Table from './table'
import DatePicker from 'react-datepicker';
import './assign.scss'
import { Progress } from 'react-sweet-progress';
import serialize from 'form-serialize'
import moment from 'moment'
import {
  Alert,

  FormFeedback,
  Row, Col, Form, Input,
  // TabContent, TabPane, Nav, NavItem, NavLink,
  Card, Label, Badge,
  // CardTitle, CardHeader, CardFooter, CustomInput,
  Button,
  // ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container
} from 'reactstrap';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { useDispatch, useSelector } from "react-redux";
import { updateAssignment, getAssignmentDescription, downloadTeacherAssignments, removeAssignment, getAssignmnetMark, downloadAssignment, downloadStudentAssignments, getAssignmentsData, addAssignment, getStudentAssignments, getAssignmentData } from '../../redux/Actions/assignment'
import { populateAllStartedClasses } from '../../redux/Actions/homeTeacher'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import SweetAlert from 'sweetalert-react';
// import { Loader, Types } from 'react-loaders';
import Modals from '../common/Modal'
// import Success from './CreateSuccess'
import StudentTable from './StudentList/table'
import RespondToAssignment from './AssigmentRespond'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import LoadingOverlay from 'react-loading-overlay'
// import { useParams } from "react-router";
import { nameValid, requiredValidator } from '../../utilities/Validator'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import { setEditData } from '../../redux/Actions/videolist';
const formData = new FormData();

const AddAssignment = (props) => {
  console.log('assagin******');

  const history = useHistory();

  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  useEffect(() => {


    console.log('ROLE ' + tokenData.role);
    if (tokenData.role === 'teacher') {
      // dispatch(populateAllUserClass(tokenData.token))
      dispatch(populateAllStartedClasses(tokenData.token))
      dispatch(getAssignmentsData(tokenData.token))

    }
    else if (tokenData) {
      console.log('ELSE  ROLE');
      dispatch(getStudentAssignments(tokenData.token))
    }

    return () => {
      formData.delete('assignment[]');
    }

  }, [history]);
  const state = {
    file: []

  }
  const [loader, setLoader] = useState();

  const [editData, setEditData] = useState();
  const [editModal, setEditModal] = useState(false)
  const editAssignment = (rowData) => {
    console.log(rowData)
    setEditModal(true)
    setEditData(rowData)
  }
  const columns = [
    {
      title: 'Quiz', field: 'title',
      render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Badge color="danger" pill>{rowData.new_notification}</Badge></>
    },
    // {
    //   field: 'new_notification',
    //   title: '',
    //   render: rowData => <Badge color="danger" pill>{rowData.new_notification}</Badge>
    //   //<h6 style={{ background: 'red', borderRadius: '20' }}> <font color="white" >{rowData.new_notification}</font></h6>

    // },
    // { title: ' Subject', field: 'subject_name' },
    { title: 'Session', field: 'class_name' },
    // { title: 'Grade', field: 'grade_name' },
    { title: 'Created_On', field: 'created_at' },
    {
      title: 'Deadline',
      field: 'deadline',
      render: rowData => <Badge color="warning" pill>{rowData.deadline}</Badge>

    },
    {
      title: 'Status',
      //field: 'submittedStatus',
      render: rowData => <Badge color={`${rowData.submittedStatus ? `success` : `danger`}`} pill>{rowData.submittedStatus ? 'Submitted' : 'Not Submitted'}</Badge>

    },

  ]

  const columnsTeacher = [
    {
      title: 'Quiz', field: 'title',
      render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Badge color="danger" pill>{rowData.new_notification}</Badge></>
    },
    // {
    //   field: 'new_notification',
    //   title: '',
    //   render: rowData => <Badge color="danger" pill>{rowData.new_notification}</Badge>
    //   //<h6 style={{ background: 'red', borderRadius: '20' }}> <font color="white" >{rowData.new_notification}</font></h6>

    // },
    // { title: ' Subject', field: 'subject_name' },
    { title: 'Session', field: 'class_name' },
    // { title: 'Grade', field: 'grade_name' },
    { title: 'Created_On', field: 'created_at' },
    {
      title: 'Deadline',
      field: 'deadline',
      render: rowData => <Badge color="warning" pill>{rowData.deadline}</Badge>

    },


  ]



  const columns2 = [
    { title: 'Name', field: 'first_name' },
    { title: ' LastName', field: 'last_name' },
    { title: ' status', field: 'status' },

  ]

  const handleChangeGrade = e => {
    const { name, value } = e.target;
    setInputfield({ ...inputField, [name]: value })
    formData.set('class_id', e.target.value);
  };


  const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)
  const assignment = useSelector(({ assignments }) => assignments)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  // const studentAssignments = useSelector(({ assignments }) => assignments)
  console.log("class id ", homeDetail);
  const downloadMyAssignment = (rowData) => {

    dispatch(downloadAssignment(tokenData.token, rowData.assignment_id, rowData.class_id, reloadTbl));

  }
  const teacherDownloadFile = (rowData) => {
    dispatch(downloadTeacherAssignment(tokenData.token, rowData.assignment_id, rowData.class_id, reloadTbl));

  }

  const downloadStudentFile = (rowData) => {

    dispatch(downloadAssignment(tokenData.token, rowData.assignment_id, rowData.class_id, reloadTbl));

  }

  const getTeacherRemark = (rowData) => {

    dispatch(getAssignmnetMark(tokenData.token, rowData.assignment_id, rowData.student_id, viewRemark));
  }
  const viewRemark = () => {
    console.log(assignment.remark)
    var titleForStudentMark;
    if (assignment.remarkNew.mark == null)
      titleForStudentMark = 'Marks Not Added'
    else
      titleForStudentMark = assignment.remarkNew.mark + "/" + assignment.remarkNew.total + ' marks'
    Swal.fire({
      icon: '',
      title: titleForStudentMark,
      text: assignment.remarkNew.teacher_remark,

    })
  }

  const downloadStudentAssignment = (rowData) => {
    if (rowData.status == 'submitted') {
      dispatch(downloadStudentAssignments(tokenData.token, rowData.assignment_id, rowData.class_id, rowData.student_id, reloadTbl));
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Assignment not submited',

      })

    }
  }


  const downloadTeacherAssignment = (rowData) => {
    console.log('Student ', rowData);
    dispatch(downloadTeacherAssignments(tokenData.token, rowData.assignment_id, rowData.class_id, rowData.student_id, reloadTbl));
    // history.push(`https://srjtest.mojgenie.com/downloadAssignment?assignment_id=`+rowData.assignment_id+'&class_id='+rowData.class_id+'&student_id='+rowData.student_id);
  }
  const reloadTbl = () => {
    console.log('ROLE ' + tokenData.role);
    if (tokenData.role === 'teacher') {
      dispatch(populateAllStartedClasses(tokenData.token))
      dispatch(getAssignmentData(tokenData.token))


    }
    else if (tokenData) {
      console.log('ELSE  ROLE');
      dispatch(getStudentAssignments(tokenData.token))
    }
  }
  const [stList, setList] = useState();
  const populateStudents = (rowData) => {

    // dispatch(populateStudentAssignment(tokenData.token,rowData.assignment_id,rowData.class_id,loadStudentTable ))
    history.push(`/dashboard/assignments/${rowData.assignment_id}/${rowData.class_id}`)

  }

  const loadStudentTable = () => {
    console.log('pop ', assignment);
    // if(assignment.length>0){
    setStudents(assignment.assignmentss);
    // history.push('/dashboard/assignments/students')
    // openStudentsModel();
    // }


  }
  const deleteAssignment = (assign_id) => {
    //  alert(assign_id)
    console.log('delete Assignment ');
    dispatch(removeAssignment(tokenData.token, assign_id))


  }
  const [successModal, setSuccessModal] = useState();
  const [studModel, setStudModel] = useState();
  const [students, setStudents] = useState([]);

  const [respond, setRespond] = useState();
  const [aAssignmentId, setaAssignmentId] = useState();
  const [class_id, setClassId] = useState();
  const [aAssignmentTitle, setTitle] = useState();
  // const [aAssignmentDescription, setDescription] = useState();

  const respondToAssignment = (rowData) => {
    console.log('respond Students ', rowData);
    // setTitle(title);
    // setDescription(desc);
    if (rowData.deadline_status == 'completed') {
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Submission has closed',

      })
    } else {
      setRespond(true);
      setaAssignmentId(rowData.assignment_id);
      setClassId(rowData.class_id);
    }
  }

  const openStudentsModel = () => {
    setStudModel(true);

  }
  const closeRespondModel = () => {
    setRespond(false);

  }

  const openRespondModel = () => {
    setStudModel(true);

  }
  const closeStudentsModel = () => {
    setStudModel(false);

  }

  const handleOpenSuccessModal = () => {
    setInputfield({
      title: '',
      grade_id: '',
      deadline: '',
      deadlineTime: '',
      assignment: '',
      description: '',
      marks: ''
    })
    console.log('callback')
    setLoader(false);
    //debugger
    formData.delete('assignment[]');
    setSuccessModal(true)
    setVisible(false);
    dispatch(getAssignmentsData(tokenData.token))
    // document.getElementById("title").value = "";
    // document.getElementById("description").value = "";
    // document.getElementById("deadline").value = "";
    // document.getElementById("assingment").value = "";
    // document.getElementById("marks").value = "";
    document.getElementById("class-form").reset();



  }
  const [visible, setVisible] = useState(false);
  // const [demo, setDemo] = useState(false);
  const handleCloseSuccesstModal = () => {
    setSuccessModal(false)
    resetForm();
  }

  const [errors, setErrors] = useState({})
  const onSubmit = (e) => {
    e.preventDefault()
    const err = {
      title: nameValid(inputField.title),
      grade_id: requiredValidator(inputField.grade_id),
      deadline: requiredValidator(inputField.deadline),
      deadlineTime: requiredValidator(inputField.deadlineTime),
      // assignment: requiredValidator(inputField.assignment),
      description: requiredValidator(inputField.description),
      marks: requiredValidator(inputField.marks)
    }
    var imagefile = document.querySelector('#assignment');
    // this.props.onSubmitForm(this.formData);
    //debugger
    if (marksView) {
      if (!err.title && !err.grade_id && !err.deadline && !err.deadlineTime && !err.description && !err.marks) {
        dispatch(addAssignment(tokenData.token, formData, handleOpenSuccessModal))
        setErrors(err)
        setLoader(true);
        setVisible(true)


      } else {
        setErrors(err)
        if (err.title) {
          Showerror('title')
        }
        else if (err.grade_id) {
          Showerror('grade')
        }
        else if (err.deadline) {
          Showerror('deadline')
        }
        else if (err.deadlineTime) {
          Showerror('time-picker')
        }
        else {
          Showerror('description')
        }
      }
    } else {
      if (!err.title && !err.grade_id && !err.deadline && !err.deadlineTime && !err.description) {
        dispatch(addAssignment(tokenData.token, formData, handleOpenSuccessModal))
        setErrors(err)
        setLoader(true);
        setVisible(true)


      } else {
        setErrors(err)
        if (err.title) {
          Showerror('title')
        }
        else if (err.grade_id) {
          Showerror('grade')
        }
        else if (err.deadline) {
          Showerror('deadline')
        }
        else if (err.deadlineTime) {
          Showerror('time-picker')
        }
        else {
          Showerror('description')
        }
      }
    }
  }

  const Showerror = (val) => {
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
  }
  // const onSubmit = (e) => {
  //   e.preventDefault()

  //   const options = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;
  //       let percent = Math.floor((loaded * 100) / total)
  //       console.log(`${loaded}kb of ${total}kb | ${percent}%`);

  //       // if( percent < 100 ){
  //       //   this.setState({ uploadPercentage: percent })
  //       // }
  //     }
  //   }
  //   const head = {
  //     Accept: 'application/json',
  //     Authorization: `Bearer ${tokenData.token}`,
  //     'content-type': 'multipart/form-data'
  //   }
  //   axios.post("https://backend.mymots.com/api/uploadAssignments", formData, options, { headers: head }).then(res => {
  //     console.log("reee", res)
  //     // this.setState({ avatar: res.data.url, uploadPercentage: 100 }, () => {
  //     setTimeout(() => {
  //       // this.setState({ uploadPercentage: 0 })
  //     }, 1000);
  //     // })
  //   })
  // }






  const onChangeTitle = (e) => {
    const { name, value } = e.target
    setInputfield({ ...inputField, [name]: value })
    formData.set('title', value)
    console.log('bsbsz')
  }
  const onChangeMarks = (e) => {
    const { name, value } = e.target
    setInputfield({ ...inputField, [name]: value })


    formData.set('marks', e.target.value)
  }
  const onChangeDescription = (e) => {
    const { name, value } = e.target
    formData.set('description', e.target.value)
    setInputfield({ ...inputField, [name]: value })

  }
  const [DeadLine, setDeadLine] = useState(null)
  const [DeadLineEdit, setDeadLineEdit] = useState(null)

  const onChangeDeadline = (e, n) => {
    if (n === 'deadline_date') {
      setDeadLineEdit(e)
    }
    else { setDeadLine(e) }

    const value = moment(e).format("YYYY-MM-DD");
    const name = n
    setInputfield({ ...inputField, [name]: value })

    formData.set('deadline', value)
    // console.log('date ', value)
    // var selDate = new Date(value);
    // var today = new Date();
    // var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // console.log('selDate ', selDate)
    // console.log('today ', today)

    // if (selDate.setHours(0, 0, 0, 0) != today.setHours(0, 0, 0, 0) && selDate < today) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: '',
    //     text: 'Enter a valid Date.',

    //   })
    //   e.target.value = "";
    // }
  }

  //   if (selDate < today && selDate != today) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: '',
  //       text: 'Enter a valid Date.',

  //     })
  //     e.target.value = "";
  //   }
  // }
  const [Default, setDefault] = useState(null)
  const [DefaultEdit, setDefaultEdit] = useState(null)
  const [EditValue, setEditValue] = useState(null)
  const onChangeDeadlineTime = (e, n) => {
    const name = n;


    let a = String(e);
    const value = a.slice(16, 21);

    // const { name, value } = e.target
    if (name === 'deadlineTime') {
      setInputfield({ ...inputField, [name]: value })
      formData.set(name, value)
    }
    else {
      setEditData(value)
    }
  }
  const onChangeClassId = (e) => {
    formData.set('class_id', e.target.value)
  }
  const onChange = (e) => {
    const { name, value } = e.target
    setInputfield({ ...inputField, [name]: value })

    formData.delete('assignment[]');
    for (let i = 0; i < e.target.files.length; i++) {

      formData.append('assignment[]', e.target.files[i])
    }


  }
  const resetForm = () => {

    document.getElementById("class-form").reset();
    setMarksView(false);

  }
  const handleStudentList = () => {
    setSuccessModal(false)
  }
  const [marksView, setMarksView] = useState(false);
  const handleChange = (val) => {

    if (val) {
      console.log('toggle ', val);
      formData.set('grading_system', 'marks')
      setMarksView(true);
    } else {
      setMarksView(false);
      formData.delete('marks')
      formData.set('grading_system', 'grading')
    }
  }

  const handleFileList = (event) => {

  }
  const getDescription = (rowData) => {
    console.warn("rowData.assignment_id--", rowData.assignment_id)
    dispatch(getAssignmentDescription(tokenData.token, rowData.assignment_id, openDescription))
  }
  const [assignDescription, setAssignDescription] = useState({

  })
  const openDescription = (data) => {
    setAssignDescription({ ...assignDescription, popup: true, description: data.description })
  }
  const closeAssignDescription = () => {
    setAssignDescription({ ...assignDescription, popup: false, description: '' })

  }
  const handleClick = (rowData) => {
    // console.log("row===========data-----", rowData)
    history.push(`/dashboard/assignments/${rowData.assignment_id}/${rowData.class_id}`)
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget
    let body = serialize(form, { hash: true, empty: true })
    console.warn(body)
    dispatch(updateAssignment(tokenData.token, body, updateSuccess))


  }
  const updateSuccess = () => {
    setEditModal(false)
    // window.location.reload();
  }
  const [inputField, setInputfield] = useState({
    title: '',
    grade_id: '',
    deadline: '',
    deadlineTime: '',
    assignment: '',
    description: '',
    marks: ''
  })
  return (
    <>
      <>
        {tokenData.role === 'teacher' ? <>
          <>{
            Array.isArray(homeDetail.startClasses) ? homeDetail.startClasses.length == 0 ? <Alert color="danger" isOpen={true}
            //toggle={onDismiss}
            //toggle={""}
            > <strong>{`You have to start class first`}</strong>
            </Alert> : null : null
          }</>

          <LoadingOverlay tag="div" active={Array.isArray(homeDetail.startClasses) ? homeDetail.startClasses.length == 0 ? true : false : false}
            styles={{
              overlay: (base) => ({
                ...base,
                background: '#fff',
                opacity: 0.5
              })
            }}
          >
            < Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary">
              <div className="widget-chat-wrapper-outer">
                {/* <LoadingOverlay tag="div" active={visible}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 0.5
                  })
                }}
                spinner={
                  <Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />
                }> */}

                <form onSubmit={onSubmit} id="class-form" enctype="multipart/form-data" >
                  <Row>
                    <Col md={12} className="assign-form-grid" style={{ paddingTop: 10 }}>
                      <h6> <strong>ADD QUIZ</strong> </h6>
                      {/* <p style={{ color: 'gray', fontSize: '10px' }}>* Assignments only for started class</p> */}
                    </Col>
                    <Col md={12} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Title
                      </Label>
                      <Input invalid={true}
                        type="text" style={{ "width": "100%" }}
                        onBlur={onChangeTitle} id="title"
                        name="title"
                        // value={inputField.title}
                        invalid={errors.title}
                      />
                      <FormFeedback >{errors.title}</FormFeedback>

                    </Col>
                    <Col md={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Session
                      </Label>
                      <Input type="select"
                        native
                        labelId="demo-simple-select-outlined-label"
                        onChange={handleChangeGrade}
                        style={{ "width": "100%" }}
                        id="grade"
                        name='grade_id'
                        label="Grade"
                        // value={inputField.grade_id}
                        invalid={errors.grade_id}

                      >

                        <option value={''}>{'Select Session'}</option>
                        {Array.isArray(homeDetail.startClasses) ?
                          homeDetail.startClasses.map(classes => {
                            return (
                              <option value={classes.class_id}>{classes.class_name}</option>
                            )
                          }) : null
                        }

                      </Input>
                      <FormFeedback >{errors.grade_id}</FormFeedback>

                    </Col>
                    <Col md={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Last date of submission
                      </Label>
                      {/* <Input type="date"
                        style={{ "width": "100%" }}
                        onBlur={onChangeDeadline} min id="deadline"
                        //value={inputField.deadline}
                        invalid={errors.deadline}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        name="deadline" /> */}
                      <DatePicker
                        selected={DeadLine}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        withPortal
                        id='deadline'
                        onChange={(e) => onChangeDeadline(e, 'deadline')}
                        minDate={new Date()}
                        // maxDate={start_date}
                        invalid={errors.deadline}
                        renderCustomHeader={({
                          date,
                          decreaseMonth,
                          increaseMonth,
                          increaseYear,
                          decreaseYear,

                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                              {"<<"}
                            </Button>
                            <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </Button>

                            <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                            <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </Button>
                            <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                              {">>"}
                            </Button>
                          </div>
                        )}

                      />
                      <span style={{ color: 'red' }}>{errors.deadline}</span>

                    </Col>
                    <Col md={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Last time of submission
                      </Label>
                      {/* <Input type="time"
                        style={{ "width": "100%" }} onBlur={onChangeDeadlineTime}
                        min id="deadlinetime" name="deadlineTime"
                        //value={inputField.deadlineTime}
                        invalid={errors.deadlineTime} */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          keyboardIcon={<AccessTimeIcon />}
                          size="small"
                          inputVariant="outlined"
                          invalidLabel={false}
                          // margin="normal"
                          id="time-picker"
                          // label="From Time"
                          //  name="class_from_time"
                          //value={"Sat Apr 24 2021 01:00:31 GMT+0530 (India Standard Time)"}
                          value={Default}
                          onChange={(e) => { onChangeDeadlineTime(e, "deadlineTime"); setDefault(e); console.log("time", e) }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </MuiPickersUtilsProvider>

                      <br />
                      <span style={{ color: 'red' }}>{errors.deadlineTime}</span>

                    </Col>
                    <Col md={12} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Description
                      </Label>
                      <Input type="textarea" rows="4"
                        style={{ "width": "100%" }} onBlur={onChangeDescription}
                        id="description" name="description"
                        //value={inputField.description}
                        invalid={errors.description}
                      />
                      <FormFeedback >{errors.description}</FormFeedback>

                    </Col>
                    <Col md={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        {/* <span className="text-danger">*</span> */}
                        {' '}Choose quiz file
                      </Label>
                      <Input
                        style={{ lineHeight: "27px" }} type="file"
                        id="assignment"
                        // onBlur={onChange} 
                        onChange={onChange}
                        name="assignment" multiple
                        // value={inputField.assignment}
                        invalid={errors.assignment}
                      />
                      <FormFeedback >{errors.assignment}</FormFeedback>

                    </Col>
                    <Col md={5} >
                      <Label for="exampleNameF">

                        For quiz evaluation
                      </Label>
                      <Row>
                        <Col md={4}>
                          <BootstrapSwitchButton
                            checked={marksView}
                            onlabel='Marks'
                            offlabel='Grade'
                            width={100}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={8}>
                          {
                            marksView ? <>
                              <Input aria-label="Username" type="number"
                                placeholder="Enter marks " style={{ "width": "100%" }}
                                onBlur={onChangeMarks} id="marks" name="marks"
                                // value={inputField.marks}
                                invalid={errors.marks}
                              />
                              <FormFeedback >{errors.marks}</FormFeedback>
                            </>
                              : false
                          }

                        </Col>



                      </Row>

                    </Col>
                    <Col md={3} className="assign-button-grid">

                      <LaddaButton className="mb-2 mr-2 btn btn-danger assign-form-button"
                        loading={loader}
                        type="submit"
                        data-style={ZOOM_IN}   >
                        Add New Quiz
                      </LaddaButton>
                    </Col>

                  </Row>



                  <br />
                </form>
                {/* </LoadingOverlay> */}

                {

                  assignment.uploadProgress != '' ? <>
                    <strong style={{ color: 'rgb(19, 140, 228)' }}>Uploading Files...</strong>
                    <Progress
                      percent={assignment.uploadProgress}
                    /></> : null

                }

              </div>

            </Card>
          </LoadingOverlay>
        </>
          : null

        }


        <Row>
          <Col sm={12} md={12}>

            <Table

              actions={tokenData.role}
              columns={tokenData.role === "teacher" ? columnsTeacher : columns}
              loader={assignment.loader}
              data={assignment.assignmentss ? assignment.assignmentss : []}
              downloadMyAssignment={downloadMyAssignment}
              downloadTeacherAssignment={downloadTeacherAssignment}
              populateStudents={populateStudents}
              deleteAssignment={deleteAssignment}
              teacherDownloadFile={teacherDownloadFile}
              downloadStudentFile={downloadStudentFile}
              respondToAssignment={respondToAssignment}
              getTeacherRemark={getTeacherRemark}
              getDescription={getDescription}
              handleClick={handleClick}
              editAssignment={editAssignment}
            />
          </Col>
        </Row>



        <Modals
          open={editModal}
          handleClose={() => setEditModal(false)}
          title={'Edit Quiz'}
        //columns={columns}
        //loader={assignment.loader}

        //downloadFile={downloadFile}

        >
          <Form onSubmit={handleSubmit} >
            <Row>
              <Col md={6}>
                Title
              </Col>
              <Col md={6}>
                Deadline
              </Col>
              <Col md={6}>
                <strong style={{ textTransform: 'capitalize' }}>
                  {editData && editData.title}

                </strong>
                <hr />
              </Col>
              <Col md={6}>
                <strong>
                  {editData && editData.deadline}

                </strong>
                <hr />
              </Col>

              <Col md={6}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span>
                  {' '}Deadline Date
                </Label>
                <Input type="hidden" name="assignment_id" value={editData && editData.assignment_id} />

                <Input type="hidden" value={inputField.deadline_date} required name="deadline_date" />
                <DatePicker
                  selected={DeadLineEdit}
                  required
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  withPortal
                  onChange={(e) => onChangeDeadline(e, 'deadline_date')}
                  minDate={new Date()}
                  // maxDate={start_date}
                  invalid={errors.deadline}
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    increaseYear,
                    decreaseYear,

                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                        {"<<"}
                      </Button>
                      <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                      </Button>

                      <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                      <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                      </Button>
                      <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                        {">>"}
                      </Button>
                    </div>
                  )}

                />

              </Col>
              <Col md={6}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span>
                  {' '}Deadline Time
                </Label>
                <Input type="hidden" value={editData} name="deadline_time" />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    keyboardIcon={<AccessTimeIcon />}
                    size="small"
                    inputVariant="outlined"
                    invalidLabel={false}
                    // margin="normal"
                    id="time-picker"
                    // label="From Time"
                    // name="class_from_time"
                    //value={"Sat Apr 24 2021 01:00:31 GMT+0530 (India Standard Time)"}
                    value={DefaultEdit}
                    onChange={(e) => { onChangeDeadlineTime(e, "deadline_time"); setDefaultEdit(e); console.log("time", e) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>


              </Col>
              <Col md={6}>
                <hr />
                {/* <Button type="submit" color="danger">UPDATE</Button> */}
                <LaddaButton className="mb-2 mr-2 btn btn-primary"
                  loading={assignment.updateAssignLoader}
                  type="submit"
                  data-style={ZOOM_IN}  >
                  UPDATE QUIZ
                </LaddaButton>
              </Col>
            </Row>
          </Form>

        </Modals>


      </>


      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />
      <Modals
        open={studModel}
        handleClose={closeStudentsModel}

      >
        <StudentTable
          actions={'dashboard'}
          columns={columns2}
          loader={assignment.loader}
          data={assignment.students ? assignment.students : []}

          downloadStudentAssignment={downloadStudentAssignment}
          populateStudents={populateStudents}
        />

      </Modals>

      <Modals
        open={respond}
        handleClose={closeRespondModel}
        title="Upload Assignment"
      >
        <RespondToAssignment
          id={aAssignmentId}
          class_id={class_id}
          closeRespondModel={closeRespondModel}
          title={aAssignmentTitle}
        />
      </Modals>


      <Modals
        open={assignDescription.popup}
        handleClose={closeAssignDescription}
        title={`Description`}
      >

        <div>
          {assignDescription.description}
        </div>
      </Modals>
      {/* <Modal isOpen={assignment.uploadProgress == '' || assignment.uploadProgress == 100 ? false : true}  >

        <ModalBody> */}


      {/* </ModalBody>

      </Modal> */}

    </>
  )


}

export default AddAssignment;
