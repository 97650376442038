const initialState = {
    currentUser: {},
    loader: false,
    tokenExpire: null,
    enterpriseLoader: false,
    createPasswordLoader: false,
    payment: null,
    payment_history: [],
    privillages: ''
}

const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case 'SET_PLIVILLAGES': {
            return {
                ...state, privillages: payload
            }
        }
        case 'PAYMENT_UPGRADE_POPUP_REQUEST': {
            return {
                ...state, payment: { ...state.payment, payment_alert: true }
            }
        }
        case 'PAYMENT_HISTORY_REQUEST': {
            return {
                ...state, paymentHistoryLoader: true
            }
        }
        case 'PAYMENT_HISTORY_SUCCESS': {
            return {
                ...state, paymentHistoryLoader: false, payment_history: payload
            }
        }
        case 'PAYMENT_SUCCESSFULLY_COMPLETED': {
            return {
                ...state, payment: { ...state.payment, payment_alert: false }

            }
        }
        case 'CHANGE_PLAN': {
            let copy = state.payment
            copy.plan_id = payload
            return {
                ...state, payment: copy

            }
        }
        case 'TOKEN_CHECK_EXPIRY_SUCCESS': {
            return {
                ...state, payment: payload

            }
        }
        case 'RESETT_PASSWORD_REQUEST': {
            return {
                ...state, createPasswordLoader: true
            }
        }
        case 'RESETT_PASSWORD_SUCCESS': {
            return {
                ...state, createPasswordLoader: false
            }
        }
        case 'RESETT_PASSWORD_FAILED': {
            return {
                ...state, createPasswordLoader: false
            }
        }
        case 'USER_AUTH_REQUEST': {
            //console.log("loading")
            return {
                ...state, loader: true
            }
        }
        case 'AUTH_CHECK_SUCCESS': {

            return {
                ...state, tokenExpire: payload
            }
        }
        case 'USER_AUTH_FAILED': {
            return {
                ...state, loader: false
            }
        }

        case 'RESET_EXPIRE': {

            return {
                ...state, tokenExpire: null
            }
        }
        case 'USER_AUTH_ENTERPRISE_REQUEST': {
            return {
                ...state, enterpriseLoader: true
            }
        }
        case 'USER_AUTH_ENTERPRISE_SUCCESS': {
            return {
                ...state, enterpriseLoader: false, currentUser: payload,

            }
        }
        case 'USER_AUTH_SUCCESS': {
            // console.log("success", payload)
            return {
                ...state, loader: false, currentUser: payload,

            }
        }
        case 'RESET_VALIDATION': {
            return {
                ...state, currentUser: {},
            }
        }

        default:
            return state
    }

}

export default authReducer