import React, { useState, useEffect, useRef } from "react";
import cx from 'classnames';
import { TextField, InputAdornment } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {
    Col, Row
} from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { recentChats, chatableUsers, removeFromGroup, createChat, unassignedUsersInGroup, chatDetails, addUsers, setNewActionData, createGroup, uploadMediaFiles, channelUserStatus } from "../../redux/Actions/new-action"
import { development } from '../../config.json';
// import Swal from 'sweetalert2';
import ChatAreaPerson from "./ChatAreaPerson"
import ChatAreaGroup from "./ChatAreaGroup"
import RecentChatList from "./RecentChatList"
import AllPeopleList from "./AllPeopleList"
import GroupCreation from "./GroupCreation"
import DummyChatArea from "./DummyChatArea"
import Echo from 'laravel-echo';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Snackbar from '@material-ui/core/Snackbar';
import { setEnableClosedSidebar } from '../../reducers/ThemeOptions';
import Swal from 'sweetalert2'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, Chip, Checkbox } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import "./chat.css"
import MicRecorder from 'mic-recorder-to-mp3';

const options = {
    broadcaster: 'pusher',
    key: development.pusherKey,
    //  wsHost: window.location.hostname,
    wsHost: development.socketUrl,
    // wsHost: "backend.mymots.com",

    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],

    // broadcaster: 'pusher',
    // key: 'key',

    // cluster: 'mt1',    
    //authEndpoint is your apiUrl + /broadcasting/auth
    // authEndpoint: `http://${development.socketUrl}/api/broadcasting/auth`,
    // authEndpoint: `${development.socketUrl}/api/broadcasting/auth`,
    // host: "http://localhost:6001",
    // wsPort: 6001,
    // wssPort: 6001,

    // auth: {
    //   headers: {
    //     Authorization: `Bearer ${tokenData.token}`,
    //     Accept: 'application/json',
    //   },
    // },
};
const echo = new Echo(options);
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function Index() {

    // const history = useHistory()
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const [active, setActive] = useState(false)
    const [chatArr, setChatArr] = useState([])
    const [show_attachment_div, setAttachmentDiv] = useState(false)
    const [show_group, setShowGroup] = useState(false)
    const [show_people, setShowPeople] = useState(false)
    const [message, setMessage] = useState('')
    const [show_search, setShowSearch] = useState(false)
    const [show_search_all, setShowSearchAll] = useState(false)
    const [recent_search, setRecentSearch] = useState('')
    const [SearchQuery, setSearchQuery] = useState('')

    const [chat_data, setChatData] = useState([])
    const [scroll, setScroll] = useState(true)
    // const [recieving_message, setRecievingMessage] = useState('')
    const [recieving_message_user, setRecievingMessageUser] = useState('')
    const [MessageFull, setMessageFull] = useState('')
    const [channel_name, setChannelName] = useState('')

    const [show_group_detais, setShowGroupDetails] = useState(false)

    /////////////single person chat api handling/////////////
    const [show_persons_chat, setShowPersonChat] = useState(false)
    const [reciever_id, setRecieverId] = useState('')
    const [reciever_id_changed, setRecieverIdChange] = useState(false)
    ////////////////////////////////////////////////////////

    /////////////GROUP chat api handling/////////////
    const [show_grpup_chat, setShowGroupChat] = useState(false)
    const [group_id, setGroupId] = useState('')
    const [group_id_changed, setGroupIdChange] = useState(false)
    const [group_created, groupCreated] = useState(false)
    const [selected_full, setSelectedFull] = useState([])
    const [selected, setSelected] = useState([])
    const [show, setShow] = useState({ opn: false, msg: '' })
    ////////////////////////////////////////////////////////

    ////////////////group////////////
    const [selected_members, setSelectedMembers] = useState([])
    const [group_name, setGroupName] = useState('')
    const [open, setOpen] = useState(false)

    /////////////record////////////
    const [isRecording, setIsRecording] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [blobURL, setBlobURL] = useState('')
    const [isBlocked, setIsBlocked] = useState(false)
    const [recorded_file, serRecordedFile] = useState('')

    const [timer, setTimer] = useState(3595)
    const increment = useRef(null)

    const page = useSelector(state => state.new_action.chat_details_page ? state.new_action.chat_details_page : 1)
    const chat_name = useSelector(state => state.new_action.chat_name ? state.new_action.chat_name : '')
    const chat_next_page = useSelector(state => state.new_action.chat_next_page ? state.new_action.chat_next_page : '')
    const current_user_id = useSelector(state => state.new_action.current_user_id ? state.new_action.current_user_id : '')

    const Recent_chat_data = useSelector(state => state.new_action.Recent_chat_data ? state.new_action.Recent_chat_data : [])
    const GroupMembers = useSelector(state => state.new_action.group_members ? state.new_action.group_members : [])
    const GroupMembers_Copy = useSelector(state => state.new_action.group_members_copy ? state.new_action.group_members_copy : [])

    const users_data = useSelector(state => state.new_action.user_list ? state.new_action.user_list : [])
    const add_user_loader = useSelector(state => state.new_action.add_user_loader ? state.new_action.add_user_loader : false)
    const new_member_list = useSelector(state => state.new_action.new_all_member_list ? state.new_action.new_all_member_list : [])
    const loading_members = useSelector(state => state.new_action.loading_members ? state.new_action.loading_members : false)

    const channel_id = useSelector(state => state.new_action.channel_id ? state.new_action.channel_id : '')

    useEffect(() => {
        dispatch(recentChats(tokenData.token, '', cb))
    }, [])
    // reciever_id_changed, group_id_changed
    useEffect(() => {
        dispatch(setEnableClosedSidebar(true))
        dispatch(chatableUsers(tokenData.token, cbchatable))
        return () => {
            clearInterval(increment.current)
            dispatch(setEnableClosedSidebar(false))
            dispatch(setNewActionData('chat_page_leaves', true))
        }
    }, [])
    const [Users, setUsers] = useState('')
    const cbchatable = (val) => {
        setUsers(val)
    }

    // console.log("Users", Users)
    /////////////single person chat api handling/////////////
    useEffect(() => {
        if (show_persons_chat) {
            dispatch(chatDetails(tokenData.token, '', reciever_id, page, Cb_chat_details))
        }
    }, [reciever_id_changed, page])
    /////////////////////////////////////////////////////////////


    /////////////groupchat api handling/////////////
    useEffect(() => {
        if (show_grpup_chat) {
            dispatch(chatDetails(tokenData.token, group_id, '', page, Cb_chat_details))
        }
    }, [group_id_changed, page])
    /////////////////////////////////////////////////////////////


    //////////////////chat area handling/////////////
    useEffect(() => {
        if (MessageFull && current_user_id !== recieving_message_user) {
            if (show_persons_chat) {
                let arr = [...chat_data]
                let obj = {
                    'created_time': new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }),
                    'is_right': false,
                    'message': MessageFull.message ? MessageFull.message : '',
                    'type': MessageFull.type ? MessageFull.type : '',
                    "full_name": MessageFull.full_name ? MessageFull.full_name : '',
                    "file_url": MessageFull.file_url ? MessageFull.file_url : '',
                    "video_thumbnail": MessageFull.video_thumbnail ? MessageFull.video_thumbnail : '',
                    "video_embed": MessageFull.video_embed ? MessageFull.video_embed : '',
                    "file_size": MessageFull.file_size ? MessageFull.file_size : '',
                    "file_name": MessageFull.file_name ? MessageFull.file_name : '',
                    "extension": MessageFull.extension ? MessageFull.extension : '',
                }
                arr.push(obj)
                setChatData(arr)
                // setRecievingMessage('')
                setMessageFull('')
                let element = document.getElementById(`for_scroll`)
                element.scrollIntoView()
            }
            else {
                if (ShowTextField()) {
                    let arr = [...chat_data]
                    let obj = {
                        'created_time': new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }),
                        'is_right': false,
                        'message': MessageFull.message ? MessageFull.message : '',
                        'type': MessageFull.type ? MessageFull.type : '',
                        "full_name": MessageFull.full_name ? MessageFull.full_name : '',
                        "file_url": MessageFull.file_url ? MessageFull.file_url : '',
                        "video_thumbnail": MessageFull.video_thumbnail ? MessageFull.video_thumbnail : '',
                        "video_embed": MessageFull.video_embed ? MessageFull.video_embed : '',
                        "file_size": MessageFull.file_size ? MessageFull.file_size : '',
                        "file_name": MessageFull.file_name ? MessageFull.file_name : '',
                        "extension": MessageFull.extension ? MessageFull.extension : '',
                        "invitation_msg": MessageFull.invitation_msg ? MessageFull.invitation_msg : 0,
                    }
                    arr.push(obj)
                    setChatData(arr)
                    // setRecievingMessage('')
                    setMessageFull('')
                    let element = document.getElementById(`for_scroll`)
                    element.scrollIntoView()
                }
            }
        }
        else {
            // setRecievingMessage('')
            setMessageFull('')
        }

    }, [MessageFull])
    /////////////////////

    const cb = (val) => {
        let arr = [...val]
        setChatArr(arr)
    }

    const Cb_chat_details = (val, name) => {
        // debugger
        let arr = val.reverse()
        let concat_arr = [...arr, ...chat_data]
        setChatData(concat_arr)
        Cb2(name)
        if (scroll) {
            let element = document.getElementById(`for_scroll`)
            element.scrollIntoView()
            setScroll(false)
        }
    }

    const Cb2 = (name) => {
        dispatch(recentChats(tokenData.token, '', cb))
        setChannelName(name)
        echo.channel(name).listen('ChatEvent', (e) => {
            setRecievingMessageUser(e.user_id)
            setMessageFull(e)
            console.log("log event", e)
        });
    }


    const ShowGroupInterface = () => {
        if (channel_id) {
            echo.leaveChannel(channel_name);
            dispatch(channelUserStatus(tokenData.token, channel_id))
            setChannelName('')
        }
        setChatData([])
        setMessage('')
        setShowPersonChat(false)
        setShowGroupChat(false)
        setShowPeople(false)
        dispatch(setNewActionData('channel_id', ''))
        ////record
        Mp3Recorder.stop()
        setIsRecording(false)
        setBlobURL(false)
        serRecordedFile('')
        clearInterval(increment.current)
        setTimer(0)
        setShowGroup((pre) => !pre)
    }

    const ShowPeopleInterface = () => {
        if (channel_id) {
            echo.leaveChannel(channel_name);
            dispatch(channelUserStatus(tokenData.token, channel_id))
            setChannelName('')
        }
        dispatch(setNewActionData('group_members', []))
        setOpen(false)
        setChatData([])
        setMessage('')
        setShowPersonChat(false)
        setShowGroupChat(false)
        setShowGroup(false)
        ////record
        Mp3Recorder.stop()
        setIsRecording(false)
        setBlobURL(false)
        serRecordedFile('')
        clearInterval(increment.current)
        setTimer(0)
        dispatch(setNewActionData('channel_id', ''))
        setShowPeople((pre) => !pre)
    }

    const ShowRecent = () => {
        setGroupName('')
        setSelectedMembers([])
        setShowGroup(false)
        setShowPeople(false)
        dispatch(recentChats(tokenData.token, '', cb))
        CloseSearchAllUsers()
    }


    const PopulateChat = (val) => {
        if (channel_id) {
            echo.leaveChannel(channel_name);
            dispatch(channelUserStatus(tokenData.token, channel_id))
            setChannelName('')
        }
        setMessage('')
        setOpen(false)
        setAttachmentDiv(false)
        setShowGroupDetails(false)
        setShowPeople(false)
        setChatData([])

        /////record
        Mp3Recorder.stop()
        setIsRecording(false)
        setBlobURL(false)
        serRecordedFile('')
        clearInterval(increment.current)
        setTimer(0)
        if (val.group_id) {
            dispatch(setNewActionData('channel_id', ''))
            setShowGroupChat(true)
            setShowPersonChat(false)
            setScroll(true)
            setRecieverId('')
            setGroupId(val.group_id)
            if (page !== 1) {
                dispatch(setNewActionData('chat_details_page', 1))
            } else {
                setGroupIdChange((pre) => !pre)
            }
        }
        else {
            dispatch(setNewActionData('channel_id', ''))
            setRecieverId(val.receiver_id)
            setGroupId('')
            setShowPersonChat(true)
            setShowGroupChat(false)
            setScroll(true)
            if (page !== 1) {
                dispatch(setNewActionData('chat_details_page', 1))
            } else {
                setRecieverIdChange((pre) => !pre)
            }
        }
    }

    const LoadMore = () => {
        dispatch(setNewActionData('chat_details_page', page + 1))
    }

    const HandleMessage = (e) => {
        setMessage(e.target.value)
    }

    const SendMessage = (e) => {
        e.preventDefault()
        if (message.trim().length !== 0) {
            let msg = message
            let obj = {
                'created_time': new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }),
                'is_right': true,
                'message': msg,
                'type': 'text'
            }
            let arr = [...chat_data]
            arr.push(obj)
            setChatData(arr)
            setMessage('')
            if (show_persons_chat) {
                dispatch(createChat(tokenData.token, msg, reciever_id, 'single'))
            }
            else {
                dispatch(createChat(tokenData.token, msg, group_id, 'group'))
            }
            let element = document.getElementById(`for_scroll`)
            element.scrollIntoView()
        }
    }

    const AddToGroup = (val) => {
        let arr = [...selected_members]
        arr.push(val)
        setSelectedMembers(arr)
    }

    const RemoveFromGroupFn = (val) => {
        let arr = [...selected_members]
        let filtered = arr.filter(i => i.receiver_id !== val.receiver_id)
        setSelectedMembers(filtered)
    }

    const CreateGroup = () => {
        let arr = []
        if (group_name) {
            for (let i of selected_members) {
                arr.push(i.receiver_id)
            }
            dispatch(createGroup(tokenData.token, group_name, arr, cb_group))
        }
        else {
            toast.error("Group name is required");
        }
    }

    const HandleGroupName = (e) => {
        setGroupName(e.target.value)
    }

    const cb_group = () => {
        groupCreated(true)
        // dispatch(recentChats(tokenData.token, '', cb))
        ShowRecent()
    }

    const showSearch = () => {
        setRecentSearch('')
        setShowSearch((pre) => !pre)
    }

    const CloseSearch = () => {
        setRecentSearch('')
        setShowSearch(false)
        dispatch(recentChats(tokenData.token, '', cb))
    }

    const HandleChangeSearch = (e) => {
        if (e.target.value) {
            setRecentSearch(e.target.value)
            let data = [...Recent_chat_data]
            let filter = data.filter(i => i.group_name ? i.group_name.toUpperCase().includes(e.target.value.toUpperCase()) : i.recent_user.toUpperCase().includes(e.target.value.toUpperCase()))
            setChatArr(filter)

        }
        else {
            setRecentSearch('')
            setChatArr(Recent_chat_data)
        }
    }

    const [SearchInput, setSearchInput] = useState('')
    const [UnAssigned, setUnAssigned] = useState([])

    const cbgroupassign = (val) => {
        setUnAssigned(val)
    }

    const searchGroupAssign = (e) => {
        if (e.target.value) {
            setSearchInput(e.target.value)
            let data = [...new_member_list]
            let filter = data.filter(i => i.full_name.toUpperCase().includes(e.target.value.toUpperCase()))
            // setChatArr(filter)
            setUnAssigned(filter)

        }
        else {
            setSearchInput('')
            //setChatArr(Recent_chat_data)
            setUnAssigned(new_member_list)
        }
    }

    const clearSearch = () => {
        setSearchInput('')
        setUnAssigned(new_member_list)
    }

    const showSearchAllUsers = () => {
        setSearchQuery('')
        setShowSearchAll((pre) => !pre)
    }

    const CloseSearchAllUsers = () => {
        setSearchQuery('')
        setShowSearchAll(false)
        //dispatch(recentChats(tokenData.token, '', cb))
        // dispatch(chatableUsers(tokenData.token, cbchatable))
        setUsers(users_data)
    }

    const HandleChangeSearchAllUser = (e) => {
        if (e.target.value) {
            // setRecentSearch(e.target.value)
            setSearchQuery(e.target.value)
            let data = [...users_data]
            let filter = data.filter(i => i.full_name.toUpperCase().includes(e.target.value.toUpperCase()))
            //setChatArr(filter)
            setUsers(filter)
        }
        else {
            // setRecentSearch('')
            setSearchQuery('')
            // setChatArr(Recent_chat_data)
            setUsers(users_data)

        }
    }

    const ViewGroupDetail = () => {
        if (channel_name) {
            echo.leaveChannel(channel_name);
            setChannelName('')
        }
        ////record
        Mp3Recorder.stop()
        setIsRecording(false)
        setBlobURL(false)
        serRecordedFile('')
        clearInterval(increment.current)
        setTimer(0)

        setChatData([])
        setScroll(true)
        setShowGroupDetails(true)
    }

    const CloseGroupDetails = () => {
        if (page !== 1) {
            dispatch(setNewActionData('chat_details_page', 1))
        } else {
            setGroupIdChange((pre) => !pre)
        }
        setShowGroupDetails(false)
    }

    ////////////file upload//////////////

    const [Preview, setPreview] = useState({
        active: false,
        file: null,
        type: null
    })

    const handleOpenPreview = (type, file) => {
        setPreview({ ...Preview, active: true, file: file, type: type })
    }

    const handleClosePreview = () => {
        // Swal.fire('The Internet?', 'That thing is still around?', 'question')
        // console.log("called  swal")
        cancelUpload()
        setPreview({ ...Preview, active: false, type: '', file: '' })
        // console.log("called cancel")
    }

    const cancelFileUpload = useRef(null);
    const handleUpload = () => {
        dispatch({ type: 'RESET_PROGRESS_BAR' })
        // setPreview({ ...Preview, active: false })
        // console.log("files console", files)
        const formData = new FormData();

        formData.append('file', Preview.file)
        formData.append('type', Preview.type)

        if (show_persons_chat) {

            formData.append('receiver_id', reciever_id)
        } else {
            formData.append('group_id', group_id)

        }

        dispatch(uploadMediaFiles(tokenData.token, formData, uploadcb, cancelFileUpload))

        // let arr = [...chat_data]
        // let obj = {
        //     'created_time': new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }),
        //     'is_right': true,
        //     'type': 'uploading',
        //     'is_uploading': true
        // } 
        // arr.push(obj)
        // setChatData(arr)

    }
    const cancelUpload = () => {
        if (cancelFileUpload.current)
            cancelFileUpload.current("User has canceled the file upload.");
    };
    //console.log("loggin chat details",chat_data)
    const uploadcb = () => {
        dispatch({ type: 'RESET_PROGRESS_BAR' })
        setPreview({ ...Preview, active: false, type: null })
        setAttachmentDiv(false)
        if (show_persons_chat) {
            if (page === 1) {
                dispatch(chatDetails(tokenData.token, '', reciever_id, page, refresh))
            }
            else {
                setScroll(true)
                setChatData([])
                dispatch(setNewActionData('chat_details_page', 1))
            }


        } else {
            if (page === 1) {
                dispatch(chatDetails(tokenData.token, group_id, '', page, refresh))
            } else {
                setScroll(true)
                setChatData([])
                dispatch(setNewActionData('chat_details_page', 1))
            }
        }
    }

    const refresh = (val) => {
        let arr = val.reverse()
        setChatData(arr)
        let element = document.getElementById(`for_scroll`)
        element.scrollIntoView()
        setScroll(false)
    }
    /////////////////////group member handling///////////////
    const AddGroupMembers = () => {
        dispatch(unassignedUsersInGroup(tokenData.token, group_id, cbgroupassign))
        setOpen(true)
    }



    const handleDelete = (val) => {
        let arr = [...selected]
        let filtered = arr.filter(id => id !== val.receiver_id)
        setSelected(filtered)

        let arr2 = [...selected_full]
        let filtered2 = arr2.filter(i => i.receiver_id !== val.receiver_id)
        setSelectedFull(filtered2)
    }
    const CloseAddUserModel = () => {
        setSelected([])
        setSelectedFull([])
        setOpen(false)
        clearSearch()
    }

    const UpdateGroup = () => {
        dispatch(addUsers(tokenData.token, group_id, selected, Cb_adduser))
    }

    const SelectMember = (val) => {
        if (selected.includes(val.receiver_id)) {
            let arr = [...selected]
            let filtered = arr.filter(id => id !== val.receiver_id)
            setSelected(filtered)

            let arr2 = [...selected_full]
            let filtered2 = arr2.filter(i => i.receiver_id !== val.receiver_id)
            setSelectedFull(filtered2)
        }
        else {
            setSelected([...selected, val.receiver_id])
            setSelectedFull([...selected_full, val])
        }
    }

    const Cb_adduser = (message, arr) => {
        dispatch(setNewActionData('group_members', arr))
        dispatch(setNewActionData('group_members_copy', arr))
        dispatch(setNewActionData('new_all_member_list', []))
        setSelected([])
        setSelectedFull([])
        setOpen(false)
        setShow({ opn: true, msg: message })
        dispatch(recentChats(tokenData.token, '', cb))
    }

    const RemoveUserFromGroup = (val) => {
        dispatch(removeFromGroup(tokenData.token, val.receiver_id, group_id, CbDelete))
    }

    const CbDelete = (receiver_id, msg) => {
        if (channel_id) {
            echo.leaveChannel(channel_name);
            setChannelName('')
        }
        let arr = [...GroupMembers]
        let filtered = arr.filter((i) => { return i.receiver_id !== receiver_id })
        dispatch(setNewActionData('group_members', filtered))
        dispatch(setNewActionData('group_members_copy', filtered))
        setShow({ opn: true, msg: msg })
    }

    // to add support for more files add that files mime type here
    let ImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
    let VideoTypes = ['video/mp4', 'video/mpeg', 'application/vnd.apple.mpegurl', 'video/quicktime', 'video/x-ms-wmv', 'video/x-msvideo', 'video/avi', 'video/x-matroska', 'video/x-flv', 'video/msvideo', 'video/x-msvideo']
    let AudioTypes = ['audio/mpeg', 'audio/wav', 'audio/vnd.wav', 'audio/m4a', 'audio/x-flac,audio/flac', 'audio/x-ms-wma', 'audio/aac', 'audio/ogg']
    let DocumentTypes = ['application/pdf', 'application/epub+zip', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

    const handleFileSelect = (type, file) => {
        // console.log("log file", file)
        // console.log("mime type of file", file.type)  
        if (file) {

            switch (type) {
                case 'image':
                    if (ImageTypes.includes(file.type))
                        setPreview({ ...Preview, active: true, file: file, type: type })
                    else {
                        Swal.fire('File Not Valid', "Selected File Isn't a Valid Image File", 'error')
                    }
                    break;

                case 'video':
                    if (VideoTypes.includes(file.type))
                        setPreview({ ...Preview, active: true, file: file, type: type })
                    else {
                        Swal.fire('File Not Valid', "Selected File Isn't a Valid Video File", 'error')
                    }
                    break;

                case 'audio':
                    if (AudioTypes.includes(file.type))
                        setPreview({ ...Preview, active: true, file: file, type: type })
                    else {
                        Swal.fire('File Not Valid', "Selected File Isn't a Valid Audio File", 'error')
                    }
                    break;

                case 'document':
                    if (DocumentTypes.includes(file.type))
                        setPreview({ ...Preview, active: true, file: file, type: type })
                    else {
                        Swal.fire('File Not Valid', "Selected File Isn't a Valid Document File", 'error')
                    }
                    break;


                default:
                    break;
            }
        }

    }

    const ShowTextField = () => {
        let exist = GroupMembers_Copy.find(x => x.receiver_id === current_user_id)
        if (exist) {
            return true
        }
        return false
    }

    const Start = () => {
        if (isBlocked) {
            navigator.mediaDevices.getUserMedia({ audio: true },
                () => {
                    setIsBlocked(false)
                    Record()
                },
                () => {
                    console.log('Permission Denied');
                    setIsBlocked(true)
                },
            );
        } else {
            Record()
        }
    };

    const Record = () => {
        setAttachmentDiv(false)
        Mp3Recorder
            .start()
            .then(() => {
                setIsRecording(true)
                increment.current = setInterval(() => {
                    setTimer((timer) => timer + 1)
                }, 1000)
            }).catch((e) => console.error(e));
    }

    const Stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const blobURL = URL.createObjectURL(blob)
                const file = new File(buffer, GenerateDate(), {
                    type: blob.type,
                    lastModified: Date.now()
                })

                clearInterval(increment.current)
                setTimer(0)
                serRecordedFile(file)
                setBlobURL(blobURL)
                setIsRecording(false)

            }).catch((e) => {
                alert('We could not retrieve your message');
                console.log(e)
            }
            );
    };

    const formatTime = () => {
        const getSeconds = `0${(timer % 60)}`.slice(-2)
        const minutes = `${Math.floor(timer / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

        return `${getHours} : ${getMinutes} : ${getSeconds}`
    }

    const RemoveRecordData = () => {
        Mp3Recorder.stop()
        setIsRecording(false)
        setBlobURL(false)
        serRecordedFile('')
        clearInterval(increment.current)
        setTimer(0)
        setPlaying(false)
    }

    const SendAudio = () => {

        // setPreview({ active: true, file: recorded_file, type: 'audio' })
        setIsRecording(false)
        setBlobURL(false)
        dispatch({ type: 'RESET_PROGRESS_BAR' })
        const formData = new FormData();
        formData.append('file', recorded_file)
        formData.append('type', 'recording')
        if (show_persons_chat) {
            formData.append('receiver_id', reciever_id)
        } else {
            formData.append('group_id', group_id)
        }
        serRecordedFile('')
        dispatch(uploadMediaFiles(tokenData.token, formData, uploadcb, cancelFileUpload))
    }

    const GenerateDate = () => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes();
        return date + ' ' + time + '.mp3';
    }

    const Playing = () => {
        Mp3Recorder.stop()
        setPlaying(true)
        setIsRecording(false)
        setBlobURL(false)
        serRecordedFile('')
        clearInterval(increment.current)
        setTimer(0)
    }

    return (
        <div className='chat_wrapper'>
            <div
                className={cx("app-inner-layout chat-layout", {
                    'open-mobile-menu': active,
                })}>

                <div className="app-inner-layout__wrapper" style={{ height: 'auto', minHeight: '80vh' }} >

                    {
                        show_persons_chat ?
                            <ChatAreaPerson
                                active={active}
                                setActive={() => { setActive(!active) }}
                                show_attachment_div={show_attachment_div}
                                setAttachmentDiv={() => { setAttachmentDiv(!show_attachment_div) }}
                                chat_name={chat_name}
                                chat_data={chat_data}
                                chat_next_page={chat_next_page}
                                LoadMore={LoadMore}
                                HandleMessage={HandleMessage}
                                message={message}
                                SendMessage={SendMessage}

                                handleOpenPreview={handleOpenPreview}
                                handleClosePreview={handleClosePreview}
                                Preview={Preview}
                                handleUpload={handleUpload}
                                cancelUpload={cancelUpload}
                                handleFileSelect={handleFileSelect}

                                HandleAudioRecord={Start}
                                record={isRecording}
                                StopRecord={Stop}
                                recoreded_data={blobURL}
                                RemoveRecordData={RemoveRecordData}
                                SendAudio={SendAudio}
                                formatTime={formatTime}
                                Playing={Playing}
                                IsPlaying={playing}
                                EndPauseHandle={() => setPlaying(false)}
                            />
                            :
                            show_grpup_chat ?
                                <ChatAreaGroup
                                    active={active}
                                    setActive={() => { setActive(!active) }}
                                    show_attachment_div={show_attachment_div}
                                    setAttachmentDiv={() => { setAttachmentDiv(!show_attachment_div) }}
                                    chat_name={chat_name}
                                    chat_data={chat_data}
                                    chat_next_page={chat_next_page}
                                    LoadMore={LoadMore}
                                    HandleMessage={HandleMessage}
                                    message={message}
                                    SendMessage={SendMessage}
                                    ViewDetail={ViewGroupDetail}
                                    GroupMembers={GroupMembers}
                                    show_group_detais={show_group_detais}
                                    CloseGroupDetails={CloseGroupDetails}
                                    AddMembers={AddGroupMembers}
                                    handleOpenPreview={handleOpenPreview}
                                    handleClosePreview={handleClosePreview}
                                    Preview={Preview}
                                    handleUpload={handleUpload}
                                    handleFileSelect={handleFileSelect}
                                    RemoveUser={RemoveUserFromGroup}
                                    ShowTextField={ShowTextField}
                                    current_user_id={current_user_id}

                                    HandleAudioRecord={Start}
                                    record={isRecording}
                                    StopRecord={Stop}
                                    recoreded_data={blobURL}
                                    RemoveRecordData={RemoveRecordData}
                                    SendAudio={SendAudio}
                                    formatTime={formatTime}

                                    Playing={Playing}
                                    IsPlaying={playing}
                                    EndPauseHandle={() => setPlaying(false)}
                                />
                                :
                                show_group ?
                                    <GroupCreation
                                        active={active}
                                        setActive={() => { setActive(!active) }}
                                        selected_members={selected_members}
                                        remove_member={RemoveFromGroupFn}
                                        CreateGroup={CreateGroup}
                                        HandleGroupName={HandleGroupName}
                                        group_name={group_name}
                                    />
                                    :
                                    <DummyChatArea
                                        active={active}
                                        setActive={() => { setActive(!active) }}
                                    />
                    }


                    {
                        show_group || show_people ?
                            <AllPeopleList
                                ShowRecent={ShowRecent}
                                show_group={show_group}
                                show_people={show_people}
                                // users_data={users_data}
                                users_data={Users}
                                EnableNewChat={PopulateChat}
                                AddToGroup={AddToGroup}
                                selected_members={selected_members}

                                showSearch={showSearchAllUsers}
                                show_search_box={show_search_all}
                                CloseSearch={CloseSearchAllUsers}
                                SearchQuery={SearchQuery}
                                HandleChangeSearch={HandleChangeSearchAllUser}
                            />
                            :
                            <RecentChatList
                                recent_list={chatArr}
                                ShowGroupInterface={ShowGroupInterface}
                                ShowPeopleInterface={ShowPeopleInterface}
                                PopulateChat={PopulateChat}
                                showSearch={showSearch}
                                show_search_box={show_search}
                                CloseSearch={CloseSearch}
                                recent_search={recent_search}
                                HandleChangeSearch={HandleChangeSearch}
                                role={tokenData.role}
                            />
                    }

                </div>
            </div>
            <ToastContainer autoClose={2000} position="bottom-right" />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={group_created}
                onClose={() => { groupCreated(false) }}
                message="Group created successfully"
                autoHideDuration={2000}
            />

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={show.opn}
                onClose={() => { setShow({ opn: false, msg: '' }) }}
                message={show.msg ? show.msg : "Completed successfully!"}
                autoHideDuration={2000}
            />

            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle style={{ textAlign: 'center', padding: '10px 20px' }}>
                    Add Members
                </DialogTitle>
                <Divider />

                <DialogContent>
                    <Row>
                        <Col xs={12}>
                            <div className='wrap-chat-main' style={{ height: '100px', overflowY: 'auto', width: '100%', border: '1px solid #777', borderRadius: '8px', padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word', margin: '5px 0px' }}>
                                {
                                    selected_full.map((val, ky) => {
                                        return (
                                            <Chip
                                                key={ky}
                                                label={val.full_name}
                                                onDelete={() => { handleDelete(val) }}
                                                color='primary'
                                                style={{ marginLeft: '3px', marginTop: '3px' }}
                                                size='small'
                                            />
                                        )
                                    })
                                }
                            </div>
                        </Col>

                        <Col xs={12} >
                            <div style={{ width: '100%', marginBottom: '10px' }} >
                                <TextField
                                    fullWidth
                                    // autoFocus
                                    size='medium'
                                    label='search'
                                    value={SearchInput}
                                    onChange={searchGroupAssign}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                {SearchInput?.length > 0 ? <CloseIcon onClick={clearSearch} style={{ cursor: 'pointer' }} /> : ''}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </Col>

                        <Col xs={12}>
                            {
                                loading_members ?
                                    "Loading..."
                                    :
                                    <div className='wrap-chat-main' style={{ height: '180px', overflowY: 'auto', width: '100%', wordWrap: 'break-word' }}>
                                        {
                                            // new_member_list.map((val, ky) => {
                                            UnAssigned.map((val, ky) => {
                                                return (
                                                    <div key={ky} style={{ padding: '0 1.5rem' }}>
                                                        <Checkbox
                                                            checked={selected.includes(val.receiver_id)}
                                                            color='primary'
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            onChange={() => { SelectMember(val) }}
                                                            size='small'
                                                        />
                                                        <span>{val.full_name}</span>
                                                        {
                                                            val.role_name === 'teacher' ?
                                                                <span className='chat_role_icons'>
                                                                    (T)
                                                                </span>
                                                                :
                                                                val.role_name === 'principal' ?
                                                                    <span className='chat_role_icons'>
                                                                        (A)
                                                                    </span>
                                                                    :
                                                                    val.role_name === 'student' ?
                                                                        <span className='chat_role_icons'>
                                                                            (C)
                                                                        </span>
                                                                        :
                                                                        val.role_name === 'staff' ?
                                                                            <span className='chat_role_icons'>
                                                                                (S)
                                                                            </span>
                                                                            :
                                                                            <span className='chat_role_icons'>
                                                                                (G)
                                                                            </span>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                            }

                        </Col>
                        <Col xs={12} style={{ textAlign: 'right' }}>
                            <LaddaButton
                                data-style={ZOOM_IN}
                                className='btn btn-primary mr-1'
                                onClick={UpdateGroup}
                                loading={add_user_loader}
                                disabled={selected.length === 0 ? true : false}
                            >
                                Add To Group
                            </LaddaButton>

                            <LaddaButton className='btn btn-danger' onClick={CloseAddUserModel}>
                                Close
                            </LaddaButton>
                        </Col>
                    </Row>

                </DialogContent>
            </Dialog>

        </div>
    )
}

export default Index
