import React from 'react'
import Tabs, { TabPane } from 'rc-tabs';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
// import SpecialClass from './specialClass'
import Holidays from './holydays'
// import ListStudents from './listStudents'
// import AssignStudents from './assignStudents'
import EditClass from './editClass'
const Index = () => {
    return (

        <Tabs
            defaultActiveKey="1"
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent />}
        >
            <TabPane tab='Edit Session' key="1"><EditClass /></TabPane>
            <TabPane tab='Holidays' key="2"><Holidays /></TabPane>
            {/* <TabPane tab='Special Class' key="3"><SpecialClass /></TabPane> */}
            {/* <TabPane tab='Add Students' key="2"><AssignStudents /></TabPane> */}
        </Tabs>


    )
}

export default Index
