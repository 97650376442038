import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CustomInput, Form, FormGroup, Label, FormFeedback, Input } from 'reactstrap'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PageTitle from '../HeaderTitle/PageTitle'
import './subject.scss'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getGradeList, populateDegree } from '../../redux/Actions/signUp'
import { createSubject, populateRegisteredTeachers, populateAllCollegeSubjects, updateSubject, deleteSubject, populateAllEnterpriseCollegeSubjects } from '../../redux/Actions/homeTeacher'
import serialize from 'form-serialize'
import Table from '../common/DataTable'
import Swal from 'sweetalert2'
import { requiredValidator, nameValid } from '../../utilities/Validator';






const Index = () => {
    const dispatch = useDispatch()
    const Grades = useSelector(({ signUp }) => signUp)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    var columns
    if (tokenData.enterpriseUser) {
        columns = [
            // { title: 'teacher', field: 'first_name', editable: 'never' },
            { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
            { title: 'Course', field: 'degree_name', editable: 'never' },
            { title: 'Specialization', field: 'grade_name', editable: 'never' },


        ]
    } else {
        columns = [
            { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
            { title: 'Course', field: 'degree_name', editable: 'never' },
            { title: 'Specialization', field: 'grade_name', editable: 'never' },



        ]

    }
    useEffect(() => {
        dispatch(populateDegree())

        if (tokenData.enterpriseUser) {
            dispatch(populateAllEnterpriseCollegeSubjects(tokenData.token))

        } else {
            dispatch(populateAllCollegeSubjects(tokenData.token))

        }

        // dispatch(populateRegisteredTeachers(tokenData.token))



        return () => {
            setInputField({})
            setError({})
        }
    }, [])
    const [inputField, setInputField] = useState({
        degree_id: '',
        grade_id:'',
        subject_name: ''
    })
    const handleDegree = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
        dispatch(getGradeList(value, 2))

    }
    const onEdit = (rowData) => {
        console.log('row-',rowData)
        if(rowData.subject_name){
            dispatch(updateSubject(tokenData.token, rowData))
        }
        else{
            Swal.fire({
                icon: 'warning',
                title: 'Declined',
                text:'Subject name cannot be emty.'
            } 
            )
        }
        

    }

    const onDelete = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',

            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteSubject(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
    }
    const [error, setError] = useState({})
    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        const err = {
            degree_id: requiredValidator(inputField.degree_id),
            grade_id: requiredValidator(inputField.grade_id),
            subject_name: nameValid(inputField.subject_name)

        }
        if (!err.degree_id && !err.grade_id && !err.subject_name) {
            dispatch(createSubject(tokenData.token, body, reset))
            setError(err)
        } else {
            setError(err)
        }

    }
    const reset = () => {
        setInputField({ 
            degree_id: '',
            grade_id:'',
            subject_name: ''
        })
        document.getElementById("create-form-college").reset();
    }
    return (
        <>




            <Form onSubmit={formSubmit} id="create-form-college">


                <Card>
                    <Row className="jms-subject-row-pad">
                        {/* {tokenData.enterpriseUser ? <>

<Col sm={3}>
        <Label for="exampleNameF">
            <span className="text-danger">*</span>
            {' '}Select Teacher
         </Label>

        <Input type="select"
            // onChange={(e) => handleChangeGrade(e)}
            name='teacher_id'
            required
        // value={this.state.loaderType}
        >
                                 <option value={''}>{'select'}</option>
                                    {tStore.registeredTeachers ?
                                        tStore.registeredTeachers.map(registeredTeachers => {
                                            return (
                                                <option value={registeredTeachers.user_id}>{registeredTeachers.first_name+" "+registeredTeachers.last_name}</option>
                                                //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                            )
                                        }) : []
                                    }

        </Input>
    </Col>
</> :null

} */}
                        <Col md={3}>
                            <input type="hidden" value={2} name="category_id" />
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">Course</Label>
                                <CustomInput type="select" name="degree_id" onChange={handleDegree}
                                    // required
                                    value={inputField.degree_id}
                                    invalid={error.degree_id}

                                >
                                    <option value="">Select</option>
                                    {
                                        Grades.degree ? Grades.degree.map((data) => {
                                            return (
                                                <option value={data.degree_id} key={data.degree_id}>{data.degree_name}</option>

                                            )
                                        })
                                            : []
                                    }
                                </CustomInput>
                                <FormFeedback >{error.degree_id}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">Specialization</Label>
                                <CustomInput type="select" name="grade_id"
                                    // required
                                    onChange={handleInputChange}
                                    invalid={error.grade_id}
                                    value={inputField.grade_id}
                                >
                                    <option value="">Select</option>

                                    {
                                        Grades.gradeCollege.grades ? Grades.gradeCollege.grades.map((data) => {
                                            return (
                                                <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                            )
                                        })
                                            : []
                                    }


                                </CustomInput>
                                <FormFeedback >{error.grade_id}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">Subject</Label>
                                <Input
                                    //  required 
                                    type="text" name="subject_name"
                                    onChange={handleInputChange}
                                    invalid={error.subject_name}
                                    value={inputField.subject_name}
                                />
                                <FormFeedback >{error.subject_name}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Subject</Label>

                                <LaddaButton className="mb-2 mr-2 btn btn-danger"
                                    loading={tStore.createSubLoader}
                                    // onClick={formSubmit}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                    style={{ whiteSpace: 'nowrap', marginRight: '0px' }}

                                >
                                    Add Subject
                                            </LaddaButton>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            </Form>
            <hr />
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        title={'Subjects'}
                        columns={columns}
                        actions={"add_subject"}
                        data={tStore.subjectList}
                        loader={tStore.collegeSubLoader}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </Col>

            </Row>



        </>

    )
}

export default Index
