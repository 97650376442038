import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import Table from '../table';
import { showStaff, deleteUser, updateUser } from '../../../redux/Actions/videolist'
import Modals from '../../common/Modal';
import Teacher from '../Teacher';
import EditStaff from './EditStaff';
import EditPrivilege from '../EditPrivilege';
import { nameValid, emailValidator, mobileValidator, requiredValidator } from '../../../utilities/Validator';
const columns = [
    // { title: '#', field: 'id' },
    {
        title: 'First Name ', field: 'first_name', editable: 'onUpdate'
    },
    { title: ' Last Name', field: 'last_name', editable: 'onUpdate' },
    // { title: 'Subject', field: 'privilege' },
    { title: 'Email', field: 'enterprise_email', editable: 'onUpdate' },
    { title: 'Password', field: 'user_password', editable: 'onUpdate' },

    { title: 'Mobile', field: 'enterprise_mobile', editable: 'onUpdate' },
    // { title: 'Roll Number', field: 'enrollment_no' },
]


const ShowStaff = () => {
    const dispatch = useDispatch()
    const tStore = useSelector(({ videolist }) => videolist)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    useEffect(() => {
        dispatch(showStaff(tokenData.token))

    }, [])

    const [openModal, setOpenModal] = useState(false)
    const [openPermissionModal, setPermissionModal] = useState(false)
    const [userId, setUserId] = useState()

    const handleCloseStudentModal = () => {
        setOpenModal(false)
    }

    // const onEdit = (user_id) =>{
    //     console.log("onEdit ",user_id);
    //     setUserId(user_id);
    //     setOpenModal(true)


    // }

    const onEdit = (rowData) => {


        const err = {
            enterprise_email: emailValidator(rowData.enterprise_email),
            first_name: nameValid(rowData.first_name),
            last_name: nameValid(rowData.last_name),
            user_password: requiredValidator(rowData.user_password),
            enterprise_mobile: mobileValidator(rowData.enterprise_mobile),

        }
        if (err.enterprise_email) {
            Swal.fire(
                'Email',
                err.enterprise_email,
                'warning'
            )
        } else if (err.first_name) {
            Swal.fire(
                'First name',
                err.first_name,
                'warning'
            )
        } else if (err.last_name) {
            Swal.fire(
                'Last name',
                err.last_name,
                'warning'
            )
        } else if (err.user_password) {
            Swal.fire(
                'Password',
                err.user_password,
                'warning'
            )
        } else if (err.enterprise_mobile) {
            Swal.fire(
                'Mobile',
                err.enterprise_mobile,
                'warning'
            )
        } else {
            dispatch(updateUser(tokenData.token, rowData))

        }



    }
    const [userID, setUserID] = useState()
    const onPermission = (user) => {
        dispatch({ type: 'RESET_EDIT_PRIVILEGE' })
        setPermissionModal(true)
        setUserID(user)
    }

    const handleClosePermissionModal = () => {
        setPermissionModal(false)
    }

    // const onDelete = (classId) => {
    //     dispatch(deleteUser(tokenData.token))
    // }
    const onDelete = (user_id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',

            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteUser(tokenData.token, user_id, cb))

                Swal.fire(
                    'Deleted!',
                    'Deleted staff successfully',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
    }

    const cb = () => {
        dispatch(showStaff(tokenData.token))
    }

    return (
        <>
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    {
                        tokenData.role === 'staff' ?
                            <Table
                                title={'Staffs'}
                                columns={columns}
                                data={tStore.staffList}
                                loader={tStore.userSubLoader}
                                role={tokenData.role}
                            />
                            :
                            <Table
                                title={'Staffs'}
                                columns={columns}
                                actions={"show_user"}
                                data={tStore.staffList}
                                loader={tStore.userSubLoader}
                                onEdit={onEdit}
                                onPermission={(user_id) => onPermission(user_id)}
                                onDelete={onDelete}
                            />
                    }

                </Col>

            </Row>
            <Modals
                open={openModal}
                handleClose={handleCloseStudentModal}
                title={`Edit Form`}
            >
                <EditStaff
                    user_id={userId}
                />
            </Modals>
            <Modals
                open={openPermissionModal}
                handleClose={handleClosePermissionModal}
                title={`Permission`}
            >
                <EditPrivilege
                    user_id={userID}
                    handleClose={handleClosePermissionModal}
                    menu={'staff'}
                />
            </Modals>
        </>
    )
}

export default ShowStaff;