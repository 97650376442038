import React, { useState, useEffect } from "react";
import PageTitle from "../../HeaderTitle/PageTitle";
import { Card, Input, Form, Label, Button, Col, Row, Badge } from "reactstrap";
import "./addInstructions.scss";
import { useDispatch, useSelector } from "react-redux";
import { addInstructions, getInstructions } from "../../../redux/Actions/exam";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Index = () => {
  const dispatch = useDispatch();
  const { exam_id } = useParams();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const examStore = useSelector(({ exam }) => exam);
  console.log("instruction", examStore.instructions);

  useEffect(() => {
    dispatch(getInstructions(cb, tokenData.token));
  }, []);

  const cb = (instruction) => {
    setinstructions(instruction);
  };

  const [inputField, setinputField] = useState();
  const [instructions, setinstructions] = useState(examStore.instructions);

  function handleChange(e) {
    setinputField(e.target.value);
  }

  function handleAdd(e) {
    e.preventDefault();
    if (!inputField) {
      Swal.fire({
        icon: "warning",
        title: "Required",
        text: "Enter instruction to add",
        confirmButtonText: "Okay",
      });
    } else {
      setinstructions(instructions.concat({ instruction: inputField }));
      setinputField("");
    }
  }

  function handleDelete(e, index, instr) {
    e.preventDefault();
    setinstructions(instructions.filter((item) => item !== instr));
  }

  function handleSubmit(e) {
    e.preventDefault();

    dispatch(addInstructions(tokenData.token, instructions, exam_id));
  }

  return (
    <div>
      <PageTitle
        heading="Add instructions for exam"
        subheading="These instructions will be shown at the beginning of exam"
        icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
      />
      <Form>
        <Card className="mb-4" style={{ padding: "4px 4px 34px 4px" }}>
          <Col className="pt-4 pb-0" md={12}>
            <p>Write instruction here</p>
          </Col>
          <Row>
            <Col className="align-center pl-4 my-auto" md={10}>
              <Input
                onChange={handleChange}
                type="text"
                name="instruction"
                value={inputField}
              />
            </Col>
            <Col md={2}>
              <Button className="align-center my-auto" onClick={handleAdd}>
                Add
              </Button>
            </Col>
          </Row>
        </Card>

        <Row className="jms-container">
          <Card className="jms-instruction">
            <Row className="p-4">
              <Col md={12}>
                <p
                  style={{
                    fontWeight: 700,
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  Instructions
                </p>
                <ol>
                  {instructions.map((instr, index) => (
                    <>
                      <Row>
                        <Col md={11}>
                          <li className="mb-3">
                            {instr.instruction} {"  "}
                          </li>
                        </Col>
                        <Col md={1}>
                          <Button
                            color="danger"
                            size="xs"
                            onClick={(e) => handleDelete(e, index, instr)}
                          >
                            <FontAwesomeIcon
                              size="xs"
                              color="white"
                              icon={faTrash}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ))}
                </ol>
              </Col>
              <Col md={12} style={{ textAlign: "center" }}>
                <hr />
                <Button
                  color="primary"
                  className="btn-pill btn-shadow btn-wide fsize-1"
                  size="lg"
                  onClick={(e) => handleSubmit(e)}
                  disabled={""}
                >
                  <Row>
                    <span className="mr-1">SUBMIT</span>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Card>
        </Row>
      </Form>
    </div>
  );
};

export default Index;
