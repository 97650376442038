import React, { useState, useRef } from 'react'
import Swal from 'sweetalert2'
import { Row, Col, Card, Button } from "reactstrap";
import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay'
import pdfIcon from './pdf.png'
import docIcon from './document.png'
// import PDFViewer from './PDFViewer';
import ImageView from './imageViewer'
import Modals from '../../common/Modal'
import Viewer from 'viewerjs';

const MySubmission = (props) => {
    const { assignmentStore, downloadStudentFile } = props
    const [currentSelected, setCurrentSelected] = useState(0)
    const [imageboxIsOpen, setImageboxIsOpen] = useState(false)
    const [pdfboxIsOpen, setPdfboxIsOpen] = useState(false)
    const [pdfFile, setPdfFile] = useState(null)
    const ims = useRef(null)

    const openImage = (image, index) => {
        //debugger
        // alert(index)
        if (image.ext === "pdf") {

            // setPdfboxIsOpen(true)
            // setPdfFile(`https://cors-anywhere.herokuapp.com/${image.src}`)
            // setPdfFile(image.src)
            var link = document.createElement("a");
            link.href = image.src;
            link.target = "_blank"
            link.click();
            link.remove();
        } else if (image.ext === "png" || image.ext === "jpg" || image.ext === "jpeg") {
            //debugger
            // setImg(assignmentStore.specificStudentAssignmentData.files.filter(row => row.ext == "png"))
            setCurrentSelected(index)
            setImageboxIsOpen(true)
        } else {
            Swal.fire({
                icon: 'warning',
                //title: "Format not supported",
                text: "Do you want download file ?",
                confirmButtonText: 'Download',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',


            }).then((result) => {
                if (result.value) {
                    var link = document.createElement("a");
                    link.href = image.src;
                    // link.target = "_blank"
                    link.click();
                    link.remove();
                }



            })


        }

    }

    const toggleModal = () => {
        setImageboxIsOpen(!imageboxIsOpen);
    }
    let gallery = null

    const showImage = () => {
        //debugger;
        const ele2 = ims.current;
        if (!gallery) {
            gallery = new Viewer(ele2);
            gallery.show()
        } else {
            gallery.show()
        }
    }
    return (
        <LoadingOverlay tag="div" active={!assignmentStore.assignmentDetails ? true : false}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 0.5
                })
            }}
            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
            <Card className="assign-details-card">
                <h6><strong>My Submission</strong></h6><hr />
                <Row>
                    <Col md={4}>
                        <div className="assign-details-my-submission-mark size-l"><strong style={{ color: '#4756ea' }}>{assignmentStore.assignmentDetails.mark}</strong><span style={{ fontWeight: 600, color: 'gray' }}>{assignmentStore.assignmentDetails.mark !== null ? assignmentStore.assignmentDetails.grading_type === null || assignmentStore.assignmentDetails.grading_type == 'grading' ? null : `Marks/${assignmentStore.assignmentDetails.Total_mark}` : <strong style={{ fontSize: '20px' }}>Not evaluated</strong>}</span> </div>
                        <div className="assign-details-my-submission-mark">{assignmentStore.assignmentDetails ? assignmentStore.assignmentDetails.Submitted_on !== null ? assignmentStore.assignmentDetails.Submitted_on != null ?
                            <>Submitted on  <strong style={{ color: '#4756ea' }}>{assignmentStore.assignmentDetails.Submitted_on}</strong></>
                            : null : null : null}</div>
                        <div className="assign-details-my-submission-mark">
                            <Button
                                disabled={assignmentStore.assignmentDetails ? assignmentStore.assignmentDetails.Submitted_on != null ? false : true : null}
                                onClick={downloadStudentFile} style={{ width: '-webkit-fill-available' }} className="mb-2 mr-2 btn-icon" color="success"> <i className="pe-7s-download btn-icon-wrapper"> </i>Download</Button></div>
                    </Col>
                    <Col md={8} style={{ borderLeft: '1px solid #e5e5e5' }}>
                        <div>
                            <strong>Client Remarks : </strong>
                            <p style={{ height: 60, overflow: 'scroll' }}>{assignmentStore.assignmentDetails.Student_remark} </p>
                        </div>
                        <hr />
                        <div>
                            <strong> Therapist Remarks :</strong>
                            <p style={{ height: 60, overflow: 'scroll' }}>{assignmentStore.assignmentDetails.Teacher_remark} </p>

                        </div>

                    </Col>

                </Row>

                {
                    assignmentStore.assignmentImageStudent ?
                        <>
                            <Row>
                                <Col md={12}>
                                    <p style={{ padding: '10px 0px 0px 10px' }}><strong>Files</strong></p>
                                    <p>Documents</p>

                                    <hr />
                                </Col>
                                <Col md={12}>
                                    <Row style={{ padding: 10 }}>
                                        {assignmentStore.assignmentDocStudent ?
                                            assignmentStore.assignmentDocStudent.map((image, index) => {
                                                return (
                                                    <Col md={2} style={{ padding: 5 }}>
                                                        <div onClick={() => openImage(image, index)}
                                                            style={{
                                                                backgroundSize: 'cover',
                                                                //backgroundImage: `url(${image.src})`, 
                                                                // backgroundImage: `url(${image.ext == "pdf" ? pdfIcon : docIcon})`,

                                                                width: '-webkit-fill-available',
                                                                borderRadius: '5px',
                                                                border: '3px solid #e8e7e7',
                                                                height: 130,
                                                                display: 'flex'
                                                            }}>
                                                            <img src={image.ext == "pdf" ? pdfIcon : docIcon} width={70} height={70} style={{ display: 'block', margin: 'auto' }} />
                                                            {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                        </div>

                                                    </Col>

                                                )
                                            })

                                            : null
                                        }
                                    </Row>
                                    <p>Images</p>
                                    <hr />
                                    <div ref={ims} >

                                        <Row style={{ padding: 10 }}>
                                            {assignmentStore.assignmentImageStudent ?
                                                assignmentStore.assignmentImageStudent.map((image, index) => {
                                                    return (
                                                        // <Col md={2} style={{ padding: 5 }}>
                                                        //     <div onClick={() => openImage(image, index)}
                                                        //         style={{
                                                        //             backgroundSize: 'cover',
                                                        //             backgroundImage: `url(${image.ext != "png" && image.ext != "jpg" && image.ext != "jpeg" ? pdfIcon : image.src})`,
                                                        //             width: '-webkit-fill-available',
                                                        //             borderRadius: '5px',
                                                        //             border: '3px solid #e8e7e7',
                                                        //             height: 130
                                                        //         }}>

                                                        //         {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                        //     </div>

                                                        // </Col>
                                                        <Col md={2} style={{ padding: 5 }}>
                                                            <div
                                                                // onClick={() => openImage(image, index)}
                                                                onClick={showImage}

                                                                style={{
                                                                    backgroundSize: 'cover',
                                                                    backgroundImage: `url(${image.ext != "png" && image.ext != "jpg" && image.ext != "jpeg" ? pdfIcon : image.src})`,
                                                                    width: '-webkit-fill-available',
                                                                    borderRadius: '5px',
                                                                    border: '3px solid #e8e7e7',
                                                                    height: 130,
                                                                    width: 130

                                                                }}>
                                                                <img src={image.src} width={70} height={70} style={{ display: 'block', margin: 'auto' }} id={index} />

                                                                {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                            </div>

                                                        </Col>

                                                    )
                                                })

                                                : null
                                            }

                                        </Row>
                                    </div>
                                </Col>
                            </Row>


                            {/* {assignmentStore.assignmentImageStudent ?

                                <ImageView
                                    modalIsOpen={imageboxIsOpen}
                                    toggleModal={toggleModal}
                                    current={currentSelected}
                                    images={assignmentStore.assignmentImageStudent}
                                />
                                : null
                            } */}
                            <Modals
                                open={pdfboxIsOpen}
                                handleClose={() => setPdfboxIsOpen(false)}
                                // title={"Add Mark"}
                                size="lg"
                            >
                                {/* <iframe src={`https://docs.google.com/viewer?url=${pdfFile}&embedded=true`} frameborder="0" height="500px" width="100%"></iframe> */}

                                {/* <PDFViewer
                             file={pdfFile}
                                // file={'https://backend.mymots.com/assignment/H7gbZFaNjYRCA71BOlqtXLf0rAmCIjC7qbhGQA42.pdf' }


                                /> */}
                            </Modals>
                        </>
                        : null
                }


            </Card>
        </LoadingOverlay>
    )
}

export default MySubmission
