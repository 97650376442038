
import axios from 'axios'
import { development } from '../../config.json'
import moment from 'moment'
import Swal from 'sweetalert2'


// import { useHistory } from 'react-router-dom'

// import React from 'react'
// import { Redirect } from 'react-router-dom'






// export const updateClassCategoryType = (token, cb) => (dispatch) => {
//     const head = {
//         Accept: 'application/json',
//         Authorization: `Bearer ${token}`,
//     }
//     const data = {
//         teacher_id: "details"
//     }
//     dispatch({ type: 'PASSING_CLASS_TYPE_REQUEST' })
//     axios.post(`${development.baseUrl}updateClassCategoryType`, data, { headers: head })
//         .then(res => {
//             if (res.data.result) {
//                 dispatch({ type: 'PASSING_CLASS_TYPE_SUCCESS', payload: res.data })
//                 cb()
//             }
//         })
//         .catch(err => {
//             dispatch({ type: 'PASSING_CLASS_TYPE_FAILED', payload: err })
//         })
// }

export const getNotificationAlert = (token, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'WHATS_NEW_REQUEST' })
    axios.get(`${development.baseUrl}getNotificationAlert`, { headers: head })
        .then(res => {
            if (res.data.result) {
                // debugger
                dispatch({ type: 'WHATS_NEW_SUCCESS', payload: res.data.notification[0] })
                const whatsnewStore = JSON.parse(localStorage.getItem("whatsnew"));
                if (whatsnewStore) {
                    // const dt = moment().format('DD-MM-YYYY')
                    //const dt = moment(res.data.notification[0].date).format('DD-MM-YYYY')
                    if (whatsnewStore.date == res.data.notification[0].date) {
                        if (whatsnewStore.count <= 1) {
                            cb(res.data.notification[0].date)

                        }
                    } else {
                        const localstore = {
                            date: res.data.notification[0].date,
                            count: 0
                        }
                        localStorage.setItem('whatsnew', JSON.stringify(localstore));
                        cb(res.data.notification[0].date)

                    }

                } else {
                    const localstore = {
                        date: res.data.notification[0].date,
                        count: 0
                    }
                    localStorage.setItem('whatsnew', JSON.stringify(localstore));
                    cb(res.data.notification[0].date)

                }




            }

        })
        .catch(err => {
            dispatch({ type: 'WHATS_NEW_FAIL', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                   
                    window.location.reload()
                }
            }

        })
}

export const populateTeacherClasses = (token, details) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        teacher_id: details
    }
    dispatch({ type: 'POPULATE_TEACHER_CLASS_REQUEST' })
    axios.post(`${development.baseUrl}populateTeacherClasses`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_TEACHER_CLASS_SUCCESS', payload: res.data.classes })

            }

        })
        .catch(err => {
            dispatch({ type: 'POPULATE_TEACHER_CLASS_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }

        })
}

export const filterAttendance = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'STUDENTS_ATTANDANCE_REQUEST' })
    axios.post(`${development.baseUrl}filterAttendance`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENTS_ATTANDANCE_SUCCESS', payload: res.data })
                cb()
            }

        })
        .catch(err => {
            dispatch({ type: 'STUDENTS_ATTANDANCE_FAILED', payload: err })
            cb()
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}


export const getStudentAttendance = (token, sessionId) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        session_id: sessionId
    }
    dispatch({ type: 'STUDENTS_ATTANDANCE_REQUEST' })
    axios.post(`${development.baseUrl}getStudentAttendance`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENTS_ATTANDANCE_SUCCESS', payload: res.data })

            }

        })
        .catch(err => {
            dispatch({ type: 'STUDENTS_ATTANDANCE_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                   
                    window.location.reload()
                }
            }
        })
}


export const getAttendanceOfTeacher = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'POPULATE_ATTENDANCE_REQUEST' })
    axios.post(`${development.baseUrl}getAttendanceOfTeacher`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ATTENDANCE_SUCCESS', payload: res.data.details })
                cb()
            }

        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ATTENDANCE_FAILED', payload: err })
            cb()
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}


export const createSubject = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'ADD_SUBJECT_REQUEST' })
    axios.post(`${development.baseUrl}createSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_SUBJECT_SUCCESS', payload: res.data.subjects[0] })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Add subject successfully',

                })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'ADD_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}


export const createSubjectSchool = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'ADD_SUBJECT_SCHOOL_REQUEST' })
    axios.post(`${development.baseUrl}createSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_SUBJECT_SCHOOL_SUCCESS', payload: res.data.subjects[0] })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Add subject successfully',

                })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'ADD_SUBJECT_SCHOOL_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}

export const createSubjectLifeLong = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'ADD_SUBJECT_LIFE_LONG_REQUEST' })
    axios.post(`${development.baseUrl}createSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_SUBJECT_LIFE_LONG_SUCCESS', payload: res.data.subjects[0] })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Add subject successfully',

                })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'ADD_SUBJECT_LIFE_LONG_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}
export const populateAllSchoolSubjects = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'POPULATE_ALL_SCHOOL_SUBJECT_REQUEST' })
    axios.get(`${development.baseUrl}populateAllSchoolSubjects`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_SCHOOL_SUBJECT_SUCCESS', payload: res.data.subjects })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_SCHOOL_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}
export const populateAllEnterpriseSchoolSubjects = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'POPULATE_ALL_SCHOOL_SUBJECT_REQUEST' })
    axios.get(`${development.baseUrlEnte}populateAllSchoolSubjects`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_SCHOOL_SUBJECT_SUCCESS', payload: res.data.subjects })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_SCHOOL_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}
export const populateAllEnterpriseCollegeSubjects = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'POPULATE_ALL_COLLEGE_SUBJECT_REQUEST' })
    axios.get(`${development.baseUrlEnte}populateAllCollegeSubjects`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_COLLEGE_SUBJECT_SUCCESS', payload: res.data.subjects })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_COLLEGE_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}
export const populateAllCollegeSubjects = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'POPULATE_ALL_COLLEGE_SUBJECT_REQUEST' })
    axios.get(`${development.baseUrl}populateAllCollegeSubjects`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_COLLEGE_SUBJECT_SUCCESS', payload: res.data.subjects })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_COLLEGE_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}

export const populateRegisteredTeachers = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'POPULATE_ALL_REGISTERED_TEACHER_REQUEST' })
    axios.post(`${development.baseUrlente}populateRegisteredTeachers`, null, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_REGISTERED_TEACHER_SUCCESS', payload: res.data.details })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_COLLEGE_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}

export const populateAllLifelongSubjects = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'POPULATE_ALL_LIFE_LONG_SUBJECT_REQUEST' })
    axios.get(`${development.baseUrl}populateAllLifelongSubjects`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_LIFE_LONG_SUBJECT_SUCCESS', payload: res.data.subjects })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_LIFE_LONG_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}
export const populateAllEnterpriseLifelongSubjects = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'POPULATE_ALL_LIFE_LONG_SUBJECT_REQUEST' })
    axios.get(`${development.baseUrlEnte}populateAllLifelongSubjects`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_LIFE_LONG_SUBJECT_SUCCESS', payload: res.data.subjects })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_LIFE_LONG_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                    
                    window.location.reload()
                }
            }
        })
}

export const updateSubject = (token, data) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'UPDATE_SUBJECT_REQUEST' })
    axios.post(`${development.baseUrl}updateSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'UPDATE_SUBJECT_SUCCESS', payload: data })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_SUBJECT_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                   
                    window.location.reload()
                }
            }
        })
}


export const updateSubjectSchhol = (token, data) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'UPDATE_SUBJECT_SCHOOL_REQUEST' })
    axios.post(`${development.baseUrl}updateSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'UPDATE_SUBJECT_SCHOOL_SUCCESS', payload: data })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_SUBJECT_SCHOOL_FAILED', payload: err })
            if (err.response) {
                if (err.response.status === 401) {
                  
                    window.location.reload()
                }
            }
        })
}




export const updateSubjectLifeLond = (token, data) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'UPDATE_SUBJECT_LIFE_LONG_REQUEST' })
    axios.post(`${development.baseUrl}updateSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'UPDATE_SUBJECT_LIFE_LONG_SUCCESS', payload: data })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_SUBJECT_LIFE_LONG_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                       
                        window.location.reload()
                    }
                }

            }
        })
}

export const deleteSubject = (token, id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        subject_id: id
    }
    dispatch({ type: 'DELETE_SUBJECT_REQUEST' })
    axios.post(`${development.baseUrl}deleteSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'DELETE_SUBJECT_SUCCESS', payload: id })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'DELETE_SUBJECT_SCHOOL_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}
export const deleteSubjectSchool = (token, id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        subject_id: id
    }
    dispatch({ type: 'DELETE_SUBJECT_SCHOOL_REQUEST' })
    axios.post(`${development.baseUrl}deleteSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'DELETE_SUBJECT_SCHOOL_SUCCESS', payload: id })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'DELETE_SUBJECT_SCHOOL_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}

export const deleteSubjectLifeLong = (token, id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        subject_id: id
    }
    dispatch({ type: 'DELETE_SUBJECT_LIFE_LONG_REQUEST' })
    axios.post(`${development.baseUrl}deleteSubject`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'DELETE_SUBJECT_LIFE_LONG_SUCCESS', payload: id })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'DELETE_SUBJECT_LIFE_LONG_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}
export const getNotification = (token, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'GET_NOTIFICATION_REQUEST' })
    axios.get(`${development.baseUrl}getNotification`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_NOTIFICATION_SUCCESS', payload: res.data.notification })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'GET_NOTIFICATION_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}



export const classStartedBroadCast = (token, id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        class_id: id
    }
    dispatch({ type: 'START_CLASS_BROADCAST_REQUEST' })
    axios.post(`${development.baseUrl}broadcastClassStarted`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'START_CLASS_BROADCAST_SUCCESS', payload: res.data.notification })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'START_CLASS_BROADCAST_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}
export const notificationMarkedAsRead = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'MARK_NOTIFICATION_AS_READ_REQUEST' })
    axios.get(`${development.baseUrl}notificationMarkedAsRead`, { headers: head })
        .then(res => {
            if (res.data.result) {

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'MARK_NOTIFICATION_AS_READ_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}


export const getActivities = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'GET_ACTIVITIES_REQUEST' })
    axios.get(`${development.baseUrl}getActivities`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_ACTIVITIES_SUCCESS', payload: res.data.activities })
            }
        })
        .catch(err => {
            dispatch({ type: 'GET_ACTIVITIES_FAILED', payload: err })
            // if (err) {
            //     if (err.response) {
            //         if (err.response) {
            //             if (err.response.status === 401) {
            //                 Swal.fire({
            //                     icon: 'error',
            //                     title: 'Token Expired',
            //                     // text: 'Token Expired'
            //                 })
            //                 window.location.reload()
            //             }
            //         }
            //     }
            // }
        })
}

export const populateGrade = (id, category_id) =>
    (dispatch) => {
        const data = {
            degree_id: id,
            category_id: category_id,
            "enterprise": true
        }
        dispatch({ type: 'POPULATE_GRADE_REQUEST' })
        axios.post(`${development.baseUrl}filterGrade`, data)
            .then(res => {
                dispatch({ type: 'POPULATE_GRADE_SUCCESS', payload: res.data })
                // console.log("grade", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_GRADE_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                               
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }





export const getGradeList = (id) =>
    (dispatch) => {
        dispatch({ type: 'POPULATE_GRADE_REQUEST' })
        axios.post(`${development.baseUrl}filterGrade`)
            .then(res => {
                dispatch({ type: 'POPULATE_GRADE_SUCCESS', payload: res.data })
                // console.log("grade", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_GRADE_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }
export const emailSendForVerification = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'EMAIL_SEND_REQUEST' })
    axios.get(`${development.baseUrl}emailSendForVerification`, { headers: head })
        .then(res => {

            dispatch({ type: 'EMAIL_SEND_SUCCESS', payload: res.data })
        })
        .catch(err => {
            dispatch({ type: 'EMAIL_SEND_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}

export const populateSubject = (token, id, category, teacher_id = null) =>
    (dispatch) => {
        const data = {
            grade_id: id,
            category_id: category,
            teacher_id: teacher_id

        }
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        dispatch({ type: 'POPULATE_SUBJECT_REQUEST' })
        axios.post(`${development.baseUrl}populateSubject`, data, { headers: head })
            .then(res => {
                dispatch({ type: 'POPULATE_SUBJECT_SUCCESS', payload: res.data })
                //console.log("grade", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_SUBJECT_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }
export const createClass = (token, data, cb, cb2, type = false) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    dispatch({ type: 'CREATE_CLASS_REQUEST' })
    axios.post(`${development.baseUrl}createClass`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                cb()//to open success popup
                cb2()//to reset form
            } else {

            }
            const dt = res.data.classes[0]

            ///const dt = [{ class_id: 86, class_from_date: "2017-01-01", class_to_date: "2017-01-01", class_name: "Class", grade_name: "Class 9", grade_id: 1, subject_id: 1, subject_name: "Maths", timing: [{ key: 0, day: "Sunday", class_from_time: "1:00 AM", class_to_time: "1:02 AM" }] }]
            if (type) {
                dispatch({ type: 'CREATE_ENTERPRISE_CLASS_SUCCESS', payload: dt })

            } else {
                dispatch({ type: 'CREATE_CLASS_SUCCESS', payload: dt })

            }

        })
        .catch(err => {
            dispatch({ type: 'CREATE_CLASS_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}


export const populateAllUserClass = (token) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'POPULATE_ALL_USER_CLASSES_REQUEST' })
        axios.get(`${development.baseUrl}populateAllUserClass`, { headers: head })

            .then(res => {
                //console.log('ff');
                if (res.data.result) {
                    dispatch({ type: 'POPULATE_ALL_USER_CLASSES_SUCCESS', payload: res.data })
                }
                // console.log("populateAllUserClass", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_ALL_USER_CLASSES_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }

export const populateStudentClasses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'POPULATE_ALL_STUDNET_CLASSES_REQUEST' })
    axios.get(`${development.baseUrl}populateStudentClasses`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_ALL_STUDNET_CLASSES_SUCCESS', payload: res.data.classes })
            }
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_STUDNET_CLASSES_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}

export const populateAllEnterpriseUserClass = (token) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
        // console.log("actio disp----------")
        //console.log(`head ${head.Authorization} data ${data.class_name}`)


        dispatch({ type: 'POPULATE_ALL_ENTERPRISE_USER_CLASSES_REQUEST' })

        axios.get(`${development.baseUrlEnte}populateAllUserClass`, { headers: head })

            .then(res => {
                //console.log('ff');

                if (res.data.result) {
                    dispatch({ type: 'POPULATE_ALL_ENTERPRISE_USER_CLASSES_SUCCESS', payload: res.data })
                }
                // console.log("populateAllUserClass", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_ALL_ENTERPRISE_USER_CLASSES_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }


export const getClassTiming = (token, id) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        // console.log("iddddddd", id)
        const data = {
            class_id: id
        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'GET_CLASS_TIMING_REQUEST' })
        axios.post(`${development.baseUrl}getClassTiming`, data, { headers: head })

            .then(res => {
                dispatch({ type: 'GET_CLASS_TIMING_SUCCESS', payload: res.data })
                //console.log("getClassTiming", res.data)
            })
            .catch(err => {
                dispatch({ type: 'GET_CLASS_TIMING_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }




export const populateRegisteredStudents = (token, grade_id, class_id, cb = null) =>
    (dispatch) => {

        const data = {
            grade_id: grade_id,
            class_id: class_id
        }
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'POPULATE_STUDENT_REQUEST' })
        axios.post(`${development.baseUrl}populateClassStudents`, data, { headers: head })

            .then(res => {
                //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
                if (res.data.result) {
                    dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })

                    cb(res.data.students)
                }
                // console.log("populateRegisteredStudents", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_STUDENT_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }
export const getClassRoomMembers = (token, class_id) =>
    (dispatch) => {

        const data = {
            // grade_id: grade_id,
            class_id: class_id
        }
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'POPULATE_CLASS_MEMBERS_REQUEST' })
        axios.post(`${development.baseUrl}getClassRoomMembers`, data, { headers: head })

            .then(res => {
                //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
                if (res.data.result) {
                    dispatch({ type: 'POPULATE_CLASS_MEMBERS_SUCCESS', payload: res.data.data })

                    // cb(res.data.students)
                }
                // console.log("populateRegisteredStudents", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_CLASS_MEMBERS_FAILED', payload: err })
            })
    }
export const getTeacherAssignedGroups = (token, class_id, teacher_id, type = "",cb=null) =>
    (dispatch) => {


        // console.log("graoup", class_id, teacher_id)
        const data = {
            teacher_id: teacher_id,
            class_id: class_id,
            type: type
        }
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }

        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'POPULATE_TEACHER_GROUP_REQUEST' })
        axios.post(`${development.baseUrlEnte}getTeacherAssignedGroups`, data, { headers: head })
            .then(res => {
                dispatch({ type: 'POPULATE_TEACHER_GROUP_SUCCESS', payload: res.data })
                cb(res.data ? res.data.details:[])
                // console.log("populateRegisteredTEACHER_GROUPs", res.data)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_TEACHER_GROUP_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }

export const addStudentToClassroom = (class_id, students, token, cb, timing) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }

    let body = students
    students.timing = timing
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: 'ADD_STUDENT_TO_CLASS_REQUEST' })
    axios.post(`${development.baseUrl}addStudentToClassroom`, body, { headers: head })

        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_STUDENT_TO_CLASS_SUCCESS', payload: res.data })
                cb();

            } else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }

            // console.log("addStudentToClassroom", res.data)
        })
        .catch(err => {
            dispatch({ type: 'ADD_STUDENT_TO_CLASS_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: "something went wrong",

                        })
                    }
                }
            }

        })
}
export const addStudentToEnterpriseClassroom = (class_id, students, token, cb, timing) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }

    let body = students
    students.timing = timing
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: 'ADD_STUDENT_TO_CLASS_REQUEST' })
    axios.post(`${development.baseUrlEnte}addStudentToClassroom`, body, { headers: head })

        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_STUDENT_TO_CLASS_SUCCESS', payload: res.data })
                cb();


            } else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }

            // console.log("addStudentToClassroom", res.data)
        })
        .catch(err => {
            dispatch({ type: 'ADD_STUDENT_TO_CLASS_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}

export const validateClassTiming = (token, data, cb, cb2, previousTime = [], removedTimeSlot = []) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        const details = data
        details.except = previousTime
        // details.removedTimeSlot = removedTimeSlot
        //console.log("data********", details)
        //console.log("data********", data)
        //console.log("data********2", details)

        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'VALIDATE_TIME_REQUEST' })
        axios.post(`${development.baseUrl}validateClassTiming`, details, { headers: head })
            .then(res => {
                dispatch({ type: 'VALIDATE_TIME_SUCCESS', payload: res.data })
                if (res.data.result === true) {
                    cb();
                } else if (res.data.result === false) {

                    cb2(res.data.message)
                }
            })
            .catch(err => {
                dispatch({ type: 'VALIDATE_TIME_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }



export const validateSpecialClassTime = (token, details, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    var dt = moment(details.class_date, "YYYY-MM-DD")
    var days = dt.format('dddd')
    //alert(dt.format('dddd'))
    if (days === 'Sunday') days = 'sun'
    if (days === 'Monnday') days = 'mon'
    if (days === 'Tuesday') days = 'tue'
    if (days === 'Wednesday') days = 'wed'
    if (days === 'Thursday') days = 'thu'
    if (days === 'Friday') days = 'fri'
    if (days === 'Saturday') days = 'sat'

    const data = {
        class_from_date: details.class_date,
        class_to_date: details.class_date,
        class_from_time: details.class_from_time,
        class_to_time: details.class_to_time,
        day: days
    }
    dispatch({ type: 'VALIDATE_SPECIAL_CLASS_TIME_REQUEST' })
    axios.post(`${development.baseUrl}validateClassTiming`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VALIDATE_SPECIAL_CLASS_TIME_SUCCESS', payload: data })
                cb();
            }
            if (!res.data.result) {

                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
            // console.log("VALIDATE_TIME_SUCCESS", res.data)
        })
        .catch(err => {
            dispatch({ type: 'VALIDATE_SPECIAL_CLASS_TIME_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}


export const deleteClasses = (token, class_id) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }

        const data = {
            class_id: class_id
        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'DELETE_CLASS_REQUEST' })
        axios.post(`${development.baseUrl}deleteClass`, data, { headers: head })
            .then(res => {
                dispatch({ type: 'DELETE_CLASS_SUCCESS', payload: res.data })
                if (res.data.result === true) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Deleted',

                    })
                } else if (res.data.result === false) {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Something went wrong',

                    })
                }



                // console.log("DELETE_CLASS_SUCCESS", res.data)
            })
            .catch(err => {
                dispatch({ type: 'EDIT_CLASS_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }


export const editClass = (token, data, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'EDIT_CLASS_REQUEST' })
        axios.post(`${development.baseUrl}updateClass`, data, { headers: head })
            .then(res => {
                if (res.data.result === true) {
                    dispatch({ type: 'EDIT_CLASS_SUCCESS', payload: res.data })

                    cb();
                } else if (res.data.result === false) {

                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: res.data.message,

                    })
                }



                // console.log("EDIT_CLASS_SUCCESS", res.data)
            })
            .catch(err => {
                dispatch({ type: 'EDIT_CLASS_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }

export const getTodaysClass = (token) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'TODAY_CLASS_REQUEST' })
        axios.get(`${development.baseUrl}getRecentClass`, { headers: head })
            .then(res => {
                // debugger
                const findCompleted = res.data.class_details.filter(row => row.status == "inprogress")
                const findOngoing = res.data.class_details.filter(row => row.status == "ongoing")

                if (findCompleted.length > 0) {
                    // console.log("findCompleted", findCompleted)
                    let filtered = res.data.class_details.filter(row => row.status !== "inprogress")


                    if (filtered.length > 0) {
                        filtered.unshift(findCompleted[0])
                        dispatch({ type: 'TODAY_CLASS_SUCCESS', payload: filtered })
                    } else {
                        dispatch({ type: 'TODAY_CLASS_SUCCESS', payload: res.data.class_details })

                    }
                } else if (findOngoing.length > 0) {
                    let filtered = res.data.class_details.filter(row => row.status !== "ongoing")
                    if (filtered.length > 0) {
                        filtered.unshift(findOngoing[0])
                        dispatch({ type: 'TODAY_CLASS_SUCCESS', payload: filtered })
                    } else {
                        dispatch({ type: 'TODAY_CLASS_SUCCESS', payload: res.data.class_details })

                    }
                } else {
                    dispatch({ type: 'TODAY_CLASS_SUCCESS', payload: res.data.class_details })

                }


                // console.log("removeCompleted", filtered)

                // console.log("TODAY_CLASS_SUCCESS", res.data)
            })
            .catch(err => {
                dispatch({ type: 'TODAY_CLASS_FAILED', payload: err })
                // if (err) {
                //     if (err.response) {
                //         if (err.response) {
                //             if (err.response.status === 401) {
                //                 Swal.fire({
                //                     icon: 'error',
                //                     title: 'Token Expired',
                //                     // text: 'Token Expired'
                //                 })
                //                 window.location.reload()
                //             }
                //         }
                //     }
                // }
            })
    }
export const populatePrincipalDashboard = (token) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'PRINCIPAL_DASH_REQUEST' })
        axios.get(`${development.baseUrl}populatePrincipalDashboard`, { headers: head })
            .then(res => {
                dispatch({ type: 'PRINCIPAL_DASH_SUCCESS', payload: res.data })
                // console.log("PRINCIPAL_DASH_SUCCESS", res.data)
            })
            .catch(err => {
                dispatch({ type: 'PRINCIPAL_DASH_FAILED', payload: err })
                // if (err) {
                //     if (err.response) {
                //         if (err.response) {
                //             if (err.response.status === 401) {
                //                 Swal.fire({
                //                     icon: 'error',
                //                     title: 'Token Expired',
                //                     // text: 'Token Expired'
                //                 })
                //                 window.location.reload()
                //             }
                //         }
                //     }
                // }
            })
    }

export const changePassword = (token, data, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'CHANGE_PASSWORD_REQUEST' })
        axios.post(`${development.baseUrl}changePassword`, data, { headers: head })

            .then(res => {

                dispatch({ type: 'CHANGE_PASSWORD_SUCCESS', payload: res.data })
                // console.log("created class", res.data)

                if (res.data.result) {
                    cb(res.data.message, "success")//to open success popup

                } else
                    cb(res.data.message, "error")
            })
            .catch(err => {
                dispatch({ type: 'CHANGE_PASSWORD_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }

export const getMyAccount = (token) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'MY_ACCOUNT_REQUEST' })
        axios.get(`${development.baseUrl}getMyAccount`, { headers: head })
            .then(res => {
                dispatch({ type: 'MY_ACCOUNT_SUCCESS', payload: res.data })
                // console.log("MY_ACCOUNT_SUCCESS", res.data)
            })
            .catch(err => {
                dispatch({ type: 'MY_ACCOUNT_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }

export const getAttendance = (token, data, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'POPULATE_ATTENDANCE_REQUEST' })
        axios.post(`${development.baseUrl}getAttendance`, data, { headers: head })
            .then(res => {
                dispatch({ type: 'POPULATE_ATTENDANCE_SUCCESS', payload: res.data.details })
                // console.log("POPULATE_ATTENDANCE_SUCCESS", res.data)
                cb()
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_ATTENDANCE_FAILED', payload: err })
                cb()
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                               
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }


export const getNextClass = (token) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,

        }
        //console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'NEXT_CLASS_REQUEST' })
        axios.get(`${development.baseUrl}getNextClass`, { headers: head })
            .then(res => {
                if (res.data.result) {
                    dispatch({ type: 'NEXT_CLASS_SUCCESS', payload: res.data })
                    //  debugger
                    //   broadCaster()
                }

            })
            .catch(err => {
                dispatch({ type: 'NEXT_CLASS_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }


export const addStudentsToSoundComponent = (students) =>
    (dispatch) => {
        dispatch({ type: 'ADD_CLASS_STUDENT_SUCCESS', payload: students })
        // console.log("getTodaysTopic", res.data)
    }


export const validateStartClass = (cb, token, week_id, class_id, videoType) =>
    (dispatch) => {
        dispatch({ type: 'VALIDATE_START_CLASS_REQUEST' })
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }

        const data = {
            class_id: class_id,
            weekly_class_id: week_id,
            type: 'conference'
        }
        // console.log("class_id from actopn", class_id)
        // const options = {
        //     headers: {'Accept': 'application/json'},
        //     headers: {'Authorization': "Bearer "+token}
        //   };

        axios.post(`${development.baseUrl}validateStartClass`, data, { headers: head })


            .then(res => {
                dispatch({ type: 'VALIDATE_START_CLASS_SUCCESS', payload: res.data })
                // console.log("validateTokenClasssssStart", res.data)
                if (!res.data.result) {
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'Error',
                    //     text: res.data.message,

                    // })

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res.data.message,
                        confirmButtonText: 'Okay',

                    }).then((result) => {
                        // props.history.push('/dashboard/')
                        // return <Redirect to='/dashboard' />
                        window.location.reload()

                    })




                }
                if (res.data.result) {
                    // console.log({ isFirefox });

                    cb(week_id, class_id);


                }
            })
            .catch(err => {

                dispatch({ type: 'VALIDATE_START_CLASS_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }


export const broadcastMikeSetting = (token, mstatus, student_id, chekedStatus) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
        const body = {
            muteStatus: mstatus,
            studentId: student_id,
            chekedStatus: chekedStatus
        }
        dispatch({ type: 'MUTE_STUDENT_REQUEST', payload: body })
        axios.post(`${development.baseUrl}broadcastMute`, body, { headers: head })
            .then(res => {
                // console.log("muteRes----", res)
                if (res.data.result === true) {
                    dispatch({ type: 'MUTE_STUDENT_SUCCESS', payload: body })
                } else if (res.data.result === false) {
                }
            })
            .catch(err => {
                dispatch({ type: 'EDIT_CLASS_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                               
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }



export const toggleChange = (student_id) => (dispatch) => {
    //debugger
    dispatch({ type: 'MUTE_SUCCESS', payload: student_id })

}



// axios.post('https://srjtest.mojgenie.com/api/leaveClassRoom', {
//     video_session_id: response.id,
//     "from": "browser",
//     "user": "teacher"
// }, options122)
//     .then((response) => {
//         console.log('response left ' + response.data);
//     }, (error) => {
//         console.log('axios ' + error);
//     });






export const leaveClassRoom = (myId) => (dispatch) => {
    console.log("class leave --- ", myId)
    const head = {
        Accept: 'application/json',

    }

    const body = {
        video_session_id: myId,
        from: "browser",
        user: "teacher",
        device_web: "web"
        // type: "hangup"
    }
    dispatch({ type: 'CLASS_LEAVE_REQUEST', payload: body })
    axios.post(`${development.baseUrl}leaveClassRoom`, body, { headers: head })
        .then(res => {


            console.log("class leave rs---", res)

        })
        .catch(err => {
            dispatch({ type: 'CLASS_LEAVE_FAILED', payload: err })
        })
}




export const removeStudenFromClass = (id) => (dispatch) => {

    dispatch({ type: 'REMOVE_STUDENT_FROM_CLASS', payload: id })
    //console.log("REMOVE_STUDENT_FROM_CLASS", id)
}



export const getEditFormDetails = (token, id, teacher_id, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }

        const body = {
            class_id: id,
            teacher_id: teacher_id
        }
        dispatch({ type: 'EDIT_DETAILS_REQUEST' })
        axios.post(`${development.baseUrl}getClassDetails`, body, { headers: head })
            .then(res => {
                if (res.data.result) {
                    dispatch({ type: 'EDIT_DETAILS_SUCCESS', payload: res.data.class_details[0] })
                    cb(res.data.class_details[0])
                }
            })
            .catch(err => {
                dispatch({ type: 'EDIT_DETAILS_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                
                                window.location.reload()
                            }
                        }
                    }
                }
            })
    }


export const handleChangeReducer = (e) =>
    (dispatch) => {
        //console.log("e.target", e.target)
        dispatch({ type: 'HANDLE_CHANGE_REQUEST', payload: e })

    }
export const getStudentDetails = (token, data) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'GET_STUDENT_DETAILS_REQUEST' })
    axios.post(`${development.baseUrl}getStudentDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                if (res.data.studentDetails.length > 0) {
                    dispatch({ type: 'GET_STUDENT_DETAILS_SUCCESS', payload: res.data.studentDetails[0] })
                }
                else {
                    dispatch({ type: 'GET_STUDENT_DETAILS_FAILED' })

                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'There no student with given email id',

                    })

                }
                //alert(res.data.result)
            }
            else {

                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })



                dispatch({ type: 'GET_STUDENT_DETAILS_FAILED' })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'GET_STUDENT_DETAILS_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}


export const removeStudentDetails = (id) => (dispatch) => {

    dispatch({ type: 'REMOVE_STUDENT_DETAILS', payload: id }
    )
}


export const addStudentUnderTeacher = (token, body, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'ADD_STUDENT_UNDER_TEACHER_REQUEST' })
    axios.post(`${development.baseUrl}addStudentUnderTeacher`, body, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_STUDENT_UNDER_TEACHER_SUCCESS', payload: res.data.studentList })
                cb()
            }
            if (!res.data.result) {

                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'ADD_STUDENT_UNDER_TEACHER_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                           
                            window.location.reload()
                        }
                    }
                }
            }
        })
}


export const getMyStudents = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'GET_MY_STUDENT_REQUEST' })
    axios.get(`${development.baseUrl}getMyStudents`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_MY_STUDENT_SUCCESS', payload: res.data.studentList })

            }
        })
        .catch(err => {
            dispatch({ type: 'GET_MY_STUDENT_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                           
                            window.location.reload()
                        }
                    }
                }
            }
        })
}

export const getAllTeacherStudents = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'GET_MY_ENTERPRISE_STUDENT_REQUEST' })
    axios.get(`${development.baseUrlente}getAllTeacherStudents`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_MY_ENTERPRISE_STUDENT_SUCCESS', payload: res.data.details })

            }
        })
        .catch(err => {
            dispatch({ type: 'GET_MY_ENTERPRISE_STUDENT_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}




export const createClassHoliday = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const details = {
        holidays: data
    }
    dispatch({ type: 'CREATE_CLASS_HOLIDAYS_REQUEST' })
    axios.post(`${development.baseUrl}createClassHoliday`, details, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'CREATE_CLASS_HOLIDAYS_SUCCESS', payload: res.data.studentList })

                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: res.data.message,

                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'CREATE_CLASS_HOLIDAYS_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                           
                            window.location.reload()
                        }
                    }
                }
            }
        })
}


export const populateAllUserSpecialClass = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'POPULATE_ALL_USER_SPECIAL_CLASS_REQUEST' })
    axios.get(`${development.baseUrl}populateAllUserSpecialClass`, { headers: head })
        .then(res => {
            if (res.data.result) {

                dispatch({ type: 'POPULATE_ALL_USER_SPECIAL_CLASS_SUCCESS', payload: res.data.class_details })
                //alert(res.data.message)
            }
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_ALL_USER_SPECIAL_CLASS_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                           
                            window.location.reload()
                        }
                    }
                }
            }
        })
}



export const populateAllStartedClasses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'POPULATE_STARTED_CLASS_REQUEST' })
    axios.get(`${development.baseUrl}populateAllStartedClasses`, { headers: head })
        .then(res => {
            if (res.data.result) {

                dispatch({ type: 'POPULATE_STARTED_CLASS__SUCCESS', payload: res.data.classes })
                //alert(res.data.message)
            }
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_STARTED_CLASS__FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            
                            window.location.reload()
                        }
                    }
                }
            }
        })
}

export const getVideoList = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'VIDEO_REQUEST' })
    axios.get(`${development.baseUrl}populateAllUserClass`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VIDEO_REQUEST_SUCCESS', payload: res.data.class_details })
                console.log("response", res.data.class_details);
            }
        })
        .catch(err => {
            dispatch({ type: 'VIDEO_REQUEST_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                           
                            window.location.reload()
                        }
                    }
                }
            }
        })
}

///////////////teacher invoice download/////////////
export const exportTeacherAttendance = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // let data={class_id:class_id}
    dispatch({ type: 'SALES_ALL_REQUEST' })
    axios.post(`${development.baseUrl}exportTeacherAttendance`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SALES_ALL_SUCCESS', })
                cb(res.data.url)
            }
        })
        .catch(err => {
            dispatch({ type: 'SALES_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                           
                            window.location.reload()
                        }
                    }
                }
            }
        })
}
