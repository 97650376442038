import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CustomInput, Form, FormGroup, Label, FormFeedback, Input,CardHeader } from 'reactstrap'

import './subject.scss'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getGradeList, populateDegree } from '../../../redux/Actions/signUp'
import { createSubjectSchool, populateAllSchoolSubjects, updateSubjectSchhol, populateSubject, deleteSubjectSchool } from '../../../redux/Actions/homeTeacher'
import serialize from 'form-serialize'
import Table from '../../common/DataTable'
import Swal from 'sweetalert2'


const columns = [
    { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
    { title: 'Grade', field: 'grade_name', editable: 'never' },



]

const Index = (props) => {
    const { getPlaylist, errors } = props;

    const dispatch = useDispatch()
    const Grades = useSelector(({ signUp }) => signUp)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    useEffect(() => {
        dispatch(getGradeList(null, 1))
        // dispatch(populateAllSchoolSubjects(tokenData.token))



        return () => {
            dispatch({ type: 'RESET_SUBJECT' })
            dispatch({ type: 'RESET_PLAYLIST_VIDEOS' })

        }
    }, [])

    const onEdit = (rowData) => {
        console.log("row-", rowData)
        dispatch(updateSubjectSchhol(tokenData.token, rowData))

    }

    const onDelete = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteSubjectSchool(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }
    const handleChangeGrade = e => {
        const { name, value } = e.target;
        //setInputField({ ...inputField, [name]: value })
       // dispatch({ type: 'RESET_PLAYLIST' })

        // if (value !== "") {
        //     dispatch(populateSubject(tokenData.token, value, 1))

        // }

    };
    const handleChangeSubject = (e) => {
        dispatch({ type: 'RESET_PLAYLIST_VIDEOS' })

        getPlaylist(e.target.value)
    }
    // const formSubmit = (e) => {
    //     e.preventDefault()
    //     const form = e.currentTarget
    //     let body = serialize(form, { hash: true, empty: true })
    //     dispatch(createSubjectSchool(tokenData.token, body, reset))

    // }
    const reset = () => {
        document.getElementById("create-form-school").reset();
    }
    return (
        <>

            <Card>
                <CardHeader>Create Video Session</CardHeader>
                <Row className="jms-subject-row-pad">

                    <Col md={1}>



                    </Col>

                    <Col md={5}>
                        <FormGroup>
                            <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Title</Label>
                            <Input
                                // required
                                type="text" name="title"
                                invalid={errors.title}
                            />
                            <FormFeedback >{errors.title}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <input type="hidden" value={1} name="category_id" />

                        <FormGroup>
                            <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '}Select Clinic</Label>
                            <CustomInput type="select" name="grade_id"
                                // required
                                invalid={errors.grade_id}

                                onChange={(e) => handleChangeGrade(e)}
                            >
                                <option value="">Select</option>

                                {
                                    Grades.gradeSchool.grades ? Grades.gradeSchool.grades.map((data) => {
                                        return (
                                            <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                        )
                                    })
                                        : []
                                }


                            </CustomInput>
                            <FormFeedback >{errors.grade_id}</FormFeedback>

                        </FormGroup>
                    </Col>

                    <Input type="hidden"
                        name='subject_id'
                        invalid={errors.subject_id}
                        value={483}
                        // required
                        onChange={(e) => handleChangeSubject(e)}
                    >


                    </Input>

                    <Col md={2}>



                    </Col>


                </Row>
            </Card>

            <hr />




        </>

    )
}

export default Index
