import React, { useState } from "react";
import {
    Card,
    // DropdownMenu,
    // UncontrolledButtonDropdown, DropdownToggle,
} from 'reactstrap';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import avatar1 from '../../assets/utils/images/avatars/singleuser.jpg';
import { TextField, InputAdornment } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
// import IconButton from '@material-ui/icons/IconButton';
import { useSelector } from "react-redux";
import group_icon from '../../assets/utils/images/avatars/group_icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
     faImage, faFileAudio, faVideo, faFile
} from '@fortawesome/free-solid-svg-icons';
import "./chat.css"

function RecentChatList({ recent_list, ShowGroupInterface, ShowPeopleInterface, PopulateChat, showSearch, show_search_box, CloseSearch, HandleChangeSearch, recent_search,role }) {

    const [dropdownOpen, setOpen] = useState(false)
    const recentChat_loader = useSelector(state => state.new_action.recentChat_loader ? state.new_action.recentChat_loader : false)

    const toggle = () => {
        setOpen(!dropdownOpen)
    }
    return (
        <Card className="app-inner-layout__sidebar" style={{ background: 'white' }}>
            <div className="app-inner-layout__sidebar-header" style={{ background: 'white' }}>
                <div className='chat_listheader'>
                    <div>
                        <span style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>Chats</span>
                    </div>
                    <div>
                        {
                            show_search_box ?
                                <TextField
                                    autoFocus
                                    size='small'
                                    label='search'
                                    value={recent_search}
                                    onChange={HandleChangeSearch}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <CloseIcon onClick={CloseSearch} style={{ cursor: 'pointer' }} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                :
                                <SearchIcon style={{ cursor: 'pointer' }} onClick={showSearch} />

                        }
                        
                        <div className="ml-2" style={{ position: 'relative', display: 'inline-block' }}>
                            {
                                dropdownOpen ?
                                    <CloseIcon style={{ color: 'black', cursor: 'pointer' }} onClick={toggle}></CloseIcon>
                                    :
                                    <AddIcon style={{ color: 'black', cursor: 'pointer' }} onClick={toggle}></AddIcon>
                            }
                            <div className={dropdownOpen ? 'recent_dropdown' : 'recent_dropdown-none'}>
                                <span className='newChat' onClick={ShowPeopleInterface}>New chat</span>
                                {
                                    role!=='student' &&
                                    <span className='newGroup' onClick={ShowGroupInterface}>New group</span>
                                }
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='wrap-chat-main people_scroll_div'>
                <div>
                    {
                        recent_list.length > 0 ? recent_list.map((val, ky) => {
                            return (
                                <div key={ky} className='chat_people_list' style={{ cursor: "context-menu" }} onClick={() => { PopulateChat(val) }}>
                                    <div className="widget-content p-0" style={{ width: '100%' }} >
                                        <div className="widget-content-wrapper" style={{ width: '100%', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                <div className="widget-content-left mr-3">
                                                    <div className="avatar-icon-wrapper">
                                                        <div className="avatar-icon">
                                                            {val.group_name ?
                                                                <img src={group_icon} alt='' />
                                                                :
                                                                <img src={val.avatar ? val.avatar : avatar1} alt="" />
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="widget-content-left" style={{ overflow: 'hidden' }}>
                                                    <div className="widget-heading"  style={{ overflow: 'hidden',maxWidth:'172px' }}>
                                                        {val.group_name ? val.group_name : val.recent_user}
                                                        {
                                                            val.role_name === 'teacher' ?
                                                                <span className='chat_role_icons'>
                                                                    (T)
                                                                </span>
                                                                :
                                                                val.role_name === 'principal' ?
                                                                    <span className='chat_role_icons'>
                                                                        (A)
                                                                    </span>
                                                                    :
                                                                    val.role_name === 'student' ?
                                                                        <span className='chat_role_icons'>
                                                                            (C)
                                                                        </span>
                                                                        :
                                                                        val.role_name === 'staff' ?
                                                                            <span className='chat_role_icons'>
                                                                                (S)
                                                                            </span>
                                                                            :
                                                                            <span className='chat_role_icons'>
                                                                                (G)
                                                                            </span>
                                                        }
                                                    </div>
                                                    <div className="widget-subheading" style={{maxWidth:'160px',overflow:'hidden'}}>
                                                       {val.group_name ? `${val.recent_group_user ? val.recent_group_user+':':''}` :""}   
                                                       {
                                                            val.type === 'text' ? val.message ? val.message.length > 15 ? val.message.slice(0,10)+'..':val.message:val.message:
                                                            val.type === 'image' ? <FontAwesomeIcon icon={faImage} /> :
                                                                val.type === 'video' ? <FontAwesomeIcon icon={faVideo} /> :
                                                                    val.type === 'audio' ? <FontAwesomeIcon icon={faFileAudio} /> :
                                                                        <FontAwesomeIcon icon={faFile} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ height: '100%', textAlign: 'right', display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ textAlign: 'right', fontSize: '10px' }}>
                                                    {val.created_time}
                                                </div>
                                                {
                                                    val.unread ?
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <div className='unread_messages'>{val.unread}</div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div style={{width:'100%',textAlign:'center',paddingTop:'5px'}}>
                           {recentChat_loader ? "Loading..." : 'No recent chats'} 
                        </div>
                    }
                </div>
            </div>
        </Card>
    )
}

export default RecentChatList
