import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import Tabs, { TabPane } from 'rc-tabs';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import CreateSchoolExam from "./school";
import CreateCollegeExam from "./college";
import CreateLifeLongExam from "./lifelong";
import classnames from "classnames";
import ListExams from "../ListExams/index";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
const Index = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    activeTab: "1",
    showMore: true,
    transform: true,
    showInkBar: true,
    selectedTabKey: 0,
    transformWidth: 400,
  });
  const toggle = (tab) => {
    dispatch({ type: "RESET_SUBJECT" });
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      });
    }
  };
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Create Exam"
          subheading="Create objective or subjective exams"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        {/* <Tabs
                    defaultActiveKey="1"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab='School' key="1"><CreateSchoolClass /></TabPane>
                    <TabPane tab='College' key="2"><CreateCollegeClass /></TabPane>
                </Tabs> */}

        <Card tabs={true} className="mb-3">
          <CardHeader>
            <Nav justified>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  School
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  College/University
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Life Long Course
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId="1">
                <CreateSchoolExam />
              </TabPane>
              <TabPane tabId="2">
                <CreateCollegeExam />
              </TabPane>
              <TabPane tabId="3">
                <CreateLifeLongExam />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>

        <ListExams />
      </CSSTransitionGroup>
    </>
  );
};

export default Index;
