import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

// import Tabs, { TabPane } from 'rc-tabs';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import SchoolPlaylist from "./CreatePlaylist/school";
import CollegePlaylist from "./CreatePlaylist/college";
import LifeLongPlaylist from "./CreatePlaylist/lifeLong";
import GenaralPlaylist from "./CreatePlaylist/genaral";
import {
  deletePlaylist,
  updatePlaylist,
} from "../../../redux/Actions/playlist";
import classnames from "classnames";
import Modals from "../../common/Modal";

import {
  Row,
  Col,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
// import { parse } from 'date-fns';
const Index = () => {
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_GRADE" });
      dispatch({ type: "RESET_PLAYLIST_MAIN" });
    };
  }, []);
  const DeletePlaylists = (playlist_id, category_id) => {
    // alert(category_id)
    Swal.fire({
      icon: "warning",
      // title: "Format not supported",
      text: "Are you sure,do you want to delete ",
      confirmButtonText: "Yes",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        dispatch(deletePlaylist(tokenData.token, playlist_id, category_id));
      }
      // else {
      //     //  formData.delete('assignment[]')

      // }
    });
  };
  // const EditPlaylists = (playlist_id, playlist_name) => {
  //     alert(playlist_name)
  //     //dispatch(deletePlaylist(tokenData.token, playlist_id, category_id))
  // }
  const [state, setState] = useState({
    activeTab: "1",
    showMore: true,
    transform: true,
    showInkBar: true,

    selectedTabKey: 0,
    transformWidth: 400,
  });
  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      });
    }
  };

  const [playlistDetails, setPlaylistDetails] = useState("");

  const openEdit = (playlist_name, playlist_id, category_id) => {
    setEditMode(true);
    setPlaylistDetails({
      playlist_name: playlist_name,
      playlist_id: playlist_id,
      category_id: category_id,
    });
  };
  const [editMode, setEditMode] = useState(false);
  const handleChange = (e) => {
    console.log(e);
    // alert(playlist_id)
    const { value } = e.target;
    // alert(value)
    setPlaylistDetails({ ...playlistDetails, playlist_name: value });

    // dispatch({ type: 'CHANGE_PLAYLIST_TITLE', payload: { title: value, id: playlist_id :} })
  };
  const [updating, setUpdating] = useState([]);

  const EditPlaylists = (playlist_id, playlist_name, playlistDetails) => {
    // setUpdating([...updating, playlist_id])
    if (!playlist_name) {
      Swal.fire({
        icon: "error",
        title: "Required",
        text: "Playlist name  required",
      });
    } else {
      dispatch(
        updatePlaylist(
          tokenData.token,
          playlist_id,
          playlist_name,
          playlistDetails,
          updated
        )
      );
    }
    // alert(playlist_name)
  };
  const updated = (playlist_id) => {
    // const removed = updating.filter(id => id != playlist_id)
    setEditMode(false);
    // setUpdating(removed)
    Swal.fire({
      icon: "success",
      title: "Updated",
      text: "Playlist Updated  successfully",
    });
  };
  const cancelEdit = (playlist_id) => {
    const removed = updating.filter((id) => id != playlist_id);
    setEditMode(false);
    setUpdating(removed);
  };
  const handleCloseEdit = () => {
    setEditMode(false);
  };
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          //style={{ display: 'none' }}
          heading="Create Playlist"
          subheading="Create playlist to attach with video session"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        {/* <Tabs
                    defaultActiveKey="1"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab='School' key="1"><CreateSchoolClass /></TabPane>
                    <TabPane tab='College' key="2"><CreateCollegeClass /></TabPane>
                </Tabs> */}

        <Card tabs={true} className="mb-3">
          
          <CardBody>
           
           

            <SchoolPlaylist
                  DeletePlaylists={DeletePlaylists}
                  // EditPlaylists={EditPlaylists}
                  editMode={editMode}
                  openEdit={openEdit}
                  // handleChange={handleChange}
                  cancelEdit={cancelEdit}
                />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
      <Modals
        open={editMode}
        size="sm"
        handleClose={handleCloseEdit}
        title={`Update Title`}
      >
        <div>
          <Row style={{ padding: 15 }}>
            <Input
              type="text"
              value={playlistDetails.playlist_name}
              onChange={handleChange}
              name="title"
            />
          </Row>
          <Row style={{ paddingTop: 10 }}>
            <Col md={6}>
              <Button
                onClick={() => handleCloseEdit()}
                className="jms-playlist-save"
              >
                Cancel
              </Button>
            </Col>
            <Col md={6}>
              <Button
                onClick={() =>
                  EditPlaylists(
                    playlistDetails.playlist_id,
                    playlistDetails.playlist_name,
                    playlistDetails.category_id
                  )
                }
                color="info"
                className="jms-playlist-save"
              >
                {"Update"}
              </Button>
            </Col>
          </Row>
        </div>
      </Modals>
    </>
  );
};

export default Index;
