import React, { Fragment, useState, useEffect } from 'react';
// import {   useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import serialize from 'form-serialize'
import Swal from 'sweetalert2'

import {

    Card, CardFooter,

    FormGroup, Label, Form, CustomInput,

} from 'reactstrap';
import { getTeacherData, setEditData, populatePermission } from '../../redux/Actions/videolist'

const EditPrivilege = (props) => {
    const dispatch = useDispatch();
    const tStore = useSelector(({ videolist }) => videolist)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [module, setModule] = useState([]);
    useEffect(() => {
        dispatch(getTeacherData(tokenData.token, props.user_id))
        dispatch(populatePermission(tokenData.token))
    }, []);


    const editData = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })

        let count = 0;
        body.permission.filter(per => {
            if (per !== "") {
                count++
            }
        })
        if (count == 0) {
            Swal.fire(
                'Required',
                'You have to select minimum permission',
                'warning'
            )
        } else {
            dispatch(setEditData(tokenData.token, body, props.handleClose))

        }
    }

    return (
        <Fragment>
            <Form onSubmit={editData} id="form-user">
                <Card className="main-card m-20">
                    <input type="hidden" name="user_id" value={props.user_id} />
                    <input type="hidden" name="user_id" value={props.user_id} />
                    <input type="hidden" name="user_id" value={props.user_id} />
                    <input type="hidden" name="user_id" value={props.user_id} />
                    <input type="hidden" name="user_id" value={props.user_id} />

                    <FormGroup className="p-10">
                        <Label for="exampleSelect">Permission</Label>
                        <div>
                            {
                                tStore.userData ? tStore.userData.privilege.map((data) => {
                                    if ((props.menu === "staff" && (data.module_id === 8 || data.module_id === 7)) || (props.menu === "teacher" && (data.module_id === 10 || data.module_id === 9 || data.module_id === 6))) {
                                        return null
                                    }
                                    else {
                                        return (
                                            <>
                                                <li>
                                                    <CustomInput className="custom-control-inline" type="checkbox" name="permission[]"
                                                        id={data.module_id} value={data.module_id}
                                                        defaultChecked={data.status}
                                                    >
                                                        {data.label}
                                                    </CustomInput>
                                                </li>

                                                <ul>
                                                    {data.content.map(subModule => {
                                                        if ((props.menu === "teacher" && (subModule.module_id === 26 || subModule.module_id === 28)) || (props.menu === "staff" && (subModule.module_id === 35 || subModule.module_id === 29 || subModule.module_id === 28))) {
                                                            return null
                                                        } else {
                                                            return (
                                                                <li>
                                                                    <CustomInput className="custom-control-inline" type="checkbox" name="permission[]"
                                                                        defaultChecked={subModule.status}
                                                                        id={subModule.module_id} value={subModule.module_id}
                                                                    >
                                                                        {subModule.label}
                                                                    </CustomInput>
                                                                </li>

                                                            )
                                                        }

                                                    })
                                                    }
                                                </ul>
                                            </>)
                                    }

                                })
                                    : []
                            }
                            {module &&
                                <div>
                                    {
                                        module ? module.map((data) => {

                                            return (
                                                <CustomInput type="checkbox" name="subPermission" name="permission"
                                                    id={data.module_id} value={data.module_id}
                                                >{data.module_name}</CustomInput>
                                            )

                                        })
                                            : []
                                    }
                                </div>
                            }
                        </div>
                    </FormGroup>
                </Card>
                <CardFooter className="d-block text-right">
                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        loading={tStore.editPrivilegeLoader}
                        type="submit"
                        data-style={ZOOM_IN}  >
                        Submit
                    </LaddaButton>
                </CardFooter>
            </Form>
        </Fragment>

    )
}

export default EditPrivilege;
