import React, { useEffect } from 'react'
import { Grid, } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DehazeIcon from '@material-ui/icons/Dehaze';
import CloseIcon from '@material-ui/icons/Close';
import { Collapse } from 'reactstrap';
import Summery from "./Summery"
import { setNewActionData } from "../../redux/Actions/new-action"
import { useDispatch, useSelector } from "react-redux"
import { Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./style.css"

function Index() {

    const dispatch = useDispatch()

    const [open, setOpen] = React.useState({ admin: true, therapist: false, client: false });
    const [admin_val, setAdminVal] = React.useState(0);

    const [group_exp, setGroupExp] = React.useState(false);
    const [user_exp, setUserExp] = React.useState(false);
    const [session_exp, setSessionExp] = React.useState(false);

    const [expand, setExpand] = React.useState(false);

    const scroll_id = useSelector(state => state.new_action.scroll_id ? state.new_action.scroll_id : '')
    const handleClick = (val) => {
        setAdminVal(0)
        setGroupExp(false)
        setUserExp(false)
        setSessionExp(false)
        if (val === 'admin') {
            setOpen({ admin: true, therapist: false, client: false })
        } else if (val === 'therapist') {
            setOpen({ admin: false, therapist: true, client: false })
        } else {
            setOpen({ admin: false, therapist: false, client: true })
        }
    };
    useEffect(() => {
        if (scroll_id) {
            // console.log('scroll_idscroll_idscroll_id',scroll_id)
            document.getElementById(scroll_id).scrollIntoView();
        }
        return () => {
            dispatch(setNewActionData('scroll_id', ''))
        }
    }, [scroll_id])

    const handleExpand = () => {
        setExpand((prev) => !prev)
    }

    const handleRightData = (val, id = '') => {
        setAdminVal(val)
        dispatch(setNewActionData('scroll_id', id))
        if (expand) {
            setExpand(false)
        }
    }

    const active_style = { color: "#a9a9a9" }
    return (
        <Grid container>
            <Grid item sm={12} md={2}>
                <div className='manual-left'>
                    <Grid container >

                        <Grid item xs={12}>

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <h5 style={{ fontWeight: '500' }}>SHAMAH</h5>

                                <span className='burger-menu' onClick={handleExpand}>
                                    {
                                        expand ? <CloseIcon /> : <DehazeIcon />
                                    }
                                </span>
                            </div>

                            <div className='button_group_list'>
                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                    <span style={{fontSize:'12px'}}>User Role: </span>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color='dark'>
                                            <i className="pe-7s-user fa-lg" style={{ color: 'white', marginRight: '5px' }}> </i>

                                            {
                                                open.admin ? "Admin" : open.therapist ? "Therapist" : 'Client'
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem onClick={() => handleClick('admin')}>
                                                Admin
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleClick('therapist')}>
                                                Therapist
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleClick('client')}>
                                                Client
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Grid>
                            </div>

                            <div className={expand ? 'menu-list-show' : 'menu-list-hide'}>

                                <div className='button_group_mobile'>
                                    <Grid item xs={6} style={{ marginTop: '20px' }}>
                                    <span style={{fontSize:'12px'}}>User Role: </span>
                                        <ButtonGroup>
                                            <Button outline={open.admin ? false :true} color='dark' style={{color:'white'}} onClick={() => handleClick('admin')}>
                                                Admin
                                            </Button>
                                            <Button outline={open.therapist ? false :true} color='dark' style={{color:'white'}} onClick={() => handleClick('therapist')}>
                                                Therapist
                                            </Button>
                                            <Button outline={open.client ? false :true} color='dark' style={{color:'white'}} onClick={() => handleClick('client')}>
                                                Clients
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </div>


                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                    <div onClick={() => { handleRightData(0) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                        <h6 style={admin_val === 0 ? active_style : {}}>Getting started</h6>
                                    </div>
                                </Grid>

                                {
                                    open.admin ?
                                        <>
                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { handleRightData(1) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                    <h6 style={admin_val === 1 ? active_style : {}}>Dashboard</h6>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { setGroupExp(!group_exp) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                    <h6 style={admin_val === 2 ? active_style : {}}>Group</h6>
                                                    {
                                                        group_exp ?
                                                            <ExpandLessIcon />
                                                            :
                                                            <ExpandMoreIcon />
                                                    }
                                                </div>
                                                <Collapse isOpen={group_exp}>
                                                    <Grid item xs={12} >
                                                        <p className='inner_li' onClick={() => { handleRightData(2) }}>Create Group</p>
                                                    </Grid>
                                                </Collapse>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { setUserExp(!user_exp) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                    <h6 style={admin_val === 3 ? active_style : {}}>User</h6>
                                                    {
                                                        user_exp ?
                                                            <ExpandLessIcon />
                                                            :
                                                            <ExpandMoreIcon />
                                                    }
                                                </div>
                                                <Collapse isOpen={user_exp}>
                                                    <Grid item xs={12} >
                                                        <p className='inner_li' onClick={() => { handleRightData(3, 'create_user') }}>Create User</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(3, 'show_therapist') }}>Show Therapists</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(3, 'show_clients') }}>Show Clients</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(3, 'show_staff') }}>Show Staff</p>
                                                    </Grid>

                                                </Collapse>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { setSessionExp(!session_exp) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                    <h6 style={admin_val === 4 ? active_style : {}}>Session</h6>
                                                    {
                                                        session_exp ?
                                                            <ExpandLessIcon />
                                                            :
                                                            <ExpandMoreIcon />
                                                    }
                                                </div>
                                                <Collapse isOpen={session_exp}>
                                                    <Grid item xs={12} >
                                                        <p className='inner_li' onClick={() => { handleRightData(4, 'create_session') }}>Create session</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(4, 'show_session') }}>Show Session</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(4, 'special_session') }}>Special Sessions</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(4, 'video_session') }}>Video Sessions</p>
                                                    </Grid>
                                                </Collapse>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { handleRightData(8) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                    <h6 style={admin_val === 8 ? active_style : {}}>Attendance</h6>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { handleRightData(5) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                    <h6 style={admin_val === 5 ? active_style : {}}>Recording</h6>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { handleRightData(6) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                    <h6 style={admin_val === 6 ? active_style : {}}>Chat</h6>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { handleRightData(7) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                    <h6 style={admin_val === 7 ? active_style : {}}>System Settings</h6>
                                                </div>
                                            </Grid>

                                        </>
                                        :
                                        open.therapist ?
                                            <>
                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(1) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 1 ? active_style : {}}>Dashboard</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(2) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 2 ? active_style : {}}>Session</h6>
                                                    </div>

                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(3) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 3 ? active_style : {}}>Quizzes</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(4) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 4 ? active_style : {}}>Attendance</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(5) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 5 ? active_style : {}}>Recording</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(8) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 8 ? active_style : {}}> Video Sessions</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(6) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 6 ? active_style : {}}>Chat</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(7) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 7 ? active_style : {}}>System Settings</h6>
                                                    </div>
                                                </Grid>

                                            </>
                                            :
                                            <>
                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(1) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 1 ? active_style : {}}>Dashboard</h6>
                                                    </div>
                                                </Grid>

                                                {/* <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(2) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 2 ? active_style : {}}>Session</h6>
                                                    </div>
                                                </Grid> */}
                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <div onClick={() => { setSessionExp(!session_exp) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                    <h6 style={admin_val === 2 ? active_style : {}}>Session</h6>
                                                    {
                                                        session_exp ?
                                                            <ExpandLessIcon />
                                                            :
                                                            <ExpandMoreIcon />
                                                    }
                                                </div>
                                                <Collapse isOpen={session_exp}>
                                                    <Grid item xs={12} >
                                                        <p className='inner_li' onClick={() => { handleRightData(2, 'show_session') }}>Show Session</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(2, 'special_session') }}>Special Sessions</p>
                                                        <p className='inner_li' onClick={() => { handleRightData(2, 'video_session') }}>Video Sessions</p>
                                                    </Grid>
                                                </Collapse>
                                            </Grid>



                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(3) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 3 ? active_style : {}}>Quizzes</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(4) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 4 ? active_style : {}}>Attendance</h6>
                                                    </div>
                                                </Grid>

                                     

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(6) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 6 ? active_style : {}}>Chat</h6>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                    <div onClick={() => { handleRightData(7) }} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px', cursor: 'pointer' }}>
                                                        <h6 style={admin_val === 7 ? active_style : {}}>System Settings</h6>
                                                    </div>
                                                </Grid>

                                            </>
                                }

                            </div>
                        </Grid>

                    </Grid>
                </div>
            </Grid>
            <Grid item sm={12} md={10}>
                <Summery
                    user={open}
                    admin_val={admin_val}
                />
            </Grid>
        </Grid>
    )
}

export default Index
