import React from "react";
import {
    Card,
} from 'reactstrap';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import avatar1 from '../../assets/utils/images/avatars/singleuser.jpg';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import "./chat.css"
import { TextField, InputAdornment, } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

function AllPeopleList({ ShowRecent, users_data, show_group, EnableNewChat, AddToGroup, selected_members,showSearch, show_search_box, CloseSearch, HandleChangeSearch, SearchQuery }) {
    return (
        <Card className="app-inner-layout__sidebar" style={{ background: 'white' }}>
            <div className="app-inner-layout__sidebar-header" style={{ background: 'white' }}>
                <div className='chat_listheader'>
                    <div>
                        <span style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                            {
                                show_group ?
                                    "New Group" : "New Chat"
                            }

                        </span>
                    </div>
                    <div>
                        {/* <SearchIcon style={{ marginRight: '5px' }} /> */}
                        {
                            show_search_box ?
                                <TextField
                                    autoFocus
                                    size='small'
                                    label='search'
                                    value={SearchQuery}
                                    onChange={HandleChangeSearch}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <CloseIcon onClick={CloseSearch} style={{ cursor: 'pointer' }} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                :
                                <SearchIcon style={{ cursor: 'pointer', marginRight: '5px' }} onClick={showSearch} />

                        }
                        <ArrowBackIcon className='ml-2' style={{ color: 'black', cursor: 'pointer' }} onClick={ShowRecent} />
                    </div>
                </div>
            </div>
            {
                show_group ?
                    <div className='wrap-chat-main people_scroll_div'>
                        <div>
                            {
                                users_data.map((val, ky) => {
                                    return (
                                        <div key={ky} className='chat_people_list' style={{ cursor: "context-menu" }}>
                                            <div className="widget-content p-0" style={{ width: '100%' }} >
                                                <div className="widget-content-wrapper" style={{ width: '100%', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                        <div className="widget-content-left mr-3">
                                                            <div className="avatar-icon-wrapper">
                                                                <div className="avatar-icon">
                                                                    <img src={avatar1} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-left" style={{ overflow: 'hidden' }}>
                                                            <div className="widget-heading">
                                                                {val.full_name ? val.full_name.length > 30 ? val.full_name.slice(0,27)+'...':val.full_name:val.full_name }
                                                            </div>
                                                            <div className="widget-subheading">
                                                                ({val.role_name === 'student' ? 'client' : val.role_name === 'teacher' ? 'therapist' : val.role_name==='principal' ? "admin":val.role_name})
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {
                                                            selected_members.includes(val) ?
                                                                <div className='group_member_add' style={{background:'#07773A'}}>
                                                                    <CheckIcon fontSize='small' style={{ color: 'white', cursor: 'pointer' }} />
                                                                </div>
                                                                :
                                                                <Tooltip arrow title='Add to group'>
                                                                    <div className='group_member_add' onClick={() => { AddToGroup(val) }}>
                                                                        <AddIcon fontSize='small' style={{ color: 'white', cursor: 'pointer' }} />
                                                                    </div>
                                                                </Tooltip>
                                                        }


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    <div className='wrap-chat-main people_scroll_div'>
                        <div>
                            {
                                users_data.map((val, ky) => {
                                    return (
                                        <div key={ky} className='chat_people_list' style={{ cursor: "context-menu" }} onClick={() => { EnableNewChat(val) }}>
                                            <div className="widget-content p-0" style={{ width: '100%' }} >
                                                <div className="widget-content-wrapper" style={{ width: '100%', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                        <div className="widget-content-left mr-3">
                                                            <div className="avatar-icon-wrapper">
                                                                <div className="avatar-icon">
                                                                    <img src={avatar1} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-left" style={{ overflow: 'hidden' }}>
                                                            <div className="widget-heading">
                                                                {val.full_name ? val.full_name.length > 30 ? val.full_name.slice(0,27)+'...':val.full_name:val.full_name }
                                                            </div>
                                                            <div className="widget-subheading">
                                                                ({val.role_name === 'student' ? 'client' : val.role_name === 'teacher' ? 'therapist' : val.role_name==='principal' ? "admin":val.role_name})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            }

        </Card>
    )
}

export default AllPeopleList
