import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import HolidaysCard from './timeSlotCard'
import { useDispatch, useSelector } from 'react-redux'
import { getClassTiming, createClassHoliday } from '../../../redux/Actions/homeTeacher'
import { Row } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

const Holydays = () => {
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch()
    const history = useHistory()
    const param = useParams()
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    //const classId = tStore.editClassId
    useEffect(() => {
        console.log("holii")
        dispatch(getClassTiming(tokenData.token, param.class_id))
    }, [])
    console.log("tStore.specificClassTime", tStore.specificClassTime)

    const changeHolidays = (e) => {
        //const { name, value } = e.target

        dispatch({ type: 'CHANGE_HOLIDAY_STATUS', payload: e })
    }
    const handleSubmit = () => {
        dispatch(createClassHoliday(tokenData.token, tStore.specificClassTime, cb))
    }
    const cb = () => {
        history.push('/dashboard/show-class')
    }
    return (
        <>
            <Row>
                {
                    tStore.specificClassTime ? tStore.specificClassTime.filter(row => row.class_type !== "special").map((days, index) => {
                        return (
                            <HolidaysCard
                                key={index}
                                days={days}
                                changeHolidays={changeHolidays}
                            />

                        )
                    }
                    ) : null
                }
            </Row>
            <Row style={{ paddingLeft: '15px' }}>
                {tStore.specificClassTime ?

                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        loading={tStore.createClassHolidaysLoader}
                        type="button"
                        onClick={handleSubmit}
                        data-style={ZOOM_IN}  >
                        Mark Holidays
          </LaddaButton>

                    : null
                }

            </Row>
        </>
    )
}

export default Holydays
