import React, {  useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import Swal from 'sweetalert2';
// import { populateGroupStudents, } from '../../../redux/Actions/grouping'
import { getClassStudentListUnderClassGroup,addStudentsToClassRoomFromGroup } from '../../../redux/Actions/new-action'

import {
    Row, Col,
    Card, CardBody, 
    CardHeader, CardFooter, Input,
    
     Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { TableRow } from "@material-ui/core";
import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { Multiselect } from 'react-widgets';
// import SweetAlert from 'sweetalert-react';
import Tooltip from '@material-ui/core/Tooltip';
const StudentGrouping = (props) => {

    useEffect(() => {

        return () => {
            dispatch({ type: 'RESET_ASSIGN_STUDENT_CLASS' })
            dispatch({ type: 'RESET_GROUP_LIST' })
        }
    }, [])
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    // const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const groupStore = useSelector(({ grouping }) => grouping)
    const sourceGroupStudents = useSelector((state) =>state.new_action.sourceGroupStudents ? state.new_action.sourceGroupStudents : []);
    const load_stdent = useSelector((state) =>state.new_action.load_stdent ? state.new_action.load_stdent : false);
    const checkedArray = useSelector((state) =>state.new_action.checkedArray ? state.new_action.checkedArray : []);
    const load_submit_stdent = useSelector((state) =>state.new_action.load_submit_stdent ? state.new_action.load_submit_stdent : false);

    

    const [Load, setLoad] = useState(false)
    const [all, setAll] = useState(false)
    const [mutliSelect, setMultiSelect] = useState([])
    const [inputField, setInputField] = useState({
        source_group_id: '',
        destination_group_id: ''
    })
    const [unticked, setUnticked] = useState([])
    // const [successModal, setSuccessModal] = useState(false);

    const handleChangeGroup = (e) => {
        const { value, name } = e.target;
        setInputField({ ...inputField, [name]: value })
        setMultiSelect([])
        dispatch({ type: 'RESET_SOURCE_STUDENT_LIST' })
        if (value !== '') {
            dispatch(getClassStudentListUnderClassGroup(tokenData.token, value,props.classId,cb))
        }

    }

    const cb=(val)=>{
        setLoad(true)
        let arr=[]
        // console.log('vaaaal',val)
        val.forEach(element => {
            if(element.selected){
               arr.push(element.user_id)
            }
        });
        setMultiSelect(arr)
        // console.log('aaarr',arr)
        setLoad(false)
    }

    const multiSelectStudent = (e,student_id) => {

        if(e.target.checked===false){
            // console.log('first')
            if(checkedArray.includes(student_id)){
                // console.log('second')
                setUnticked([...unticked,student_id])
            }
        }
        else{
            if(checkedArray.includes(student_id)){
                let filtered = unticked.filter(id => id !== student_id)
                setUnticked(filtered)
            }
        }
        if (mutliSelect.includes(student_id)) {
            const filtered = mutliSelect.filter(videoId => videoId != student_id)
            setMultiSelect(filtered)
        } else {
            setMultiSelect([...mutliSelect, student_id])
        }

    }


    const SelectAll=(e)=>{
        setAll(e.target.checked)
        if(e.target.checked===true){
            let arr=[]
            sourceGroupStudents.forEach(element => {
                arr.push(element.user_id)
            });
            setMultiSelect(arr)
            setUnticked([])
        }
        else{
            // setMultiSelect([])
            dispatch(getClassStudentListUnderClassGroup(tokenData.token, inputField.source_group_id,props.classId,cb))
        }
    }


    const handleClick = (e) => {
        e.preventDefault();
        // console.log('unticked',unticked)        
        // console.log('all ticked',mutliSelect)  
        dispatch(addStudentsToClassRoomFromGroup(tokenData.token,props.classId,mutliSelect,unticked,props.handleCloseStudentModal))      

    };

    // const cb2=()=>{
    //     setSuccessModal(true)
    // }

    // const handleCloseSuccesstModal=()=>{
    //     setSuccessModal(false)
    // }
    return (
        // <Fragment>
        <Form onSubmit={handleClick}>

            <Card className="main-card">
                <CardHeader>
                    Assign Student From Group
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs={4}><span>Select Group :</span></Col>
                        <Col xs={8}>
                        <Input type="select"
                            onChange={handleChangeGroup}
                            name='source_group_id'
                        >
                            <option value={''}>{'select'}</option>
                            {
                            groupStore.groupList.map((listGroup,ky)=>{
                                return (
                                    <option key={ky} value={listGroup.group_id}>{listGroup.group_name}</option>
                                )
                            })
                            }
                        </Input>
                        </Col>
                    </Row>
        
                        {
                            sourceGroupStudents.length > 0 ? 
                            <Table  aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        <Tooltip title='Select All' arrow>
                                            <Input style={{position:'absolute',marginTop:'0px'}} type="checkbox" name='all' onChange={SelectAll} checked={all}></Input>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align='left'>Student</TableCell>
                                    {/* <TableCell>Id</TableCell> */}
                                    
                                </TableRow>
                            </TableHead>
                        </Table>
                        :''
                        }
                        
                        <div className="scroll-area-sm">
                        <PerfectScrollbar>
                        
                        <Col md={12} style={{ padding: '15px 15px 20px 20px' }}>
                            {   load_stdent || Load ? 
                                "Fetching....." 
                                :
                                <>
                                {sourceGroupStudents.length > 0 ? sourceGroupStudents.map((student,ky) => {
                                    return (
                                        <Row style={{ paddingTop: 10 }} key={ky}>
                                            <Col md={1}></Col>
                                            <Col md={8} style={{marginLeft:'3px'}}> 
                                            <Input type="checkbox" 
                                                onChange={(e) => multiSelectStudent(e,student.user_id)} value={student.user_id} checked={mutliSelect.includes(student.user_id) ? true : null} /> 
                                                <span style={{marginLeft:'65px'}}>{student.full_name}</span>
                                            </Col>
                                            {/* <Col md={4}> {student.user_id}</Col> */}
                                        </Row>
                                    )
                                }) : null
                                }
                                </>

                            }
                        </Col>
          

                        </PerfectScrollbar>
                        </div>
                </CardBody>
                
                    <CardFooter className="d-block text-right">
                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                        loading={load_submit_stdent}
                        type="button"
                        data-style={ZOOM_IN}
                        type="submit"
                        disabled={mutliSelect.length === 0 && unticked.length === 0 ? true:false}
                    >
                        Submit
                    </LaddaButton>
                </CardFooter>
                
                
            </Card>
        </Form>

        //  <SweetAlert
        //     title="Success"
        //     confirmButtonColor=""
        //     show={successModal}
        //     text= {"Successfully completed"}
        //     type="success"
        //     onConfirm={() => handleCloseSuccesstModal()} />

        //     </Fragment> 
    )
}

export default StudentGrouping
