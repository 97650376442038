import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getAnswerPaperDetails,
  submitStudentEvaluation,
} from "../../../redux/Actions/exam";
import { Col, Row, Card, Button, Form } from "reactstrap";
import Header from "../common/questionPaperHeader";
import Questions from "../common/questionAndOptions";
import serialize from "form-serialize";
import LaddaButton, { ZOOM_IN } from "react-ladda";

const Exam = () => {
  const dispatch = useDispatch();
  const { exam_id, student_id } = useParams();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const examStore = useSelector(({ exam }) => exam);

  useEffect(() => {
    dispatch(getAnswerPaperDetails(tokenData.token, exam_id, student_id));
  }, []);
  let a = 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    var body = serialize(form, { hash: true });
    dispatch(
      submitStudentEvaluation(tokenData.token, body, student_id, exam_id)
    );
  };
  return (
    <div>
      <Header
        heading={examStore.answerSheet?.Exam_title}
        color={"#99DDFF"}
        subject={examStore.answerSheet?.Subject}
        deadline={examStore.answerSheet?.Date}
        icon="pe-7s-note2 icon-gradient bg-sunny-morning"
        //   buttonAction={downloadTeacherFile}
        background={"rgb(0 125 138)"}
        //   assignmentStore={assignmentStore}
        lastDataColor={"rgb(180, 193, 189)"}
        right_1={` Mark : ${examStore.answerSheet?.Total_mark}`}
        // right_2={`No. of Questions : ${20}`}
      />
      <form onSubmit={handleSubmit} id="class-form">
        <Card style={{ marginTop: 20 }}>
          <Row style={{ paddingTop: 20 }}>
            {examStore.answerSheet?.Sections.map((val, counter) => {
              return (
                <>
                  <Col md={12}>
                    <p style={{ textAlign: "center" }}>{val.Section} </p>
                    <hr />
                  </Col>
                  {val.Questions.map((data, index) => {
                    a++;
                    return (
                      <Col md={12} style={{ padding: "0px 70px 30px" }}>
                        <Questions
                          component_type={"review"}
                          question={data.Question}
                          question_id={data.Question_id}
                          questionNumber={a}
                          question_type={data.Type}
                          SavedAnswer={data.SavedAnswer}
                          mark={data.Mark}
                          options={
                            data["Options"] !== undefined ? data.Options : null
                          }
                          score={data.Score}
                          correctAnswer={data.CorrectAnswer}
                        />
                        <hr />
                      </Col>
                    );
                  })}
                </>
              );
            })}

            <Col md={12} style={{ padding: "0px 70px 30px" }}>
              <LaddaButton
                className="mb-2 mr-2 btn btn-primary"
                loading={examStore?.submitEvaluationLoader}
                type="submit"
                data-style={ZOOM_IN}
              >
                Upload Mark
              </LaddaButton>

              {/* <Button type="submit" color={"primary"} ></Button> */}
            </Col>
          </Row>
        </Card>
      </form>
    </div>
  );
};
export default Exam;
