import React, { Component, Fragment } from 'react';

// import CKEditor from "react-ckeditor-component";
import CKEditor from 'ckeditor4-react';

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import {
    Row, Col,
    Card, CardBody,
    CardTitle
} from 'reactstrap';

const FormCkEditorEditor = (props) => {
    const { SavedAnswer } = props
    const onChange = (evt) => {
        console.log(evt.editor.getData());

    }

    const onBlur = (evt) => {
    }

    const afterPaste = (evt) => {
    }


    return (
        <Fragment>
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row>
                    <Col md="12">
                        <CardTitle></CardTitle>
                        <CKEditor data={SavedAnswer} onChange={onChange} />

                    </Col>
                </Row>
            </CSSTransitionGroup>
        </Fragment >
    );

}
export default FormCkEditorEditor