import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Row, Col, Card, Button } from "reactstrap";
import { Loader, Types } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

const PageTitle = (props) => {

    const {

        heading,
        icon,
        subject,
        deadline,
        buttonAction,
        background,
        color,
        right_1,
        right_2,
        lastDataColor,

    } = props;

    return (
        <div className="app-page-title" style={{ margin: '0px', borderRadius: '6px', backgroundColor: background, color: "white" }}>
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div
                        className={cx("page-title-icon", { 'd-none': false })}>
                        <i className={icon} />
                    </div>

                    <div className="jms-opacity-overwrite" style={{ fontWeight: 600 }}>
                        <span style={{ textTransform: 'uppercase' }}> {heading}</span>
                        <div style={{ color: color, fontWeight: 600, textTransform: 'capitalize' }} className={cx("page-title-subheading", { 'd-none': false })}>
                            {subject}&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <span style={{ color: lastDataColor, fontSize: 12 }}> <strong>{deadline}</strong></span>
                        </div>
                    </div>
                </div>
                <div className="page-title-actions">
                    <span style={{ textTransform: 'capitalize', fontSize: 12 }}> {right_1}</span>
                    <div style={{ textTransform: 'capitalize', fontSize: 12, paddingTop: 5 }}> {right_2}</div>

                </div>
            </div>
        </div>
    );
}

export default PageTitle;