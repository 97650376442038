import React from 'react'
import { Elastic } from 'react-burgers';
import {
    Card
} from 'reactstrap';
import start_new_chat from '../../assets/utils/images/start_new_chat.png';

function DummyChatArea({active,setActive}) {
    return (
        <Card className="app-inner-layout__content">
            <div className="app-inner-layout__top-pane">
                <div className="pane-left">
                    <div className="mobile-app-menu-btn">
                        <Elastic
                            width={26}
                            lineHeight={2}
                            lineSpacing={5}
                            color='#6c757d'
                            active={active}
                            onClick={setActive} />
                    </div>
                </div>
               
            </div>
            <div className="wrap-chat-main wrap_chat_height_style">
                <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                    <img src={start_new_chat} alt='' />
                    <span></span>
                </div>
            </div>

        </Card>
    )
}

export default DummyChatArea
