import { Route,Switch } from 'react-router-dom';
import React from 'react';
import Login from '../../Components/Login'
import Dashboards from '../../Components/Dashboard'
import ForgotPassword from '../../Components/ForgotPassword';
import resetPassword from '../../Components/ResetPassword'
import Usermanual from "../../Components/UserManual/Index"
import RecordAudio from "./RecordAudio"
const AppMain = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route path="/dashboard" component={Dashboards} />
                <Route path="/resetPassword/:token" component={resetPassword} />
                <Route path="/forgot" component={ForgotPassword} />
                <Route path="/user-manual" component={Usermanual} />
                <Route path="/record" component={RecordAudio} />
                 <Route component={() => <p>page not found</p>} />
            </Switch>
        </>
    )
};
export default AppMain;