import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import {
  Col,
  Row,
  Card,
  Button,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getMarkStucture, addQuestions } from "../../../redux/Actions/exam";
import {
  requiredValidator,
  numberValidator,
} from "../../../utilities/Validator";

// import './createQuestionPaper.scss'
import PageTitle from "../../HeaderTitle/PageTitle";
const Exam = () => {
  const dispatch = useDispatch();
  const { question_paper_id } = useParams();

  const examStore = useSelector(({ exam }) => exam);

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  useEffect(() => {
    dispatch(getMarkStucture(tokenData.token, question_paper_id));
    //let question=examStore.question_paper_id
  }, []);
  const [currentMarkStructure, setCurrentMarkStructure] = useState();
  const [markSid, setmarkSid] = useState();

  console.log("qwertttt", examStore);
  const handleMarkStructure = (e) => {
    const { name, value } = e.target;
    // console.log('asdf',e.target[e.target.selectedIndex].getAttribute('data-mid'))
    setCurrentMarkStructure(examStore.markStructure[value]);
    setmarkSid(e.target[e.target.selectedIndex].getAttribute("data-mid"));

    setInputField({
      ...inputField,
      [name]: value,
      question_paper_id: question_paper_id,
      option_count: "",
      question: "",
      correct_answer: "",
      option_A: "",
      option_B: "",
      option_C: "",
      option_D: "",
      option_E: "",
      option_F: "",
    });
  };

  const [inputField, setInputField] = useState({
    markStructureId: "",
    mark_structure_id: "",
    option_count: "",
    question: "",
    correct_answer: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // alert(value)
    setInputField({ ...inputField, [name]: value });
  };
  console.log({ inputField });

  const [optionCount, setOptionCount] = useState(0);
  const handleChangeOptions = (e) => {
    //setInputField({...inputField,type:'objective'})
    const { name, value } = e.target;
    setOptionCount(value);
    if (value == 4) {
      setInputField({
        ...inputField,
        option_A: "",
        option_B: "",
        option_C: "",
        option_D: "",
        [name]: value,
        correct_answer: "",
      });
    } else {
      setInputField({
        ...inputField,
        option_A: "",
        option_B: "",
        option_C: "",
        option_D: "",
        option_E: "",
        option_F: "",
        [name]: value,
        correct_answer: "",
      });
    }
  };
  console.log({ optionCount });
  const options = ["A", "B", "C", "D", "E", "F"];
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    if (
      currentMarkStructure.current_count === currentMarkStructure.total_count
    ) {
      Swal.fire({
        icon: "warning",
        title: "Exceeded",
        text: "Maximum number of questions added in this section",
        confirmButtonText: "Okay",
      });
    } else {
      let error;
      let isInvalid;
      if (currentMarkStructure) {
        if (currentMarkStructure.type === "objective") {
          if (optionCount == 6) {
            error = {
              mark_structure_id: requiredValidator(
                inputField.mark_structure_id
              ),
              option_count: requiredValidator(inputField.option_count),
              question: requiredValidator(inputField.question.trim()),
              correct_answer: requiredValidator(
                inputField.correct_answer.trim()
              ),
              option_A: requiredValidator(inputField.option_A.trim()),
              option_B: requiredValidator(inputField.option_B.trim()),
              option_C: requiredValidator(inputField.option_C.trim()),
              option_D: requiredValidator(inputField.option_D.trim()),
              option_E: requiredValidator(inputField.option_E.trim()),
              option_F: requiredValidator(inputField.option_F.trim()),
            };
            //  alert(6)
            isInvalid =
              error.mark_structure_id ||
              error.option_count ||
              error.question ||
              error.correct_answer ||
              error.option_A ||
              error.option_B ||
              error.option_C ||
              error.option_D ||
              error.option_E ||
              error.option_F;
          } else if (optionCount == 4) {
            error = {
              mark_structure_id: requiredValidator(
                inputField.mark_structure_id
              ),
              option_count: requiredValidator(inputField.option_count),
              question: requiredValidator(inputField.question.trim()),
              correct_answer: requiredValidator(inputField.correct_answer),
              option_A: requiredValidator(inputField.option_A.trim()),
              option_B: requiredValidator(inputField.option_B.trim()),
              option_C: requiredValidator(inputField.option_C.trim()),
              option_D: requiredValidator(inputField.option_D.trim()),
            };
            // alert(4)

            isInvalid =
              error.mark_structure_id ||
              error.option_count ||
              error.question ||
              error.correct_answer ||
              error.option_A ||
              error.option_B ||
              error.option_C ||
              error.option_D;
          } else {
            error = {
              mark_structure_id: requiredValidator(
                inputField.mark_structure_id
              ),
              option_count:
                inputField.option_count == 0 || !inputField.option_count,
              question: requiredValidator(inputField.question),
              correct_answer: requiredValidator(inputField.correct_answer),
            };

            //   alert(0)

            isInvalid =
              error.mark_structure_id ||
              error.option_count ||
              error.question ||
              error.correct_answer;
          }
        } else {
          error = {
            mark_structure_id: requiredValidator(inputField.mark_structure_id),
            question: requiredValidator(inputField.question.trim()),
          };
          isInvalid = error.mark_structure_id || error.question;
        }
      }
      // else {
      //   error = {
      //     mark_structure_id: requiredValidator(inputField.mark_structure_id),
      //     question: requiredValidator(inputField.question),
      //   };
      //   isInvalid = error.mark_structure_id || error.question;
      // }

      if (isInvalid) {
        // alert("required")
      } else {
        // alert("success")

        dispatch(addQuestions(tokenData.token, inputField, markSid, resetForm));
      }
      setErrors(error);
    }
  };
  const resetForm = () => {
    dispatch(getMarkStucture(tokenData.token, question_paper_id));
    setCurrentMarkStructure(null);
    setInputField({
      mark_structure_id: "",
      option_count: "",
      question: "",
      correct_answer: "",
      option_A: "",
      option_B: "",
      option_C: "",
      option_D: "",
      option_E: "",
      option_F: "",
    });
  };

  return (
    <>
      <PageTitle
        heading="Add Questions Into Question Paper"
        subheading="Create question paper here to create exam"
        icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
      />
      <Card style={{ marginTop: 20 }}>
        <Form onSubmit={"submitForm"} id="class-form">
          <Card style={{ margin: "32px 29px 10px 29px" }}>
            <Row style={{ padding: "40px" }}>
              <Col md={12}>
                <p style={{ color: "#495259", fontWeight: 600 }}> DETAILS</p>
                <hr />
              </Col>

              <Col
                md={currentMarkStructure?.type === "objective" ? 5 : 10}
                className="jms-p5"
              >
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Mark Structure
                </Label>

                <Input
                  value={inputField.mark_structure_id}
                  type="select"
                  name="mark_structure_id"
                  onChange={handleMarkStructure}
                  invalid={errors.mark_structure_id}
                >
                  <option value={""}>{"Select Mark"}</option>
                  {examStore.markStructure?.map((item, index) => (
                    <option
                      data-mid={item.mark_structure_id}
                      value={index}
                    >{`${item.mark} Marks - ${item.type}`}</option>
                  ))}
                </Input>
                <FormFeedback>{errors.mark_structure_id}</FormFeedback>
              </Col>
              {currentMarkStructure?.type === "objective" ? (
                <Col md={5} className="jms-p5">
                  <Label for="exampleNameF">
                    <span className="text-danger">*</span> Options Count
                  </Label>
                  <Input
                    onChange={handleChangeOptions}
                    type="select"
                    name="option_count"
                    value={inputField.option_count}
                    invalid={errors.option_count}
                  >
                    <option value={0}>{"Select no. of options"}</option>
                    <option value={4}>{"4"}</option>
                    <option value={6}>{"6"}</option>
                  </Input>
                  <FormFeedback>{errors.option_count}</FormFeedback>
                </Col>
              ) : null}

              <Col md={2} className="jms-p5">
                <Label for="exampleTime">Question Count</Label>

                <p
                  style={{
                    margin: "10px 0px 0px 0px",
                    textAlign: "center",
                    fontWeight: 800,
                  }}
                >{`${
                  currentMarkStructure
                    ? currentMarkStructure.current_count +
                      "/" +
                      currentMarkStructure.total_count
                    : "0/0"
                }`}</p>
              </Col>
            </Row>
            <hr />
            <Row style={{ padding: "0px 40px 20px 40px" }}>
              <Col md={12} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Question
                </Label>
                <Input
                  type="textarea"
                  onChange={handleChange}
                  name="question"
                  invalid={errors.question}
                  value={inputField.question}
                />
                <FormFeedback>{errors.question}</FormFeedback>
              </Col>
              {currentMarkStructure?.type === "objective" && (
                <>
                  {Array(parseInt(optionCount))
                    .fill()
                    .map((i, index) => (
                      <Col md={6} className="jms-p5">
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span> Option{" "}
                          {options[index]}
                        </Label>
                        <Input
                          onChange={handleChange}
                          value={`${inputField[`option_${options[index]}`]}`}
                          invalid={errors[`option_${options[index]}`]}
                          type="text"
                          name={`option_${options[index]}`}
                        />
                        <FormFeedback>
                          {errors[`option_${options[index]}`]}
                        </FormFeedback>
                      </Col>
                    ))}

                  <Col md={6} className="jms-p5">
                    <Label for="exampleNameF">
                      <span className="text-danger">*</span> Correct Answer
                    </Label>
                    <Input
                      onChange={handleChange}
                      type="select"
                      name="correct_answer"
                      value={inputField.correct_answer}
                      invalid={errors.correct_answer}
                    >
                      <option value={""}>{"Select type"}</option>
                      {options.slice(0, parseInt(optionCount)).map((item) => (
                        <option value={`option_${item}`}>{item}</option>
                      ))}
                    </Input>
                    <FormFeedback>{errors.correct_answer}</FormFeedback>
                  </Col>
                </>
              )}
              <Col md={12} className="jms-p5" style={{ textAlign: "end" }}>
                <LaddaButton
                  className="mb-2 mr-2 btn "
                  loading={examStore.addQuestionLoader}
                  style={{
                    width: "auto",
                    backgroundColor: "#545cd8",
                    color: "white",
                  }}
                  onClick={() => handleSubmit()}
                  type="button"
                  data-style={ZOOM_IN}
                >
                  Add Question
                </LaddaButton>
              </Col>
            </Row>
          </Card>
        </Form>
      </Card>
    </>
  );
};
export default Exam;
