import React, { useEffect, useState } from 'react'
import PageTitle from '../common/HeaderTitle/PageTitle'
import './style.scss'
import {
    Button, Input, Label, FormGroup,
    Card, CardBody, CardFooter, CardHeader, Col, CardTitle,
    Collapse, Fade, Row
} from 'reactstrap';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getAllFeedbackSubject, submitFeedback, getAllFeedback } from '../../redux/Actions/feedback'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const Index = () => {


    const columns = [{ title: 'Group ', field: 'group_name', editable: 'onUpdate' },];
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const feedbackStore = useSelector(({ feedback }) => feedback)
    useEffect(() => {
        if (tokenData.role == "student") {
            dispatch(getAllFeedbackSubject(tokenData.token))

        } else {
            dispatch(getAllFeedback(tokenData.token))

        }

        return () => {

        }
    }, [])

    console.log("feedbackStore", feedbackStore)


    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        dispatch(submitFeedback(tokenData.token, body, resetForm))
    }

    const resetForm = () => {
        document.getElementById("feedback-form").reset();
    }
    return (
        <>
            <PageTitle

                heading="Feedback"
                subheading="Send  your feedback to   teacher"
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
            />
            {
                tokenData.role == "student" ?
                    <Card style={{ padding: 20 }}>
                        <h6>Feedback</h6><hr />
                        <form onSubmit={formSubmit} id="feedback-form">
                            <Row >
                                <Col md={2}>

                                </Col>
                                <Col sm={6}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                        {' '} Subject
                                                    </Label>

                                    <Input type="select"
                                        //onChange={(e) => handleChangeTeacher(e)}
                                        name='subject_id'
                                        required
                                    // value={this.state.loaderType}
                                    >

                                        <option value={''}>{'select'}</option>
                                        {
                                            feedbackStore.feedbackSubject ?
                                                feedbackStore.feedbackSubject.map(sub => {
                                                    return (
                                                        <option value={sub.subject_id}>{sub.feedback_subject}</option>

                                                    )
                                                })
                                                : null
                                        }

                                    </Input>
                                </Col>
                                <Col md={3}>

                                </Col>

                            </Row>
                            <Row style={{ paddingTop: 15 }}>
                                <Col md={2}>

                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="exampleText"> <span className="text-danger">*</span>{' '}Feedback</Label>
                                        <Input type="textarea" required name="comment" id="exampleText" style={{ minHeight: 130 }} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>

                                </Col>
                                <Col md={3}>
                                    <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                        <span className="text-danger">*</span>
                                        {' '} Name
                                            </Label>
                                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                        style={{ width: '-webkit-fill-available' }}
                                        loading={feedbackStore.sendFeedBackLoader}
                                        type="submit"
                                        data-style={ZOOM_IN}  >
                                        Send  Feedback
                        </LaddaButton>
                                </Col>
                            </Row>
                        </form>
                    </Card>
                    :
                    <>
                        <Row>
                            {feedbackStore.feedbacksLoader ? 'Fetching......' : null}
                            {
                                feedbackStore.feedbacksDemmy ?
                                    feedbackStore.feedbacksDemmy.map(feed => {
                                        return (
                                            <Col md={6}>
                                                <Card className="jms-feedback-card">
                                                    <Row>
                                                        <Col md={12} >
                                                            <h6 className="jms-feedback-subject"> {feed.feedback_subject}</h6>
                                                        </Col>
                                                        <Col md={12} className="jms-feedback-card-col">
                                                            {feed.comment}
                                                        </Col>
                                                        <Col md={12} className="jms-feedback-card-col-2">
                                                            <p
                                                                //style={{ float: 'left', marginBottom: 0 }}
                                                                className="jms-feedback-author"
                                                            >{`${feed.first_name} ${feed.last_name}`}</p>
                                                            <p style={{ float: 'right', marginBottom: 0 }}>{feed.created_at}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    }) :
                                    null
                            }

                            {/* 
                            <Col md="12">
                                <div id="accordion" className="accordion-wrapper mb-3">
                                    <Card>
                                        <CardHeader id="headingOne">
                                            <Button block color="link" className="text-left m-0 p-0"
                                                onClick={() => toggleAccordion(0)}
                                                aria-expanded={accordion}
                                                aria-controls="collapseOne">
                                                <h5 className="m-0 p-0">Collapsible Group Item #1</h5>
                                            </Button>
                                        </CardHeader>
                                        <Collapse isOpen={this.state.accordion[0]} data-parent="#accordion"
                                            id="collapseOne" aria-labelledby="headingOne">
                                            <CardBody>
                                                1. Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                accusamus
                                                terry richardson ad squid. 3 wolf moon officia aute, non
                                                cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                                laborum
                                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                                on it squid single-origin coffee nulla assumenda shoreditch et.
                                                Nihil
                                                anim keffiyeh helvetica, craft beer labore wes anderson cred
                                                nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                Leggings occaecat craft
                                                beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                                haven't heard of them accusamus labore sustainable VHS.
                                    </CardBody>
                                        </Collapse>
                                    </Card>
                                    <Card>
                                        <CardHeader className="b-radius-0" id="headingTwo">
                                            <Button block color="link" className="text-left m-0 p-0"
                                                onClick={() => this.toggleAccordion(1)}
                                                aria-expanded={this.state.accordion[1]}
                                                aria-controls="collapseTwo">
                                                <h5 className="m-0 p-0">Collapsible Group Item #2</h5>
                                            </Button>
                                        </CardHeader>
                                        <Collapse isOpen={this.state.accordion[1]} data-parent="#accordion"
                                            id="collapseTwo">
                                            <CardBody>
                                                2. Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                accusamus
                                                terry richardson ad squid. 3 wolf moon officia aute, non
                                                cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                                laborum
                                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                                on it squid single-origin coffee nulla assumenda shoreditch et.
                                                Nihil
                                                anim keffiyeh helvetica, craft beer labore wes anderson cred
                                                nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                Leggings occaecat craft
                                                beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                                haven't heard of them accusamus labore sustainable VHS.
                                    </CardBody>
                                        </Collapse>
                                    </Card>
                                    <Card>
                                        <CardHeader id="headingThree">
                                            <Button block color="link" className="text-left m-0 p-0"
                                                onClick={() => this.toggleAccordion(2)}
                                                aria-expanded={this.state.accordion[2]}
                                                aria-controls="collapseThree">
                                                <h5 className="m-0 p-0">Collapsible Group Item #3</h5>
                                            </Button>
                                        </CardHeader>
                                        <Collapse isOpen={this.state.accordion[2]} data-parent="#accordion"
                                            id="collapseThree">
                                            <CardBody>
                                                3. Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                accusamus
                                                terry richardson ad squid. 3 wolf moon officia aute, non
                                                cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                                laborum
                                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                                on it squid single-origin coffee nulla assumenda shoreditch et.
                                                Nihil
                                                anim keffiyeh helvetica, craft beer labore wes anderson cred
                                                nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                Leggings occaecat craft
                                                beer farm-to-table, raw denim aesthetic synth nesciunt you probably
                                                haven't heard of them accusamus labore sustainable VHS.
                                    </CardBody>
                                        </Collapse>
                                    </Card>
                                </div>
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <CardTitle>
                                            Simple
                                </CardTitle>
                                        <div id="exampleAccordion" data-children=".item">
                                            <div className="item">
                                                <Button className="m-0 p-0" color="link"
                                                    onClick={() => this.toggleCustom(0)}
                                                    aria-expanded={this.state.custom[0]}
                                                    aria-controls="exampleAccordion1">
                                                    Toggle item
                                        </Button>
                                                <Collapse isOpen={this.state.custom[0]} data-parent="#exampleAccordion"
                                                    id="exampleAccordion1">
                                                    <p className="mb-3">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium
                                                        lorem non vestibulum scelerisque. Proin a vestibulum sem, eget
                                                        tristique massa. Aliquam lacinia rhoncus nibh quis ornare.
                                            </p>
                                                </Collapse>
                                            </div>
                                            <div className="item">
                                                <Button className="m-0 p-0" color="link"
                                                    onClick={() => this.toggleCustom(1)}
                                                    aria-expanded={this.state.custom[1]}
                                                    aria-controls="exampleAccordion2">
                                                    Toggle item 2
                                        </Button>
                                                <Collapse isOpen={this.state.custom[1]} data-parent="#exampleAccordion"
                                                    id="exampleAccordion2">
                                                    <p className="mb-3">
                                                        Donec at ipsum dignissim, rutrum turpis scelerisque, tristique
                                                        lectus.
                                                        Pellentesque habitant morbi tristique senectus et netus et
                                                        malesuada fames ac turpis egestas. Vivamus nec dui turpis. Orci
                                                        varius
                                                        natoque penatibus et magnis dis parturient montes,
                                                        nascetur ridiculus mus.
                                            </p>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                        {/* <Row>
                            <Button>More...</Button>
                        </Row> */}
                    </>
            }



        </>
    )
}

export default Index
