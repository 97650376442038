import React from 'react'
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux'
import { Progress } from 'react-sweet-progress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LaddaButton, { ZOOM_IN } from 'react-ladda';

function Overlay({ type, file, handleClose, handleUpload }) {

    const fileChatPercentage = useSelector(state => state.new_action.fileChatPercentage ? state.new_action.fileChatPercentage : '')
    const UploadButtonLoader = useSelector(state => state.new_action.UploadButtonLoader ? state.new_action.UploadButtonLoader : false)

    let srcUrl = ''
    if (file) {
        srcUrl = URL.createObjectURL(file)
    }
    // let srcUrl = 'https://picsum.photos/700/500'

    return (
        <div style={{
            position: "fixed",
            // opacity:'0.7',
            backgroundColor: 'rgba(0,0,0,0.9)',
            // backgroundColor: 'rgba(54, 69, 79,0.9)',
            display: 'flex',
            justifyContent: 'center',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: '98'
        }} >

            <div style={{ color: 'white', marginLeft: '30px' }} class='my-auto' >
                {type === 'image' ? <img style={{ maxWidth: '700px', maxHeight: '500px', marginTop: '60px', }} src={srcUrl} alt='preview' /> :
                    type === 'video' ? <video width="400" controls  > <source src={srcUrl} />  </video> :
                        type === 'audio' ? <audio width="400" controls  > <source src={srcUrl} />  </audio> :
                            <div>

                                <div >  <span style={{ fontSize: '15pt', color: 'white' }} ><InsertDriveFileIcon fontSize='20pt' /> {file.name} </span> </div>
                            </div>

                }
                <div>
                    {fileChatPercentage !== '' &&
                        <Progress
                            percent={fileChatPercentage}
                            // theme={this.props.theme}
                            // type="circle"
                            width={90}
                        //strokeWidth={this.props.strokeWidth}
                        />
                    }
                </div>
                <div style={{ textAlign: 'right', color: '#C0C0C0', marginTop: '5px' }} >
                    {/* <i onClick={handleClose} style={{ cursor: 'pointer' }} className='pe-7s-close' ></i> </div> */}
                    <Button onClick={handleClose} style={{ color: '#C0C0C0', marginRight: '5px' }} > Cancel </Button>

                    {/* <Button
                        size='small'
                        // disabled={!message}
                        onClick={handleUpload}
                        style={{ color: '#C0C0C0', cursor: 'pointer', backgroundColor: '#000000' }}
                    >
                        Upload
                       
                    </Button> */}
                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        loading={UploadButtonLoader}
                        type="submit"
                        onClick={handleUpload}
                        data-style={ZOOM_IN}  >
                        Upload
                    </LaddaButton>
                </div>
            </div>
        </div>
    )
}

export default Overlay
