import React, { Fragment } from 'react';

import {
    Popover,
    Nav, NavLink, NavItem
} from 'reactstrap';

// import bg4 from '../../../assets/utils/images/dropdown-header/city5.jpg';

import {
    faAngleUp,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Assignment from '../../../Components/Assignment/assignment'
class FooterMegaMenu extends React.Component {
    constructor(props) {
        super(props);
        console.log('class ' + props.class_id)
        this.toggle = this.toggle.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.state = {
            popoverOpen: false,
            popoverOpen2: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    toggle2() {
        this.setState({
            popoverOpen2: !this.state.popoverOpen2
        });
    }

    state = {};

    render() {
        return (
            <Fragment>
                <Nav className="header-megamenu">
                    <NavItem>
                        <NavLink href="#" onClick={this.toggle} id="PopoverMegaMenuFooter">Assignment
                            <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleUp} />
                        </NavLink>
                    </NavItem>
                    <Popover className="rm-max-width" placement="top-start" fade={false} hideArrow trigger="legacy" isOpen={this.state.popoverOpen}
                        target="PopoverMegaMenuFooter"
                        toggle={this.toggle}>
                        <Assignment />

                    </Popover>

                </Nav>
            </Fragment>
        )
    }
}

export default FooterMegaMenu;
