import React from 'react'
import { Row, Col } from 'reactstrap'
import './updatestyle.scss'
const Updates = (props) => {
    const { data } = props
    return (
        <>
            {
                data ?
                    <>
                        <Row className="jms-update-row">
                            <strong style={{ textTransform: "uppercase" }}>{data.title}</strong>
                        </Row>
                        <Row className="jms-update-row">
                            <p style={{ color: 'black', fontWeight: 500 }}>{data.message} </p>
                        </Row>


                        {data.details.length > 0 ?
                            data.details.map(row => {
                                return (
                                    <>
                                        <li>
                                            <strong style={{ textTransform: "capitalize" }} style={{ textTransform: "uppercase", color: '#205fd6' }}>{row.sub_title}</strong>
                                        </li>
                                        <Row className="jms-update-row-bullet">
                                            <Col md={12} style={{ margin: '7px 0px' }}>

                                                <ul type="square">
                                                    {row.sub_points.map(ft => {
                                                        return (
                                                            <li className="jms-update-bullot-font">{ft}</li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            })

                            : null
                        }




                    </>
                    :
                    null
            }

        </>
    )
}

export default Updates
