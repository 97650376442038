import React, { Fragment, useState, useEffect, useRef } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, ListGroup, FormFeedback, ListGroupItem, Input, Form } from "reactstrap";
import { getAssignmentData, downloadTeacherAssignments, downloadAssignment, addAssignmentResponse } from '../../../redux/Actions/assignment'
import pdfIcon from './pdf.png'
import docIcon from './document.png'
// import PDFViewer from './PDFViewer';
import ImageView from './imageViewer'
import Modals from '../../common/Modal'
import Viewer from 'viewerjs';
import './imageView.scss'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { Progress } from 'react-sweet-progress';
import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay'
import Table from '../../common/Table'
import { useHistory } from 'react-router-dom'
import '../assign.scss'
import { Link } from 'react-router-dom'
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import 'viewerjs/dist/viewer.css';

import {
    faHome

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import PageTitle from "./header";
import Dropzone from 'react-dropzone'

import DescriptionDetails from "./descriptionDetails";
import MySubmission from "./mySubmission";

import Breadcrumbs from '../../common/Breadcrumbs'
import { requiredValidator } from "../../../utilities/Validator";

const SplitLayout = () => {
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"))
    const params = useParams()
    const history = useHistory();

    const ims = useRef(null)
    //debugger

    useEffect(() => {

        const ele2 = ims.current;
        //  const gallery = new Viewer(ele2);


        dispatch(getAssignmentData(tokenData.token, params.assig_id, params.class_id))
        return () => {
            dispatch({ type: 'RESET_ASSIGNMENT_DETAILS' })
        }
    }, [])
    let gallery = null
    const showImage = () => {
       // debugger;
        const ele2 = ims.current;
        if (!gallery) {
            gallery = new Viewer(ele2);
            gallery.show()
        } else {
            gallery.show()
        }
    }
    const assignmentStore = useSelector(({ assignments }) => assignments)

    const [state, setState] = useState({
        assignment: [],


    })

    const onDrop = (assignment) => {
        setState({ assignment });
    }
    const [remarks, setRemarks] = useState()
    const handleRemarks = (e) => {
        setRemarks(e.target.value)
    }

    const getDetails = (student_id, submitted, assesment_id) => {
        if (submitted) {
            history.push(`/dashboard/assignments/${params.assig_id}/${params.class_id}/${student_id}/${assesment_id}`)

        } else {
            Swal.fire({
                icon: 'error',
                title: '',
                text: 'Quiz not submitted',

            })
        }

    }
    const formData = new FormData();
    const [errors, setErrors] = useState({})

    const submitAssignment = (e) => {
        e.preventDefault()
        if (assignmentStore.assignmentDetails.Deadline_status === "completed") {
            Swal.fire({
                icon: 'error',
                title: 'Sorry',
                text: 'You crossed deadline',

            })
        } else {
            const err = {
                remarks: requiredValidator(remarks)
            }
            if (!err.remarks) {
                setErrors(err)
                if (state.assignment.length == 0) {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'choose assignment file to upload',

                    })
                    //alert("choose assignment file to upload")
                }
                else {

                    formData.set("class_id", params.class_id);
                    formData.set("assignment_id", params.assig_id);
                    formData.set("remark", remarks)
                    for (let i = 0; i < state.assignment.length; i++) {
                        formData.append('assignment[]', state.assignment[i])
                    }
                    console.log("formData!!!!!!", formData)
                    if (assignmentStore.assignmentDetails.Submitted_on !== null) {
                        Swal.fire({
                            icon: 'warning',
                            // title: "Format not supported",
                            text: "Old file will be deleted !",
                            confirmButtonText: 'Okay',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',


                        }).then((result) => {
                            if (result.value) {
                                dispatch(addAssignmentResponse(tokenData.token, formData, cb))

                            } else {
                                formData.delete('assignment[]')

                            }



                        })
                    } else {
                        dispatch(addAssignmentResponse(tokenData.token, formData, cb))

                    }
                }
            } else {
                setErrors(err)

            }

        }



    }


    const onCancel = () => {

        setState({
            assignment: []
        });
    }


    const downloadTeacherFile = () => {

        dispatch(downloadTeacherAssignments(tokenData.token, params.assig_id, params.class_id));

    }
    const downloadStudentFile = () => {
        dispatch(downloadAssignment(tokenData.token, params.assig_id, params.class_id));

    }


    const cb = () => {
        formData.delete('assignment[]')
        setState({
            assignment: []
        });

        Swal.fire(
            'Done',
            'Your file has been uploaded.',
            'success'
        )
        document.getElementById("file-form").reset();
        // window.location.reload();
    }
    const files = state.assignment.map(file => (
        <ListGroupItem key={file.name}>
            {file.name} - {file.size} bytes
        </ListGroupItem>
    ))

    const [currentSelected, setCurrentSelected] = useState(0)
    const [imageboxIsOpen, setImageboxIsOpen] = useState(false)
    const [pdfboxIsOpen, setPdfboxIsOpen] = useState(false)
    // const [pdfFile, setPdfFile] = useState(null)
    const openImage = (image, index) => {
        // debugger
        // alert(index)
        if (image.ext === "pdf") {

            // setPdfboxIsOpen(true)
            // // setPdfFile(`https://cors-anywhere.herokuapp.com/${image.src}`)
            // setPdfFile(image.src)
            var link = document.createElement("a");
            link.href = image.src;
            link.target = "_blank"
            link.click();
            link.remove();
        } else if (image.ext === "png" || image.ext === "jpg" || image.ext === "jpeg") {
            //debugger
            // setImg(assignmentStore.specificStudentAssignmentData.files.filter(row => row.ext == "png"))
            setCurrentSelected(index)
            setImageboxIsOpen(true)
        } else {

            Swal.fire({
                icon: 'warning',
                // title: "Format not supported",
                text: "Do you want download file ?",
                confirmButtonText: 'Download',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',


            }).then((result) => {
                if (result.value) {
                    var link = document.createElement("a");
                    link.href = image.src;
                    // link.target = "_blank"
                    link.click();
                    link.remove();
                }



            })

        }

    }

    const toggleModal = () => {
        setImageboxIsOpen(!imageboxIsOpen);
    }

    return (
        <Fragment>

            <CSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
                transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                {tokenData.role === "student" ? <Breadcrumbs /> :
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/dashboard" ><FontAwesomeIcon icon={faHome} /></Link>

                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to="/dashboard/assignments" >Quizzes</Link>

                        </BreadcrumbItem>
                        <BreadcrumbItem active>Details</BreadcrumbItem>
                    </Breadcrumb>

                }

                <Row>
                    <Col md={12} className="assign-details-grid">
                        <LoadingOverlay tag="div" active={!assignmentStore.assignmentDetails ? true : false}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                            <PageTitle
                                heading={assignmentStore.assignmentDetails.Title}
                                color={'#99DDFF'}
                                subject={assignmentStore.assignmentDetails.Subject}
                                deadline={assignmentStore.assignmentDetails.Deadline}
                                icon="pe-7s-note2 icon-gradient bg-sunny-morning"
                                buttonAction={downloadTeacherFile}
                                background={'#00458B'}
                                assignmentStore={assignmentStore}
                                lastDataColor={'rgb(180, 193, 189)'}

                                button={'DOWNLOAD'}

                            />
                        </LoadingOverlay>
                    </Col>
                </Row>
                <Row>
                    <DescriptionDetails
                        assignmentStore={assignmentStore}
                        role={tokenData.role}
                    />
                </Row>
                {
                    tokenData.role === "student" ?

                        <>

                            {
                                assignmentStore.assignmentImageStudent ?
                                    <>
                                        <Row>
                                            <Col md={12}>
                                                <h6 style={{ padding: '10px 0px 0px 0px' }}><strong>Quiz Files</strong></h6>
                                                <p>Documents</p>

                                                <hr />
                                            </Col>

                                            <Col md={12}>
                                                <Row style={{ padding: 10 }}>
                                                    {assignmentStore.assignmentDocTeacher ?
                                                        assignmentStore.assignmentDocTeacher.map((image, index) => {
                                                            return (
                                                                <Col md={2} style={{ padding: 5 }}>
                                                                    <div onClick={() => openImage(image, index)}
                                                                        style={{
                                                                            backgroundSize: 'cover',
                                                                            //backgroundImage: `url(${image.src})`, 
                                                                            // backgroundImage: `url(${image.ext == "pdf" ? pdfIcon : docIcon})`,

                                                                            width: '-webkit-fill-available',
                                                                            borderRadius: '5px',
                                                                            border: '3px solid #e8e7e7',
                                                                            height: 130,
                                                                            display: 'flex'
                                                                        }}>
                                                                        <img src={image.ext == "pdf" ? pdfIcon : docIcon} width={70} height={70} style={{ display: 'block', margin: 'auto' }} />
                                                                        {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                                    </div>

                                                                </Col>

                                                            )
                                                        })

                                                        : null
                                                    }
                                                </Row>
                                                <p>Images</p>
                                                <hr />
                                                <div ref={ims} >

                                                    <Row style={{ padding: 10 }}>
                                                        {assignmentStore.assignmentImageTeacher ?
                                                            assignmentStore.assignmentImageTeacher.map((image, index) => {
                                                                return (
                                                                    <Col md={2} style={{ padding: 5 }}>
                                                                        <div
                                                                            // onClick={() => openImage(image, index)}
                                                                            onClick={showImage}

                                                                            style={{
                                                                                backgroundSize: 'cover',
                                                                                backgroundImage: `url(${image.ext != "png" && image.ext != "jpg" && image.ext != "jpeg" ? pdfIcon : image.src})`,
                                                                                width: '-webkit-fill-available',
                                                                                borderRadius: '5px',
                                                                                border: '3px solid #e8e7e7',
                                                                                height: 130,
                                                                                width: 130

                                                                            }}>
                                                                            <img src={image.src} width={70} height={70} style={{ display: 'block', margin: 'auto' }} id={index} />

                                                                            {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                                        </div>

                                                                    </Col>

                                                                )
                                                            })

                                                            : null
                                                        }

                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>


                                        {/* {assignmentStore.assignmentImageTeacher ?

                                            <ImageView
                                                modalIsOpen={imageboxIsOpen}
                                                toggleModal={toggleModal}
                                                current={currentSelected}
                                                images={assignmentStore.assignmentImageTeacher}
                                            />
                                            : null
                                        } */}
                                        <Modals
                                            open={pdfboxIsOpen}
                                            handleClose={() => setPdfboxIsOpen(false)}
                                            // title={"Add Mark"}
                                            size="lg"
                                        >
                                            {/* <iframe src={`https://docs.google.com/viewer?url=${pdfFile}&embedded=true`} frameborder="0" height="500px" width="100%"></iframe> */}

                                            {/* <PDFViewer
                             file={pdfFile}
                                // file={'https://backend.mymots.com/assignment/H7gbZFaNjYRCA71BOlqtXLf0rAmCIjC7qbhGQA42.pdf' }


                                /> */}
                                        </Modals>
                                    </>
                                    : null
                            }

                            {
                                assignmentStore.assignmentDetails.Submitted_on !== null ?
                                    <Row>
                                        <Col md={12} className="assign-details-grid">

                                            <MySubmission
                                                assignmentStore={assignmentStore}
                                                downloadStudentFile={downloadStudentFile}

                                            />
                                        </Col>
                                    </Row> : null
                            }


                            {assignmentStore.assignmentDetails.Deadline_status !== "completed" ?
                                <Row>
                                    <Col md={12} className="assign-details-grid"

                                    // style={{ display: assignmentStore.assignmentDetails ? assignmentStore.assignmentDetails.Submitted_on != null ? 'none' : '' : '' }}
                                    >
                                        <Form onSubmit={submitAssignment} id="file-form">
                                            <LoadingOverlay tag="div" active={!assignmentStore.assignmentDetails ? true : false}
                                                styles={{
                                                    overlay: (base) => ({
                                                        ...base,
                                                        background: '#fff',
                                                        opacity: 0.5
                                                    })
                                                }}
                                                spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                                                <Card className="assign-details-card" >

                                                    <Row>

                                                        <Col md={12}><h6><strong style={{ padding: 10 }}>Upload File</strong></h6></Col>
                                                        <Col md="8" style={{ height: 325 }}>
                                                            <div className="dropzone-wrapper dropzone-wrapper-lg">
                                                                <Dropzone
                                                                    onDrop={onDrop}
                                                                    onFileDialogCancel={onCancel}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="dropzone-content">
                                                                                <p>Try dropping some files here, or click to select files to upload.</p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        </Col>

                                                        <Col md="4">
                                                            <Row>

                                                                <Col md={12}>
                                                                    <b className="mb-2 d-block">Dropped Files</b>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <ListGroup style={{ height: 270, overflowY: 'auto' }}>
                                                                        {files}
                                                                    </ListGroup>

                                                                </Col>
                                                                {/* <Col md={12} style={{ paddingTop: 18 }}>
                                        <Button

                                            disabled={assignmentStore.assignmentDetails ? assignmentStore.assignmentDetails.Submitted_on.length > 0 ? true : false : null}
                                            onClick={submitAssignment}
                                            style={{ width: '-webkit-fill-available' }} className="mb-2 mr-2 btn-icon" color="danger">
                                            <i className="pe-7s-upload btn-icon-wrapper"> </i>
                                    UPLOAD
                                </Button>
                                    </Col> */}
                                                            </Row>




                                                        </Col>
                                                        <Col md={12}>
                                                            <p><strong>Remarks</strong></p>
                                                            <Input invalid={errors.remarks} type="textarea" onChange={handleRemarks} name="remarks" id="exampleText" />
                                                            {/* <textarea style={{ width: '-webkit-fill-available' }}>fsdfsdf sdfsdf sdfsdf sdfsd sdfsdf sdfsdf sdfsdf sdf werwer wer wef sdfsdv sdfsdf sdfs dfsdf sdfsd fsdf sdf sdfsd fsdf sdfser wer weg</textarea> */}
                                                            <FormFeedback >{errors.remarks}</FormFeedback>

                                                        </Col>
                                                        <Col md={4} style={{ paddingTop: 15 }}>
                                                            {/* <p style={{ visibility: 'hidden' }}><strong>Remarks</strong></p> */}

                                                            <LaddaButton className="mb-2 mr-2 btn btn-danger"
                                                                // disabled={assignmentStore.assignmentDetails ? assignmentStore.assignmentDetails.Submitted_on != null ? true : false : null}
                                                                loading={assignmentStore.uploadAssignButton}
                                                                style={{ width: '-webkit-fill-available' }}
                                                                type="submit"
                                                                data-style={ZOOM_IN}   >
                                                                <i className="pe-7s-upload btn-icon-wrapper"></i> UPLOAD
                                   </LaddaButton>
                                                            {/* <Button
                                        type="submit"
                                        // disabled={assignmentStore.assignmentDetails ? assignmentStore.assignmentDetails.Submitted_on.length > 0 ? true : false : null}

                                        style={{ width: '-webkit-fill-available', fontSize: '15px', padding: '20px' }} className="mb-2 mr-2 btn-icon" color="danger">
                                        <i className="pe-7s-upload btn-icon-wrapper"> </i>
                                    UPLOAD
                                </Button> */}
                                                        </Col>
                                                        <Col md={12}>
                                                            {

                                                                assignmentStore.uploadProgress != '' ? <>
                                                                    <strong style={{ color: 'rgb(19, 140, 228)' }}>Uploading Files</strong>
                                                                    <Progress
                                                                        percent={assignmentStore.uploadProgress}
                                                                    /></> : null

                                                            }
                                                        </Col>

                                                    </Row>

                                                </Card>
                                            </LoadingOverlay>
                                        </Form>
                                    </Col>
                                </Row> : null
                            }


                        </>
                        : tokenData.role === "teacher" ?

                            assignmentStore.assignmentDetails ?
                                <>
                                    <hr />
                                    <Row>
                                        <Col md="6" lg="4">
                                            <div
                                                style={{ color: 'white', backgroundColor: '#00458B' }}

                                                className="card mb-3 widget-chart card-hover-shadow-2x text-left">
                                                <div className="icon-wrapper border-light rounded">
                                                    <div className="icon-wrapper-bg bg-light" />
                                                    <i className="lnr-graduation-hat icon-gradient bg-heavy-rain" />
                                                </div>
                                                <div className="widget-chart-content">
                                                    <div className="widget-numbers">
                                                        {assignmentStore.assignmentDetails.Total_student}
                                                    </div>
                                                    <div className="widget-subheading">
                                                        Total Clients
                                        </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="6" lg="4">
                                            <div
                                                className="card mb-3 widget-chart card-hover-shadow-2x text-left">
                                                <div className="icon-wrapper border-light rounded">
                                                    <div className="icon-wrapper-bg bg-light" />
                                                    {/* <i className="lnr-enter icon-gradient bg-grow-early" /> */}
                                                    <i className="lnr-laptop-phone icon-gradient bg-grow-early "> </i>

                                                </div>
                                                <div className="widget-chart-content">
                                                    <div className="widget-numbers">
                                                        {assignmentStore.assignmentDetails.Replied_student}

                                                    </div>
                                                    <div className="widget-subheading">
                                                        Replied Clients
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="6" lg="4">
                                            <div
                                                style={{ color: 'white', backgroundColor: '#3FD2C7 ' }}

                                                className="card mb-3 widget-chart card-hover-shadow-2x text-left">
                                                <div className="icon-wrapper border-light rounded">
                                                    <div className="icon-wrapper-bg bg-light" />
                                                    <i className="lnr-enter icon-gradient bg-heavy-rain" />
                                                </div>
                                                <div className="widget-chart-content jms-opacity-overwrite">
                                                    <div className="widget-numbers">
                                                        {assignmentStore.assignmentDetails.Total_student - assignmentStore.assignmentDetails.Replied_student}
                                                    </div>
                                                    <div className="widget-subheading">
                                                        Not  Replied
                                        </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Card style={{ padding: 10 }}>


                                        <Row>
                                            <Col md={12}>
                                                <h6 style={{ padding: '10px 0px 0px 10px' }}><strong>Files</strong></h6>
                                                <p>Documents</p>

                                                <hr />
                                            </Col>
                                            <Col md={12}>



                                                <Row style={{ padding: 10 }} >
                                                    {assignmentStore.assignmentDoc ?
                                                        assignmentStore.assignmentDoc.map((image, index) => {
                                                            return (
                                                                <Col md={2} style={{ padding: 5 }}>
                                                                    <div
                                                                        onClick={() => openImage(image, index)}
                                                                        // onClick={showImage}
                                                                        style={{
                                                                            backgroundSize: 'cover',
                                                                            //backgroundImage: `url(${image.src})`, 
                                                                            // backgroundImage: `url(${image.ext == "pdf" ? pdfIcon : docIcon})`,

                                                                            width: '-webkit-fill-available',
                                                                            borderRadius: '5px',
                                                                            border: '3px solid #e8e7e7',
                                                                            height: 130,
                                                                            display: 'flex'
                                                                        }}
                                                                    // className="jms-file-view"
                                                                    >
                                                                        <img src={image.ext == "pdf" ? pdfIcon : docIcon} width={70} height={70} style={{ display: 'block', margin: 'auto' }} />
                                                                        {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                                    </div>

                                                                </Col>

                                                            )
                                                        })

                                                        : null
                                                    }

                                                </Row>
                                                <p>Images</p>

                                                <hr />
                                                <div ref={ims}>
                                                    <Row style={{ padding: 10 }} >

                                                        {assignmentStore.assignmentImage ?
                                                            assignmentStore.assignmentImage.map((image, index) => {
                                                                return (
                                                                    <Col md={2} style={{ padding: 5 }} >
                                                                        <div
                                                                            //  onClick={() => openImage(image, index)}
                                                                            onClick={showImage}
                                                                            // className="jms-image-view"
                                                                            style={{
                                                                                backgroundSize: 'cover',
                                                                                backgroundImage: `url(${image.ext != "png" && image.ext != "jpg" && image.ext != "jpeg" ? pdfIcon : image.src})`,
                                                                                width: '-webkit-fill-available',
                                                                                borderRadius: '5px',
                                                                                border: '3px solid #e8e7e7',
                                                                                height: 130,
                                                                                width: 130
                                                                            }}
                                                                        // style={{
                                                                        //     backgroundSize: 'cover',
                                                                        //     //backgroundImage: `url(${image.src})`, 
                                                                        //     // backgroundImage: `url(${image.ext == "pdf" ? pdfIcon : docIcon})`,

                                                                        //     width: '-webkit-fill-available',
                                                                        //     borderRadius: '5px',
                                                                        //     border: '3px solid #e8e7e7',
                                                                        //     height: 130,
                                                                        //     display: 'flex'
                                                                        // }}
                                                                        >
                                                                            <img src={image.src} width={70} height={70} style={{ display: 'block', margin: 'auto' }} id={index} />

                                                                            {/* <img src={image.src} height={148} width={148}
                                                                                style={{ borderRadius: '5px', border: '3px solid #e8e7e7', display: 'none' }}
                                                                                className="jms-img-tag"
                                                                            /> */}
                                                                        </div>

                                                                    </Col>

                                                                )
                                                            })

                                                            : null
                                                        }



                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>

                                    {/* {assignmentStore.assignmentImage ?

                                        <ImageView
                                            modalIsOpen={imageboxIsOpen}
                                            toggleModal={toggleModal}
                                            current={currentSelected}
                                            images={assignmentStore.assignmentImage}
                                        />
                                        : null
                                    } */}
                                    <Modals
                                        open={pdfboxIsOpen}
                                        handleClose={() => setPdfboxIsOpen(false)}
                                        // title={"Add Mark"}
                                        size="lg"
                                    >

                                        {/* <PDFViewer
                                            file={pdfFile}
                                        // file={'https://backend.mymots.com/assignment/H7gbZFaNjYRCA71BOlqtXLf0rAmCIjC7qbhGQA42.pdf' } */}
                                        {/* <iframe src={`https://docs.google.com/viewer?url=${pdfFile}&embedded=true`} frameborder="0" height="500px" width="100%"></iframe> */}



                                    </Modals>

                                    <Table
                                        getDetails={getDetails}
                                        List_of_student={assignmentStore.assignmentDetails.List_of_student}
                                    />
                                </>
                                : null
                            : null

                }

            </CSSTransitionGroup >




        </Fragment >
    );

}
export default SplitLayout