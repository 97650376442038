import React, { useState, useEffect } from 'react'
import { addRequestVideo } from '../../../redux/Actions/videolist'
import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import {
    Row, Col,
    Card, CardBody, CardTitle,
    CardHeader, CardFooter, Button,Label,Input,
    Nav,
    NavItem,
    NavLink,
    CustomInput,
    FormGroup, Form
} from 'reactstrap';

const RequestVideo = (props) => {
    console.log("proppsssss",props);
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const tStore = useSelector(({ videolist }) => videolist)
    const data = tStore.specificStudent
    
    const handleClick = (e) => {
        e.preventDefault();
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        console.log("assigned", body)
        // dispatch(addRequestVideo(props.classId, body, tokenData.token))

    };
    return (
        <Form onSubmit={handleClick}>
            <input name="class_id" type="hidden" value={props.class_id} />
            <Card className="main-card mb-3">
                <CardBody>
                    <Row>
                        <Col sm={6}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Subject
                            </Label>
                            <Input type="select"
                                style={{ "width": "100%" }}
                                name='subject'
                                required
                            >
                                <option>Select</option>
                                <option>Physics</option>
                                <option>English</option>
                            </Input>

                        </Col>
                        <Col sm={6}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                Message
                            </Label>
                            <Input
                                type="textarea"
                                name="message"
                                required
                            />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="d-block text-right">
                    <Button className="btn-primary" type="submit" size="lg" color="success">Save</Button>
                </CardFooter>
            </Card>
        </Form>
    )
}

export default RequestVideo;
