const initialState = {
    feedbackSubjectLoader: false,
    feedbackSubject: [],
    sendFeedBackLoader: false,
    feedbacksLoader: false,
    feedback: [],
    feedbacksDemmy: [{ feedback_subject: 'Technical issue', comment: 'loren sdfshsd fsdfjhsdf sdfvsdf sdjfhvsdf sdv sdfhgsdf sdjfhsdf sdfhsdf sdjfhvsdf sdjhfvsdf sdjfvsdb fsdfvsd fjshdvfsdb fsjhdvfsdb fjshdvfjsd fjhsdvfs dfjhsdvfsd fjshdvfs dfjhsdvfhjsdvfsd ', first_name: 'John', created_at: '12/05/2020' },
    { feedback_subject: 'Technical issue', comment: 'loren sdfshsd fsdfjhsdf sdfvsdf sdjfhvsdf sdv sdfhgsdf sdjfhsdf sdfhsdf sdjfhvsdf sdjhfvsdf sdjfvsdb fsdfvsd fjshdvfsdb fsjhdvfsdb fjshdvfjsd fjhsdvfs dfjhsdvfsd fjshdvfs dfjhsdvfhjsdvfsd ', first_name: 'John', created_at: '12/05/2020' },
    { feedback_subject: 'Technical issue', comment: 'loren sdfshsd fsdfjhsdf sdfvsdf sdjfhvsdf sdv sdfhgsdf sdjfhsdf sdfhsdf sdjfhvsdf sdjhfvsdf sdjfvsdb fsdfvsd fjshdvfsdb fsjhdvfsdb fjshdvfjsd fjhsdvfs dfjhsdvfsd fjshdvfs dfjhsdvfhjsdvfsd ', first_name: 'John', created_at: '12/05/2020' },
    { feedback_subject: 'Technical issue', comment: 'loren sdfshsd fsdfjhsdf sdfvsdf sdjfhvsdf sdv sdfhgsdf sdjfhsdf sdfhsdf sdjfhvsdf sdjhfvsdf sdjfvsdb fsdfvsd fjshdvfsdb fsjhdvfsdb fjshdvfjsd fjhsdvfs dfjhsdvfsd fjshdvfs dfjhsdvfhjsdvfsd ', first_name: 'John', created_at: '12/05/2020' },
    { feedback_subject: 'Technical issue', comment: 'loren sdfshsd fsdfjhsdf sdfvsdf sdjfhvsdf sdv sdfhgsdf sdjfhsdf sdfhsdf sdjfhvsdf sdjhfvsdf sdjfvsdb fsdfvsd fjshdvfsdb fsjhdvfsdb fjshdvfjsd fjhsdvfs dfjhsdvfsd fjshdvfs dfjhsdvfhjsdvfsd ', first_name: 'John', created_at: '12/05/2020' }]
}
const feedbackReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case 'GET_ALL_FEEDBACK_REQUEST': {
            return {
                ...state, feedbacksLoader: true
            }
        }
        case 'GET_ALL_FEEDBACK_SUCCESS': {
            return {
                ...state, feedbacksLoader: false, feedbacks: payload
            }
        }
        case 'SEND_FEEDBACK_REQUEST': {
            return {
                ...state, sendFeedBackLoader: true
            }
        }
        case 'SEND_FEEDBACK_SUCCESS': {
            return {
                ...state, sendFeedBackLoader: false
            }
        }
        case 'SEND_FEEDBACK_FAILED': {
            return {
                ...state, sendFeedBackLoader: false

            }
        }
        case 'GET_FEEDBACK_SUBJECT_REQUEST': {
            return {
                ...state, feedbackSubjectLoader: true
            }
        }
        case 'GET_FEEDBACK_SUBJECT_SUCCESS': {
            return {
                ...state, feedbackSubjectLoader: false, feedbackSubject: payload
            }
        }
        default:
            return state
    }
}
export default feedbackReducer