import React  from 'react';
import ReactAudioPlayer from 'react-audio-player';

const App = () => {

  const Playing=()=>{
    console.log('playinngggggg')
  }

  const Ended=()=>{
    console.log('endedddd')
  }
  const Paused=()=>{
    console.log('pausedd')

  }
  return (
    <div className="app">
      <ReactAudioPlayer
        src="https://stio.mymots.com/chat/20/audio/1642053938.mp3"
        autoPlay={false}
        controls
        onPlay={Playing}
        onEnded={Ended}
        onPause={Paused}
      />
    </div>
  );
}

export default App;