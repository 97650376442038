const initialState = {
    assignments: [],
    assignmentss: [],
    loader: false,
    students: [],
    days: '0',
    remark: [],
    remarkNew: null,
    uploadProgress: '',
    assignmentDetails: '',
    downloadTeacherLoader: false,
    uploadAssignButton: false,
    specificStudentAssignmentData: '',
    assignmentImage: [],
    assignmentDoc: [],
    getAssigmentMarkLoader: false,
    homePageAssignmentLoader: false,
    assignmentImageStudent: [],
    assignmentDocStudent: [],
    assignmentImageTeacher: [],
    assignmentDocTeacher: []
}
const assignmentsReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        // case 'POPULATE_ASSIGNMENT_REQUEST': {
        //     console.log("success")
        //     return {
        //         ...state, loader: true,

        //     }
        // }
        case 'UPDATE_ASSIGNMENT_REQUEST': {
            return {
                ...state, updateAssignLoader: true
            }
        }
        case 'UPDATE_ASSIGNMENT_SUCCESS': {
            return {
                ...state, updateAssignLoader: false, assignmentss: payload
            }
        }
        case 'DELETE_ASSIGNMENT_REQUEST': {
            return {
                ...state, loader: true

            }

        }
        case 'DELETE_ASSIGNMENT_SUCCESS': {
            const filtered = state.assignmentss.filter(assignment => assignment.assignment_id !== payload)
            return {
                ...state, loader: false, assignmentss: filtered

            }
        }
        case 'POPULATE_DOWNLOAD_REQUEST': {
            console.log("success")
            return {
                ...state, loader: false,

            }
        }

        case 'GET_ASSIGNMENT_MARK_RESPONSE': {

            console.log("success---", payload.details)
            //  var copy = state.remark

            //  copy.push(payload[0])
            //  console.log("success", copy)
            return {
                ...state, loader: false, remarkNew: payload.details[0], getAssigmentMarkLoader: false
            }
        }
        case 'POPULATE_ALL_ASSIGNMENT_REQUEST': {
            return {
                ...state, loader: true
            }
        }
        case 'POPULATE_ALL_ASSIGNMENT_SUCCESS': {
            return {
                ...state, assignmentss: payload.assignments, loader: false
            }
        }
        case 'POPULATE_ALL_ASSIGNMENT_FAILED': {
            return {
                ...state, loader: false
            }
        }
        case 'POPULATE_ASSIGNMENT_REQUEST': {
            return {
                ...state, homePageAssignmentLoader: true, loader: true
            }
        }

        case 'POPULATE_ASSIGNMENTS': {

            return {
                ...state, loader: false, assignments: payload.classes, homePageAssignmentLoader: false
            }
        }
        case 'POPULATE_ASSIGNMENTS_FAILED': {
            return {
                ...state, loader: false, homePageAssignmentLoader: false
            }
        }

        case 'STUDENT_ASSIGNMENT_RESPONSE': {
            console.log('resp ', payload)
            return {
                ...state, loader: false, remark: payload
            }
        }

        case 'POPULATE_STUDENT_ASSIGNMENTS': {

            return {
                ...state, loader: false, assignmentss: payload.assignments
            }
        }

        case 'GET_TRIAL_DAYS': {
            console.log('action', payload)
            return {
                ...state, loader: false, days: payload
            }
        }

        case 'ASSIGNMENTS_POPULATE_STUDENTS': {
            console.log('pay ' + payload);
            return {
                ...state, loader: false, students: payload
            }

        }
        case 'EDIT_MARKS_REMARKS': {
            const { name, value } = payload
            return {
                ...state, remarkNew: { ...state.remarkNew, [name]: value }
            }
        }
        case 'UPLOAD_PROGRESS_BAR': {

            return {
                ...state, uploadProgress: payload
            }
        }
        case 'UPLOAD_ASSIGNMENT_SUCCESS': {
            return {
                ...state, uploadProgress: ''
            }
        }
        case 'UPLOAD_ASSIGNMENT_FAILED': {
            return {
                ...state, uploadProgress: ''

            }
        }
        case 'UPLOAD_ASSIGNMENT_STUDENT_REQUEST': {
            return {
                ...state, uploadAssignButton: true
            }
        }
        case 'UPLOAD_ASSIGNMENT_SUCCESS': {
            return {
                ...state, uploadAssignButton: false

            }

        }
        case 'UPLOAD_ASSIGNMENT_REQUEST': {
            return {
                ...state, uploadAssignButton: true

            }
        }
        case 'UPLOAD_ASSIGNMENT_SUCCESS': {
            return {
                ...state, uploadAssignButton: false

            }
        }
        case 'UPLOAD_ASSIGNMENT_REQUEST': {
            return {
                ...state, uploadAssignButton: false

            }
        }

        case 'UPLOAD_ASSIGNMENT_STUDENT_SUCCESS': {

            let student_images = [];
            for (let i = 0; i < payload.student_files.length; i++) {
                if (payload.student_files[i].ext == "png" || payload.student_files[i].ext == "jpeg" || payload.student_files[i].ext == "jpg") {
                    student_images.push(payload.student_files[i]);
                }
            }
            let student_doc = [];
            for (let i = 0; i < payload.student_files.length; i++) {
                if (payload.student_files[i].ext != "png" && payload.student_files[i].ext != "jpeg" && payload.student_files[i].ext != "jpg") {
                    student_doc.push(payload.student_files[i]);
                }
            }
            //alert(moment())

            const copy = state.assignmentDetails
            copy.Submitted_on = payload.submitted_on
            copy.Student_remark = payload.remarks

            return {
                ...state, uploadProgress: '',
                uploadAssignButton: false,
                assignmentDetails: copy,
                assignmentImageStudent: student_images,
                assignmentDocStudent: student_doc,
            }
        }
        case 'UPLOAD_ASSIGNMENT_STUDENT_FAILED': {
            return {
                ...state, uploadAssignButton: false, uploadProgress: ''

            }
        }
        case 'GET_ASSIGNMENT_DATA_SUCCESS': {

            const tokenData = JSON.parse(localStorage.getItem("authDetails"))
            if (tokenData) {
                if (tokenData.role === "teacher") {
                    let images = [];
                    for (let i = 0; i < payload.files.length; i++) {
                        if (payload.files[i].ext == "png" || payload.files[i].ext == "jpeg" || payload.files[i].ext == "jpg") {
                            images.push(payload.files[i]);
                        }
                    }
                    let doc = [];
                    for (let i = 0; i < payload.files.length; i++) {
                        if (payload.files[i].ext != "png" && payload.files[i].ext != "jpeg" && payload.files[i].ext != "jpg") {
                            doc.push(payload.files[i]);
                        }
                    }
                    return {
                        ...state, assignmentDetails: payload, assignmentImage: images, assignmentDoc: doc
                    }
                } else {


                    let teacher_images = [];
                    for (let i = 0; i < payload.teacher_files.length; i++) {
                        if (payload.teacher_files[i].ext == "png" || payload.teacher_files[i].ext == "jpeg" || payload.teacher_files[i].ext == "jpg") {
                            teacher_images.push(payload.teacher_files[i]);
                        }
                    }
                    let teacher_doc = [];
                    for (let i = 0; i < payload.teacher_files.length; i++) {
                        if (payload.teacher_files[i].ext != "png" && payload.teacher_files[i].ext != "jpeg" && payload.teacher_files[i].ext != "jpg") {
                            teacher_doc.push(payload.teacher_files[i]);
                        }
                    }
                    let student_images = [];
                    for (let i = 0; i < payload.student_files.length; i++) {
                        if (payload.student_files[i].ext == "png" || payload.student_files[i].ext == "jpeg" || payload.student_files[i].ext == "jpg") {
                            student_images.push(payload.student_files[i]);
                        }
                    }
                    let student_doc = [];
                    for (let i = 0; i < payload.student_files.length; i++) {
                        if (payload.student_files[i].ext != "png" && payload.student_files[i].ext != "jpeg" && payload.student_files[i].ext != "jpg") {
                            student_doc.push(payload.student_files[i]);
                        }
                    }
                    return {
                        ...state, assignmentDetails: payload,
                        assignmentImageStudent: student_images,
                        assignmentDocStudent: student_doc,
                        assignmentImageTeacher: teacher_images,
                        assignmentDocTeacher: teacher_doc
                    }


                    // return {
                    //     ...state, assignmentDetails: payload
                    // }
                }
            }


        }
        case 'RESET_ASSIGNMENT_DETAILS': {
            return {
                ...state, assignmentDetails: '', assignmentImage: [], assignmentDoc: []
            }
        }
        case 'POPULATE_DOWNLOAD_REQUEST': {
            return {
                ...state, downloadTeacherLoader: true
            }
        }
        case 'POPULATE_DOWNLOAD_SUCCESS': {
            return {
                ...state, downloadTeacherLoader: false
            }
        }
        case 'POPULATE_DOWNLOAD_FAILED': {
            return {
                ...state, downloadTeacherLoader: false
            }
        }
        case 'GET_STUDENT_ASSIGNMENT_DATA_SUCCESS': {
            // debugger
            let images = [];
            for (let i = 0; i < payload.files.length; i++) {
                if (payload.files[i].ext == "png" || payload.files[i].ext == "jpeg" || payload.files[i].ext == "jpg") {
                    images.push(payload.files[i]);
                }
            }
            let doc = [];
            for (let i = 0; i < payload.files.length; i++) {
                if (payload.files[i].ext != "png" && payload.files[i].ext != "jpeg" && payload.files[i].ext != "jpg") {
                    doc.push(payload.files[i]);
                }
            }
            return {
                ...state, specificStudentAssignmentData: payload, assignmentImage: images, assignmentDoc: doc
            }
        }
        case 'CLEANUP_SPECIFIC_STUDENT': {
            return {
                ...state, specificStudentAssignmentData: '', assignmentImage: [], assignmentDoc: []
            }
        }
        case 'GET_ASSIGNMENT_MARK_REQUEST': {
            return {
                ...state, getAssigmentMarkLoader: true
            }
        }
        case 'SUBMIT_ASSIGNMENT_MARK_SUCCESS': {

            const copy = state.specificStudentAssignmentData
            copy.Mark = payload.mark
            copy.Teacher_remark = payload.teacher_remarks
            return {
                ...state, specificStudentAssignmentData: copy
            }
        }
        default:
            return state
    }
}
export default assignmentsReducer