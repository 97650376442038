import React, { useState, useEffect, } from 'react'
import PageTitle from '../../HeaderTitle/PageTitle'
import Select from 'react-select';
import {
    Row, Col, Form, FormGroup, Input,

    Card, CardBody, Label,
    CardTitle,
    FormFeedback,
    Button
} from 'reactstrap';
import { requiredValidator } from '../../../utilities/Validator'
import ViewStudent from './viewStudent'
import TimeShedule from './viewTime'
// import Loader from 'react-loader'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import AssignStudents from './addStudentToClass'
import Modals from '../../common/Modal'
import Swal from 'sweetalert2';
// import Table from "./table";
import SweetAlert from 'sweetalert-react';
// import Switchy from "react-switch";


// import Tabl from '../../common/DataTable'
import moment from 'moment'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { deleteClasses, validateClassTiming, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, populateRegisteredTeachers, getTeacherAssignedGroups, populateAllEnterpriseUserClass, } from '../../../redux/Actions/homeTeacher'
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid'
import { logout, authCheck } from '../../../redux/Actions/auth'
import { getAllUsersInEnterprise } from '../../../redux/Actions/new-action'

import DatePicker from 'react-datepicker';
import CreateTimeSlot from './createTimeSlot'
// import TimeSlot from './timeSlotCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock, faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
// import { set } from 'date-fns';
import Showclass from "../ShowClass/index"

const Index = () => {


    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const st = useSelector(state => state)
    // var userClasses
    var columns
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    // const userlist_for_createclass = useSelector(state => state.new_action.userlist_for_createclass ? state.new_action.userlist_for_createclas : [])
    // const userlist_loader = useSelector(state => state.new_action.userlist_loader ? state.new_action.userlist_loader : false)

    const userlist_for_createclass = useSelector(state => state.new_action.userlist_for_createclass ? state.new_action.userlist_for_createclass : [])

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];


    useEffect(() => {

        dispatch(authCheck(tokenData.token, cbLogOut))

        if (!localStorage.getItem('authDetails')) {
            history.push('/')
        }
        dispatch(populateGrade(null, 1))
        if (tokenData.enterpriseUser) {
            // dispatch(populateRegisteredTeachers(tokenData.token))
            dispatch(getAllUsersInEnterprise(tokenData.token))
        }

        // if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) {
        //     dispatch(populateAllEnterpriseUserClass(tokenData.token))


        // } else {
        //     dispatch(populateAllUserClass(tokenData.token))

        // }

    }, []);

    // console.log("tStore-------", st)
    const cbLogOut = () => {

        dispatch(logout())
        localStorage.removeItem("authDetails");
        history.push('/')
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
    }


    if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) {
        columns = [
            { title: 'Class ', field: 'class_name', headerStyle: { fontWeight: '600' } },
            { title: 'Host ', field: 'teacher_name', headerStyle: { fontWeight: '600' } },
            { title: 'Grade', field: 'grade_name', headerStyle: { fontWeight: '600' } },
            { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
            { title: 'From Date', field: 'class_from_date', headerStyle: { fontWeight: '600' } },
            { title: 'To Date', field: 'class_to_date', headerStyle: { fontWeight: '600' } }

        ]

    } else {
        columns = [
            { title: 'Class ', field: 'class_name', headerStyle: { fontWeight: '600' } },
            { title: 'Grade', field: 'grade_name', headerStyle: { fontWeight: '600' } },
            { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
            { title: 'From Date', field: 'class_from_date', headerStyle: { fontWeight: '600' } },
            { title: 'To Date', field: 'class_to_date', headerStyle: { fontWeight: '600' } }

        ]
    }

    // new time picker related





    // console.log("momemt", moment().format('DD-MM-YYYY'))
    const [createTimeSlot, setTimeSlot] = useState([
        {
            key: uuid(),
            days: [
                { day: null, val: null },
                { day: 'Sunday', val: 'sun' },
                { day: 'Monday', val: 'mon' },
                { day: 'Tuesday', val: 'tue' },
                { day: 'Wednesday', val: 'wed' },
                { day: 'Thursday', val: 'thu' },
                { day: 'Friday', val: 'fri' },
                { day: 'Saturday', val: 'sat' }]
        }

    ])
    const [tempCart, setTempCard] = useState({
        class_from_date: null,
        class_to_date: null,
        day: null,
        class_from_time: '',
        class_to_time: ''
    })
    const handleChadeCard = (e, name) => {

        if (name) {

            let a = String(e)
            let b = a.slice(16, 21)

            setTempCard({ ...tempCart, key: uuid(), [name]: b })
            setValidayeData({ ...validateData, [name]: b })
        }
        else {
            const { id, value } = e.target;
            setTempCard({ ...tempCart, key: uuid(), [id]: value })
            setValidayeData({ ...validateData, [id]: value })
        }
    }
    // console.log("day==========", tempCart)
    const [realCard, setRealCard] = useState([])

    const removeCard = (id) => {

        const updatedCard = realCard.filter(card => !id.includes(card.key))
        setRealCard(updatedCard)

    }

    //     const setStartTime = (e) => {
    //         alert(e)
    //    let a = String(e)
    //    let  b = a.slice(16,21)

    //        setTempCard({ ...tempCart, key: uuid(), class_from_time :b })

    //        //setinputField({...inputField, class_start_date:b})
    //    }
    // console.log("event happppp", tempCart)
    const [validateData, setValidayeData] = useState({
        class_from_date: new Date(),
        class_to_date: moment().add(7, 'd')._d,
        day: null
    })

    const handleChange = (e, c) => {
        // console.log(`eee${e}-${c}`)
        const dt = moment(e).format('YYYY-MM-DD');
        // console.log("moment--", dt)
        // const dtt = moment(dt).toDate()
        //console.log("newdate--", dtt)
        //const a = moment(validateData.class_from_date).format('YYYY-MM-DD')
        //console.log("date==", a)
        // var dateObj = new Date('Thu Jul 15 2016 19:31:44 GMT+0200 (CEST)');
        // console.log("dt---", dateObj)
        // var momentObj = moment(dateObj);
        // var momentString = momentObj.format('YYYY-MM-DD');
        // var dateString = 'Thu Jul 15 2016 19:31:44 GMT+0200 (CEST)';
        // var dateObj = new Date(dateString);
        // var momentObj = moment(dateObj);
        // var momentString = momentObj.format('YYYY-MM-DD'); // 2016-07-15

        if (!c) {
            const { name, value } = e.target;
            setValidayeData({ ...validateData, [name]: value })
        }
        else {
            if (c === 'class_from_date') {
                setValidayeData({ ...validateData, [c]: dt, class_to_date: moment(dt).add(7, 'd')._d })

            }
            else {

                setValidayeData({ ...validateData, [c]: dt })

            }
        }

    }
    // console.log("validateData", validateData)

    const [slotLoader, setSlotLoader] = useState(false)
    const loaderFalse = (res) => {
        setSlotLoader(false)
        Swal.fire({
            icon: 'error',
            title: '',
            text: res,

        })

    }

    const validateCard = () => {


        var startTime = moment(validateData.class_from_time, 'hh:mm');
        var endTime = moment(validateData.class_to_time, 'hh:mm');
        var duration = endTime.diff(startTime, 'minutes');
        const maxDuration = tokenData.class_duration * 60
        // console.log(`diff:${duration}`)
        // console.log(`realCard:${realCard.length}`)

        //         if (tempCart.class_from_time > tempCart.class_to_time || tempCart.class_from_time == tempCart.class_to_time) {
        //             alert(`Start time should be less than End time
        // or 
        // should not be same`)


        //         }
        // else {

        if (tokenData.allowed_classes != '' && realCard.length >= tokenData.allowed_classes) {
            Swal.fire({
                icon: 'error',
                title: '',
                text: `You just have to create ${tokenData.allowed_classes}  classes...`,


            })
        } else {
            if (validateData.class_from_date &&
                validateData.class_to_date &&
                tempCart.class_from_time &&
                inputField.teacher_id &&
                tempCart.class_to_time &&
                tempCart.day !== null || '') {

                validateData.teacher_id = inputField.teacher_id

                if (tokenData.class_duration != null) {
                    if (Math.sign(duration) === -1 && !tokenData.enterpriseUser) {
                        const convertedDuration = 1440 - maxDuration //1440 is minuts in a day - 60*24
                        // alert(-Math.abs(convertedDuration))

                        if (tokenData.class_duration != '' && !(duration <= (-Math.abs(convertedDuration)))) {//   -1260 its minutes and its converted to negative
                            Swal.fire({
                                icon: 'error',
                                title: '',
                                text: `You just have to create ${tokenData.class_duration} Hour class...`,


                            })


                        } else {

                            dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse))
                            setSlotLoader(true)
                        }

                    } else {
                        // alert(duration)

                        if (tokenData.class_duration != '' && !(duration <= maxDuration) && !tokenData.enterpriseUser) {
                            // alert("You just have to create 3 Hour class...")
                            Swal.fire({
                                icon: 'error',
                                title: '',
                                text: `You just have to create ${tokenData.class_duration} Hour class...`,

                            })
                        }
                        else {
                            dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse))
                            setSlotLoader(true)
                        }
                    }
                } else {
                    dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse))
                    setSlotLoader(true)
                }


            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: 'Fill the required fields',

                })
            }
        }

    }
    const [dateDisablity, setDateDisabliyt] = useState(false)
    const createCard = () => {
        setDateDisabliyt(true)

        let err;

        if (realCard.length === 0) {
            setRealCard([...realCard, tempCart])
            setTempCard({
                class_from_date: '',
                class_to_date: '',
                day: '',
                class_from_time: '',
                class_to_time: ''
            })
            setSlotLoader(false)
        }
        else {

            realCard.forEach(item => {
                if (tempCart.day == item.day) {
                    console.log("hii")
                    if (tempCart.class_from_time !== item.class_from_time && tempCart.class_to_time !== item.class_to_time) {
                        const fr = moment(tempCart.class_from_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));
                        const to = moment(tempCart.class_to_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));
                        const rfr = moment(item.class_from_time, 'hh:mm').isBetween(moment(tempCart.class_from_time, 'hh:mm'), moment(tempCart.class_to_time, 'hh:mm'));
                        const rto = moment(item.class_to_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));

                        if (fr || to || rfr || rto) {
                            err = "from or to slot already taken";
                        }
                    }

                    else {
                        err = "from time t0 time same so notpossible";
                    }
                }
            })
            if (!err) {

                setRealCard([...realCard, tempCart])
                setTempCard({
                    class_from_date: '',
                    class_to_date: '',
                    day: '',
                    class_from_time: '',
                    class_to_time: ''
                })
                setSlotLoader(false)


            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: err,

                })
                setSlotLoader(false)

            }
        }

        // console.log("tmp=============", tempCart)


    }

    // console.log("store", tStore)

    //const [inputField, setInputField] = useState({})

    const handleChangeGrade = e => {
        const { name, value } = e.target;
        // setInputField({ ...inputField, subject_id: '' })

        setInputField({ ...inputField, [name]: value, subject_id: '' })

        dispatch(populateSubject(tokenData.token, value, 1, teacherId))
    };
    const handleChangeTeacher = e => {
        const { name, value } = e.target;
        setTeacherId(value)
        //setInputField({ ...inputField, [name]: value })
        //S  dispatch(populateSubject(tokenData.token, value, 1))
    };


    const [openModal, setOpenModal] = useState(false)
    const hadleOpenModal = (id) => {
        //alert(id)
        dispatch(getClassTiming(tokenData.token, id))
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const [openStdModal, setOpenStdModal] = useState(false)
    const [classId, setClassId] = useState()
    const [teacherId, setTeacherId] = useState()

    const [timeData, setTimeData] = useState()
    const hadleOpenStudentModal = (class_id, grade_id, teacher_id, rowData) => {
        // console.log("rorrrrrrrrr", teacher_id);

        setClassId()

        setTimeData(rowData)
        // console.warn("rrr", rowData)
        setClassId(class_id);
        if (tokenData.enterpriseUser) {

            dispatch(getTeacherAssignedGroups(tokenData.token, class_id, teacher_id))

        } else {
            dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id, getStudentCount))

        }
        setOpenStdModal(true)

    }
    const [selectedStudents, setSelectedStudents] = useState([])
    const getStudentCount = (studentList) => {
        // console.log("studentList", studentList)
        setSelectedStudents([])
        studentList.map(row => {
            if (row.selected === true) {
                return (
                    setSelectedStudents([...selectedStudents, row.user_id])
                )
            }
        })

    }
    const handleCloseStudentModal = () => {
        setOpenStdModal(false)
    }
    const handleCloseStudentModal2 = () => {
        setOpenStdModal(false)
        handleOpenSuccessModal()
    }
    const [successModal, setSuccessModal] = useState();
    const handleOpenSuccessModal = () => {
        if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) {
            dispatch(populateAllEnterpriseUserClass(tokenData.token))
        } 
        else {
            dispatch(populateAllUserClass(tokenData.token))
        }
        setSuccessModal(true)

    }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
        // console.log("close-----", tStore.classes)
        // window.location.reload(false);

    }
    const [studentListModal, setStudentListModal] = useState();
    const handleOpenStudentListModal = (class_id, grade_id) => {

        dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        setStudentListModal(true)
    }

    const handleCloseStudentListModal = () => {
        setStudentListModal(false)
    }
    const deleteClass = (class_id) => {
        dispatch(deleteClasses(tokenData.token, class_id))
    }



    // console.log("added days#######", moment(new Date).add(3, 'd')._d)
    const [editForm, setEditForm] = useState()
    const [editModal, setEditModal] = useState()
    const handleOpenEditModal = (rowData) => {

        setEditForm(rowData);
        setEditModal(true)

    }

    const handleCloseEditModal = () => {
        setEditModal(false)
    }




    const handleEdit = (classId, teacherId) => {
        //alert(classId)

        history.push(`/dashboard/editClass/${classId}/${teacherId}`)
    }

    const [inputField, setInputField] = useState({
        class_name: '',
        grade_id: '',
        subject_id: '',
        teacher_id: '',
        // fromDate: '',
        // toDate: ''
    })
    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setInputField({ ...inputField, [name]: value })
    }
    const [selectedOption, setselectedOption] = useState()

    const handleChangeSelectInput = (e) => {
        // const { name, value } = e.target;
        setselectedOption(e)
        setInputField({ ...inputField, 'teacher_id': e.user_id })
    }
    const [errors, setErrors] = useState({})
    const submitForm = (e) => {
        e.preventDefault();

        let error = null;
        if (tokenData.enterpriseUser) {
            error = {
                class_name: requiredValidator(inputField.class_name),
                grade: requiredValidator(inputField.grade_id),
                // subject: requiredValidator(inputField.subject_id),
                teacher: requiredValidator(inputField.teacher_id),
            }
            if (!error.class_name && !error.grade && !error.teacher) {
                // console.log("realCard in form ", realCard)
                setErrors(error)

                if (realCard.length > 0) {
                    const form = e.currentTarget
                    let body = serialize(form, { hash: true, empty: true })
                    const type = tokenData.enterpriseUser

                    dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm, type))
                    // dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Create atleast one time slot',

                    })
                }
            } else {
                setErrors(error)
                if (error.class_name) {
                    Showerror('classname')
                }
                else if (error.grade) {
                    Showerror('grade')
                }
                // else if (error.subject) {
                //     Showerror('subject')
                // }
                else {
                    Showerror('teacher')
                }

            }
        } else {
            error = {
                class_name: requiredValidator(inputField.class_name),
                grade: requiredValidator(inputField.grade_id),
                // subject: requiredValidator(inputField.subject_id)
            }
            if (!error.class_name && !error.grade) {
                // console.log("realCard in form ", realCard)
                setErrors(error)

                if (realCard.length > 0) {
                    const form = e.currentTarget
                    let body = serialize(form, { hash: true, empty: true })
                    const type = tokenData.enterpriseUser
                    dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm, type))
                    // dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Create atleast one time slot',

                    })
                }
            } else {
                setErrors(error)
                if (error.class_name) {
                    Showerror('classnamemots')
                }
                else if (error.grade) {
                    Showerror('grade')
                }
                else {
                    Showerror('subject')
                }
            }
        }
    }

    const Showerror = (val) => {
        var elmnt = document.getElementById(val);
        elmnt.scrollIntoView();
    }


    const resetForm = () => {
        setInputField({
            class_name: '',
            grade_id: '',
            subject_id: '438',
            teacher_id: '',
            // fromDate: '',
            // toDate: ''
        })
        setselectedOption(null)
        document.getElementById("class-form").reset();
        setRealCard([])

    }


    return (
        <>
            <PageTitle
                style={{ display: 'none' }}
                heading="Create Sessions"
                subheading="Create sessions and  your time slot for clients"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />
            <div style={{ padding: "0px 15px 0px 15px" }}>
                <Form onSubmit={submitForm} id="class-form" >


                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle>
                                SESSION DETAILS
                            </CardTitle>

                            <FormGroup row>
                                <Col sm={6}>
                                    <Row>

                                        {tokenData.enterpriseUser ? <>

                                            <Col sm={12}>

                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}Title
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="class_name"
                                                    id='classname'
                                                    invalid={errors.class_name}
                                                    onChange={handleChangeInput}
                                                    value={inputField.class_name}
                                                />
                                                <FormFeedback >{errors.class_name}</FormFeedback>

                                                {/* <FormFeedback >oops! Course is required</FormFeedback> */}

                                                <input
                                                    name='class_type'
                                                    type="hidden"
                                                    value="sheduled"
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}Select Host
                                                </Label>

                                                <Select
                                                   
                                                    getOptionLabel={option =>
                                                        `${option.full_name} - ${option.role_name}`
                                                    }
                                                    getOptionValue={option => `${option.user_id}`}
                                                    //invalid={true}
                                                    value={selectedOption}
                                                    onChange={(e) => { handleChangeSelectInput(e) }}
                                                    options={userlist_for_createclass}
                                                />
                                                <span style={{color:'red'}} >{errors.teacher}</span>

                                                <input
                                                    name='teacher_id'
                                                    type='hidden'
                                                    value={inputField.teacher_id}
                                                />
                                                {/* <Input type="select"
                                                    onChange={(e) => handleChangeTeacher(e)}
                                                    name='teacher_id'
                                                    invalid={errors.teacher}
                                                    onChange={handleChangeInput}
                                                    value={inputField.teacher_id}
                                                    id='teacher'
                                               
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        userlist_for_createclass ? userlist_for_createclass.map(registeredTeachers => {
                                                            return (
                                                                <option value={registeredTeachers.user_id}>{registeredTeachers.full_name} - {registeredTeachers.role_name} </option>
                                                            )
                                                        }) : []
                                                    }

                                                </Input> */}
                                                <FormFeedback >{errors.teacher}</FormFeedback>

                                            </Col>
                                        </> :

                                            (<Col sm={12}>

                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}Class Name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="class_name"
                                                    //required
                                                    id='classnamemots'
                                                    invalid={errors.class_name}
                                                    onChange={handleChangeInput}
                                                    value={inputField.class_name}
                                                />
                                                <FormFeedback >{errors.class_name}</FormFeedback>

                                                <input
                                                    name='class_type'
                                                    type="hidden"
                                                    value="sheduled"
                                                />
                                            </Col>
                                            )
                                        }


                                        <Col sm={6}>
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Clinic
                                            </Label>

                                            <Input type="select"
                                                onChange={(e) => handleChangeGrade(e)}
                                                name='grade_id'
                                                id='grade'
                                                //required
                                                // onChange={handleChangeInput}
                                                invalid={errors.grade}

                                                value={inputField.grade_id}
                                            >

                                                <option value={''}>{'select'}</option>
                                                {tStore.grade ?
                                                    tStore.grade.map(grade => {
                                                        return (
                                                            <option value={grade.grade_id}>{grade.grade_name}</option>
                                                            //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                                        )
                                                    }) : []
                                                }

                                            </Input>
                                            <FormFeedback >{errors.grade}</FormFeedback>

                                        </Col>
                                        <Input type="hidden"
                                            name='subject_id'
                                            //required
                                            onChange={handleChangeInput}
                                            value={438}
                                            invalid={errors.subject}
                                            id='subject'


                                        ></Input>
                                        {/* <Col sm={6}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Subject
                                            </Label>
                                            <Input type="select"
                                                name='subject_id'
                                                //required
                                                onChange={handleChangeInput}
                                                value={inputField.subject_id}
                                                invalid={errors.subject}
                                                id='subject'


                                            >
                                                <option value={''}>{'select'}</option>

                                                {tStore.subject ?
                                                    tStore.subject.map(subj => {
                                                        return (
                                                            <option value={subj.subject_id}>{subj.subject_name}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Input>
                                            <FormFeedback >{errors.subject}</FormFeedback>

                                        </Col> */}
                                    </Row>


                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12} style={{ borderLeft: 'solid 1px #ced4db' }}>


                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}From Date
                                            </Label>
                                            <DatePicker
                                                selected={moment(validateData.class_from_date).toDate()}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                onChange={(e) => handleChange(e, 'class_from_date')}
                                                withPortal
                                                minDate={moment().toDate()}
                                                required
                                                renderCustomHeader={({
                                                    date,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    increaseYear,
                                                    decreaseYear,

                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                                                            {"<<"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                        </Button>

                                                        <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                                            {">>"}
                                                        </Button>
                                                    </div>
                                                )}

                                            />
                                            <Input
                                                value={moment(validateData.class_from_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_from_date"
                                            />
                                            {/* <Input
                                        value={validateData.class_from_date}
                                        required
                                        type="date"
                                        name="class_from_date"
                                        onChange={handleChange}
                                        id="exampleDate"
                                        placeholder="date placeholder"
                                        min={moment().format("YYYY-MM-DD")}
                                    /> */}
                                        </Col>
                                        <Col sm={12} style={{ borderLeft: 'solid 1px #ced4db' }}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}To Date
                                            </Label>

                                            <DatePicker
                                                selected={moment(validateData.class_to_date).toDate()}
                                                className="form-control"
                                                onChange={(e) => handleChange(e, 'class_to_date')}
                                                withPortal
                                                dateFormat="dd-MM-yyyy"
                                                // minDate={moment(validateData.class_from_date).add(5, 'days')}
                                                minDate={moment(validateData.class_from_date).add(7, 'd')._d}
                                                required
                                                renderCustomHeader={({
                                                    date,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    increaseYear,
                                                    decreaseYear,

                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                                                            {"<<"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                        </Button>

                                                        <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                                            {">>"}
                                                        </Button>
                                                    </div>
                                                )}

                                            />
                                            <Input
                                                value={moment(validateData.class_to_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_to_date"
                                            />
                                            {/* <Input
                                        required
                                        type="date"
                                        name="class_to_date"
                                        onChange={handleChange}
                                        id="exampleDate"
                                        placeholder="date placeholder"
                                        min={moment().format("YYYY-MM-DD")}

                                    /> */}

                                        </Col>
                                    </Row>
                                </Col>


                            </FormGroup>
                            {/* <strong>Class Type</strong>
                            <hr /> */}
                            <input type="hidden" name="class_category" value={null} />

                            {/* <Row>
                                <Col md={2}>
                                    Normal:
                                </Col>
                                <Col md={1}>
                                    <input defaultChecked type="radio" name="class_category" value={`normal`} />

                                </Col>
                                <Col md={2}>
                                    Conference:
                                </Col>
                                <Col md={1}>
                                    <input type="radio" name="class_category" value={`conference`} />

                                </Col>
                            </Row> */}

                        </CardBody>

                    </Card>

                    <CreateTimeSlot
                        handleChadeCard={handleChadeCard}
                        createTimeSlot={createTimeSlot}
                        createCard={validateCard}
                        tempCart={tempCart}
                        loader={slotLoader}
                        setStartTime={""}

                    />

                    <Row>
                        {realCard ?
                            realCard.map(days => {
                                return (
                                    <Col md="6" lg="3">
                                        <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content"> <p style={{ textAlign: 'right' }}><FontAwesomeIcon onClick={() => removeCard(days.key)} icon={faWindowClose} /></p>
                                                    <div className="widget-title opacity-5 text-uppercase">
                                                        <h6>
                                                            {`${days ? moment(days.class_from_time, 'hh:mm ').format("h:mm A") : null} - ${days ? moment(days.class_to_time, 'hh:mm ').format("h:mm A") : null}`}
                                                        </h6>
                                                        <input
                                                            value={days ? days.class_from_time : null}
                                                            type="hidden"
                                                            name={

                                                                days.day === 'sun' ? "week[sun][class_from_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_from_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_from_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_from_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_from_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_from_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_from_time][]" :
                                                                                            "name from"
                                                            }
                                                            id="from"
                                                        />
                                                        <input type="hidden"
                                                            value={days ? days.class_to_time : null}
                                                            id='to'
                                                            name={
                                                                days.day === 'sun' ? "week[sun][class_to_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_to_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_to_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_to_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_to_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_to_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_to_time][]" :
                                                                                            "name to"
                                                            }
                                                        />
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="widget-chart-flex align-items-center">

                                                            <div>
                                                                <h4>

                                                                    {days ? days.day === 'sun' ? 'Sunday'
                                                                        : days.day === 'mon' ? 'Monday'
                                                                            : days.day === 'tue' ? 'Tuesday'
                                                                                : days.day === 'wed' ? 'Wednesday'
                                                                                    : days.day === 'thu' ? 'Thursday'
                                                                                        : days.day === 'fri' ? 'Friday'
                                                                                            : days.day === 'sat' ? 'Saturday' : null
                                                                        : null}
                                                                </h4>

                                                            </div>
                                                            <div style={{ display: 'none' }}>

                                                                <select
                                                                    id='day'
                                                                    name='day'
                                                                >
                                                                    <option value={days ? days.day : null}>
                                                                        {days ? days.day === 'sun' ? 'Sunday'
                                                                            : days.day === 'mon' ? 'Monday'
                                                                                : days.day === 'tue' ? 'Tuesday'
                                                                                    : days.day === 'wed' ? 'Wednesday'
                                                                                        : days.day === 'thu' ? 'Thursday'
                                                                                            : days.day === 'fri' ? 'Friday'
                                                                                                : days.day === 'sat' ? 'Saturday' : null
                                                                            : null}</option>
                                                                </select>

                                                            </div>
                                                            <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                                                <div className="ml-auto">
                                                                    <FontAwesomeIcon style={{ fontSize: '40px' }} color="#545bd8" icon={faClock} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            }) : null
                        }

                    </Row>

                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        loading={tStore.ccLoader}
                        type="submit"
                        data-style={ZOOM_IN}  >
                        CREATE SESSION
                    </LaddaButton>

                </Form>
                <Button style={{ visibility: 'hidden' }} type="submit">{` `}</Button>
                <Button style={{ visibility: 'hidden' }} type="submit">{` `}</Button>

                <Row>
                    <Col sm={12} md={12}>
                        {/* <Table
                            loader={tStore.allUserTableLoader}
                            actions={'assign_student'}
                            data={(tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) ? tStore.enterpriseClasses : tStore.classes}
                            columns={columns}
                            hadleOpenModal={hadleOpenModal}
                            hadleOpenStudentModal={hadleOpenStudentModal}
                            handleOpenStudentListModal={handleOpenStudentListModal}
                            handleOpenEditModal={handleOpenEditModal}
                            handleEdit={handleEdit}
                            deleteClass={deleteClass}
                        /> */}
                        <Showclass />
                    </Col>
                </Row >
                <Modals
                    open={openStdModal}
                    handleClose={handleCloseStudentModal}
                    title={tokenData.enterpriseUser ? 'Assign Group' : 'Assign Students'}

                >
                    <AssignStudents
                        classId={classId}
                        loader={tStore.specificGroupLoader}
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        data={tokenData.enterpriseUser ? tStore.specificGroup : tStore.specificStudent}
                        // data={tStore.specificStudent ? tStore.specificStudent : tStore.specificGroup}
                        handleCloseStudentModal={handleCloseStudentModal2}
                        timeData={timeData}
                    />
                </Modals>
                <Modals
                    open={studentListModal}
                    handleClose={handleCloseStudentListModal}
                    title={`View Clients`}

                    contentClassName="bg-light"
                >

                    <ViewStudent
                        loader={tStore.specificStudentLoader}

                        data={tStore.specificStudent}

                    />
                </Modals>
                <Modals
                    open={openModal}
                    handleClose={handleCloseModal}
                    title={`Time shedule`}
                >
                    <TimeShedule
                        loader={tStore.specificClassTimeLoader}

                        data={tStore.specificClassTime}
                    />
                </Modals>

                <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={successModal}
                    text="Completed successfully"
                    type="success"
                    onConfirm={() => handleCloseSuccesstModal()} />
            </div>
        </>
    )

}

export default Index
