import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Form, FormGroup, Label, FormFeedback, Input, CardHeader } from 'reactstrap'
import PlaylistTumnile from '../../../common/Thumbnile/playlist'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { requiredValidator } from '../../../../utilities/Validator'

// import { getGradeList, populateDegree } from '../../../redux/Actions/signUp'
import { populateSubject, populateGrade } from '../../../../redux/Actions/homeTeacher'
import serialize from 'form-serialize'
import { populatePlayList, createPlayList } from '../../../../redux/Actions/playlist'

// import Table from '../common/DataTable'
import Swal from 'sweetalert2'


const School = (props) => {
    const { DeletePlaylists, EditPlaylists, editMode, openEdit, handleChange, cancelEdit } = props

    const dispatch = useDispatch()
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const playlistStore = useSelector(({ playlist }) => playlist)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    useEffect(() => {


        dispatch(populatePlayList(tokenData.token, 1))

        dispatch(populateGrade(null, 1))



        return () => {
            console.log("unmount school")
        }
    }, [])



    const handleChangeGrade = e => {
        const { name, value } = e.target;
        //setInputField({ ...inputField, [name]: value })
        dispatch(populateSubject(tokenData.token, value, 1))
        setInputField({ ...inputField, [name]: value })
    };


    // const onEdit = (rowData) => {
    //     console.log("row-", rowData)
    //     dispatch(updateSubjectSchhol(tokenData.token, rowData))

    // }

    // const onDelete = (id) => {

    //     Swal.fire({
    //         icon: 'warning',
    //         title: 'Are you sure !',
    //         // text: 'All the classes under the subject will be deleted',
    //         showCancelButton: true,
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: "CONFIRM",
    //         cancelButtonText: "CANCEL",

    //     }).then((result) => {
    //         if (result.value) {
    //             dispatch(deleteSubjectSchool(tokenData.token, id))

    //             Swal.fire(
    //                 'Deleted!',
    //                 'Subject has been deleted.',
    //                 'success'
    //             )

    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             Swal.fire(
    //                 'Cancelled',
    //                 'Delete action cancelled',
    //                 'error'
    //             )
    //         }
    //     })

    // }
    const [inputField, setInputField] = useState({
        playlist_name: '',
        grade_id: '',
        subject_id: 435,
    })
    const handleChangeInput = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
    }
    const [errors, setErrors] = useState({})

    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        let error;
        error = {
            playlist_name: requiredValidator(inputField.playlist_name),
            grade_id: requiredValidator(inputField.grade_id),
            subject_id: requiredValidator(inputField.subject_id),
        }
        if (!error.playlist_name && !error.grade_id && !error.subject_id) {
            dispatch(createPlayList(tokenData.token, body, reset))
            setErrors(error)
        } else {
            setErrors(error)

        }

    }
    const reset = () => {
        document.getElementById("create-form-school").reset();
    }
    return (
        <>




            <Form onSubmit={formSubmit} id="create-form-school">


                <Card>
                    <CardHeader>Create playlist</CardHeader>
                    <Row className="jms-subject-row-pad">
                        <Col md={1} ></Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">
                                    <span className="text-danger">*</span>
                                    {' '}Title</Label>
                                <Input type="text" name="playlist_name"
                                    onChange={handleChangeInput}
                                    invalid={errors.playlist_name}
                                />
                                <FormFeedback >{errors.playlist_name}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <input type="hidden" value={1} name="category_id" />

                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">
                                    <span className="text-danger">*</span>
                                    {' '}Select Clinic</Label>
                                <Input type="select" name="grade_id"

                                    onChange={(e) => handleChangeGrade(e)}
                                    invalid={errors.grade_id}

                                >
                                    <option value="">Select</option>

                                    {tStore.grade ?
                                        tStore.grade.map(grade => {
                                            return (
                                                <option value={grade.grade_id}>{grade.grade_name}</option>
                                                //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                            )
                                        }) : []
                                    }

                                </Input>
                                <FormFeedback >{errors.grade_id}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Input type="hidden" name="subject_id"
                            onChange={handleChangeInput}
                            invalid={errors.subject_id}
                            value={438}


                        />

                        <Col md={2}>
                            <FormGroup>
                                {/* <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Subject</Label> */}

                                <LaddaButton className=" btn btn-danger"
                                    loading={playlistStore.createPlayListSchoolLoader}
                                    // onClick={formSubmit}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                    style={{ whiteSpace: 'nowrap', marginRight: '0px', width: '-webkit-fill-available', marginTop:'30px' }}

                                >
                                    Create Playlist
                                </LaddaButton>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            </Form>
            <div style={{ paddingTop: 20, fontWeight: 700 }}>{'PLAYLIST'} </div>


            <hr />

            <Row>

                {

                    playlistStore.populatePlaylistSchoolLoader === true ?
                        Array(3).fill().map(() => {
                            return (
                                <Col md={4} style={{ paddingBottom: '10px' }}>
                                    <Card style={{ padding: '30px' }}>
                                        <Row>
                                            <Col md={3}>
                                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                                    <Skeleton circle={true} height={50} width={50} count={1} />
                                                </SkeletonTheme>
                                            </Col>
                                            <Col md={9}>
                                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                                    <Skeleton height={20} count={4} />
                                                </SkeletonTheme>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            )
                        })

                        : playlistStore.playlistSchool ? playlistStore.playlistSchool.map((playlist, index) => {
                            return (
                                <Col md={4} style={{ paddingBottom: '25px' }}>

                                    <PlaylistTumnile
                                        to={playlist.playlist_id}
                                        category_id={playlist.category_id}
                                        subject_id={playlist.subject_id}
                                        index={index}
                                        title={playlist.playlist_name}
                                        subject={playlist.subject_name}
                                        grade={playlist.grade_name}
                                        count={playlist.video_count}
                                        DeletePlaylists={DeletePlaylists}
                                        EditPlaylists={EditPlaylists}
                                        editMode={editMode}
                                        openEdit={openEdit}
                                        handleChange={handleChange}
                                        cancelEdit={cancelEdit}
                                    />


                                </Col>
                            )
                        }) : null
                }




            </Row>




        </>

    )
}

export default School
