const initialState = {
    createPlayListSchoolLoader: false,
    createPlayListCollegeLoader: false,
    createPlayListLifeLongLoader: false,
    createPlayListGenaralLoader: false,
    populatePlaylistSchoolLoader: false,
    populatePlaylistCollegeLoader: false,
    populatePlaylistLifelongLoader: false,
    populatePlaylistGenaralLoader: false,
    playlistSchool: [],
    playlistCollege: [],
    playlistLifelong: [],
    playlistGenaral: [],
    playListDetailsLoader: false,
    playListDetails: '',
    uploadProgress: '',
    uploadVideoButtonLoader: false,
    videos: [],
    classPlaylistLoader: false,
    classPlaylist: [],
    playlistVideosLoader: false,
    playlistVideos: [],
    createVideoClassLoader: false,
    allVideoClassesLoader: false,
    allVideoClasses: [],
    specificStudentLoader: false,
    specificStudent: [],
    assignStudentLoader: false,
    updatePlayList: false,
    updateVideoLoader: false,
    deleteVideoLoader: false,

}

const studentHomeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // case 'DELETE_PLAYLIST_VIDEO_REQUEST': {
        //     return {
        //         ...state, deleteVideoLoader: true

        //     }
        // }
        case 'CHANGE_TITLE': {
            const copyData = [...state.playlistVideos]
            copyData[payload.position].title = payload.title
            console.log("copyData[payload.id]", payload);


            return {
                ...state, playlistVideos: copyData
            }


        }
        case 'REQUEST_FOR_UPDATE_CLASS': {
            return {
                ...state, classUpdateLoader: true
            }
        }
        case 'SUCCESS_FOR_UPDATE_CLASS': {
            return {
                ...state, classUpdateLoader: false
            }
        }
        case 'REQUEST_FOR_UPDATE_CLASS_FAILED': {
            return {
                ...state, classUpdateLoader: false
            }
        }
        case 'REQUEST_FOR_EDIT_CLASS_SUCCESS': {
            return {
                ...state, editClassDetails: payload
            }
        }
        case 'UPDATE_PLAYLIST_VIDEO_SUCCESS': {
            const update = state.playListDetails.vidoes

            update.map(row => {
                if (row.video_id == payload.id) {
                    row.title = payload.title

                }
            })
            state.playListDetails.vidoes = update
            return {
                ...state, updateVideoLoader: false
            }
        }
        case 'CHANGE_PLAYLIST_TITLE': {
            const update = state.playlistSchool
            // const row = update.filter(row => row.playlist_id == payload.id)
            update.map(row => {
                if (row.playlist_id == payload.id) {
                    row.playlist_name = payload.title

                }
            })

            return {
                ...state, playlistSchool: update
            }
        }
        case 'DELETE_PLAYLIST_VIDEO_SUCCESS': {
            const update = state.playListDetails
            update.vidoes = payload
            //debugger
            return {
                ...state, deleteVideoLoader: false, playListDetails: update

            }
        }
        // case 'UPDATE_PLAYLIST_VIDEO_REQUEST': {
        //     return {
        //         ...state, updateVideoLoader: true
        //     }
        // }
        // case 'UPDATE_PLAYLIST_VIDEO_SUCCESS': {
        //     return {
        //         ...state, updateVideoLoader: false
        //     }
        // }


        case 'UPDATE_PLAYLIST_SCHOOL_REQUEST': {
            return {
                ...state, updatePlayList: true, populatePlaylistSchoolLoader: true

            }
        }
        case 'UPDATE_PLAYLIST_SCHOOL_SUCCESS': {
            const update = state.playlistSchool
            update.map(row => {
                if (row.playlist_id == payload.playlist_id) {
                    row.playlist_name = payload.playlist_name
                }
            })
            return {
                ...state, updatePlayList: false, populatePlaylistSchoolLoader: false, playlistSchool: update
            }
        }
        case 'UPDATE_PLAYLIST_COLLEGE_REQUEST': {
            return {
                ...state, updatePlayList: true, populatePlaylistCollegeLoader: true

            }
        }
        case 'UPDATE_PLAYLIST_COLLEGE_SUCCESS': {
            const update = state.playlistCollege
            update.map(row => {
                if (row.playlist_id == payload.playlist_id) {
                    row.playlist_name = payload.playlist_name
                }
            })
            return {
                ...state, updatePlayList: false, populatePlaylistCollegeLoader: false, playlistCollege: update
            }
        }
        case 'UPDATE_PLAYLIST_LIFELONG_REQUEST': {
            return {
                ...state, updatePlayList: true, populatePlaylistLifelongLoader: true

            }
        }
        case 'UPDATE_PLAYLIST_LIFELONG_SUCCESS': {
            const update = state.playlistLifelong
            update.map(row => {
                if (row.playlist_id == payload.playlist_id) {
                    row.playlist_name = payload.playlist_name
                }
            })
            return {
                ...state, updatePlayList: false, populatePlaylistLifelongLoader: false, playlistLifelong: update
            }
        }
        case 'UPDATE_PLAYLIST_GENARAL_REQUEST': {
            return {
                ...state, updatePlayList: true, populatePlaylistGenaralLoader: true

            }
        }
        case 'UPDATE_PLAYLIST_GENARAL_SUCCESS': {
            const update = state.playlistGenaral
            update.map(row => {
                if (row.playlist_id == payload.playlist_id) {
                    row.playlist_name = payload.playlist_name
                }
            })
            return {
                ...state, updatePlayList: false, populatePlaylistGenaralLoader: false, playlistGenaral: update
            }
        }


        // case 'UPDATE_PLAYLIST_REQUEST': {
        //     return {
        //         ...state, updatePlayList: true, populatePlaylistSchoolLoader: true
        //     }
        // }
        // case 'UPDATE_PLAYLIST_SUCCESS': {
        //     const update = state.playlistSchool
        //     // const row = update.filter(row => row.playlist_id == payload.id)
        //     update.map(row => {
        //         if (row.playlist_id == payload.playlist_id) {
        //             row.playlist_name = payload.playlist_name

        //         }
        //     })


        //     return {
        //         ...state, updatePlayList: false, populatePlaylistSchoolLoader: false, playlistSchool: update
        //     }
        // }
        case 'ADD_STUDENTS_TO_VIDEO_CLASS_REQUEST': {
            return {
                ...state, assignStudentLoader: true
            }
        }
        case 'ADD_STUDENTS_TO_VIDEO_CLASS_SUCCESS': {
            return {
                ...state, assignStudentLoader: false
            }
        }
        case 'POPULATE_VIDEO_CLASS_STUDENT_REQUEST': {
            return {
                ...state, specificStudentLoader: true

            }
        }
        case 'POPULATE_VIDEO_CLASS_STUDENT_SUCCESS': {
            return {
                ...state, specificStudentLoader: false, specificStudent: payload

            }
        }
        case 'POPULATE_VIDEO_CLASS_REQUEST': {
            return {
                ...state, allVideoClassesLoader: true
            }
        }
        case 'POPULATE_VIDEO_CLASS_SUCCESS': {
            return {
                ...state, allVideoClassesLoader: false, allVideoClasses: payload
            }
        }
        case 'CREATE_VIDEO_CLASS_REQUEST': {
            return {
                ...state, createVideoClassLoader: true
            }
        }
        case 'CREATE_VIDEO_CLASS_SUCCESS': {
            return {
                ...state, createVideoClassLoader: false
            }
        }
        case 'CREATE_VIDEO_CLASS_FAILED': {
            return {
                ...state, createVideoClassLoader: false

            }
        }
        case 'GET_PLAYLIST_VIDEO_REQUEST': {
            return {
                ...state, playlistVideosLoader: true, playlistVideos: []
            }
        }
        case 'GET_PLAYLIST_VIDEO_SUCCESS': {
            return {
                ...state, playlistVideos: payload, playlistVideosLoader: false
            }
        }
        case 'POPULATE_PLAYLIST_IN_CLASS_REQUEST': {
            return {
                ...state, classPlaylistLoader: true
            }
        }

        case 'POPULATE_PLAYLIST_IN_CLASS_SUCCESS': {
            return {
                ...state, classPlaylistLoader: false, classPlaylist: payload
            }
        }

        case 'UPLOAD_VIDEO_PROGRESS_BAR': {
            return {
                ...state, uploadProgress: payload
            }
        }
        case 'UPLOAD_VIDEO_REQUEST': {
            return {
                ...state, uploadVideoButtonLoader: true
            }
        }
        case 'UPLOAD_CHOOSE_FROM_REQUEST': {
            return {
                ...state, uploadVideoButtonLoader: true

            }
        }
        case 'UPLOAD_CHOOSE_FROM_SUCCESS': {
            const copy = state.playListDetails
            payload.forEach(row => copy.vidoes.unshift(row));

            return {
                ...state, uploadVideoButtonLoader: false, playListDetails: copy
            }

        }
        case 'UPLOAD_CHOOSE_FROM_FAILED': {
            return {
                ...state, uploadVideoButtonLoader: false

            }
        }

        case 'UPLOAD_VIDEO_SUCCESS': {

            const copy = state.playListDetails
            copy.vidoes.unshift(payload)
            return {
                ...state, uploadVideoButtonLoader: false, uploadProgress: '', playListDetails: copy
            }
        }
        case 'UPLOAD_VIDEO_FAILED': {
            console.log("error--", payload)
            return {
                ...state, uploadVideoButtonLoader: false
            }
        }
        case 'POPULATE_PLAYLIST_DETAILS_REQUEST': {
            return {
                ...state, playListDetailsLoader: true, playListDetails: ''
            }
        }
        case 'POPULATE_PLAYLIST_DETAILS_SUCCESS': {
            return {
                ...state, playListDetails: payload, playListDetailsLoader: false
            }
        }
        case 'DELETE_PLAYLIST_SCHOOL_REQUEST': {
            return {
                ...state, populatePlaylistSchoolLoader: true
            }
        }
        case 'DELETE_PLAYLIST_SCHOOL_SUCCESS': {
            return {
                ...state, populatePlaylistSchoolLoader: false, playlistSchool: payload
            }
        }
        case 'DELETE_PLAYLIST_COLLEGE_REQUEST': {

            return {
                ...state, populatePlaylistCollegeLoader: true
            }
        }
        case 'DELETE_PLAYLIST_COLLEGE_SUCCESS': {
            return {
                ...state, populatePlaylistCollegeLoader: false, playlistCollege: payload
            }
        }
        case 'DELETE_PLAYLIST_LIFELONG_REQUEST': {
            return {
                ...state, populatePlaylistLifelongLoader: true
            }
        }
        case 'DELETE_PLAYLIST_LIFELONG_SUCCESS': {
            return {
                ...state, populatePlaylistLifelongLoader: false, playlistLifelong: payload
            }
        }
        case 'DELETE_PLAYLIST_GENARAL_REQUEST': {
            return {
                ...state, populatePlaylistGenaralLoader: true
            }
        }
        case 'DELETE_PLAYLIST_GENARAL_SUCCESS': {
            return {
                ...state, populatePlaylistGenaralLoader: false, playlistGenaral: payload
            }
        }
        case 'POPULATE_PLAYLIST_SCHOOL_REQUEST': {
            return {
                ...state, populatePlaylistSchoolLoader: true
            }
        }
        case 'POPULATE_PLAYLIST_SCHOOL_SUCCESS': {
            return {
                ...state, populatePlaylistSchoolLoader: false, playlistSchool: payload
            }
        }
        case 'POPULATE_PLAYLIST_COLLEGE_REQUEST': {
            return {
                ...state, populatePlaylistCollegeLoader: true
            }
        }
        case 'POPULATE_PLAYLIST_COLLEGE_SUCCESS': {
            return {
                ...state, populatePlaylistCollegeLoader: false, playlistCollege: payload
            }
        }
        case 'POPULATE_PLAYLIST_LIFELONG_REQUEST': {
            return {
                ...state, populatePlaylistLifelongLoader: true
            }
        }
        case 'POPULATE_PLAYLIST_LIFELONG_SUCCESS': {
            return {
                ...state, populatePlaylistLifelongLoader: false, playlistLifelong: payload
            }
        }
        case 'POPULATE_PLAYLIST_GENARAL_REQUEST': {
            return {
                ...state, populatePlaylistGenaralLoader: true
            }
        }
        case 'POPULATE_PLAYLIST_GENARAL_SUCCESS': {
            return {
                ...state, populatePlaylistGenaralLoader: false, playlistGenaral: payload
            }
        }
        case 'CREATE_PLAYLIST_SCHOOL_REQUEST': {
            return {
                ...state, createPlayListSchoolLoader: true
            }
        }
        case 'CREATE_PLAYLIST_SCHOOL_SUCCESS': {
            return {
                ...state, playlistSchool: [payload, ...state.playlistSchool], createPlayListSchoolLoader: false
            }
        }
        case 'CREATE_PLAYLIST_COLLEGE_REQUEST': {
            return {
                ...state, createPlayListCollegeLoader: true
            }
        }
        case 'CREATE_PLAYLIST_COLLEGE_SUCCESS': {
            return {
                ...state, createPlayListCollegeLoader: false, playlistCollege: [payload, ...state.playlistCollege]
            }
        }
        case 'CREATE_PLAYLIST_LIFELONG_REQUEST': {
            return {
                ...state, createPlayListLifeLongLoader: true
            }
        }
        case 'CREATE_PLAYLIST_LIFELONG_SUCCESS': {
            return {
                ...state, createPlayListLifeLongLoader: false, playlistLifelong: [payload, ...state.playlistLifelong]
            }
        }
        case 'CREATE_PLAYLIST_GENARAL_REQUEST': {
            return {
                ...state, createPlayLisGenaralLoader: true
            }
        }

        case 'CREATE_PLAYLIST_GENARAL_SUCCESS': {
            return {
                ...state, createPlayLisGenaralLoader: false, playlistGenaral: [payload, ...state.playlistGenaral]
            }
        }
        case 'RESET_PLAYLIST': {
            return {
                ...state, classPlaylist: []
            }
        }
        case 'RESET_PLAYLIST_DETAILS': {
            return {
                ...state, playListDetails: ''
            }
        }

        case 'RESET_PLAYLIST_VIDEOS': {
            return {
                ...state, playlistVideos: []
            }
        }
        case 'RESET_PLAYLIST_MAIN': {
            return {
                ...state, playlistSchool: [],
                playlistCollege: [],
                playlistLifelong: [],
                playlistGenaral: [],
            }
        }
        case 'RESET_ASSIGN_STUDENT_VIDEO': {
            return {
                ...state, specificStudent: []
            }
        }
        default:
            return state
    }

}

export default studentHomeReducer