import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CustomInput, Form, FormGroup, Label, FormFeedback, Input } from 'reactstrap'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PageTitle from '../HeaderTitle/PageTitle'
import './subject.scss'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getGradeList, populateDegree } from '../../redux/Actions/signUp'
import { createSubjectSchool, populateRegisteredTeachers, populateAllSchoolSubjects, updateSubjectSchhol, deleteSubjectSchool, populateAllEnterpriseSchoolSubjects } from '../../redux/Actions/homeTeacher'
import serialize from 'form-serialize'
import Table from '../common/DataTable'
import Swal from 'sweetalert2'
import { requiredValidator, nameValid } from '../../utilities/Validator';



const Index = () => {
    const dispatch = useDispatch()
    const Grades = useSelector(({ signUp }) => signUp)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    var columns
    if (tokenData.enterpriseUser) {

        columns = [
            //    { title: 'teacher', field: 'first_name', editable: 'never' },

            { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
            { title: 'Grade', field: 'grade_name', editable: 'never' },

        ]
    } else {
        columns = [

            { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
            { title: 'Grade', field: 'grade_name', editable: 'never' },

        ]

    }


    useEffect(() => {
        dispatch(getGradeList(null, 1))
        if (tokenData.enterpriseUser) {
            dispatch(populateAllEnterpriseSchoolSubjects(tokenData.token))

        } else {
            dispatch(populateAllSchoolSubjects(tokenData.token))

        }

        dispatch(populateRegisteredTeachers(tokenData.token))




        return () => {
            setInputField({})
            setError({})
        }
    }, [])

    const onEdit = (rowData) => {
        console.log("row-", rowData)
        if(rowData.subject_name){
            dispatch(updateSubjectSchhol(tokenData.token, rowData))
        }
        else{
            Swal.fire({
                icon: 'warning',
                title: 'Declined',
                text:'Subject name cannot be emty.'
            } 
            )
        }

    }

    const onDelete = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteSubjectSchool(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }
    const [inputField, setInputField] = useState({
        grade_id:'',subject_name:''
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
    }
    const [error, setError] = useState({})
    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        const err = {
            grade_id: requiredValidator(inputField.grade_id),
            subject_name: nameValid(inputField.subject_name)

        }
        if (!err.grade_id && !err.subject_name) {
            dispatch(createSubjectSchool(tokenData.token, body, reset))
            setError(err)
        } else {
            setError(err)

        }


    }
    const reset = () => {
        setInputField({grade_id:'',subject_name:''})
        setError({})
        document.getElementById("create-form-school").reset();
    }

    return (
        <>



            <Form onSubmit={formSubmit} id="create-form-school">


                <Card>
                    <Row className="jms-subject-row-pad">

                        {/* {tokenData.enterpriseUser ? <>

                        <Col sm={3}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                    {' '}Select Teacher
                                 </Label>

                                <Input type="select"
                                    // onChange={(e) => handleChangeGrade(e)}
                                    name='teacher_id'
                                    required
                                // value={this.state.loaderType}

                                >
                                    <option value={''}>{'select'}</option>
                                    {tStore.registeredTeachers ?
                                        tStore.registeredTeachers.map(registeredTeachers => {
                                            return (
                                                <option value={registeredTeachers.user_id}>{registeredTeachers.first_name+" "+registeredTeachers.last_name}</option>
                                                //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                            )
                                        }) : []
                                    }

                                </Input>
                            </Col>
                        </> :null
     
                        } */}





                        <Col md={4}>
                            <input type="hidden" value={1} name="category_id" />

                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">Grade</Label>
                                <Input type="select" name="grade_id"
                                    value={inputField.grade_id}
                                    onChange={handleChange}
                                    invalid={error.grade_id}
                                >
                                    <option value="">Select</option>

                                    {
                                        Grades.gradeSchool.grades ? Grades.gradeSchool.grades.map((data) => {
                                            return (
                                                <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                            )
                                        })
                                            : []
                                    }


                                </Input>
                                <FormFeedback >{error.grade_id}</FormFeedback>



                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">Subject</Label>
                                <Input
                                    invalid={error.subject_name}
                                    type="text"
                                    value={inputField.subject_name}
                                    name="subject_name"
                                    onChange={handleChange} />
                                <FormFeedback >{error.subject_name}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Subject</Label>

                                <LaddaButton className="mb-2 mr-2 btn btn-danger"
                                    loading={tStore.createSubLoaderSchool}
                                    // onClick={formSubmit}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                    style={{ whiteSpace: 'nowrap', marginRight: '0px', width: '-webkit-fill-available' }}

                                >
                                    Add Subject
                                            </LaddaButton>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            </Form>
            <hr />
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        title={'Subjects'}
                        columns={columns}
                        actions={"add_subject"}
                        data={tStore.subjectListSchool}
                        loader={tStore.schoolSubLoader}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </Col>

            </Row>



        </>

    )
}

export default Index
