import React, { Fragment, useState, useEffect } from 'react'
// import { addStudentToClassroom } from '../../../redux/Actions/homeTeacher'
import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import {
    FormFeedback,
    Row, Col,
    Card,
    CardTitle, Button, ButtonGroup, Container,
    FormGroup, Label, Form, CustomInput,
    Input,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const Teacher = (props) => {

    const { errors, handleChange } = props;
    return (
        <Fragment>
            <Card className="main-card " style={{ padding: '30px', background: '#eff1f5' }}>
                <CardTitle>Therapist Form</CardTitle>
                <Row>
                    <Col md="12" xs="12">
                        <input type="hidden" name="user_id" value="" />
                        <FormGroup className="p-10">
                            <Label for="exampleEmail">First Name</Label>
                            <Input type="text" name="first_name" id="firstname"
                                placeholder="First name"
                                // required
                                onChange={(e) => handleChange(e)}
                                invalid={errors.first_name}
                            />
                            <FormFeedback >{errors.first_name}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="examplePassword">Last Name</Label>
                            <Input type="text" name="last_name" id="lastname"
                                placeholder="Last Name"
                                // required
                                invalid={errors.last_name}
                                onChange={(e) => handleChange(e)}

                            />
                            <FormFeedback >{errors.last_name}</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="examplePassword">Phone</Label>
                            <Input type="text" name="enterprise_mobile" id="phone"
                                placeholder="phone no. with country code"
                                // required
                                onChange={(e) => handleChange(e)}

                                invalid={errors.enterprise_mobile}
                            />
                            <FormFeedback >{errors.enterprise_mobile}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="exampleEmail">Email</Label>
                            <Input type="text" name="enterprise_email" id="email"
                                placeholder="Email"
                                // required
                                invalid={errors.enterprise_email}
                                onChange={(e) => handleChange(e)}


                            />
                            <FormFeedback >{errors.enterprise_email}</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="exampleEmail">Password</Label>
                            <Input type="password" name="password" id="password"
                                placeholder="Password"
                                // required
                                invalid={errors.password}

                                onChange={(e) => handleChange(e)}

                            />
                            <FormFeedback >{errors.password}</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>
            </Card>
        </Fragment>

    )
}

export default Teacher;
