import React, { Fragment,useState, useEffect } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import serialize from 'form-serialize'
import {
    TabContent, TabPane, Nav, NavItem, NavLink,
    Row, Col,
    Card, CardBody, CardHeader, CardFooter,
    CardTitle, Button, ButtonGroup, Container,
    FormGroup, Label, Form, CustomInput,
    Input,
} from 'reactstrap';
import { getTeacherData, setEditData } from '../../../redux/Actions/videolist'

const EditStaff = (props) => {
    const dispatch = useDispatch();
    // const history = useHistory();
    const tStore = useSelector(({ videolist }) => videolist)
    const teacherDetail = tStore.teacherDetail;
    console.log("tssss",tStore);
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const param = useParams()
    useEffect((id) => {       
        dispatch(getTeacherData(tokenData.token,id))
    }, []);

    const editData = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        dispatch(setEditData(tokenData.token, body))
    }

    return (
        <Fragment>
            <Form onSubmit={editData} id="form-user">  
                <Card className="main-card m-20">
                    <CardTitle>Teacher Form</CardTitle>
                    <Row>
                        <Col md="6" xs="6">
                            <input type="hidden" name="user_id" value=""/>
                            <FormGroup className="p-10">
                                <Label for="exampleEmail">First Name</Label>
                                <Input type="text" name="first_name" id="firstname"
                                    placeholder="First name" 
                                    // onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="6">
                            <FormGroup className="p-10">
                                <Label for="examplePassword">Last Name</Label>
                                <Input type="text" name="last_name" id="lastname"
                                    placeholder="Last Name" 
                                    // onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" xs="6">
                            <FormGroup className="p-10">
                                <Label for="examplePassword">Phone</Label>
                                <Input type="text" name="enterprise_mobile" id="phone"
                                    placeholder="phone" 
                                    // onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="6">
                            <FormGroup className="p-10">
                                <Label for="exampleEmail">Email</Label>
                                <Input type="email" name="enterprise_email" id="email"
                                    placeholder="Email" 
                                    //onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
                <CardFooter className="d-block text-right">
                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        // loading={tStore.ccLoader}
                        type="submit"
                        data-style={ZOOM_IN}  >
                    Submit
                    </LaddaButton>
                </CardFooter>
            </Form>
        </Fragment>
        
    )
}

export default EditStaff;
