import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { editQuestion, updateQuestion } from "../../../redux/Actions/exam";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../HeaderTitle/PageTitle";
import serialize from "form-serialize";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import {
  Col,
  Row,
  Card,
  Button,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import { requiredValidator } from "../../../utilities/Validator";

function QuestionEdit() {
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const dispatch = useDispatch();

  const { question_id } = useParams();
  const { question_type } = useParams();
  const { question_paper_id } = useParams();
  const qStore = useSelector(({ exam }) => exam);
  console.log("strr", qStore.questionDetails);
  const history = useHistory();
  const [errors, setErrors] = useState({});

  let questionData = qStore?.questionDetails;
  const optionCount = qStore?.questionDetails?.objective_options.length;

  const handleChange = (e) => {
    const { name, value } = e.target;
    // alert(value)
    setinputField({ ...inputField, [name]: value });
  };

  const [inputField, setinputField] = useState({
    question: " ",
    option_A: " ",
    option_B: " ",
    option_C: " ",
    option_D: " ",
    option_E: " ",
    option_F: " ",
    correct_answer: "",
  });

  useEffect(() => {
    dispatch(
      editQuestion(
        tokenData.token,
        question_id,
        question_paper_id,
        question_type,
        cb
      )
    );
  }, []);

  const cb = (data) => {
    setinputField({
      ...inputField,
      question: data.question,
    });
  };

  const reset = () => {
    setinputField({
      question: " ",
      option_A: " ",
      option_B: " ",
      option_C: " ",
      option_D: " ",
      option_E: " ",
      option_F: " ",
      correct_answer: "",
    });
    history.push(`/dashboard/exam/showQuestions/${question_paper_id}`);
  };

  const handleOptionChange = (e) => {
    setinputField({
      option_a: e.currentTarget.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log("qdata", questionData);

    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });

    //VALIDATIONS
    let error;
    let isInvalid;

    if (questionData.type === "subjective") {
      error = {
        question: requiredValidator(inputField.question),
      };
      isInvalid = error.question;
    } else if (optionCount === 4) {
      error = {
        question: requiredValidator(inputField.question),
        option_A: requiredValidator(inputField.option_A),
        option_B: requiredValidator(inputField.option_B),
        option_C: requiredValidator(inputField.option_C),
        option_D: requiredValidator(inputField.option_D),
        correct_answer: requiredValidator(inputField.correct_answer),
      };
      setErrors(error);
      isInvalid =
        error.question ||
        error.correct_answer ||
        error.option_A ||
        error.option_B ||
        error.option_C ||
        error.option_D;
    } else {
      error = {
        question: requiredValidator(inputField.question),
        option_A: requiredValidator(inputField.option_A),
        option_B: requiredValidator(inputField.option_B),
        option_C: requiredValidator(inputField.option_C),
        option_D: requiredValidator(inputField.option_D),
        option_E: requiredValidator(inputField.option_E),
        option_F: requiredValidator(inputField.option_F),
        correct_answer: requiredValidator(inputField.correct_answer),
      };
      setErrors(error);
      isInvalid =
        error.question ||
        error.correct_answer ||
        error.option_A ||
        error.option_B ||
        error.option_C ||
        error.option_D ||
        error.option_E ||
        error.option_F;
    }

    if (isInvalid) {
      Swal.fire({
        icon: "warning",
        title: "Required",
        text: "All fields are required",
        confirmButtonText: "Okay",
      });
    } else {
      dispatch(
        updateQuestion(
          tokenData.token,
          body,
          question_id,
          question_type,
          question_paper_id,
          reset
        )
      );
    }
    console.log("ser", body);
  };
  return (
    <>
      <PageTitle
        heading="Edit Questions"
        subheading="Edit questions of the question paper"
        icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
      />
      <Card style={{ marginTop: 20 }}>
        <Form onSubmit={submitForm} id="class-form">
          <Card style={{ margin: "32px 29px 10px 29px" }}>
            <Row style={{ padding: "40px" }}>
              <Col md={12}>
                <p style={{ color: "#495259", fontWeight: 600 }}>
                  {" "}
                  Question Details
                </p>
                <hr />
              </Col>
              <Col md={12} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Question
                </Label>
                <Input
                  type="textarea"
                  onChange={handleChange}
                  name="question"
                  invalid={errors.question}
                  value={inputField.question}
                />
                <hr />
              </Col>
              {qStore.questionDetails?.type == "objective" &&
                qStore.questionDetails.objective_options.map(
                  (option, index) => {
                    console.log("check", option?.option_key);
                    return (
                      <Col md={6} className="jms-p5">
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span> Option{" "}
                          {option.option_key}
                        </Label>
                        <Input
                          onChange={handleChange}
                          value={
                            `${inputField[`option_${option.option_key}`]}` !==
                            " "
                              ? `${inputField[`option_${option.option_key}`]}`
                              : option.option_value
                          }
                          // value={inputField.option_A}
                          invalid={errors[`option_${option.option_key}`]}
                          type="text"
                          name={`option_${option.option_key}`}
                          // value={
                          //   inputField[`${option.option_key}`]
                          //     ? inputField[`${option.option_key}`]
                          //     : option.option_value
                          // }
                          // value={
                          //   inputField.option_A
                          //     ? inputField.option_A
                          //     : option.option_value
                          // }
                          //value={inputField.option_a}
                        ></Input>
                      </Col>
                    );
                  }
                )}

              {qStore.questionDetails?.type == "objective" && (
                <Col md={6} className="jms-p5">
                  <Label for="exampleNameF">
                    <span className="text-danger">*</span> Correct Answer{" "}
                    <Input
                      name="correct_answer"
                      type="select"
                      onChange={handleChange}
                      value={inputField.correct_answer}
                      invalid={errors.correct_answer}
                    >
                      <option value={""}>select</option>
                      {qStore.questionDetails.objective_options.map(
                        (option, index) => {
                          return (
                            <option value={option.option_key}>
                              {option.option_key}
                            </option>
                          );
                        }
                      )}
                    </Input>
                  </Label>
                </Col>
              )}
              <Col md={12} className="jms-p5" style={{ textAlign: "end" }}>
                <LaddaButton
                  className="mb-2 mr-2 btn btn-primary"
                  loading={qStore?.editQuestionLoader}
                  type="submit"
                  data-style={ZOOM_IN}
                >
                  Edit question
                </LaddaButton>
              </Col>
            </Row>
          </Card>
        </Form>
      </Card>
    </>
  );
}

export default QuestionEdit;
