

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Input } from 'reactstrap'
import ChooseFromPlaylist from '../../common/chooseFromPlaylist'
import { populatePlayListInClass, getPlayListVideos } from '../../../../redux/Actions/playlist'
import '../playlist.scss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Loader from 'react-loaders'

const ChooseVideos = (props) => {
    const dispatch = useDispatch()
    const { subject_id, errors, resetSelectedVideos, handleDate, multiSelectVideo, playlistsVideoId, setMultiSelect2, mutliSelect, handleClickSync, syncVideo } = props;

    const playlistStore = useSelector(({ playlist }) => playlist)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"))
    useEffect(() => {
        //  dispatch({ type: 'RESET_PLAYLIST_DETAILS' })

        dispatch(populatePlayListInClass(tokenData.token, subject_id))
        return () => {
            dispatch({ type: 'RESET_PLAYLIST' })
            dispatch({ type: 'RESET_PLAYLIST_VIDEOS' })
            resetSelectedVideos()

        }

    }, [])
    //  let playlistVideos1 = playlistStore.playlistVideos
    const [playlistVideos, setPlylisVideos] = useState([])
    console.log({ playlistVideos });

    const [selectedPlaylist, setSelectedPlaylist] = useState()
    const getVideos = (playlist_id) => {
        setMultiSelect2([])
        setSelectedPlaylist(playlist_id)
        dispatch(getPlayListVideos(tokenData.token, playlist_id, setPlaylistVideoForValidation))

        // if (selectedPlaylist.includes(playlist_id)) {
        //     const filtered = selectedPlaylist.filter(playlistId => playlistId != playlist_id)
        //     setSelectedPlaylist(filtered)
        // } else {
        //     setSelectedPlaylist([...selectedPlaylist, playlist_id])
        //     dispatch(getPlayListVideos(tokenData.token, playlist_id))
        // }
        // debugger;


    }
    const handleChange = (e, id) => {
        //  alert(id)
        const { value, name } = e.target
        //alert(value)
        dispatch({ type: 'CHANGE_TITLE', payload: { position: id, title: value } })
        // const copyData = [...playlistVideos]
        // copyData[id].title = value
        // console.log({ copyData });

        // setPlylisVideos(copyData)

    }
    const setPlaylistVideoForValidation = (videos) => {
        setPlylisVideos(videos)
    }

    const arrLength = playlistStore.playlistVideos.length;
    console.log("arrLength", arrLength);

    // const [elRefs, setElRefs] = React.useState([]);

    return (
        <Row >
            {console.log({ playlistVideos })
            }
            {
                playlistStore.classPlaylist.length > 0 ?
                    <>
                        <Col md={3} className="jms-choose-from-col-title">
                            {
                                playlistStore.classPlaylist.map(playlist => {
                                    return (<>
                                        <div

                                            className={`jms-choose-from-title ${selectedPlaylist == playlist.playlist_id ? 'jms-active-playlist' : null}`}

                                            // className={`jms-choose-from-title ${selectedPlaylist.includes(playlist.playlist_id) ? 'jms-active-playlist' : null}`} 
                                            onClick={() => getVideos(playlist.playlist_id)}>
                                            {playlist.playlist_name}
                                        </div>
                                    </>
                                    )
                                })
                            }




                        </Col>
                        <Input required type="hidden" name="isType" value={"CHOOSE"} />

                        <Col md={9} className="jms-choose-from-col-videos">
                            <Row>
                                {
                                    playlistStore.playlistVideos.length > 0 ?
                                        playlistStore.playlistVideos.map((video, index) => {
                                            return (
                                                <ChooseFromPlaylist
                                                    thumbnile={video.thumbnails[2].link}
                                                    type={"upload_video"}
                                                    videoId={video.video_id}
                                                    title={video.title}
                                                    url={video.url}
                                                    duration={video.video_duration}
                                                    format={'mp4'}
                                                    multiSelectVideo={multiSelectVideo}
                                                    mutliSelect={mutliSelect}
                                                    playlist_video_id={video.playlist_video_id}
                                                    handleClickSync={(data) => handleClickSync(data)}
                                                    syncVideo={syncVideo}
                                                    id={index}
                                                    playlistsVideoId={playlistsVideoId}
                                                    handleDate={handleDate}
                                                    handleChange={handleChange}
                                                />

                                            )
                                        })



                                        : playlistStore.playlistVideosLoader ? <p className="jms-position"> <Loader color="#f7b924" type="ball-rotate" /> </p>
                                            : !selectedPlaylist ? <p className="jms-position">  Select a playlist</p>
                                                : <p className="jms-position">  <i style={{ fontSize: 30 }} className="lnr-warning icon-gradient bg-sunny-morning"> </i> There is no videos</p>

                                }
                            </Row>



                        </Col>
                    </>
                    : playlistStore.classPlaylistLoader ?
                        <>
                            <Col md={3}>
                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                    <Skeleton height={50} count={4} />
                                </SkeletonTheme>
                            </Col>
                            <Col md={9}>
                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                    <Skeleton height={100} count={2} />
                                </SkeletonTheme>
                            </Col>


                        </>
                        :
                        <p >  <i style={{ fontSize: 30, paddingLeft: 30 }} className="lnr-warning icon-gradient bg-sunny-morning"> </i> There is no playlists</p>
            }



        </Row >
    )
}

export default ChooseVideos
