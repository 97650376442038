import React, { useState, } from 'react'
// import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Progress } from 'react-sweet-progress';

import PdfIcon from '../../../assets/images/pdficon.png'
import DocxIcon from '../../../assets/images/docxicon.png'
import SheetIcon from '../../../assets/images/sheeticon.png'
import blank_sheet from '../../../assets/images/blank_sheet.png'
// import AudioIcon from '../../../assets/images/audioicon.png'
import image_processing from '../../../assets/images/image_processing.jpg'
import {
    faFileAudio
} from '@fortawesome/free-solid-svg-icons';
import "../chat.css"
import ReactAudioPlayer from 'react-audio-player';

function Index({ val,Playing,EndPauseHandle }) {

    const handleDownload = (url) => {

        var link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
        link.remove();
    }


    const [OverLay, setOverLay] = useState({ isOpen: false, data: null, type: null })

    const closeOverlay = () => {
        EndPauseHandle()
        setOverLay({ isOpen: false, data: null, type: null })
        //dispatch(setEnableClosedSidebar(false))
    }

    const openOverlay = (type, data) => {
        Playing()
        setOverLay({ isOpen: true, data: data, type: type })
        // dispatch(setEnableClosedSidebar(true))
    }
  
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', background: 'rgb(248, 251, 255)', borderTopLeftRadius: '30px', }} className="chat-box">

                {
                    val.type === 'image' ?
                        <div style={{ width: '170px', height: '170px', display: 'flex', flexDirection: 'column' }} >
                            <img src={val.file_url} alt='' onClick={() => openOverlay('image', val)} width='160px' height='170px' />

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ fontSize: '12px', paddingTop: '3px' }} > {val.file_size} </div>

                                <div onClick={() => handleDownload(val.file_url)} style={{ cursor: 'pointer', borderRadius: '3px', verticalAlign: 'middle' }} >
                                    {/* <FontAwesomeIcon icon={faDownload} className="mr-2" /> */}
                                    <ArrowDownwardIcon fontSize='small' style={{ marginRight: '5px' }} />
                                </div>

                            </div>
                        </div>
                        :
                        val.type === 'document' ?
                            <div style={{ minWidth: '170px', display: 'flex', alignItems: 'center', height: '50px', justifyContent: 'space-between' }}>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {
                                        val.extension === 'pdf' ?
                                            <div>
                                                <img src={PdfIcon} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                            </div>
                                            :
                                            val.extension === ('xls' || 'sheet' || 'xls' || 'csv' || 'tsv') ?
                                                <div>
                                                    <img src={SheetIcon} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                                </div>
                                                :
                                                val.extension === ('docx' || 'doc' || 'docm' || 'dot') ?
                                                    <div>
                                                        <img src={DocxIcon} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <img src={blank_sheet} alt='' width='40px' height='40px' ></img> <span style={{ fontSize: '13px' }}>{val.file_name?.length < 35 ? val.file_name : val.file_name?.slice(0, 35) + '... '}</span>
                                                    </div>


                                    }
                                    <span className='ml-2' style={{ fontSize: '10px' }}>{val.file_size} . {val.extension}</span>
                                </div>
                                <div onClick={() => handleDownload(val.file_url)} style={{ cursor: 'pointer', marginLeft: '6px' }}>
                                    <ArrowDownwardIcon fontSize='small' />
                                </div>
                            </div>
                            :
                            val.type === 'audio' ?
                                <>
                                    <div style={{ minWidth: '170px', display: 'flex', alignItems: 'center', height: '50px', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', wordWrap: 'break-word' }}>
                                            <FontAwesomeIcon color='#4f4f76' size='2x' icon={faFileAudio} />
                                            <span style={{ fontSize: '13px', marginLeft: '4px' }}> <span style={{ marginLeft: '4px' }} > {val.file_name.length < 35 ? val.file_name : val.file_name.slice(0, 35) + '... '}  </span> </span>
                                        </div>
                                        <div onClick={() => handleDownload(val.file_url)} style={{ cursor: 'pointer', marginLeft: '6px' }}>
                                            <ArrowDownwardIcon fontSize='small' />
                                        </div>

                                    </div>
                                    <span className='ml-2' style={{ fontSize: '10px' }}>{val.file_size} . {val.extension}</span>
                                </>
                                :
                                val.type === 'video' ?
                                    <div style={{ width: '200px', height: '113px', position: 'relative' }}>
                                        {
                                            val.video_processed === 1 ?
                                                <img
                                                    src={val.video_thumbnail ? val.video_thumbnail[3]?.link_with_play_button : ''}
                                                    alt=''
                                                    style={{ borderRadius: '10px' }}
                                                    width='100%' height='100%'
                                                    onClick={() => openOverlay('video', val)}
                                                />

                                                :
                                                <img alt='processing' src={image_processing} width='100%' height='100%' />

                                        }
                                        {
                                            val.video_processed === 1 ?
                                                <div className='video_size_div pl-2'>
                                                    <span >
                                                        {/* <ArrowDownwardIcon onClick={(val) => VideoDownload(val)} style={{ fontSize: '12px' }} /> */}
                                                        {val.file_size}
                                                    </span>
                                                </div>
                                                :
                                                <div className='video_processing_div'>
                                                    <span>Processing</span>
                                                </div>
                                        }
                                    </div>
                                    :
                                    val.type === 'recording' ?
                                        <>
                                            <div style={{ minWidth: '300px', display: 'flex', height: '50px', flexDirection: 'column' }}>
                                                <span style={{ marginLeft: '4px', fontSize: '11px' }} > {val.file_name}  </span>
                                                {/* <audio id="record_audio" style={{ height: '30px', width: '100%', marginTop: '5px' }} src={val.file_url} controls="controls" /> */}
                                                <ReactAudioPlayer
                                                    src={val.file_url}
                                                    autoPlay={false}
                                                    controls
                                                    onPlay={Playing}
                                                    onEnded={EndPauseHandle}
                                                    onPause={EndPauseHandle}
                                                    className='audio_player'
                                                />
                                            </div>
                                            <span className='ml-2' style={{ fontSize: '10px' }}>{val.file_size} . {val.extension}</span>
                                        </>
                                        :
                                        ''
                }
            </div>
            <small className="opacity-6">
                {val.created_time}
            </small>


            {OverLay.isOpen &&

                <div
                    onClick={closeOverlay}
                    style={{
                        position: "fixed",
                        // opacity:'0.7',
                        backgroundColor: 'rgba(0,0,0,0.9)',
                        display: 'flex',
                        justifyContent: 'center',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        zIndex: '98'
                    }} >
                    <div class='my-auto' style={{ margin: '10px', display: 'flex' }} >
                        <div >{OverLay.type === 'video' ?
                            ReactHtmlParser(OverLay.data?.video_embed)
                            : <img style={{ maxWidth: '600px', maxHeight: '300px' }} alt='failed to load img' src={OverLay.data?.file_url} ></img>
                        }
                        </div>
                        <div style={{ color: 'white', marginLeft: '30px' }} >
                            <div style={{ textAlign: 'right', width: '100%', fontSize: '30pt', marginBottom: '80px' }} > <i onClick={closeOverlay} style={{ cursor: 'pointer' }} className='pe-7s-close' ></i> </div>
                        </div>
                    </div>
                </div>

            }

        </>
    )
}

export default Index
