import React, { useState, useEffect } from 'react';
import { Row, Col, } from 'reactstrap'
import Table from './table';
import { useDispatch, useSelector } from 'react-redux'
import { populateAllVideoClass, populateVideoClassStudents, changeStatusOfVideoClass, } from '../../../redux/Actions/playlist'
import { getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'

import { useHistory, useLocation } from "react-router-dom";
// import Modals from '../../common/Modal'
// import AssignStudents from './assignStudents'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, Chip, Checkbox, TextField, InputAdornment } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import SearchIcon from '@material-ui/icons/Search';
import { getAllUsersInVideoClass, setNewActionData, addUsersToVideoClassRoom, addGroupToStudyClassRoom } from "../../../redux/Actions/new-action"
const columns = [
    { title: '#', field: 'tableData.id' },
    {
        title: 'Therapist ', field: 'teacher_name',
    },
    {
        title: 'Session ', field: 'video_class_name',
    },
    { title: ' Clinic', field: 'grade_name' },

    // {

    //     render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Button color="danger" pill>{props.name}</Button></>
    // }

]

const columnsteacher = [
    { title: '#', field: 'tableData.id' },
    {
        title: 'Session ', field: 'video_class_name',
    },
    { title: 'Clinic', field: 'grade_name' },
    // { title: 'Subject', field: 'subject_name' },
    // {

    //     render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Button color="danger" pill>{props.name}</Button></>
    // }

]



const VideoClass = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const videoStore = useSelector(({ playlist }) => playlist)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const [openAssignModal, setAssignModal] = useState(false)
    const [classID, setClassID] = useState()
    const [open, setOpen] = useState(false)

    const [student_selected, setStudentSelected] = useState([])
    const [student_selected_copy, setStudentSelectedCopy] = useState([])
    const [student_unselected, setStudentUnSelected] = useState([])
    const [selected_all, setSelectedAll] = useState([])

    const [teacher_selected, setTeacherSelected] = useState([])
    const [teacher_selected_copy, setTeacherSelectedCopy] = useState([])
    const [teacher_unselected, setTeacherUnSelected] = useState([])

    const loader = useSelector(state => state.new_action.userlist_loader ? state.new_action.userlist_loader : false)
    const clientList = useSelector(state => state.new_action.fullclientList ? state.new_action.fullclientList : [])
    const clientListCopy = useSelector(state => state.new_action.fullclientListCopy ? state.new_action.fullclientListCopy : [])
    const userlist_add_loader = useSelector(state => state.new_action.userlist_add_loader ? state.new_action.userlist_add_loader : false)

    const [group_selected, setGroupSelected] = useState([])
    const [group_selected_copy, setGroupSelectedCopy] = useState([])
    const [group_unselected, setGroupUnSelected] = useState([])
    const [group_loader, setGroupLoader] = useState(false)
    const [group_all, setGroupAll] = useState([])

    useEffect(() => {

        if (location.pathname === '/dashboard/videoClass' && tokenData.role === 'teacher') {
            dispatch(populateAllVideoClass(tokenData.token, true))
        } else {
            dispatch(populateAllVideoClass(tokenData.token, false))
        }

    }, [])

    const onVideopress = (classId) => {
        history.push(`/dashboard/videolist/${classId}`)
    }


    const hadleOpenStudentModal = (classId, gradeId) => {
        if (tokenData.enterpriseUser) {
            // dispatch(getTeacherAssignedGroups(tokenData.token, classId, "", "video"))
            dispatch(getAllUsersInVideoClass(tokenData.token, classId, Cb_videousers))
        } else {
            dispatch(populateVideoClassStudents(tokenData.token, classId, gradeId))
        }
        setClassID(classId)
        // setAssignModal(true) 
        setOpen(true)
    }

    const OpenGroupModal = (id) => {
        setClassID(id)
        dispatch(getTeacherAssignedGroups(tokenData.token, id, "", "video", Cb_group))
        setGroupLoader(true)
        setAssignModal(true)
    }

    const Cb_group = (val) => {
        let arr = [...val]
        let selected = []
        for (let i of arr) {
            if (i.selected) {
                selected.push(i.group_id)
            }
        }
        setGroupSelected(selected)
        setGroupSelectedCopy(selected)
        setGroupAll(val)
        setGroupLoader(false)

    }

    const Cb_videousers = (data) => {
        let arr = [...data]
        let selected = arr.filter(val => val.is_selected)

        let student = []
        let teacher = []
        for (let i of selected) {
            if (i.role_name === 'student') {
                student.push(i.user_id)
            } else {
                teacher.push(i.user_id)
            }
        }
        setStudentSelected(student)
        setStudentSelectedCopy(student)

        setTeacherSelected(teacher)
        setTeacherSelectedCopy(teacher)
        setSelectedAll(selected)
    }

    const hadleCloseStudentModal = () => {
        // dispatch({ type: "RESET_ASSIGN_STUDENT_CLASS" }); //Resetting specific student and group
        dispatch({ type: "RESET_GROUP_LIST" });
        setClassID('')
        setGroupUnSelected([])
        setGroupSelectedCopy([])
        setGroupSelected([])
        setGroupAll([])
        setAssignModal(false)

    }
    const editClass = (class_id, subject_id) => {
        history.push(`/dashboard/videoClass/edit/${class_id}/${subject_id}`)

    }

    const changeStatus = (class_id, status, teacher_id) => {
        let activeClass = status
        if (activeClass === 1) {
            activeClass = 0
        } else {
            activeClass = 1
        }


        dispatch(changeStatusOfVideoClass(tokenData.token, class_id, activeClass, teacher_id, cb))
    }
    const cb = () => {
        dispatch(populateAllVideoClass(tokenData.token))

    }

    const CloseAddUserModel = () => {
        setSelectedAll([])
        setStudentSelectedCopy([])
        setStudentUnSelected([])
        setStudentSelected([])
        setTeacherSelected([])
        setTeacherSelectedCopy([])
        setTeacherUnSelected([])
        dispatch(setNewActionData('fullclientList', []))
        dispatch(setNewActionData('fullclientListCopy', []))
        setClassID('')
        setOpen(false)
    }

    const HandleUser = (val, e) => {
        if (val.role_name === 'student') {
            if (e.target.checked) {
                let assigned = [...student_selected, val.user_id]
                setSelectedAll([...selected_all, val])
                setStudentSelected(assigned)
                if (student_unselected.includes(val.user_id)) {
                    let filtered = student_unselected.filter(i => i !== val.user_id)
                    setStudentUnSelected(filtered)
                }
            } else {
                let filter = student_selected.filter(i => i !== val.user_id)
                setStudentSelected(filter)

                let filter_all = selected_all.filter(i => i.user_id !== val.user_id)
                setSelectedAll(filter_all)

                if (student_selected_copy.includes(val.user_id)) {
                    setStudentUnSelected([...student_unselected, val.user_id])
                }
            }
        } else {
            if (e.target.checked) {
                let assigned = [...teacher_selected, val.user_id]
                setSelectedAll([...selected_all, val])
                setTeacherSelected(assigned)
                if (teacher_unselected.includes(val.user_id)) {
                    let filtered = student_unselected.filter(i => i !== val.user_id)
                    setTeacherUnSelected(filtered)
                }
            } else {
                let filter = teacher_selected.filter(i => i !== val.user_id)
                setTeacherSelected(filter)

                let filter_all = selected_all.filter(i => i.user_id !== val.user_id)
                setSelectedAll(filter_all)

                if (teacher_selected_copy.includes(val.user_id)) {
                    setTeacherUnSelected([...student_unselected, val.user_id])
                }
            }
        }

    }

    const handleRemove = (val) => {
        let filter_all = selected_all.filter(i => i.user_id !== val.user_id)
        setSelectedAll(filter_all)

        if (val.role_name === 'student') {
            let filter = student_selected.filter(i => i !== val.user_id)
            setStudentSelected(filter)

            if (student_selected_copy.includes(val.user_id)) {
                setStudentUnSelected([...student_unselected, val.user_id])
            }
        } else {
            let filter = teacher_selected.filter(i => i !== val.user_id)
            setTeacherSelected(filter)

            if (teacher_selected_copy.includes(val.user_id)) {
                setTeacherUnSelected([...teacher_unselected, val.user_id])
            }
        }

    }

    const SearchUsers = (e) => {
        if (e.target.value) {
            let data = [...clientListCopy]
            let filter = data.filter(i => i.full_name.toUpperCase().includes(e.target.value.toUpperCase()))
            dispatch(setNewActionData('fullclientList', filter))
        } else {
            dispatch(setNewActionData('fullclientList', clientListCopy))
        }
    }

    const AddUserToclassFn = () => {
        let arr = [...student_selected]
        arr = arr.filter(val => !student_selected_copy.includes(val));
        let arr_teacher = [...teacher_selected]
        arr_teacher = arr_teacher.filter(val => !teacher_selected_copy.includes(val));
        dispatch(addUsersToVideoClassRoom(tokenData.token, classID, arr, student_unselected, CloseAddUserModel, arr_teacher, teacher_unselected))
    }

    const HandleGroupManage = (id, e) => {
        if (e.target.checked) {
            let arr = [...group_selected, id]
            setGroupSelected(arr)

            if (group_unselected.includes(id)) {
                let arr = [...group_unselected]
                let filter = arr.filter(i => i !== id)
                setGroupUnSelected(filter)
            }
        } else {
            let arr = [...group_selected]
            let filter = arr.filter(i => i !== id)
            setGroupSelected(filter)

            if (group_selected_copy.includes(id)) {
                setGroupUnSelected([...group_unselected, id])
            }
        }
    }

    const AssignGroupToClassFn = () => {
        let arr = [...group_selected]
        arr = arr.filter(val => !group_selected_copy.includes(val));
        dispatch(addGroupToStudyClassRoom(tokenData.token, classID, arr, group_unselected, hadleCloseStudentModal))
    }

    const SearchGroup = (e) => {
        if (e.target.value) {
            let data = [...tStore.specificGroup]
            let filter = data.filter(i => i.group_name.toUpperCase().includes(e.target.value.toUpperCase()))
            setGroupAll(filter)
        } else {
            setGroupAll(tStore.specificGroup)
        }
    }

    return (
        <>
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    {
                        tokenData.role === 'teacher' && location.pathname === '/dashboard/videoClass' ?
                            <Table
                                role={tokenData.role}
                                title={'All Video Sessions'}
                                columns={columnsteacher}
                                actions={"video_class_control_teacher"}
                                editClass={editClass}
                                data={videoStore.allVideoClasses}
                                loader={videoStore.allVideoClassesLoader}
                                onVideopress={onVideopress}
                            />
                            :
                            tokenData.role === 'teacher' && location.pathname !== '/dashboard/videoClass' ?
                                <Table
                                    role={tokenData.role}
                                    title={'My Video Sessions'}
                                    columns={columnsteacher}
                                    actions={"video_class"}
                                    editClass={editClass}
                                    data={videoStore.allVideoClasses}
                                    loader={videoStore.allVideoClassesLoader}
                                    onVideopress={onVideopress}
                                    hadleOpenStudentModal={hadleOpenStudentModal}
                                    changeStatus={changeStatus}
                                    OpenGroupModal={OpenGroupModal}
                                />
                                :
                                <Table
                                    role={tokenData.role}
                                    title={'Video Sessions'}
                                    columns={columns}
                                    actions={"video_class"}
                                    editClass={editClass}
                                    data={videoStore.allVideoClasses}
                                    loader={videoStore.allVideoClassesLoader}
                                    onVideopress={onVideopress}
                                    hadleOpenStudentModal={hadleOpenStudentModal}
                                    changeStatus={changeStatus}
                                    OpenGroupModal={OpenGroupModal}

                                />

                    }

                </Col>

            </Row>
            {/* <Modals
                open={openAssignModal}
                handleClose={hadleCloseStudentModal}
                title={`Assign Groups`}
            >
                <AssignStudents
                    loader={tokenData.enterpriseUser
                        ? tStore.specificGroupLoader
                        : videoStore.specificStudentLoader}
                    classId={classID}
                    data={tokenData.enterpriseUser ? tStore.specificGroup : videoStore.specificStudent}
                    handleCloseStudentModal={hadleCloseStudentModal}
                    timeData={"timeData"}
                />
            </Modals> */}

            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={openAssignModal}
                onClose={hadleCloseStudentModal}
            >
                <DialogTitle style={{ textAlign: 'center', padding: '10px 20px' }}>
                    Assign Group
                </DialogTitle>
                <Divider />

                <DialogContent>
                    <Row>
                        <Col xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <span style={{ fontSize: '15px', fontWeight: '700', marginLeft: '35px' }}>Group Name</span>
                        </Col>
                        <Col xs={6}>
                            <TextField
                                size='small'
                                label='Search'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={SearchGroup}
                            />
                        </Col>

                        <Col xs={12}>
                            <div className='wrap-chat-main' style={{ height: '250px', overflowY: 'auto', width: '100%', wordWrap: 'break-word', marginTop: '10px' }}>
                                {
                                    group_loader ? <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>Loading...</div> : ''

                                }
                                {
                                    group_all.length > 0 ? group_all.map((val, ky) => {
                                        return (
                                            <div key={ky} style={{ padding: '0 1.5rem' }}>
                                                <Checkbox
                                                    checked={group_selected.includes(val.group_id)}
                                                    color='primary'
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={(e) => { HandleGroupManage(val.group_id, e) }}
                                                    size='small'
                                                />
                                                <span style={{ fontWeight: '500', color: 'black' }}>{val.group_name}</span>
                                            </div>
                                        )
                                    })
                                        :
                                        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                                            {!group_loader && "No groups to list"}
                                        </div>
                                }
                            </div>
                        </Col>

                        <Col xs={12} style={{ textAlign: 'right' }}>
                            <LaddaButton
                                data-style={ZOOM_IN}
                                className='btn btn-primary mr-1'
                                onClick={AssignGroupToClassFn}
                                loading={userlist_add_loader}
                                disabled={(group_unselected.length === 0 && group_selected.length === group_selected_copy.length) ? true : false}
                            >
                                Assign
                            </LaddaButton>

                            <LaddaButton className='btn btn-danger' onClick={hadleCloseStudentModal}>
                                Close
                            </LaddaButton>
                        </Col>

                    </Row>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                onClose={CloseAddUserModel}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle style={{ textAlign: 'center', padding: '10px 20px' }}>
                    Assign Users
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Row>
                        <Col xs={12}>
                            <div className='wrap-chat-main' style={{ height: '100px', overflowY: 'auto', width: '100%', border: '1px solid #777', borderRadius: '8px', padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word', margin: '5px 0px 0px 0px' }}>
                                {
                                    selected_all.map((val, ky) => {
                                        return (
                                            <Chip
                                                key={ky}
                                                label={val.full_name}                                                // onDelete={() => { handleRemove(val) }}
                                                color='primary'
                                                style={{ marginLeft: '3px', marginTop: '3px' }}
                                                size='small'
                                                onDelete={() => { handleRemove(val) }}
                                            />
                                        )
                                    })
                                }

                            </div>
                        </Col>
                        <Col xs={6} style={{ display: 'flex', alignItems: 'end', }}>
                            <span style={{ fontSize: '15px', fontWeight: '700', marginLeft: '35px' }}>Name</span>
                        </Col>
                        <Col xs={6} className='text-center'>
                            <TextField
                                size='small'
                                label='search'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={SearchUsers}
                            />
                        </Col>
                        <Col xs={12}>
                            <div className='wrap-chat-main' style={{ height: '220px', overflowY: 'auto', width: '100%', wordWrap: 'break-word', marginTop: '10px' }}>

                                {
                                    loader ? <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>Loading...</div> : ''

                                }
                                {
                                    clientList.length > 0 ? clientList.map((val, ky) => {
                                        return (
                                            <div key={ky} style={{ padding: '0 1.5rem' }}>
                                                <Checkbox
                                                    checked={[...student_selected, ...teacher_selected].includes(val.user_id)}
                                                    color='primary'
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={(e) => { HandleUser(val, e) }}
                                                    size='small'
                                                />
                                                <span style={{ fontWeight: '500', color: 'black' }}>{val.full_name}</span>
                                            </div>
                                        )
                                    })
                                        :
                                        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                                            {!loader && "No clients to list"}
                                        </div>
                                }
                            </div>
                        </Col>
                        <Col xs={12} style={{ textAlign: 'right' }}>
                            <LaddaButton
                                data-style={ZOOM_IN}
                                className='btn btn-primary mr-1'
                                onClick={AddUserToclassFn}
                                loading={userlist_add_loader}
                                disabled={(student_unselected.length === 0 && student_selected.length === student_selected_copy.length && teacher_unselected.length === 0 && teacher_selected.length === teacher_selected_copy.length) ? true : false}
                            >
                                Assign
                            </LaddaButton>

                            <LaddaButton className='btn btn-danger' onClick={CloseAddUserModel}>
                                Close
                            </LaddaButton>
                        </Col>
                    </Row>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default VideoClass;