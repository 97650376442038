import React, { useState, useEffect } from 'react'
//import { addStudentToClassroom } from '../../../redux/Actions/homeTeacher'
import { useDispatch, useSelector } from 'react-redux'
//import serialize from 'form-serialize'
import Loader from 'react-loaders'

import {
    Row, Col,
    Card, CardBody,
    CardHeader,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
function MembersList(props) {

    const classMembers = useSelector(state => state.homeTeacher.classMembers ? state.homeTeacher.classMembers : [ ])
    const membersLoader = useSelector(state => state.homeTeacher.membersLoader ? state.homeTeacher.membersLoader : false)

    return (
        <div>
            <Card className="main-card mb-3">
                <CardHeader>
                    <Col md={4} sm={4}>
                        Participant
                    </Col>
                    <Col md={4} sm={4}>
                        Phone
                    </Col>
                    <Col md={4} sm={4}>
                        Email
                    </Col>
                </CardHeader>
                <CardBody>
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>
                            {
                                membersLoader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }
                            {

                                classMembers.map(dt => (
                                    <Row key={dt.user_id}>
                                        <Col md={4} sm={4} style={{ paddingLeft: '30px' }}>
                                            <span style={{ fontWeight: 'bold' }} >{dt.full_name}</span> <span style={{ marginLeft: '4px', color: '#808080', fontSize: '8pt', fontStyle: 'italic' }} >{dt.role_name === 'student' ? 'Client' :
                                                dt.role_name === 'teacher' ? 'Therapist' :
                                                    dt.role_name === 'principal' ? 'Admin' : 'Staff'
                                            }</span>
                                        </Col>
                                        <Col md={4} sm={4} style={{ paddingLeft: '20px' }}>
                                            {dt.mobile? dt.mobile : 'not available !'}
                                        </Col>
                                        <Col md={4} sm={4} style={{ paddingLeft: '20px' }}>
                                            {dt.email? dt.email :'not available !'}
                                        </Col>

                                    </Row>

                                ))
                            }

                        </PerfectScrollbar>
                    </div>
                </CardBody>

            </Card>
        </div>
    )
}

export default MembersList
