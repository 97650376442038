import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getQuestionPaperPreview,
  deleteQuestion,
} from "../../../redux/Actions/exam";
import { Col, Row, Card, Button } from "reactstrap";
import Header from "../common/questionPaperHeader";
import Questions from "../common/questionAndOptions";
import { useHistory } from "react-router-dom";

const Exam = () => {
  const dispatch = useDispatch();
  const { question_paper_id } = useParams();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const examStore = useSelector(({ exam }) => exam);
  const history = useHistory();

  useEffect(() => {
    dispatch(getQuestionPaperPreview(tokenData.token, question_paper_id));
  }, []);
  let a = 0;
  const removeQuestion = (question_id, mark_structure_id) => {
    dispatch(
      deleteQuestion(
        tokenData.token,
        question_id,
        question_paper_id,
        mark_structure_id
      )
    );
  };
  const editQuestion = (question_id, question_type) => {
    history.push(
      `/dashboard/exam/editQuestion/${question_id}/${question_type}/${question_paper_id}`
    );
  };
  return (
    <div>
      <Header
        heading={examStore.questionPaperDetails?.Exam_title}
        color={"#99DDFF"}
        subject={examStore.questionPaperDetails?.Subject}
        deadline={examStore.questionPaperDetails?.Grade}
        icon="pe-7s-note2 icon-gradient bg-sunny-morning"
        //   buttonAction={downloadTeacherFile}
        background={"rgb(0 125 138)"}
        //   assignmentStore={assignmentStore}
        lastDataColor={"rgb(180, 193, 189)"}
        right_1={` Mark : ${examStore.questionPaperDetails?.Total_mark}`}
        right_2={`No. of Questions : ${examStore.questionPaperDetails?.Total_question}`}
      />
      <Card style={{ marginTop: 20 }}>
        <Row style={{ paddingTop: 20 }}>
          {/* 
                    {

                        Array(4).fill().map(val => {
                            return (
                                <Col md={12} style={{ padding: '0px 70px 30px' }}>
                                    <Questions

                                        component_type={"list_questions"}
                                        question={"The sample sdfsdf........ ?"}
                                        questionNumber={val}
                                        question_type={'objective'}
                                        mark={'3'}
                                        options={[{ a: "option 1" }, { b: "option 1" }, { c: "option 1" }, { d: "option 1" }]}
                                        // score={data.Score}
                                        correctAnswer={"c"}
                                    />
                                    <hr />
                                </Col>
                            )
                        })


                    } */}

          {examStore.questionPaperDetails?.Sections.map((val, counter) => {
            return (
              <>
                <Col md={12}>
                  <p style={{ textAlign: "center" }}>{val.Section} </p>
                  <hr />
                </Col>
                {val.Questions.map((data, index) => {
                  a++;
                  return (
                    <Col md={12} style={{ padding: "0px 70px 30px" }}>
                      <Questions
                        deleteStatus={
                          examStore.questionPaperDetails?.DeleteStatus
                        }
                        component_type={"list_questions"}
                        question={data.Question}
                        questionNumber={a}
                        question_type={data.Type}
                        SavedAnswer={data.SavedAnswer}
                        mark={data.Mark}
                        options={
                          data["Options"] !== undefined ? data.Options : null
                        }
                        score={data.Score}
                        correctAnswer={data.CorrectAnswer}
                        question_id={data.Question_id}
                        mark_structure_id={data.MarkStructureId}
                        deleteQuestion={removeQuestion}
                        editQuestion={editQuestion}
                      />
                      <hr />
                    </Col>
                  );
                })}
              </>
            );
          })}
        </Row>
      </Card>
    </div>
  );
};
export default Exam;
