import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  validateStartExam,
  getQuestionPaperDetails,
  saveAnswers,
  finishExam,
  setLocalAnswer,
  getDuration,
} from "../../redux/Actions/exam";
import { useParams } from "react-router";
import { Col, Row, Card, Button } from "reactstrap";
import Header from "./common/questionPaperHeader";
import "./style.scss";
import Swal from "sweetalert2";
import QuestionAndOptions from "./common/questionAndOptions";
import Countdown from "../common/Timer/countdown";
import Timer from "react-compound-timer";
import SwitchQuestions from "./SwitchQuestions/index";
const Exam = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { exam_id } = useParams();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const [validationPass, setValidationPass] = useState(false);
  const examStore = useSelector(({ exam }) => exam);
  //const aStore = useSelector(({ answer }) => answer);
  // const answerStore = useSelector(({ answers }) => answers )
  // const sStore = useSelector(({ homeStudent }) => homeStudent )

  let a = 0;
  //console.log("store store",sStore)

  useEffect(() => {
    dispatch(
      validateStartExam(startExam, tokenData.token, exam_id, "instruction")
    );
    dispatch(getDuration(tokenData.token, exam_id));
  }, []);

  const [readInstruction, setReadInstruction] = useState(true);
  useEffect(() => {
    if (readInstruction) {
      dispatch(getQuestionPaperDetails(tokenData.token, exam_id, cb));
    }
  }, [readInstruction]);

  const [isTimeToStart, setIsTimeToStart] = useState(false);
  const [Localstate, setLocalstate] = useState();

  useEffect(() => {
    // setTimeout(function () { setIsTimeToStart(true) }, 1000);
    // element.scrollIntoView();
  }, []);

  const [questionNumber, setQuestionNumber] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [objectiveAnswer, setObjectiveAnswer] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState([]);

  const cb = (question) => {
    let newArray = [];
    let count = {};
    question.map((data, index) => {
      // console.log("data.Questions",data.Questions);
      count = { ...count, [index]: data.Questions.length };
      newArray.push(data.Questions);
    });
    const flat = [].concat(...newArray);
    console.log("flat", flat);
    setQuestions(flat);
    setCurrentQuestion([flat[0]]);
  };
  console.log({ currentQuestion });
  console.log(currentQuestion.length);

  const startExam = () => setValidationPass(true);
  const prev = () => {
    if (questionNumber > 0) {
      setQuestionNumber(questionNumber - 1);
      setCurrentQuestion([questions[questionNumber - 1]]);
    }
  };
  const next = (question_type) => {
    if (questionNumber < questions.length - 1) {
      setQuestionNumber(questionNumber + 1);
      setCurrentQuestion([questions[questionNumber + 1]]);
    }

    // if(question_type==='subjective' && questionNumber < (questions.length - 1)){
    //    console.log("qno",questionNumber)

    //    setQuestionNumber(questionNumber + 1)
    //    setCurrentQuestion([questions[questionNumber + 1]])
    // }
    // else{

    //     setQuestionNumber(questionNumber + 1)
    //     setCurrentQuestion([questions[questionNumber + 1]])
    // }
  };

  //     if (questionNumber < (questions.length - 1)) {
  //         setQuestionNumber(questionNumber + 1)
  //         setCurrentQuestion([questions[questionNumber + 1]])

  //     }
  // }
  console.log("estore", examStore);

  const handleClick = (option_key, questionNumber, type) => {
    window.currentAnswer = {
      currentAnswer: option_key[0],
      questionNumber: questionNumber,
      type: type,
    };
    console.log("current ans", window.currentAnswer);
    dispatch({
      type: "ADD_LOCAL_ANSWER",
      payload: {
        question_no: questionNumber,
        answer: window.currentAnswer.currentAnswer,
        type: "objective",
      },
    });
  };

  const handleAnswer = (option_key, question_type) => {
    //     const copys = currentQuestion
    //     if (copys[0]["SavedAnswer"] == option_key) {
    //         copys[0]["SavedAnswer"] = false
    //         console.log("if copy",copys)
    //     } else {
    //         copys[0]["SavedAnswer"] = option_key[0]
    //         console.log("else copy",copys)
    //     }
    //     setCurrentQuestion([...currentQuestion, copys])
    // const filter=options.map(option =>{
    //     if(Object.keys(option)==option_key){
    //         option[option_key]=
    //     }
    // } )
    // const current = questions.filter(question =>{
    //     question.Question_id === question_id
    // } )
    // {`${question_id==Object.keys(option)?"success":"light"}`}
  };
  // const questionChooser = (data) => {
  //     let question_id = data.Question_id
  //     // alert(question_id)
  //     console.log("data from choose",data)
  //     console.log(document.getElementById("question_count"));
  //     const current = questions.filter(question => question.Question_id === question_id)
  //     const index = questions.map(data => data.Question_id).indexOf(question_id);
  //     setCurrentQuestion(current)
  //     setQuestionNumber(index)
  //     //    console.log({index});

  // }
  const start = () => {
    //setReadInstruction(true)
    setIsTimeToStart(true);
  };

  const save = (saveData) => {
    console.log("saved check", { saveData });
    saveData.exam_id = exam_id;

    if (saveData.question_type === "subjective") {
      if (
        (!saveData.savedAnswer || saveData.savedAnswer.length <= 8) &&
        !(examStore?.questionPaperDetails.Total_question === questionNumber + 1)
      ) {
        Swal.fire({
          icon: "warning",
          title: "Required",
          text: "Please  answer the question",
          confirmButtonText: "Okay",
        });
      } else if (saveData.savedAnswer.length === 8) {
        next();
        finished();
      } else {
        dispatch(saveAnswers(tokenData.token, saveData, questionNumber + 1));
        next();
        finished();
      }
    } else {
      if (
        !saveData.savedAnswer &&
        !(examStore?.questionPaperDetails.Total_question === questionNumber + 1)
      ) {
        // if (!saveData && !(saveData.savedAnswer?.length===questionNumber+1)) {
        Swal.fire({
          icon: "warning",
          title: "Required",
          text: "Please select the answer",
          confirmButtonText: "Okay",
        });
      } else if (!saveData.savedAnswer) {
        next();
        finished();
      } else {
        next();
        finished();
        dispatch(saveAnswers(tokenData.token, saveData, questionNumber + 1));
      }
    }
  };
  const finished = () => {
    if (questions.length === questionNumber + 1) {
      // Swal.fire({
      //     icon: 'warning',
      //     title: 'Are you sure ?.',
      //     text: 'You have completed  the exam and are going out',
      //     confirmButtonText: 'Yes',

      // }).then((result) => {
      //     // props.history.push('/dashboard/')
      //     // return <Redirect to='/dashboard' />
      //     // window.history.back();
      //     alert("yes")
      // })

      Swal.fire({
        icon: "warning",
        title: "Are you sure ?",
        text:
          "If you click on the Yes button, you will complete the exam and exit",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          dispatch(finishExam(tokenData.token, result.value, exam_id));
          Swal.fire(
            "Exam Completed",
            "You completed the exam successfully",
            "success"
          );
          history.push("/dashboard");
        }
      });
    }
  };
  const ExamPaper = () => {
    return (
      <Row>
        <Col md={12}>
          <Header
            heading={examStore.instructions?.Exam_title}
            color={"#99DDFF"}
            subject={examStore.instructions?.Subject}
            deadline={examStore.instructions?.Date}
            icon="pe-7s-note2 icon-gradient bg-sunny-morning"
            //   buttonAction={downloadTeacherFile}
            background={"#00458B"}
            //   assignmentStore={assignmentStore}
            lastDataColor={"rgb(180, 193, 189)"}
            right_1={`Duration : ${examStore.instructions?.Duration} `}
            right_2={`Total Mark : ${examStore.instructions?.Total_mark}`}
            button={"DOWNLOAD"}
          />
        </Col>
        <Col md={readInstruction ? 8 : 12} className="p-3">
          {readInstruction && (
            <Card className="p-5">
              {console.log("check", currentQuestion)}
              <QuestionAndOptions
                loader={examStore.QuestionPaperloader}
                question={
                  currentQuestion?.length > 0
                    ? currentQuestion[0]?.Question
                    : null
                }
                questionNumber={questionNumber + 1}
                question_type={
                  currentQuestion?.length > 0 ? currentQuestion[0]?.Type : null
                }
                question_id={
                  currentQuestion?.length > 0
                    ? currentQuestion[0]?.Question_id
                    : null
                }
                SavedAnswer={
                  currentQuestion?.length > 0
                    ? currentQuestion[0]?.SavedAnswer
                    : null
                }
                mark={
                  currentQuestion?.length > 0 ? currentQuestion[0]?.Mark : null
                }
                options={
                  currentQuestion?.length > 0
                    ? currentQuestion[0]?.["Options"] !== undefined
                      ? currentQuestion[0]?.Options
                      : null
                    : null
                }
                component_type={"question"}
                handleAnswer={handleAnswer}
                handleClick={handleClick}
                prev={prev}
                next={next}
                save={save}
                questionLength={questions.length}
              />
              {/* <hr />
                            <div style={{ textAlign: 'center' }}>
                                <Button className="mb-2 mr-2 btn-pill" color="secondary" onClick={prev}>  &lt;  Prev</Button>
                                <Button className="mb-2 mr-2 btn-pill" color="success" onClick={next}>   Save & Next</Button>
                                <Button className="mb-2 mr-2 btn-pill" color="secondary" onClick={next}>  Next 	&gt;</Button>
                            </div> */}
            </Card>
          )}
        </Col>
        {readInstruction && (
          <Col md={4} className="p-3">
            <Card className="p-4">
              <SwitchQuestions
                questionNumber={questionNumber}
                setQuestionNumber={setQuestionNumber}
                questions={questions}
                setCurrentQuestion={setCurrentQuestion}
              />
            </Card>
          </Col>
        )}
      </Row>
    );
  };
  return (
    <>
      <ExamPaper />
    </>
  );
};
export default Exam;
