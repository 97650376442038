


import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { IoIosAnalytics } from "react-icons/io";
import './timerStyle.scss'
import {
    Row, Col,
    Button,
    CardHeader,
    Container,
    Card,
    CardBody,
    Progress,
    ListGroup,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown
} from 'reactstrap';
import Loader from 'react-loaders'
import ClipLoader from "react-spinners/ClipLoader";

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            date: this.props.date
        }
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown('');
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }
    preveProps = this.props.date
    //preveState=this.state.date

    // componentWillUpdate(prevProps) {
    //     console.log("prevState----", prevProps)
    //     if (prevProps.date !== this.props.date) {
    //         this.setState({
    //             days: 0,
    //             hours: 0,
    //             min: 0,
    //             sec: 0,
    //             date: this.props.date
    //         })
    //     }
    // }

    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    render() {

        const tokenData = JSON.parse(localStorage.getItem("authDetails"));

        const countDown = this.state;
        const { joinClass, weekly_class_id, class_id, classname, active, label } = this.props;
        var sc = this.addLeadingZeros(countDown.sec)
        var mn = this.addLeadingZeros(countDown.min)
        var hr = this.addLeadingZeros(countDown.hours)

        return (





            <>



                <Card className="mb-3">
                    <CardHeader className="card-header-tab z-index-6">
                        <div
                            className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                            {`${label && label}: ${classname && classname}`}


                        </div>

                        <div id='sample-date'></div>

                        {/* <div className="btn-actions-pane-right text-capitalize">
                            <span className="d-inline-block ml-2" style={{width: 200}}>
                                <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={options}
                                />
                            </span>
                    </div> */}
                    </CardHeader>
                    <Row className="no-gutters">
                        <Col sm="6" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-10 bg-happy-green" />
                                    <i className="lnr-clock text-dark opacity-8" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Day
                                </div>
                                    <div className="widget-numbers">
                                        {this.addLeadingZeros(countDown.days)}
                                    </div>
                                    {/* <div className="widget-description opacity-8 text-focus">
                                    <div className="d-inline text-danger pr-1">
                                        <FontAwesomeIcon icon={faAngleDown}/>
                                        <span className="pl-1">54.1%</span>
                                    </div>
                                    less earnings
                                </div> */}
                                </div>
                            </div>
                            <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="6" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                                    <i className="lnr-clock  text-dark opacity-8" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Hour
                                </div>
                                    <div className="widget-numbers">
                                        {this.addLeadingZeros(countDown.hours)}
                                    </div>
                                    {/* <div className="widget-description opacity-8 text-focus">
                                    <div className="d-inline text-danger pr-1">
                                        <FontAwesomeIcon icon={faAngleDown}/>
                                        <span className="pl-1">54.1%</span>
                                    </div>
                                    less earnings
                                </div> */}
                                </div>
                            </div>
                            <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="6" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-9 bg-danger" />
                                    <i className="lnr-clock text-white" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Minutes
                                </div>
                                    <div className="widget-numbers">
                                        {this.addLeadingZeros(countDown.min)}
                                        {/* <CountUp start={0}
                                             end={8.7}
                                             separator=""
                                             decimals={0}
                                             decimal=","
                                             prefix=""
                                             useEasing={false}
                                             suffix="M"
                                             duration="5"/> */}
                                    </div>
                                    {/* <div className="widget-description opacity-8 text-focus">
                                    Grow Rate:
                                    <span className="text-info pl-1">
                                            <FontAwesomeIcon icon={faAngleDown}/>
                                            <span className="pl-1">14.1%</span>
                                        </span>
                                </div> */}
                                </div>
                            </div>
                            <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="12" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-9 bg-success" />
                                    <i className="lnr-clock text-white" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Seconds
                                </div>
                                    <div className="widget-numbers text-success">
                                        {this.addLeadingZeros(countDown.sec)}
                                        {/* <CountUp start={0}
                                             end={563}
                                             separator=""
                                             decimals={0}
                                             decimal="."
                                             prefix="$"
                                             useEasing={false}
                                             suffix=""
                                             duration="7"/> */}
                                    </div>
                                    {/* <div className="widget-description text-focus">
                                    Increased by
                                    <span className="text-warning pl-1">
                                        <FontAwesomeIcon icon={faAngleUp}/>
                                        <span className="pl-1">7.35%</span>
                                    </span>
                                </div> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <CardFooter className="text-center d-block p-3">
                        <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg"
                            onClick={() => joinClass(weekly_class_id, class_id)}

                            //disabled={hr && mn && sc == '00' && this.props.active === true ? false : true}
                            disabled={true}

                        //disabled={hr == '00' && mn == '00' && (sc == '01' || sc == '00') === true}
                        >
                            <Row>
                                <span className="mr-2 opacity-7">

                                    <IoIosAnalytics color="#ffffff" />
                                </span>
                                <span className="mr-1">
                                    Loading...
                                    {/* {tokenData ? tokenData.role === 'teacher' ? 'Start Your Class' : tokenData.role === 'student' ? 'Join Your Class' : null : null} */}
                                </span>

                            </Row>





                        </Button>
                    </CardFooter>
                </Card>










            </>
        );
    }
}

Countdown.propTypes = {
    date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
    date: new Date()
};

export default Countdown;