import { development } from "../config.json";

const baseUrl = development.baseUrl;
const baseUrlente = development.baseUrlente;
export const endpoints = {
  hostUrl: baseUrl,
  getExamDetails: `${baseUrl}getExamDetails`,
  validateStartExam: `${baseUrl}validateStartExam`,
  getQuestionPaperDetails: `${baseUrl}getQuestionPaperDetails`,
  saveAnswers: `${baseUrl}saveAnswers`,
  getStudentExams: `${baseUrl}getStudentExams`,
  getAnswerPaperDetails: `${baseUrl}getAnswerPaperDetails`,
  getMarkStucture: `${baseUrl}getMarkStucture`,
  getTeacherQuestions: `${baseUrl}getTeacherQuestions`,
  getQuestionPaperPreview: `${baseUrl}getQuestionPaperPreview`,
  getTeacherExams: `${baseUrl}getTeacherExams`,
  filterQuestionPaper: `${baseUrl}filterQuestionPaper`,
  deleteQuestion: `${baseUrl}deleteQuestion`,
  createExam: `${baseUrl}createExam`,
  createQuestions: `${baseUrl}createQuestions`,
  addQuestions: `${baseUrl}addQuestions`,
  submitStudentEvaluation: `${baseUrl}submitStudentEvaluation`,
  populateExamGroups: `${baseUrlente}populateExamGroups`,
  addStudentsExamEnte: `${baseUrlente}addStudentToExam`,
  getTeacherAssignedGroups: `${baseUrlente}getTeacherAssignedGroups`,
  deleteQuestionPaper: `${baseUrl}deleteQuestionPaper`,
  editQuestion: `${baseUrl}editQuestion`,
  updateQuestion: `${baseUrl}updateQuestion`,
  getInstructions: `${baseUrl}getInstructions`,
};
