
import axios from 'axios'
import { development } from '../../config.json'
// import moment from 'moment'
import Swal from 'sweetalert2'




export const getAllFeedback = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'GET_ALL_FEEDBACK_REQUEST' })
    axios.get(`${development.baseUrlEnte}getAllFeedback`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_ALL_FEEDBACK_SUCCESS', payload: res.data.details })

            }

        })
        .catch(err => {
            dispatch({ type: 'GET_ALL_FEEDBACK_FAILED', payload: err })


        })
}

export const submitFeedback = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'SEND_FEEDBACK_REQUEST' })
    axios.post(`${development.baseUrlEnte}submitFeedback`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SEND_FEEDBACK_SUCCESS' })
                cb()
                Swal.fire({
                    icon: 'success',
                    title: 'Sent',
                    text: 'Feedback sent successfully',

                })
            }

        })
        .catch(err => {
            dispatch({ type: 'SEND_FEEDBACK_FAILED', payload: err })
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong',

            })

        })
}

export const getAllFeedbackSubject = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'GET_FEEDBACK_SUBJECT_REQUEST' })
    axios.get(`${development.baseUrlEnte}getAllFeedbackSubject`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_FEEDBACK_SUBJECT_SUCCESS', payload: res.data.details })

            }

        })
        .catch(err => {
            dispatch({ type: 'GET_FEEDBACK_SUBJECT_FAILED', payload: err })


        })
}
