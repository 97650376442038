import React from "react";
import serialize from "form-serialize";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col, Form, Input, Label } from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { UpdateExamIndividual } from "../../../redux/Actions/exam";

function UpdateExam(props) {
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  useEffect(() => {});
  const dispatch = useDispatch();

  const [ExamTime, setExamTime] = useState(props.rowData.time);
  const [ExamDate, setExamDate] = useState(props.rowData.date);
  //console.log('dt',ExamDate)

  const ExamID = props.exam_id;
  const StudentID = props.rowData.student_id;
  const ExamTitle = props.exam_title;

  const formattedDate = ExamDate.split("-")
    .reverse()
    .join("-");
  const formattedTime = moment(ExamTime, "h:mm a").format("HH:mm");

  function handleDateChange(e) {
    let dt = e.target.value;
    let fdate = dt
      .split("-")
      .reverse()
      .join("-");
    setExamDate(fdate);
  }
  function handleTimeChange(e) {
    setExamTime(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    dispatch(UpdateExamIndividual(tokenData.token, body, props.handleClose));
    console.warn(body);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>Student</Col>
          <Col md={6}>Exam</Col>

          <Col md={6}>
            <strong style={{ textTransform: "capitalize" }}>
              {props.rowData.student_name}
            </strong>
            <hr />
          </Col>

          <Col md={6}>
            <strong>{ExamTitle}</strong>
            <hr />
          </Col>

          <Col md={6}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span> Exam Date
            </Label>
            <Input type="hidden" name="exam_id" value={ExamID} />
            <Input type="hidden" name="student_id" value={StudentID} />
            <Input
              type="date"
              required
              name="exam_date"
              value={formattedDate}
              onChange={handleDateChange}
            />
          </Col>
          <Col md={12}></Col>
          <Col md={6}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span> Exam Time
            </Label>
            <Input
              type="time"
              required
              name="exam_time"
              value={formattedTime}
              onChange={handleTimeChange}
            />
          </Col>
          <Col md={6}>
            <hr />
            {/* <Button type="submit" color="danger">UPDATE</Button> */}
            <LaddaButton
              className="mb-2 mr-2 btn btn-primary"
              //loading={assignment.updateAssignLoader}
              type="submit"
              data-style={ZOOM_IN}
            >
              UPDATE EXAM
            </LaddaButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default UpdateExam;
