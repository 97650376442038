import React from 'react'
import { Col, Row, Input, FormGroup, Label, FormFeedback, Alert } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

const UploadVideos = (props) => {
    const { handleChange, errors } = props
    return (<>

        <Row >
            <Col md={12}>
                <Input type="hidden" name="isType" value={"UPLOAD"} />

                <FormGroup>
                    <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Title</Label>
                    <Input type="text" name="title" onChange={handleChange}
                        invalid={errors.title}
                    />

                    <FormFeedback >{errors.title}</FormFeedback>

                </FormGroup>
            </Col>
            {/* <Col md={3}>
                <FormGroup>
                    <Label for="exampleCustomSelectDisabled">Choose poster {` *`}</Label>

                    <Input required type="file" name="poster" style={{ lineHeight: '32px' }} onChange={handleChange} />
                    <FormFeedback >oops! Type is required</FormFeedback>

                </FormGroup>
            </Col> */}
            <Col md={12}>
                <FormGroup>
                    <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Choose videos</Label>

                    <Input
                        invalid={errors.video}
                        type="file" id="fup" name="video"
                        style={{ lineHeight: '32px' }} onChange={handleChange} />
                    <FormFeedback >{errors.video}</FormFeedback>

                </FormGroup>
                {/* <label class="btn-bs-file btn-lg btn-primary">
                    Browse
                <input type="file" required name="video" />
                </label> */}
            </Col>
            <Col md={3}>
                <FormGroup>
                    {/* <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Title {` *`}</Label> */}


                </FormGroup>

            </Col>
        </Row>
    </>
    )
}

export default UploadVideos
