import { populateExamGroups } from "../Actions/exam";

const initialState = {
  question_paper_id: null,
  nextExam: null,
  nextExamValidatorLoader: false,
  upcomingExams: [],
  instaructions: null,
  questionPaperDetails_: null,
  listExamLoader: false,
  listExams: [],
  answerSheet: null,
  markStructure: null,
  listQuestionPaperLoader: false,
  listQuestionPaper: [],
  priviewPaperLoader: false,
  questionPaperDetails: null,
  examDetails: null,
  assignExamLoader: false,
  populateExamStudentsLoader: false,
  populateExamGroupsLoader: false,
  updateExamLoader: false,
  updateExamIndividualLoader: false,
  createQPLoader: false,
  addQuestionLoader: false,
  createExamLoader: false,
  savedLocalAnswer: [],
  questionDetails: null,
  instructions: [],
  general_instructions: [],
  specific_instructions: [],
  exam_students: [],
  submitEvaluationLoader: false,
  editQuestionLoader: false,
};

const examReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_INSTRUCTIONS_SUCCESS": {
      console.log("called");
      console.log("payload", payload);
      return {
        ...state,
        instructions: payload.details,
      };
    }
    case "POPULATE_EXAM_STUDENT_SUCCESS": {
      return {
        ...state,
        exam_students: payload,
      };
    }

    case "FINISH_EXAM_SUCCESS": {
      return {
        ...state,
        savedLocalAnswer: [],
      };
    }
    case "QUESTION_PREVIEW_SUCCESS": {
      console.log("payload from preview", payload);
      return {
        ...state,
        questionDetails: payload,
      };
    }
    case "QUESTION_PAPER_DELETE_SUCESS": {
      return {
        ...state,
        listQuestionPaper: payload,
      };
    }

    case "SET_LOCAL_ANSWER": {
      //todos: [...state.todos, action.payload] //new todos array
      const newArray = [...state.savedLocalAnswer];
      console.log("the array", newArray);
      // newArray.splice(1, 1, payload.answer);
      newArray[payload.question_no] = payload.answer;
      console.log("spliced", newArray);
      return {
        // ...state,
        // arr: state.arr.concat(action.newItem)

        // ...state , savedLocalAnswer:[...state.savedLocalAnswer[payload.question_id], payload.answer]
        ...state,
        savedLocalAnswer: newArray,
      };
    }

    case "UPDATE_EXAM_INDIVIDUAL_REQUEST": {
      return {
        ...state,
        updateExamIndividualLoader: true,
      };
    }
    case "UPDATE_EXAM_INDIVIDUAL_SUCCESS": {
      return {
        ...state,
        updateExamIndividualLoader: false,
        examDetails: payload,
      };
    }
    case "UPDATE_EXAMS_INDIVIDUAL_FAILED": {
      return {
        ...state,
        updateExamIndividualLoader: false,
      };
    }
    case "EDIT_QUESTION_REQUEST": {
      return {
        ...state,
        editQuestionLoader: true,
      };
    }
    case "EDIT_QUESTION_SUCCESS": {
      return {
        ...state,
        editQuestionLoader: false,
      };
    }
    case "EDIT_QUESTION_FAILED": {
      return {
        ...state,
        editQuestionLoader: false,
      };
    }
    case "EVALUATION_REQUEST": {
      return {
        ...state,
        submitEvaluationLoader: true,
      };
    }
    case "EVALUATION_SUCCESS": {
      return {
        ...state,
        submitEvaluationLoader: false,
      };
    }
    case "EVALUATION_FAILED": {
      return {
        ...state,
        submitEvaluationLoader: false,
      };
    }
    case "UPDATE_EXAM_REQUEST": {
      return {
        ...state,
        updateExamLoader: true,
      };
    }
    case "UPDATE_EXAM_SUCCESS": {
      return {
        ...state,
        updateExamLoader: false,
        listExams: payload,
      };
    }
    case "UPDATE_EXAMS_FAILED": {
      return {
        ...state,
        updateExamLoader: false,
      };
    }

    case "ADD_QUESTION_ID": {
      return {
        ...state,
        question_paper_id: payload,
      };
    }

    case "ADD_STUDENTS_TO_EXAM_REQUEST": {
      return {
        ...state,
        assignExamLoader: true,
      };
    }
    case "ADD_STUDENTS_TO_EXAM_SUCCESS": {
      return {
        ...state,
        assignExamLoader: false,
      };
    }

    case "GET_EXAM_DETAILS_REQUEST": {
      return {
        ...state,
        examDetailsLoader: true,
      };
    }
    case "GET_EXAM_DETAILS_SUCCESS": {
      return {
        ...state,
        examDetailsLoader: false,
        examDetails: payload,
      };
    }
    case "GET_EXAM_DETAILS_FAILED": {
      return {
        ...state,
        examDetailsLoader: false,
      };
    }
    // case 'QUESTION_PAPER_PREVIEW_REQUEST': {
    //     return {
    //         ...state, priviewPaperLoader: true
    //     }
    // }
    // case 'QUESTION_PAPER_PREVIEW_SUCCESS': {
    //     return {
    //         ...state, priviewPaperLoader: false, questionPaperDetails: payload
    //     }
    // }
    // case 'QUESTION_PAPER_PREVIEW_FAILED': {
    //     return {
    //         ...state, priviewPaperLoader: false
    //     }
    // }
    case "GET_FILTER_QUESTION_PAPER_REQUEST": {
      return {
        ...state,
        questionPaperLoader: true,
      };
    }
    case "GET_FILTER_QUESTION_PAPER_SUCCESS": {
      return {
        ...state,
        questionPaperLoader: false,
        questionPapers: payload,
      };
    }
    case "GET_FILTER_QUESTION_PAPER_FAILED": {
      return {
        ...state,
        questionPaperLoader: false,
      };
    }
    case "QUESTION_PAPER_PREVIEW_REQUEST": {
      return {
        ...state,
        priviewPaperLoader: true,
      };
    }
    case "QUESTION_PAPER_PREVIEW_SUCCESS": {
      return {
        ...state,
        priviewPaperLoader: false,
        questionPaperDetails: payload,
      };
    }
    case "QUESTION_PAPER_PREVIEW_FAILED": {
      return {
        ...state,
        priviewPaperLoader: false,
      };
    }
    case "GET_TEACHER_QUESTION_PAPER_REQUEST": {
      return {
        ...state,
        listQuestionPaperLoader: true,
      };
    }
    case "GET_TEACHER_QUESTION_PAPER_SUCCESS": {
      return {
        ...state,
        listQuestionPaperLoader: false,
        listQuestionPaper: payload,
      };
    }
    case "GET_TEACHER_QUESTION_PAPER_FAILED": {
      return {
        ...state,
        listQuestionPaperLoader: false,
      };
    }
    case "GET_MARK_STRUCTURE_REQUEST": {
      return {
        ...state,
        markStructionLoader: true,
      };
    }
    case "GET_MARK_STRUCTURE_SUCCESS": {
      return {
        ...state,
        markStructionLoader: false,
        markStructure: payload,
      };
    }
    case "GET_MARK_STRUCTURE_FAILED": {
      return {
        ...state,
        markStructionLoader: false,
      };
    }
    case "STUDENT_ANSWER_REQUEST": {
      return {
        ...state,
        answerSheetLoader: true,
      };
    }
    case "STUDENT_ANSWER_SUCCESS": {
      return {
        ...state,
        answerSheetLoader: false,
        answerSheet: payload,
      };
    }
    case "STUDENT_ANSWER_FAILED": {
      return {
        ...state,
        answerSheetLoader: false,
      };
    }
    case "GET_EXAMS_REQUEST": {
      return {
        ...state,
        listExamLoader: true,
      };
    }
    case "GET_EXAMS_SUCCESS": {
      return {
        ...state,
        listExamLoader: false,
        listExams: payload,
      };
    }
    case "GET_EXAMS_FAILED": {
      return {
        ...state,
        listExamLoader: false,
      };
    }
    case "GET_TEACHER_EXAMS_REQUEST": {
      return {
        ...state,
        listExamLoader: true,
      };
    }
    case "GET_TEACHER_EXAMS_SUCCESS": {
      return {
        ...state,
        listExamLoader: false,
        listExams: payload,
      };
    }
    case "GET_TEACHER_EXAMS_FAILED": {
      return {
        ...state,
        listExamLoader: false,
      };
    }
    case "GET_UPCOMING_EXAM_DETAILS_SUCCESS": {
      return {
        ...state,
        nextExam: payload.upcoming_exam,
        upcomingExams: payload.exams,
      };
    }
    case "GET_QUESTION_PAPER_REQUEST": {
      return {
        ...state,
        QuestionPaperloader: true,
      };
    }
    case "GET_QUESTION_PAPER_SUCCESS": {
      return {
        ...state,
        QuestionPaperloader: false,
        questionPaperDetails: payload,
      };
    }
    case "GET_EXAM_DURATION_SUCCESS": {
      return {
        ...state,
        duration: payload.Duration,
      };
    }
    case "GET_QUESTION_PAPER_FAILED": {
      return {
        ...state,
        QuestionPaperloader: false,
      };
    }
    case "VALIDATE_START_EXAM_REQUEST": {
      return {
        ...state,
        nextExamValidatorLoader: true,
      };
    }
    case "VALIDATE_START_EXAM_SUCCESS": {
      return {
        ...state,
        nextExamValidatorLoader: false,
        general_instructions: payload.Points.general,
        specific_instructions: payload.Points.teacher,
        instructions: payload,
      };
    }
    case "VALIDATE_START_EXAM_FAILED": {
      return {
        ...state,
        nextExamValidatorLoader: false,
      };
    }
    case "CREATE_QUESTION_PAPER_REQUEST": {
      return {
        ...state,
        createQPLoader: true,
      };
    }
    case "CREATE_QUESTION_PAPER_SUCCESS": {
      return {
        ...state,
        createQPLoader: false,
      };
    }
    case "ADD_QUESTION_REQUEST": {
      return {
        ...state,
        addQuestionLoader: true,
      };
    }
    case "ADD_QUESTION_SUCCESS": {
      return {
        ...state,
        addQuestionLoader: false,
      };
    }
    case "CREATE_EXAM_REQUEST": {
      return {
        ...state,
        createExamLoader: true,
      };
    }
    case "CREATE_EXAM_SUCCESS": {
      return {
        ...state,
        createExamLoader: false,
      };
    }
    // case 'SAVE_ANSWER_SUCCESS' :{
    //     return {
    //         ...state, questionPaperDetails
    //     }
    // }

    default:
      return state;
  }
};

export default examReducer;
