import React from 'react'
import { Elastic } from 'react-burgers';
import {
    Card
} from 'reactstrap';
import { TextField, Chip, Avatar } from "@material-ui/core";
import "./chat.css"
import avatar1 from '../../assets/utils/images/avatars/1.jpg';
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { useSelector } from "react-redux"
import group_icon from '../../assets/utils/images/avatars/group_icon.png';

function GroupCreation({ active, setActive, selected_members, remove_member, CreateGroup,group_name,HandleGroupName }) {

    const group_loader = useSelector(state => state.new_action.group_loader ? state.new_action.group_loader : false)

    return (
        <Card className="app-inner-layout__content">
            <div className="app-inner-layout__top-pane">
                <div className="pane-left">
                    <div className="mobile-app-menu-btn">
                        <Elastic
                            width={26}
                            lineHeight={2}
                            lineSpacing={5}
                            color='#6c757d'
                            active={active}
                            onClick={setActive} />
                    </div>
                </div>

            </div>
            <div className="wrap-chat-main wrap_chat_height_style">
                <div style={{ width: '100%', height: 'calc(100vh - 200px)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                        <img src={group_icon} width='80px' height='80px' />
                        <TextField
                            name='groupname'
                            value={group_name}
                            label='Add group name here'
                            onChange={HandleGroupName}
                        />
                    </div>
                    <div className='group_members'>
                        <div style={{ width: '100%' }}>{selected_members.length} participants added</div>
                        {
                            selected_members.map((val, ky) => {
                                return (
                                    <Chip
                                        avatar={<Avatar alt="" src={avatar1} />}
                                        key={ky}
                                        label={val.full_name}
                                        onDelete={() => { remove_member(val) }}
                                        color='primary'
                                        style={{ marginLeft: '3px', marginTop: '3px' }}
                                        size='small'
                                        variant='outlined'
                                    />
                                )
                            })
                        }
                    </div>

                    <div>
                        <LaddaButton
                            data-style={ZOOM_IN}
                            className='btn btn-primary btn-pill pr-3 pl-3'
                            style={{background:'#240777'}}
                            onClick={CreateGroup}
                            loading={group_loader}
                            disabled={selected_members.length <= 0}
                        >
                            Create Group
                        </LaddaButton>
                    </div>
                </div>
            </div>

        </Card>
    )
}

export default GroupCreation
