import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Row, Col, Card, Button } from "reactstrap";
import { Loader, Types } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay'
// import TitleComponent1 from './PageTitleExamples/Variation1'
// import TitleComponent2 from './PageTitleExamples/Variation2'
// import TitleComponent3 from './PageTitleExamples/Variation3'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

const PageTitle = (props) => {


    const {
        enablePageTitleIcon,
        enablePageTitleSubheading,
        assignmentStore,
        heading,
        icon,
        subject,
        deadline,
        buttonAction,
        background,
        color,
        button,
        lastDataColor,
        loader,
        grade,
        buttonColor,
        buttonFontColor,
        skelton,
        type

    } = props;

    // var arr = [<TitleComponent1 />, <TitleComponent2 />, <TitleComponent3 />]
    // var arr = [<TitleComponent3 />]


    return (

        <>
            {
                skelton ?
                    <Card style={{ padding: '30px' }}>
                        <Row>
                            <Col md={2}>
                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                    <Skeleton height={50} width={50} count={1} />
                                </SkeletonTheme>
                            </Col>
                            <Col md={8}>
                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                    <Skeleton height={30} count={2} />
                                </SkeletonTheme>
                            </Col>
                            <Col md={2}>
                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                    <Skeleton height={25} width={50} count={1} />
                                </SkeletonTheme>
                            </Col>
                        </Row>

                    </Card>
                    :
                    <div className="app-page-title" style={{ margin: '0px', borderRadius: '6px', backgroundColor: background, color: "white" }}>
                        <div className="page-title-wrapper">
                            <div className="page-title-heading">
                                <div
                                    className={cx("page-title-icon", { 'd-none': !enablePageTitleIcon })}>
                                    <i className={icon} />
                                </div>
                                <div className="jms-opacity-overwrite" style={{ fontWeight: 600 }}>
                                    <span style={{ textTransform: 'uppercase' }}> {heading}</span>
                                    <div style={{ color: color, fontWeight: 600, textTransform: 'capitalize' }} className={cx("page-title-subheading", { 'd-none': !enablePageTitleSubheading })}>
                                        {subject}&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                         <span style={{ color: lastDataColor, fontSize: 12 }}> <strong>{grade}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="page-title-actions">
                                {
                                    type !== "hide" &&
                                    <LaddaButton className="mb-2 mr-2 btn btn-dark"
                                        loading={loader}
                                        type="button"
                                        onClick={() => buttonAction()}
                                        data-style={ZOOM_IN}
                                        style={{ color: buttonFontColor, backgroundColor: buttonColor, borderColor: buttonColor }}
                                    >
                                        {
                                            button === 'ADD MARK' ?
                                                <i className="lnr-plus-circle btn-icon-wrapper"> </i>
                                                : button === 'DELETE' ?
                                                    <i className="lnr-delete btn-icon-wrapper"> </i>
                                                    :
                                                    <i className="lnr-download btn-icon-wrapper"> </i>
                                        }
                                        {/* <i className="pe-7s-download btn-icon-wrapper"> </i> */}
                                    &nbsp;{button}

                                    </LaddaButton>
                                }

                                {/* <Button className="mb-2 mr-2 btn-icon" color="focus"
            
            
                                >
                                    <i className="pe-7s-download btn-icon-wrapper"> </i>
                                                    DOWNLOAD
                                                </Button> */}
                            </div>
                        </div>
                    </div>

            }


        </>
    );

}
const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});



export default connect(mapStateToProps)(PageTitle);