import React from 'react'
import { Elastic } from 'react-burgers';
import {
    Card, Row, Col
} from 'reactstrap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import avatar1 from '../../assets/utils/images/avatars/singleuser.jpg';
import group_icon from '../../assets/utils/images/avatars/group_icon.png';
import { Button, Tooltip } from '@material-ui/core';
import { useSelector } from "react-redux"
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

function GroupDetails({ active, setActive, CloseGroupDetails, chat_name, GroupMembers, AddMembers, RemoveUser, current_user_id, ShowTextField }) {

    const group_owner = useSelector(state => state.new_action.group_owner ? state.new_action.group_owner : false)
    const group_created_at = useSelector(state => state.new_action.group_created_at ? state.new_action.group_created_at : '')


    return (
        <Card className="app-inner-layout__content">
            <div className="app-inner-layout__top-pane">
                <div className="pane-left">
                    <div className="mobile-app-menu-btn">
                        <Elastic
                            width={26}
                            lineHeight={2}
                            lineSpacing={5}
                            color='#6c757d'
                            active={active}
                            onClick={setActive} />
                    </div>
                </div>

            </div>
            <div className="wrap-chat-main wrap_chat_height_style">
                <div style={{ width: '100%', height: 'calc(100vh - 180px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ height: '30%' }}>
                        <Row>
                            <Col xs={2}>
                                <ArrowBackIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={CloseGroupDetails} />
                            </Col>
                            <Col xs={8} className='text-center'>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100px' }}>
                                    <img src={group_icon} width='80px' height='80px' />
                                    <div>
                                        <span style={{ fontWeight: '500', color: 'black', fontSize: '15px' }}>{chat_name}</span><br />
                                        <span style={{ fontWeight: 'normal', color: 'black', fontSize: '13px' }}>Created on {group_created_at}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2}>
                                {/* {
                                    !val.clickable || group_owner ?
                                        <DeleteOutlineOutlinedIcon size='small' style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => { RemoveUser(val) }} />
                                        : ''
                                } */}
                            </Col>
                        </Row>
                    </div>

                    <div style={{ height: '70%' }}>
                        <Row style={{ height: '100%' }}>
                            <Col sm={12} md={12} style={{ height: '100%', paddingBottom: '10px' }}>
                                <div style={{ height: '100%', width: '100%', paddingLeft: '10px', boxSizing: 'border-box' }}>
                                    <div style={{ width: '100%', height: '10%', display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: 'bold', paddingLeft: '5px', fontSize: '13px' }}>Group Members</span>
                                        {
                                            group_owner && ShowTextField() ?
                                                <Tooltip arrow title='Add Members'>
                                                    <Button onClick={AddMembers}>
                                                        <AddIcon />
                                                    </Button>
                                                </Tooltip>
                                                : ''
                                        }

                                    </div>
                                    <div className='group_members_div' style={{ height: '90%', width: '100%', overflowY: 'scroll' }}>
                                        {
                                            GroupMembers.map((val, ky) => {
                                                return (
                                                    <div key={ky} style={{ width: '100%', height: '50px', marginTop: '10px', borderBottom: '1px solid #d3cece', display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                            <div className="avatar-icon-wrapper">
                                                                <div className="avatar-icon">
                                                                    <img src={avatar1} alt="" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span>{val.full_name}</span>
                                                                {
                                                                    val.clickable === false ? <span style={{ fontWeight: 'bold' }}> (YOU)</span> : val.receiver_id === val.owner_id ? <span style={{ fontWeight: 'bold' }}> (GROUP ADMIN)</span> : ''
                                                                }

                                                                <br />

                                                                <span style={{ fontSize: '11px', paddingLeft: '2px' }}>
                                                                    {val.role_name === 'student' ? 'Client' :
                                                                        val.role_name === 'teacher' ? 'Therapist' :
                                                                            val.role_name === 'principal' ? 'Admin' :
                                                                                'Staff'
                                                                    }
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                            {
                                                                (!val.clickable || group_owner) && ShowTextField() ?
                                                                    <Tooltip arrow title='Remove User'>
                                                                        <div className='group_member_add' style={{ background: '#a53434', marginRight: '15px' }} onClick={() => { RemoveUser(val) }} >
                                                                            <RemoveIcon fontSize='small' style={{ color: 'white', cursor: 'pointer' }} />
                                                                        </div>
                                                                    </Tooltip>
                                                                    : ''

                                                            }
                                                        </div>

                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            {/* <Col sm={12} md={6}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    GROUP MEDIA
                                </div>
                            </Col> */}
                        </Row>
                    </div>

                </div>
            </div>
        </Card>
    )
}

export default GroupDetails
