import React, { useState, useEffect, Fragment } from 'react';
import Swal from 'sweetalert2'

import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import serialize from 'form-serialize'
import { changePassword } from '../../redux/Actions/homeTeacher'
import SweetAlert from 'sweetalert-react';



import {
    Label,
    FormGroup,
    Form,
    FormText,

    Row, Col,
    Button,
    CardHeader,
    Container,
    Card,
    CardBody,
    CardTitle,
    Progress,
    ListGroup,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown
} from 'reactstrap';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

// import CountUp from 'react-countup';

// import ReactTable from "react-table";

// import avatar1 from '../../../../assets/utils/images/avatars/1.jpg';
// import avatar2 from '../../../../assets/utils/images/avatars/2.jpg';

// import Ionicon from 'react-ionicons';
import { IoIosAnalytics } from "react-icons/io";

import PerfectScrollbar from 'react-perfect-scrollbar';

import Slider from "react-slick";

// import { makeData } from "../../../Tables/DataTables/Examples/utils";

import {
    ResponsiveContainer,
    AreaChart,
    Area,
} from 'recharts';

import {
    Sparklines,
    SparklinesCurve
} from 'react-sparklines';

import {
    faAngleUp,
    faAngleDown,
    faCalendarAlt,
    faEllipsisH,
    faCheck,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from 'react-select';


import { useHistory } from 'react-router-dom'

import classnames from 'classnames';

import {

    Table,
    ButtonGroup,
    Nav,
    NavItem,
    NavLink,
    Popover,
    PopoverBody,
} from 'reactstrap';



import {
    faBusinessTime,
    faCog
} from '@fortawesome/free-solid-svg-icons';






const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    // const [state, setState] = useState({
    //     data: makeData(),
    //     dropdownOpen: false
    // });

    const history = useHistory()

    const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)

    console.log("homeDetail", homeDetail)

    const joinClass = (weekId, classId) => {

        // dispatch(validateStartClass(tokenData.token, id, cb))
        // console.log("id----------", classId)

        if (tokenData.role === 'student') {
            history.push(`/dashboard/join/${weekId}/${classId}`)
        } else if (tokenData.role === 'teacher') {
            history.push(`/dashboard/startclass/${weekId}/${classId}`)
        }
        else {
            //alert("invalid")
            Swal.fire({
                icon: 'warning',
                title: 'Invalid',
                //  text: "select  classes"

            })
        }
    }
    // setState({
    //     selectedOption: null,
    // });

    // const handleChange = (selectedOption) => {
    //     setState({ selectedOption });
    // };

    const toggle = () => {
        // setState(prevState => ({
        //     dropdownOpen: !prevState.dropdownOpen
        // }));
    }





    const [successModal, setSuccessModal] = useState();

    const [message, setmessage] = useState();

    const [messageType, setmessageType] = useState();



    const handleOpenSuccessModal = (resultMessage, messageType) => {
        setSuccessModal(true)
        setmessage(resultMessage)
        setmessageType(messageType)

    }

    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
    }

    const submitForm = (e) => {
        e.preventDefault();
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })


        dispatch(changePassword(tokenData.token, body, handleOpenSuccessModal))

    }



    // const { selectedOption } = state;
    // const { data } = state;

    // const settings = {
    //     className: "",
    //     centerMode: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     speed: 500,
    //     dots: true,
    // };
    // const dateInFuture = '2029-12-31 23:59:59'              
    return (
        <>
            <Form onSubmit={submitForm} id="class-form" >

                <Fragment>
                    <Container fluid>
                        <Row>
                            <Col md="3">
                            </Col>
                            <Col md="6">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <CardTitle>Change Password</CardTitle>

                                        {/* <FormGroup>
                                                <Label for="exampleEmail">Email</Label>
                                                <Input type="email" name="email" id="exampleEmail"
                                                       placeholder="with a placeholder"/>
                                            </FormGroup> */}
                                        <FormGroup>
                                            <Label for="examplePassword">Current Password</Label>
                                            <Input type="password" name="current_password" id="examplePassword"
                                                placeholder="" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="examplePassword">New Password</Label>
                                            <Input type="password" name="new_password" id="examplePassword"
                                                placeholder="" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="examplePassword">Confirm Password</Label>
                                            <Input type="password" name="confirm_password" id="examplePassword"
                                                placeholder="" />
                                        </FormGroup>

                                        <Button color="primary" className="mt-1" type="submit" >Submit</Button>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>



                    </Container>
                </Fragment >
            </Form>

            <SweetAlert
                title="Message"
                confirmButtonColor=""
                show={successModal}
                text={message}
                type={messageType}
                onConfirm={() => handleCloseSuccesstModal()}
            />
        </>
    )

}
export default AnalyticsDashboard1