import React from "react";
import {
    Col, FormGroup,
    Card, Form
} from 'reactstrap';
import { Elastic } from 'react-burgers';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import MicIcon from '@material-ui/icons/Mic';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';

import ImageIcon from '@material-ui/icons/Image';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import avatar1 from '../../assets/utils/images/avatars/singleuser.jpg';
import { useSelector } from "react-redux";
import "./chat.css"
import CircularProgress from "@material-ui/core/CircularProgress";
import MediaChat from './MediaChat/Index'
import Overlay from './PreviewOverlay'
// import { uploadMediaFiles } from '../../redux/Actions/new-action'
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import { Tooltip } from '@material-ui/core';

function ChatArea({ setActive, active, show_attachment_div, setAttachmentDiv, chat_name, chat_data, chat_next_page, LoadMore, HandleMessage, message, SendMessage, handleOpenPreview, handleUpload, handleClosePreview, Preview, handleFileSelect, HandleAudioRecord, record, StopRecord, recoreded_data, RemoveRecordData, SendAudio, formatTime, Playing, IsPlaying, EndPauseHandle }) {

    const loader = useSelector(state => state.new_action.chat_details_loader ? state.new_action.chat_details_loader : false)

    return (
        <Card className="app-inner-layout__content">
            <div className="app-inner-layout__top-pane">
                <div className="pane-left">
                    <div className="mobile-app-menu-btn">
                        <Elastic
                            width={26}
                            lineHeight={2}
                            lineSpacing={5}
                            color='#6c757d'
                            active={active}
                            onClick={setActive} />
                    </div>
                    <div className="avatar-icon-wrapper mr-2">

                        <div className="avatar-icon avatar-icon-xl rounded">
                            <img width={82} src={avatar1} alt="" />
                        </div>
                    </div>
                    <h4 className="mb-0 text-nowrap">
                        {chat_name.length > 65 ? chat_name.slice(0, 62) + '...' : chat_name}
                    </h4>
                </div>

            </div>
            <div className="wrap-chat-main wrap_chat_height_style" style={{ overflowY: 'scroll' }}>

                {
                    chat_next_page ?
                        <div style={{ width: '100%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                            {
                                loader ?
                                    <CircularProgress size={20} style={{ marginTop: '20px' }} />
                                    :
                                    <span style={{ cursor: 'pointer', color: 'blue', }} onClick={LoadMore}>Load More..</span>
                            }
                        </div>
                        :
                        ''
                }


                {
                    chat_data.length > 0 ? chat_data.map((val, ky) => {
                        if (val.is_right) {
                            if (val.type === "text") {
                                return (
                                    <div key={ky} style={{ width: '100%', textAlign: 'right' }} >
                                        <div className="chat-box-wrapper chat-box-wrapper-right" style={{ wordBreak: 'break-word', display: 'flex', justifyContent: 'flex-end' }}>
                                            <div>
                                                <div className="chat-box" style={{ background: '#F8FBFF' }}>
                                                    {val.message}
                                                </div>
                                                <small className="opacity-6">
                                                    {val.created_time}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div key={ky} style={{ width: '100%', textAlign: 'right' }} >
                                        <div className="chat-box-wrapper chat-box-wrapper-right" style={{ wordBreak: 'break-word', display: 'flex', justifyContent: 'flex-end' }}>
                                            <div>
                                                <MediaChat
                                                    val={val}
                                                    Playing={Playing}
                                                    EndPauseHandle={EndPauseHandle}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                        else {
                            if (val.type === "text") {
                                return (
                                    <div key={ky} className="chat-box-wrapper" style={{ wordBreak: 'break-word' }}>
                                        <div>
                                            <div className="chat-box" style={{ alignItems: 'center', background: '#F8FBFF' }}>
                                                {val.message}
                                            </div>
                                            <small className="opacity-6">
                                                {val.created_time}
                                            </small>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div key={ky} className="chat-box-wrapper" style={{ wordBreak: 'break-word' }}>
                                        <div>
                                            <MediaChat
                                                val={val}
                                                Playing={Playing}
                                                EndPauseHandle={EndPauseHandle}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                        }
                    })
                        :
                        <div style={{ width: '100%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                            {loader ? "" : <span>No chat history</span>}
                        </div>
                }

                <div id='for_scroll' style={{ width: '100%', height: '75px' }}></div>
            </div>


            <div className="app-inner-layout__bottom-pane d-block text-center" style={{ padding: '0px' }}>
                <Form>
                    <FormGroup className="mb-0" row>
                        <Col xs={9} sm={10}>
                            {/* controlling message input area when recording or record data is there */}
                            {
                                record || recoreded_data ?
                                    <>
                                        {/* controlling record icon when recording or record data is there */}
                                        {
                                            recoreded_data ?
                                                <div className='send_input'>
                                                    <audio style={{ height: '30px', width: '100%', marginTop: '5px' }} src={recoreded_data} controls="controls" />
                                                </div>
                                                :
                                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', marginTop: '5px' }}>
                                                    {formatTime()} recording...<CircularProgress color="error" size={15} />
                                                </div>
                                        }
                                    </>

                                    :
                                    <form onSubmit={SendMessage}>
                                        <div className='send_input'>
                                            <input
                                                type='text'
                                                value={message}
                                                onChange={HandleMessage}
                                            ></input>
                                            <Button
                                                size='small'
                                                disabled={!message}
                                                type='submit'
                                            >
                                                <SendIcon fontSize='small' style={{ color: '#C0C0C0', cursor: 'pointer' }} />
                                            </Button>

                                        </div>
                                    </form>
                            }

                        </Col>
                        <Col xs={3} sm={2} style={{ textAlign: 'left' }}>
                            <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-around' }}>

                                {
                                    recoreded_data ?
                                        <>
                                            <Tooltip title='Delete'>
                                                <Button>
                                                    <CloseIcon
                                                        fontSize="small"
                                                        style={{ cursor: 'pointer', color: 'red' }}
                                                        onClick={RemoveRecordData}
                                                    />
                                                </Button>
                                            </Tooltip>
                                            <Button
                                                size='small'
                                                onClick={SendAudio}
                                                type='submit'
                                                color='primary'
                                                variant="contained"
                                            >
                                                <SendIcon fontSize='small' style={{ color: '#C0C0C0', cursor: 'pointer' }} />
                                            </Button>
                                        </>

                                        :
                                        <>
                                            {
                                                record ?
                                                    <Tooltip title='Stop recording'>
                                                        <StopIcon style={{ cursor: 'pointer', color: 'red' }} onClick={StopRecord} />
                                                    </Tooltip>
                                                    :
                                                    <>
                                                        {
                                                            !IsPlaying &&
                                                            <MicIcon
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={HandleAudioRecord}
                                                            />
                                                        }

                                                    </>
                                            }
                                        </>
                                }


                                {
                                    record || recoreded_data ?
                                        null
                                        :
                                        <>
                                            {
                                                show_attachment_div ?
                                                    <CloseIcon
                                                        style={{ cursor: 'pointer', position: 'relative' }}
                                                        onClick={setAttachmentDiv}
                                                    />
                                                    :
                                                    <AttachFileIcon
                                                        style={{ cursor: 'pointer', position: 'relative' }}
                                                        onClick={setAttachmentDiv}
                                                    />
                                            }
                                        </>
                                }

                                <div className={show_attachment_div ? 'attachment_div' : "attachment_div_hide"}>

                                    <div className='attachment_item'>
                                        <label for='image-file' >
                                            <ImageIcon />
                                        </label>
                                        <input onClick={(e) => e.target.value = ''} onChange={(e) => handleFileSelect('image', e.target.files[0])} class='hidden' type='file' id='image-file' accept="image/png, image/gif, image/jpeg" ></input>
                                    </div>

                                    <div className='attachment_item'>
                                        <label for='video-file' >
                                            <YouTubeIcon />
                                        </label>
                                        <input onClick={(e) => e.target.value = ''} onChange={(e) => handleFileSelect('video', e.target.files[0])} class='hidden' type='file' id='video-file' accept="video/mp4,video/x-m4v,video/*" ></input>
                                    </div>


                                    <div className='attachment_item'>
                                        <label for='audio-file' >
                                            <LibraryMusicIcon />
                                        </label>
                                        <input onClick={(e) => e.target.value = ''} onChange={(e) => handleFileSelect('audio', e.target.files[0])} class='hidden' type='file' id='audio-file' accept=".mp3,audio/*" ></input>
                                    </div>

                                    <div className='attachment_item'>
                                        <label for='doccument-file'>
                                            <InsertDriveFileIcon />
                                        </label>
                                        <input onClick={(e) => e.target.value = ''} onChange={(e) => handleFileSelect('document', e.target.files[0])} class='hidden' type='file' id='doccument-file' accept="application/msword, application/vnd.ms-excel,application/vnd.ms-powerpoint, application/vnd.ms-powerpoint, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv" ></input>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </FormGroup>
                </Form>
            </div>

            {Preview.active &&
                <Overlay
                    type={Preview.type}
                    file={Preview.file}
                    handleClose={handleClosePreview}
                    handleUpload={handleUpload}

                />
            }

        </Card>
    )
}

export default ChatArea
