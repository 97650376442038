import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { validateStartExam } from '../../redux/Actions/exam'
import TextEditor from "./textEditor";
import TextEditor2 from "./textEditorDraft";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { Col, Row, Card, Button, Input, Form, Label } from "reactstrap";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import Badge from "reactstrap/lib/Badge";
import Loader from "react-loaders";

const QuestionAndOptions = (props) => {
  const {
    question_type,
    questionLength,
    correctAnswer,
    deleteStatus,
    deleteQuestion,
    save,
    score,
    mark_structure_id,
    question_id,
    options,
    prev,
    next,
    handleClick,
    component_type,
    question,
    questionNumber,
    mark,
    SavedAnswer,
    editQuestion,
  } = props;
  const examStore = useSelector(({ exam }) => exam);
  const aStore = useSelector(({ answers }) => answers);
  console.log("store at quest and options", examStore.review_marked);
  // const [Ans, setAns] = useState()

  const [MarkInput, setMarkInput] = useState(0);
  const [InputField, setInputField] = useState();
  const [Required, setRequired] = useState(false);

  console.log("current question length", questionLength);
  console.log("current question number", questionNumber);
  console.log("current question", question);
  // console.log("stor of",sStore.subAnswer);
  // console.log("stor of quest",questionNumber);
  //console.log("stor",sStore?.subAnswer[questionNumber]);

  const markReview = (Qno) => {
    console.log("question numb", questionNumber);
    dispatch({
      type: "MARK_REVIEW",
      payload: {
        Qno: questionNumber,
      },
    });
  };
  const handleMarkChange = (e) => {
    setMarkInput(e.target.value);
  };

  console.log("ip field", InputField);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("props changed");
  }, [SavedAnswer]);

  const handleObjAnswer = (option) => {
    if (aStore.subAnswer[questionNumber]?.answer === option[0]) {
      dispatch({
        type: "ADD_LOCAL_ANSWER",
        payload: { question_no: questionNumber, answer: "", type: "objective" },
      });
    } else {
      dispatch({
        type: "ADD_LOCAL_ANSWER",
        payload: {
          question_no: questionNumber,
          answer: option[0],
          type: "objective",
        },
      });
    }
    // console.log("already saved",saved)
  };

  //    const [Answer, setAnswer] = useState(EditorState.createWithContent(
  //        ContentState.createWithContent(
  //         ContentState.createFromBlockArray(
  //             convertFromHTML(SavedAnswer ? SavedAnswer : '')
  //         )
  //        )
  //    ));
  // console.log("saved answer",examStore.savedLocalAnswer)
  // console.log("saved answer",examStore.savedLocalAnswer[questionNumber])
  // console.log("saved question_number",questionNumber)

  //console.log("saved answer for  ",examStore.savedLocalAnswer[question_id-3])

  // var result = examStore.savedLocalAnswer.find(obj => {
  //     return obj[] === 6
  //   })

  const [Answer, setAnswer] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(
          SavedAnswer
            ? SavedAnswer
            : aStore.subAnswer[questionNumber]
            ? aStore.subAnswer[questionNumber]["answer"]
            : ""
        )
        // convertFromHTML(SavedAnswer ? SavedAnswer :"" )
        // convertFromHTML(examStore ? examStore.savedLocalAnswer[question_id-3] : SavedAnswer)
      )
    )
  );

  // const onEditorStateChange = (editorState) => {
  //
  //     setState({
  //         editorState,
  //     });

  //     // handleAnswer(editorValue, question_type)
  // };

  const onEditorStateChange = (editorState) => {
    setAnswer(editorState);
    let svd = draftToHtml(convertToRaw(Answer.getCurrentContent()));
    dispatch({
      type: "ADD_LOCAL_ANSWER",
      payload: { question_no: questionNumber, answer: svd, type: "subjective" },
    });
  };

  const getAnswer = () => {
    if (question_type === "subjective") {
      const data = {
        question_id: question_id,
        savedAnswer: draftToHtml(convertToRaw(Answer.getCurrentContent())),
        question_type: question_type,
      };
      return data;
    } else {
      const data = {
        question_id: question_id,
        //  savedAnswer: SavedAnswer,
        savedAnswer: aStore?.subAnswer[questionNumber]
          ? aStore?.subAnswer[questionNumber].answer
          : false,
        question_type: question_type,
      };
      return data;
    }
  };
  //const { editorState } = state;
  console.log("savd", SavedAnswer);
  return (
    <div style={{ padding: "20px 0px 20px 0px" }}>
      <Row>
        {props.loader ? (
          <Loader color="#f7b924" type="ball-rotate" />
        ) : (
          <Col md={10}>
            <div className="exam-question-font">
              {" "}
              {`${questionNumber} . ${question}`}
            </div>
          </Col>
        )}
        {component_type === "question" && !props.loader && (
          <Col md={2}>
            <div className="exam-question-font">
              {" "}
              <p>Mark : {mark}</p>
            </div>
          </Col>
        )}
        {component_type === "list_questions" && (
          <Col md={2}>
            <div className="exam-question-font">
              {" "}
              <p>Mark : {mark}</p>
            </div>
          </Col>
        )}

        {component_type === "answer" && (
          <Col md={2}>
            <div className="exam-question-font">
              <p
                style={
                  question_type === "objective"
                    ? correctAnswer && correctAnswer === SavedAnswer
                      ? { fontWeight: 700, color: "green" }
                      : !SavedAnswer
                      ? { fontWeight: 700, color: "#f3b011" }
                      : { fontWeight: 700, color: "red" }
                    : !SavedAnswer
                    ? { fontWeight: 700, color: "#f3b011" }
                    : { fontWeight: 700, color: "green" }
                }
              >
                {question_type === "objective"
                  ? correctAnswer && correctAnswer === SavedAnswer
                    ? "Correct"
                    : !SavedAnswer
                    ? "Not Answered"
                    : "Wrong"
                  : !SavedAnswer
                  ? "Not Answered"
                  : "Answered"}{" "}
                <span> </span>
              </p>
            </div>
          </Col>
        )}
      </Row>

      {question_type === "subjective" && component_type === "question" && (
        // <TextEditor
        //     SavedAnswer={SavedAnswer}
        // />
        // <TextEditor2
        //     SavedAnswer={SavedAnswer}
        //     handleAnswer={handleAnswer}
        //     question_type={question_type}
        //     state={state}
        //     onEditorStateChange={onEditorStateChange}

        // />

        <Row>
          <Col md="12">
            <Editor
              // toolbarHidden

              editorState={Answer}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
            />
          </Col>
        </Row>
      )}
      {question_type === "objective" && component_type === "question" && (
        <Row className="p-4">
          {options?.map((option) => (
            <Col md={6} className="p-2">
              <Button
                className="mb-2 mr-2 btn-pill "
                disabled={
                  aStore.subAnswer[questionNumber]?.remote || SavedAnswer
                }
                onClick={() =>
                  handleObjAnswer(
                    Object.keys(option),
                    questionNumber,
                    question_type
                  )
                }
                color={`${
                  SavedAnswer == Object.keys(option)
                    ? "success"
                    : aStore.subAnswer[questionNumber]?.remote &&
                      aStore.subAnswer[questionNumber].answer ===
                        Object.keys(option)[0]
                    ? "success"
                    : aStore.subAnswer[questionNumber]?.answer ==
                      Object.keys(option)
                    ? "warning"
                    : "light"
                }`}
                style={{ width: "-webkit-fill-available", textAlign: "left" }}
              >
                {`${Object.keys(option)}.${option[Object.keys(option)]}`}
              </Button>
            </Col>
          ))}
        </Row>
      )}
      {(component_type === "list_questions" || component_type === "review") &&
        question_type === "objective" && (
          <Row className="p-4">
            {options?.map((option) => (
              <Col md={6} className="p-2">
                <Button
                  onClick={() => {
                    console.log("no action");
                  }}
                  className="mb-2 mr-2 btn-pill"
                  color={`${
                    correctAnswer == Object.keys(option)
                      ? "success"
                      : SavedAnswer == Object.keys(option)
                      ? "danger"
                      : "light"
                  }`}
                  style={{ width: "-webkit-fill-available", textAlign: "left" }}
                >
                  {`${Object.keys(option)}.${option[Object.keys(option)]}`}
                </Button>
              </Col>
            ))}
          </Row>
        )}

      {question_type === "objective" && component_type === "answer" && (
        <Row className="p-4">
          {options?.map((option) => (
            <Col md={6} className="p-2">
              <Button
                onClick={() => {
                  console.log("no action");
                }}
                className="mb-2 mr-2 btn-pill"
                color={`${
                  SavedAnswer == Object.keys(option) ? "success" : "light"
                }`}
                style={{ width: "-webkit-fill-available", textAlign: "left" }}
              >
                {`${Object.keys(option)}.${option[Object.keys(option)]}`}
              </Button>
            </Col>
          ))}
        </Row>
      )}

      {question_type === "subjective" && component_type === "answer" && (
        <Row className="p-4">
          <Col md={12}>
            {SavedAnswer ? (
              <div
                class="subjective"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  paddingRight: "10px",
                }}
                dangerouslySetInnerHTML={{ __html: SavedAnswer }}
              />
            ) : (
              <h4 style={{ color: "red", paddingTop: "10px" }}>Not Attended</h4>
            )}
          </Col>
        </Row>
      )}
      {component_type === "answer" && (
        <Row>
          <Col md={10}> </Col>
          <Col md={2}>Scored : {`${score}/${mark}`}</Col>
        </Row>
      )}
      {//component_type === "review" && question_type === "objective" && <Row><Col md={9}> </Col><Col md={3}><Badge pill color="warning"> {correctAnswer == SavedAnswer ? mark : 0} MARKS</Badge> <Input type="hidden"  required name={`${question_id}`} value={correctAnswer == SavedAnswer ? mark : 0}  /><span></span></Col></Row>
      component_type === "review" && question_type === "objective" && (
        <Row>
          <Col md={6}> </Col>
          <Col className="pt-4" md={3}>
            {SavedAnswer ? (
              SavedAnswer === correctAnswer ? (
                <h4 style={{ color: "green" }}>Correct Answer</h4>
              ) : (
                <h4 style={{ color: "red" }}>Wrong Answer</h4>
              )
            ) : (
              <h4 style={{ color: "red" }}>Not Attended</h4>
            )}
          </Col>
          <Col md={3}>
            Marks:
            <Input
              id={question_id}
              onInvalid={() => {
                setRequired(true);
                var input = document.getElementById(`${question_id}`);
                input
                  ? input.classList.add("input-wrong")
                  : console.log("null");
              }}
              type="number"
              onChange={handleMarkChange}
              required
              name={`${question_id}`}
              value={
                SavedAnswer === null
                  ? MarkInput
                  : SavedAnswer === correctAnswer
                  ? mark
                  : MarkInput
              }
            />
            <span style={{ color: "red" }}>{Required ? "Enter mark" : ""}</span>
          </Col>
        </Row>
      )}
      {component_type === "review" && question_type === "subjective" && (
        <Row>
          <Col md={9}>
            {" "}
            {SavedAnswer ? (
              <div
                class="subjective"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  paddingRight: "10px",
                }}
                dangerouslySetInnerHTML={{ __html: SavedAnswer }}
              />
            ) : (
              <h4 style={{ color: "red", paddingTop: "10px" }}>Not Attended</h4>
            )}
          </Col>
          <Col md={3}>
            {" "}
            <Input
              id={question_id}
              onInvalid={() => {
                setRequired(true);
                var input = document.getElementById(`${question_id}`);
                input
                  ? input.classList.add("input-wrong")
                  : console.log("null");
              }}
              step="0.5"
              required
              name={`${question_id}`}
              type="number"
              max={mark}
              min={0}
              placeholder={`Mark out of ${mark}`}
            />
            <span style={{ color: "red" }}>{Required ? "Enter mark" : ""}</span>
          </Col>
        </Row>
      )}
      {component_type === "list_questions" && (
        <Row>
          <Col md={8}> </Col>{" "}
          {!deleteStatus ? (
            <Col md={4}>
              <Button
                className="mr-4 bg-primary"
                onClick={() => editQuestion(question_id, question_type)}
              >
                {" "}
                <FontAwesomeIcon color="white" icon={faEdit} />
                Edit{" "}
              </Button>
              <Button
                className="bg-danger"
                onClick={() => deleteQuestion(question_id, mark_structure_id)}
              >
                {" "}
                <FontAwesomeIcon color="white" icon={faTrashAlt} /> Delete{" "}
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
      )}
      {component_type === "question" && (
        <>
          <hr />
          <div style={{ textAlign: "center" }}>
            <Button
              className="mb-2 mr-2 btn-pill"
              color="secondary"
              onClick={prev}
            >
              {" "}
              &lt; Prev
            </Button>
            <Button
              className="mb-2 mr-2 btn-pill"
              color="info"
              onClick={(questionNumber) => markReview(questionNumber)}
            >
              Mark For Review
            </Button>
            <Button
              className="mb-2 mr-2 btn-pill"
              //color={questionLength === questionNumber ? "success" : "success"}
              color="success"
              onClick={() => save(getAnswer())}
            >
              {questionLength === questionNumber
                ? "Save & Finish"
                : "Save & Next"}{" "}
            </Button>
            <Button
              className="mb-2 mr-2 btn-pill"
              disabled={questionLength === questionNumber ? true : false}
              color="secondary"
              onClick={next}
            >
              {" "}
              Next &gt;
            </Button>
          </div>
          {/* <div style={{ textAlign: "right" }}>
            <Row style={{ textAlign: "right" }}>
               <Button className="mt-3 mr-1">Mark to review</Button> 
              <p>Mark for review</p>
              <Input id="review" type="checkbox" />
            </Row>
          </div> */}
        </>
      )}
    </div>
  );
};
//  color={`${aStore.subAnswer[questionNumber]?.remote?"success":aStore.subAnswer[questionNumber]?.answer == Object.keys(option) ? "warning" : SavedAnswer == Object.keys(option) ? "success" : "light"}`}
export default QuestionAndOptions;
