import React, { useState, useEffect } from 'react'
import Loader from 'react-loaders'
import { useDispatch } from 'react-redux'


import {
    Row, Col,
    Card, CardBody, CardTitle,
    CardHeader, CardFooter, Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    CustomInput,
    FormGroup, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AddStudentToClass = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {

        return () => {
            dispatch({ type: 'RESET_CLASS_TIME' })
        }
    }, [])

    return (


        <Card className="main-card mb-3">
            <CardHeader>
                <Col md={5} sm={5}>
                    Day
                    </Col>
                <Col md={4} sm={4}>
                    From Time
                    </Col>
                <Col md={3} sm={3}>
                    To Time
                    </Col>
            </CardHeader>
            <CardBody>
                <div className="scroll-area-sm">
                    <PerfectScrollbar>
                        {
                            props.loader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                        }
                        {props.data ? props.data.map(dt => (
                            <Row key={dt.user_id}>
                                <Col md={5} sm={5} style={{ paddingLeft: '30px' }}>
                                    {`${dt.day} `}
                                </Col>
                                <Col md={4} sm={4} style={{ paddingLeft: '20px' }}>
                                    {`${dt.time_from} `}
                                </Col>
                                <Col md={3} sm={3} style={{ paddingLeft: '20px' }}>
                                    {`${dt.time_to}     `}
                                </Col>

                            </Row>

                        )) : null}

                    </PerfectScrollbar>
                </div>
            </CardBody>

        </Card>

    )
}

export default AddStudentToClass
