import React, { useEffect, useState } from 'react'
import PageTitle from '../common/HeaderTitle/PageTitle'
import { Card, Col, Row, Input, Label, FormFeedback } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Table from '../common/DataTable'
import { populateGroup, createGroup, deleteGroup, editGroup, getGroupAssignedTeachers, populateGroupStudents, getAllUsersInGroup, addParticipantToGroup } from '../../redux/Actions/grouping'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import AssignTeacher from './assignTeacher'
import Modals from '../common/Modal'
import Swal from 'sweetalert2'
import ListStudent from './ListStudent'
import { requiredValidator } from '../../utilities/Validator';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, Chip, Checkbox, TextField, InputAdornment } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
//import LaddaButton, { ZOOM_IN } from "react-ladda";
import SearchIcon from '@material-ui/icons/Search';

const Index = () => {


    const columns = [{ title: 'Group ', field: 'group_name', editable: 'onUpdate' },];
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const groupStore = useSelector(({ grouping }) => grouping)
    useEffect(() => {

        dispatch(populateGroup(tokenData.token))

        return () => {

        }
    }, [])

    console.log("groupStore", groupStore)
    const onEdit = (newData) => {

        console.log("newData", newData)
        if (!requiredValidator(newData.group_name)) {
            dispatch(editGroup(tokenData.token, newData))

        } else {
            Swal.fire(
                'Required',
                requiredValidator(newData.group_name),
                'warning'
            )
        }

    }
    const onDelete = (id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteGroup(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }
    const [assignStudent, setAssignStudent] = useState(false)
    const closeAssignStudent = () => {
        dispatch({ type: 'RESET_ASSIGN_TEACHERS_LIST' })
        setAssignStudent(false)
    }

    const [SelectedAll, setSelectedAll] = useState([])

    const handleAssignStudent = (id) => {
        // alert("Group-ID", id)
        // console.log("group_id", id)
        setGroupID(id)
        //  dispatch(populateGroupStudents(tokenData.token, id, 'test'))
        dispatch(getAllUsersInGroup(tokenData.token, id, cb2))
        setAssignStudent(true)

    }

    const cb2 = (data) => {

        let arr = [...data]
        let selected = arr.filter(val => val.is_selected)

        setSelectedAll(selected)
    }

    const [AssignMembers, setAssignMembers] = useState(false);

    const [assignedlist, setAssignedList] = useState([])
    const [assignedlist_compare, setAssignedListCompare] = useState([])
    const [full_userlist, setFullUserList] = useState([])
    const [full_userlist_copy, setFullUserListCopy] = useState([])

    const [NewSelected, setNewSelected] = useState([])
    const [UnSelected, setUnSelected] = useState([])


    const handleAssignMembers = (id) => {
        //  alert("Group-ID", id)
        // console.log("group_id", id)
        setGroupID(id)
        setLoader(true)
        dispatch(getAllUsersInGroup(tokenData.token, id, cb))
        setAssignMembers(true)



    }

    const [AssignLoader, setAssignLoader] = useState(false);

    const SubmitAssignParticipants = () => {
        setAssignLoader(true);
        dispatch(addParticipantToGroup(tokenData.token, student_selected, student_unselected, others_selected, others_unselected, groupID, cbsuccess))
    }

    const cbsuccess = (msg) => {
        setAssignMembers(false)
        setAssignLoader(false)
        Swal.fire('Updated', 'Group Members Updated', 'success')
        setFullUserList([])
        setStudentSelected([])
        setOthersSelected([])
        setStudentUnSelected([])
        setOthersUnSelected([])
        setAssignedListCompare([])
        setAssignedList([])
        setGroupID('')
    }

    const [Loader, setLoader] = useState(false);

    const cb = (data) => {
        setLoader(false)
        setFullUserList(data)
        setFullUserListCopy(data)
        let arr = [...data]
        let selected = arr.filter(val => val.is_selected)
        setAssignedListCompare(selected)
        setAssignedList(selected)

        let student = []
        let others = []
        for (let i of selected) {
            // selected_id.push(i) 
            if (i.role_name === 'student') {
                student.push(i.user_id)
            }
            else {
                others.push(i.user_id)
            }
        }
        // setStudentSelected(student)
        // setOthersSelected(others)

    }


    // const HandleUser = (val, e) => {

    //     if (e.target.checked) {
    //         let assigned = [...assignedlist, val]
    //         setAssignedList(assigned)


    //         if (!assignedlist_compare.includes(val)) {
    //             let student = [...NewSelected, val.user_id]
    //             setNewSelected(student)

    //         }
    //         if (UnSelected.includes(val.user_id)) {
    //             let filtered = UnSelected.filter(i => i !== val.user_id)
    //             setUnSelected(filtered)
    //         }


    //     } else {
    //         let filter = assignedlist.filter(i => i.user_id !== val.user_id)
    //         setAssignedList(filter)
    //         if (assignedlist_compare.includes(val)) {
    //             setUnSelected([...UnSelected, val.user_id])
    //         }
    //         if (NewSelected.includes(val.user_id)) {
    //             let filtered = NewSelected.filter(i => i !== val.user_id)
    //             setNewSelected(filtered)
    //         }

    //     }

    // }

    const [student_selected, setStudentSelected] = useState([])
    const [student_unselected, setStudentUnSelected] = useState([])

    const [others_selected, setOthersSelected] = useState([])
    const [others_unselected, setOthersUnSelected] = useState([])


    const HandleUser = (val, e) => {
        if (e.target.checked) {
            let assigned = [...assignedlist, val]
            setAssignedList(assigned)
            if (val.role_name === 'student') {
                let student = [...student_selected, val.user_id]
                setStudentSelected(student)
                if (student_unselected.includes(val.user_id)) {
                    let filtered = student_unselected.filter(i => i !== val.user_id)
                    setStudentUnSelected(filtered)
                }
            } else {
                let others = [...others_selected, val.user_id]
                setOthersSelected(others)
                if (others_unselected.includes(val.user_id)) {
                    let filtered = others_unselected.filter(i => i !== val.user_id)
                    setOthersUnSelected(filtered)
                }
            }


        } else {
            let filter = assignedlist.filter(i => i.user_id !== val.user_id)
            setAssignedList(filter)
            if (assignedlist_compare.includes(val)) {
                if (val.role_name === 'student') {
                    setStudentUnSelected([...student_unselected, val.user_id])
                } else {
                    setOthersUnSelected([...others_unselected, val.user_id])
                }
            }

            if (val.role_name === 'student') {
                if (student_selected.includes(val.user_id)) {
                    let filtered = student_selected.filter(i => i !== val.user_id)
                    setStudentSelected(filtered)
                }
            } else {
                if (others_selected.includes(val.user_id)) {
                    let filtered = others_selected.filter(i => i !== val.user_id)
                    setOthersSelected(filtered)
                }
            }
        }

    }

    // const handleRemove = (item) => {

    //     let filter = assignedlist.filter(val => val.user_id !== item.user_id)
    //     setAssignedList(filter)

    //     if (assignedlist_compare.includes(item)) {
    //         setUnSelected([...UnSelected, item.user_id])
    //     }
    //     if (NewSelected.includes(item.user_id)) {
    //         let filtered = NewSelected.filter(i => i !== item.user_id)
    //         setNewSelected(filtered)
    //     }
    // }

    const handleRemove = (item) => {

        let filter = assignedlist.filter(val => val.user_id !== item.user_id)
        setAssignedList(filter)
        if (assignedlist_compare.includes(item)) {
            if (item.role_name === 'student') {
                setStudentUnSelected([...student_unselected, item.user_id])
            } else {
                setOthersUnSelected([...others_unselected, item.user_id])
            }
        }

        if (item.role_name === 'student') {
            if (student_selected.includes(item.user_id)) {
                let filtered = student_selected.filter(i => i !== item.user_id)
                setStudentSelected(filtered)
            }
        } else {
            if (others_selected.includes(item.user_id)) {
                let filtered = others_selected.filter(i => i !== item.user_id)
                setOthersSelected(filtered)
            }
        }
    }

    const [inputField, setInputField] = useState({
        group_name: ''
    })
    const handleChange = (e) => {
        const { value, name } = e.target
        setInputField({ ...inputField, [name]: value })
    }
    const [errors, setErrors] = useState({})
    const formSubmit = (e) => {
        const err = {
            group_name: requiredValidator(inputField.group_name)
        }
        e.preventDefault()
        if (!err.group_name) {
            dispatch(createGroup(tokenData.token, inputField, resetForm))
            setErrors(err)
        } else {
            setErrors(err)

        }
    }
    const [assignPopup, setAssignPopup] = useState(false)
    const [groupID, setGroupID] = useState()
    const handleAssignTeacher = (id) => {
        //  alert("Group-ID", id)
        console.log("group_id", id)
        setGroupID(id)
        dispatch(getGroupAssignedTeachers(tokenData.token, id))
        setAssignPopup(true)

    }
    const closeAssignPopup = () => {
        dispatch({ type: 'RESET_ASSIGN_TEACHERS_LIST' })
        setAssignPopup(false)

    }
    const resetForm = () => {
        setInputField({ group_name: '' })
        document.getElementById("group-form").reset();
    }

    const handleClose = () => {
        setAssignMembers(false)
        // setNewSelected('')
        // setUnSelected('')
        setAssignLoader(false)
        setFullUserList([])
        setStudentSelected([])
        setOthersSelected([])
        setStudentUnSelected([])
        setOthersUnSelected([])
        setAssignedListCompare([])
        setAssignedList([])
    }

    const SearchUsers = (e) => {
        if (e.target.value) {
            let data = [...full_userlist_copy]
            let filter = data.filter(i => i.full_name.toUpperCase().includes(e.target.value.toUpperCase()))
            setFullUserList(filter)
        } else {
            setFullUserList(full_userlist_copy)
        }
    }

    return (
        <>
            <PageTitle

                heading="Create Group"
                subheading=""
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
            />
            <Card style={{ padding: 20 }}>
                <h6>Group</h6><hr />
                <form onSubmit={formSubmit} id="group-form">
                    <Row >
                        <Col md={2}>

                        </Col>
                        <Col md={5}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Group Name
                            </Label>

                            <Input
                                type="text"
                                name="group_name"
                                // required
                                value={inputField.group_name}
                                onChange={handleChange}
                                invalid={errors.group_name}
                            />
                            <FormFeedback >{errors.group_name}</FormFeedback>

                        </Col>
                        <Col md={2}>
                            <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                <span className="text-danger">*</span>
                                {' '}Group Name
                            </Label>
                            <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                loading={groupStore.createGroupLoader}
                                type="submit"
                                data-style={ZOOM_IN}  >
                                CREATE GROUP
                            </LaddaButton>
                        </Col>
                        <Col md={3}>

                        </Col>
                    </Row>
                </form>
            </Card>

            <h6 style={{ paddingTop: 20 }}>List of Groups</h6>
            <hr />
            <Table
                data={groupStore.groupList}
                loader={groupStore.groupListLoader}
                columns={columns}
                onEdit={onEdit}
                onDelete={onDelete}
                handleAssignTeacher={handleAssignTeacher}
                title={'Groups'}
                handleAssignStudent={handleAssignStudent}
                // handleSelectedRow={handleSelectedRow}
                handleAssignMembers={handleAssignMembers}
                actions={"create_group"}
            />
            <Modals
                open={assignPopup}
                handleClose={closeAssignPopup}
                title={`Assign teacher`}
            >
                <AssignTeacher
                    loader={groupStore.assignTeahersLoader}
                    groupID={groupID}
                    data={groupStore.assignTeahersList}
                    handleCloseStudentModal={closeAssignPopup}

                />
            </Modals>
            <Modals
                open={assignStudent}
                handleClose={closeAssignStudent}
                title={`Participants list`}
            >
                {groupStore.AllUsersInGroup ? 'Loading...' :
                    <ListStudent
                        loader={groupStore.destinationGroupStudentsLoader}
                        groupID={groupID}
                        // data={groupStore.destinationGroupStudents}
                        data={SelectedAll}
                        handleCloseStudentModal={closeAssignPopup}

                    />
                }
            </Modals>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={AssignMembers}
                // onClose={CloseAddUserModel}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle style={{ textAlign: 'center', padding: '10px 20px' }}>
                    Add Members
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Row>
                        <Col xs={12}>
                            <div className='wrap-chat-main' style={{ height: '100px', overflowY: 'auto', width: '100%', border: '1px solid #777', borderRadius: '8px', padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word', margin: '5px 0px' }}>
                                {

                                    assignedlist.map((val, ky) => {
                                        return (
                                            <Chip
                                                key={ky}
                                                label={val.full_name}
                                                onDelete={() => {
                                                    handleRemove(val)
                                                }}
                                                color='primary'
                                                style={{ marginLeft: '3px', marginTop: '3px' }}
                                                size='small'
                                            />
                                        )
                                    })
                                }

                            </div>
                        </Col>
                        <Col xs={6} style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
                            <span style={{ fontSize: '15px', fontWeight: '700' }}>Name</span>
                        </Col>
                        <Col xs={6} className='text-center'>
                            <TextField
                                size='small'
                                label='search'
                                // variant=''
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={SearchUsers}
                            />
                        </Col>
                        <Col xs={12}>


                            <div className='wrap-chat-main' style={{ height: '200px', overflowY: 'auto', width: '100%', wordWrap: 'break-word' }}>

                                {
                                    Loader ? <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>Loading...</div> : ''

                                }
                                {
                                    full_userlist.length > 0 ? full_userlist.map((val, ky) => {
                                        return (
                                            <div key={ky} style={{ padding: '0 1.5rem' }}>
                                                <Checkbox
                                                    checked={assignedlist.includes(val)}
                                                    color='primary'
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={(e) => { HandleUser(val, e) }}
                                                    size='small'
                                                />
                                                <span style={{ fontWeight: '500', color: 'black' }}>{val.full_name}</span>
                                                <span style={{ fontSize: '13px' }}> ({val.role_name === 'student' ? 'client' : val.role_name === 'teacher' ? 'therapist' : val.role_name === 'principal' ? "admin" : val.role_name})</span>
                                            </div>
                                        )
                                    })
                                        :
                                        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                                            {/* {!userlist_loader && "No users to list"} */}
                                        </div>
                                }


                            </div>


                        </Col>
                        <Col xs={12} style={{ textAlign: 'right', marginTop: '4px' }}>
                            <LaddaButton
                                data-style={ZOOM_IN}
                                className='btn btn-primary mr-1'
                                onClick={SubmitAssignParticipants}
                                loading={AssignLoader}
                            // disabled={(student_unselected.length === 0 && student_selected.length === 0 && others_selected.length === 0 && others_unselected === 0) ? true : false}
                            >
                                Assign
                            </LaddaButton>

                            <LaddaButton className='btn btn-danger'
                                onClick={handleClose}
                            >
                                Close
                            </LaddaButton>
                        </Col>
                    </Row>

                </DialogContent>
            </Dialog>

        </>
    )
}

export default Index
