import React from 'react'
import Admin from "./Users/Admin"
import Therapist from "./Users/Therapist"
import Client from "./Users/Client"
function Summery({ user, admin_val }) {
    return (
        <div className='menu-right'>
            <main>
                {
                    user.admin ?
                        <Admin admin_val={admin_val} />
                        : user.therapist ?
                            <Therapist admin_val={admin_val} />
                            :
                            <Client admin_val={admin_val}/>
                }
            </main>
        </div>
    )
}

export default Summery
