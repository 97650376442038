import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Index = (props) => {
    const { title, children, handleClose, open, size } = props;
    return (
        <span className="d-inline-block mb-2 mr-2">
            <Modal
                isOpen={open} toggle={handleClose}
                size={size ? size : 'md'}

            >
                <ModalHeader toggle={handleClose}>{title ? title : null}</ModalHeader>
                <ModalBody>
                    {children ? children : null}
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="link" onClick={handleClose}>Cancel</Button>
                    <Button color="primary" onClick={"this.toggle"}>Do Something</Button>{' '}
                </ModalFooter> */}
            </Modal>
        </span>

    )
}

export default Index
