import React, { useState, useEffect } from "react";
import {
  addStudentToClassroom,
  addStudentToEnterpriseClassroom,
} from "../../../redux/Actions/homeTeacher";
import { useDispatch, useSelector } from "react-redux";
import serialize from "form-serialize";
import LaddaButton, { ZOOM_IN } from "react-ladda";

import Swal from "sweetalert2";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  CustomInput,
  FormGroup,
  Form,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Loader from "react-loaders";

const AddStudentToClass = (props) => {
  const { selectedStudents, setSelectedStudents } = props;
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const dispatch = useDispatch();

  useEffect(() => {
    const data = tStore.specificStudent;
    console.log("qwerty", data);
    return () => {
      dispatch({ type: "RESET_ASSIGN_STUDENT_CLASS" });
      dispatch({ type: "RESET_GROUP_LIST" });
    };
  }, []);

  const tStore = useSelector(({ homeTeacher }) => homeTeacher);

  const handleChange = (user_id) => {
    // alert(user_id)
    if (!tokenData.enterpriseUser) {
      // alert(selectedStudents.length)
      if (selectedStudents.includes(user_id)) {
        const filtered = selectedStudents.filter((userId) => userId != user_id);
        console.log("after removed", filtered);
        setSelectedStudents(filtered);
      } else if (
        selectedStudents.length < tokenData.number_of_student ||
        tokenData.number_of_student == ""
      ) {
        setSelectedStudents([...selectedStudents, user_id]);

        dispatch({ type: "CHANGE_ASSIGN_STUDENT_TO_CLASS" });
      } else {
        // setSelectedStudents([...selectedStudents, user_id])

        dispatch({ type: "CHANGE_ASSIGN_STUDENT_TO_CLASS" });
        //alert("limit crossed")
        Swal.fire({
          icon: "error",
          title: "Limit exceeded",
          text: `Maximum number of student is ${tokenData.number_of_student}`,
        });
      }
    } else {
      if (!tokenData.enterpriseUser) {
        setSelectedStudents([...selectedStudents, user_id]);
      }

      dispatch({ type: "CHANGE_ASSIGN_STUDENT_TO_CLASS" });
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    console.log("props.timeData", props.timeData);

    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    // debugger

    if (tokenData.enterpriseUser) {
      dispatch(
        addStudentToEnterpriseClassroom(
          props.classId,
          body,
          tokenData.token,
          props.handleCloseStudentModal,
          props.timeData
        )
      );
    } else {
      dispatch(
        addStudentToClassroom(
          props.classId,
          body,
          tokenData.token,
          props.handleCloseStudentModal,
          props.timeData
        )
      );
    }
  };
  return (
    <Form onSubmit={handleClick}>
      <input name="class_id" type="hidden" value={props.classId} />
      <input
        name="teacher_id"
        type="hidden"
        value={props.data[0] ? props.data[0].teacher_id : null}
      />

      <Card className="main-card mb-3">
        <CardHeader>
          <Col md={5} sm={5}>
            {tokenData.enterpriseUser ? "Group" : "Student"}
          </Col>
          <Col md={7} sm={7}>
            {tokenData.enterpriseUser ? "" : "Phone"}
          </Col>
        </CardHeader>
        <CardBody>
          <div className="scroll-area-sm">
            <PerfectScrollbar>
              {props.loader ? (
                <p style={{ position: "absolute", top: "20%", left: "45%" }}>
                  <Loader color="#f7b924" type="ball-rotate" />{" "}
                </p>
              ) : null}

              {tStore.specificStudentLoader ? (
                <p style={{ position: "absolute", top: "20%", left: "45%" }}>
                  <Loader color="#f7b924" type="ball-rotate" />{" "}
                </p>
              ) : null}

              {props.data
                ? props.data.map((dt) => (
                    <Row key={dt.user_id ? dt.user_id : dt.group_id}>
                      <Col md={5} sm={5} style={{ paddingLeft: "30px" }}>
                        {dt.user_id ? dt.first_name : dt.group_name}{" "}
                        {dt.user_id ? dt.last_name : null}
                      </Col>
                      {!tokenData.enterpriseUser ? (
                        <Col md={5} sm={5} style={{ paddingLeft: "20px" }}>
                          {`${dt.user_id ? dt.mobile : null}`}
                        </Col>
                      ) : null}

                      <Col md={2} sm={2}>
                        <FormGroup>
                          <div>
                            <input
                              type="checkbox"
                              id="exampleCustomCheckbox"
                              name={
                                dt.user_id
                                  ? "registered_student[]"
                                  : "group_assign_id[]"
                              }
                              defaultChecked={
                                tokenData.enterpriseUser && dt.selected
                              }
                              checked={
                                !tokenData.enterpriseUser
                                  ? selectedStudents.includes(
                                      dt.user_id ? dt.user_id : dt.group_id
                                    )
                                    ? true
                                    : false
                                  : null
                              }
                              onChange={() =>
                                handleChange(
                                  dt.user_id ? dt.user_id : dt.group_id
                                )
                              }
                              value={dt.user_id ? dt.user_id : dt.group_id}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ))
                : null}
            </PerfectScrollbar>
          </div>
        </CardBody>
        <CardFooter className="d-block text-right">
          <LaddaButton
            className="mb-2 mr-2 btn btn-danger"
            loading={tStore.assignStudentLoader}
            // style={{ width: '-webkit-fill-available' }}

            type="button"
            data-style={ZOOM_IN}
            type="submit"
          >
            Save
          </LaddaButton>
          {/* <Button type="submit" size="lg" color="success">Save</Button> */}
        </CardFooter>
      </Card>
    </Form>
  );
};

export default AddStudentToClass;
