

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { IoIosAnalytics } from "react-icons/io";
import './timerStyle.scss'
import {
    Row, Col,
    Button,
    CardHeader,

    Card,
    CardFooter,
    Input,

} from 'reactstrap';
// import Loader from 'react-loaders'
import ClipLoader from "react-spinners/ClipLoader";

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            date: this.props.date
        }
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.state.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }
    preveProps = this.props.date
    //preveState=this.state.date

    // componentWillUpdate(prevProps) {
    //     console.log("prevState----", prevProps)
    //     if (prevProps.date !== this.props.date) {
    //         this.setState({
    //             days: 0,
    //             hours: 0,
    //             min: 0,
    //             sec: 0,
    //             date: this.props.date
    //         })
    //     }
    // }

    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
        // var deadline = new Date(Date.UTC(2020, 9, 21, 21, 59, 59));

        // let diff = deadline - new Date() / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    render() {

        const tokenData = JSON.parse(localStorage.getItem("authDetails"));
        const countDown = this.state;
        const { joinExam, exam_id, classname, loader, label } = this.props;

        var sc = this.addLeadingZeros(countDown.sec)
        var mn = this.addLeadingZeros(countDown.min)
        var hr = this.addLeadingZeros(countDown.hours)
        console.log({ loader });

        return (





            <>



                <Card className="mb-3">
                    <CardHeader className="card-header-tab z-index-6">
                        <div
                            className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                            {`${label}: ${classname}`}
                        </div>

                        <div id='sample-date'></div>


                    </CardHeader>
                    <Row className="no-gutters">
                        <Col sm="6" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-10 bg-info" />
                                    <i className="lnr-clock text-white opacity-8" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Day
                                </div>
                                    <div className="widget-numbers">
                                        {this.addLeadingZeros(countDown.days)}
                                    </div>

                                </div>
                            </div>
                            <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="6" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-10 bg-success" />
                                    <i className="lnr-clock  text-white opacity-8" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Hour
                                </div>
                                    <div className="widget-numbers">
                                        {this.addLeadingZeros(countDown.hours)}
                                    </div>

                                </div>
                            </div>
                            <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="6" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-9 bg-danger" />
                                    <i className="lnr-clock text-white" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Minutes
                                </div>
                                    <div className="widget-numbers">
                                        {this.addLeadingZeros(countDown.min)}

                                    </div>

                                </div>
                            </div>
                            <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="12" md="3" xl="3">
                            <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                                <div className="icon-wrapper rounded-circle">
                                    <div className="icon-wrapper-bg opacity-9 bg-success" />
                                    <i className="lnr-clock text-white" />
                                </div>
                                <div className="widget-chart-content">
                                    <div className="widget-subheading">
                                        Seconds
                                </div>
                                    <div className="widget-numbers text-success">
                                        {this.addLeadingZeros(countDown.sec) === "NaN" ? "00" : this.addLeadingZeros(countDown.sec)}

                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    <CardFooter className="text-center d-block p-3">
                        <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg"
                            onClick={() => joinExam(exam_id)}
                            disabled={(hr == '00' && mn == '00' && (sc == '01' || sc == '00' || sc == "NaN") && loader === false) ? false : true}
                        >
                            <Row>
                                <span className="mr-2 opacity-7">

                                    <IoIosAnalytics color="#ffffff" />
                                </span>
                                <span className="mr-1">

                                    Start Exam
                                </span>
                                <ClipLoader
                                    size={20}
                                    color={"white"}
                                    loading={loader}
                                />
                            </Row>

                        </Button>
                    </CardFooter>
                </Card>

            </>
        );
    }
}

Countdown.propTypes = {
    date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
    date: new Date()
};

export default Countdown;