import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap'

const BrowserNotSupport = () => {

    return (
        <>
            <Row className="jms-update-row-bullet">
                <Col md={12} style={{ margin: '7px 0px' }}>
                    <p>You should use Chrome , Safari or Opera to start class</p>

                </Col>
            </Row>
        </>
    )
}

export default BrowserNotSupport;