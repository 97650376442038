import React from 'react'
import DataTable from '../common/AssignmentTable'


const Table = (props) => {
    return (
        <>
            <DataTable
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns}
                data={props.data}
                loader={props.loader}
                downloadMyAssignment={(data) => props.downloadMyAssignment(data)}
                teacherDownloadFile={(data) => props.teacherDownloadFile(data)}
                downloadTeacherAssignment={(data) => props.downloadTeacherAssignment(data)}
                populateStudents={(data) => props.populateStudents(data)}
                deleteAssignment={(data) => props.deleteAssignment(data)}
                downloadStudentFile={(data) => props.downloadStudentFile(data)}
                respondToAssignment={(data) => props.respondToAssignment(data)}
                getTeacherRemark={(data) => props.getTeacherRemark(data)}
                getDescription={(data) => props.getDescription(data)}
                handleClick={(data) => props.handleClick(data)}
                editAssignment={props.editAssignment}
            />
        </>
    )
}

export default Table
