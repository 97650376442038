import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import serialize from "form-serialize";
import Loader from "react-loaders";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import {
  assignStudentToExam,
  assignStudentToExamEnte,
} from "../../../redux/Actions/exam";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Form,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Swal from "sweetalert2";

const AddStudentToExam = (props) => {
  const examStore = useSelector(({ exam }) => exam);

  console.log("estore data", examStore);
  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_ASSIGN_STUDENT_CLASS" }); //Resetting specific student and group
      dispatch({ type: "RESET_GROUP_LIST" });
    };
  }, []);
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const dispatch = useDispatch();
  const handleChange = (user_id) => {
    // no use
    // alert(selectedStudents.length)
    dispatch({ type: "CHANGE_ASSIGN_STUDENT_TO_CLASS" });
  };
  const handleClick = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    body.class_id = props.classId;
    console.log("body", body);
    if (tokenData.enterpriseUser) {
      // dispatch(addStudentToVideoClassroomEnter(props.classId, body, tokenData.token, props.handleCloseStudentModal, props.timeData))

      dispatch(
        assignStudentToExamEnte(
          tokenData.token,
          body,
          props.handleCloseStudentModal
        )
      );
    } else {
      dispatch(
        assignStudentToExam(
          tokenData.token,
          body,
          props.handleCloseStudentModal
        )
      );
    }
  };

  return (
    <Form onSubmit={handleClick}>
      <input name="exam_id" type="hidden" value={props.examId} />
      <Card className="main-card mb-3">
        <CardHeader>
          <Col md={5} sm={5}>
            {tokenData.enterpriseUser ? "Group" : "Student"}
          </Col>
          <Col md={7} sm={7}>
            {tokenData.enterpriseUser ? "" : "Phone"}
          </Col>
        </CardHeader>
        <CardBody>
          <div className="scroll-area-sm">
            <PerfectScrollbar>
              {props.loader ? (
                <p style={{ position: "absolute", top: "20%", left: "45%" }}>
                  <Loader color="#f7b924" type="ball-rotate" />{" "}
                </p>
              ) : null}

              {props.data.specificStudentLoader ? (
                <p style={{ position: "absolute", top: "20%", left: "45%" }}>
                  <Loader color="#f7b924" type="ball-rotate" />{" "}
                </p>
              ) : null}

              {props.data
                ? props.data.map((dt) => (
                    <Row key={dt.user_id ? dt.user_id : dt.group_id}>
                      <Col md={5} sm={5} style={{ paddingLeft: "30px" }}>
                        {dt.user_id ? dt.first_name : dt.group_name}{" "}
                        {dt.user_id ? dt.last_name : null}
                      </Col>
                      {!tokenData.enterpriseUser ? (
                        <Col md={5} sm={5} style={{ paddingLeft: "20px" }}>
                          {`${dt.user_id ? dt.mobile : null}`}
                        </Col>
                      ) : null}

                      <Col md={2} sm={2}>
                        <FormGroup>
                          <div>
                            <input
                              type="checkbox"
                              id="exampleCustomCheckbox"
                              name={
                                dt.user_id
                                  ? "registered_student[]"
                                  : "group_assign_id[]"
                              }
                              defaultChecked={dt.selected}
                              // checked={selectedStudents.includes(dt.user_id ? dt.user_id : dt.group_id) ? true : false}
                              // checked={
                              //   selectedStudents.includes(
                              //     dt.user_id ? dt.user_id : dt.group_id
                              //   )
                              //     ? true
                              //     : false
                              // }
                              onChange={() =>
                                handleChange(
                                  dt.user_id ? dt.user_id : dt.group_id
                                )
                              }
                              value={dt.user_id ? dt.user_id : dt.group_id}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ))
                : null}
            </PerfectScrollbar>
          </div>
        </CardBody>
        <CardFooter className="d-block text-right">
          <LaddaButton
            className="mb-2 mr-2 btn btn-danger"
            loading={examStore.assignExamLoader}
            // style={{ width: '-webkit-fill-available' }}

            type="button"
            data-style={ZOOM_IN}
            type="submit"
          >
            Save
          </LaddaButton>
          {/* <Button  size="lg" color="success">Save</Button> */}
        </CardFooter>
      </Card>
    </Form>
  );
};

export default AddStudentToExam;
