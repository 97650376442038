import React, { useEffect,useState } from 'react';
import {
    Table,
    Row, Col,
    Button,
    Card,
    CardHeader,
    CardFooter,
} from 'reactstrap';
import {

    faBusinessTime,
    faCog
} from '@fortawesome/free-solid-svg-icons';
import { useHistory, Link } from 'react-router-dom'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useDispatch, useSelector } from "react-redux";
import { populatePrincipalDashboard, validateStartClass, getNextClass } from '../../redux/Actions/homeTeacher'
import PageTitle from '../common/HeaderTitle/AdminPageTitle'
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Swal from 'sweetalert2'
import Logo from '../../assets/utils/images/shamahlogosquare.png'
// src\assets\utils\images\logoShamah.png
import Timer from '../common/Timer'
import TimerCopy from '../common/Timer/copy'
import LoadingOverlay from 'react-loading-overlay'
import { Loader } from 'react-loaders';

const Principal = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const history = useHistory()

    const dispatch = useDispatch();
    const checkHost = useSelector((state) => state.homeTeacher.checkHost ? state.homeTeacher.checkHost : 0);
    const [classStatus, setClassStatus] = useState(false)

    useEffect(() => {
        dispatch(populatePrincipalDashboard(tokenData.token))
        dispatch(getNextClass(tokenData.token))
        return()=>{
            dispatch({ type: 'TIMER_RESET' })
        }
    }, [])
    const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    // console.log(homeDetail.counterDetails.total_class)


    const joinClass = (weekId, classId) => {
        dispatch(validateStartClass(cb, tokenData.token, weekId, classId))

    }
    const cb = (weekId, classId) => {

        if(checkHost===1){
            history.push(`/dashboard/startclass/${weekId}/${classId}/${"conference"}`)
        }
        else{
            history.push(`/dashboard/join/${weekId}/${classId}`)
        }
        //props.history.push('/dashboard/')
        //alert("teacher")
        // const weekId = homeDetail.liveClass[0].weekly_class_id
        // const classId = homeDetail.liveClass[0].class_id
        // if (res.result) {
        //     history.push(`/dashboard/join/${weekId}/${classId}`)
        // } else if (!res.result) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: '',
        //         text: res.message,

        //     })
        // }
    }

    // debugger
    return (
        <>
            <PageTitle

                heading={tokenData.role === 'principal' ? "Admin Dashboard" : "Staff Dashboard"}
                subheading="Monitor your account from here"
                icon={Logo}
            />




            {homeDetail.counterDetails == '' ?

                <>
                    <Row>{Array(6).fill().map(() => {
                        return (
                            <Col md={4} style={{ padding: 15 }}>
                                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                    <Skeleton height={70} count={1} />

                                </SkeletonTheme>

                            </Col>
                        )
                    }

                    )
                    }

                    </Row>

                </>
                :
                <>
                    <Row>
                        <Col lg="6" xl="4">
                            <div className="card mb-3 widget-content bg-night-fade">
                                <div className="widget-content-wrapper text-white">
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Total Sessions
                                        </div>
                                        <div className="widget-subheading">
                                            Scheduled Sessions
                                        </div>
                                    </div>

                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-white">
                                            <CountUp end={`${homeDetail.counterDetails.total_class}`} duration="10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" xl="4">
                            <div className="card mb-3 widget-content bg-arielle-smile">
                                <div className="widget-content-wrapper text-white">
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Live Sessions
                                        </div>
                                        <div className="widget-subheading">
                                            Presently active Sessions
                                        </div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-white">
                                            <CountUp
                                                //start={75}
                                                end={`${homeDetail.counterDetails.live_class}`}
                                                //separator=""
                                                //decimals={0}
                                                //decimal=","
                                                //prefix="$ "
                                                duration="10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" xl="4">
                            <div className="card mb-3 widget-content bg-premium-dark">
                                <div className="widget-content-wrapper text-white">
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Active Hosts
                                        </div>
                                        <div className="widget-subheading">
                                            Hosts currently on live
                                        </div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-warning">
                                            <CountUp
                                                start={1}
                                                end={`${homeDetail.counterDetails.live_present_teacher}`}
                                                // separator=""
                                                // decimals={0}
                                                // decimal=","
                                                // prefix="$"
                                                // suffix="M"
                                                duration="10"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" xl="4">
                            <div className="card mb-3 widget-content bg-happy-green">
                                <div className="widget-content-wrapper text-white">
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Inactive Hosts
                                        </div>
                                        <div className="widget-subheading">
                                            Hosts currently not live
                                        </div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-dark">
                                            <CountUp start={0}
                                                end={`${homeDetail.counterDetails.live_absent_teacher}`}
                                                // separator=""
                                                // decimals={0}
                                                // decimal=","
                                                // prefix=""
                                                // suffix="%"
                                                duration="15" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" xl="4">
                            <div className="card mb-3 widget-content bg-sunny-morning">
                                <div className="widget-content-wrapper text-white">
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Active Participants
                                        </div>
                                        <div className="widget-subheading">
                                        Participants attending the session
                                        </div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-white">
                                            <CountUp end={`${homeDetail.counterDetails.live_present_student}`} duration="10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" xl="4">
                            <div className="card mb-3 widget-content bg-plum-plate">
                                <div className="widget-content-wrapper text-white">
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Inactive Participants
                                        </div>
                                        <div className="widget-subheading">
                                        Participants who missed the session
                                        </div>
                                    </div>
                                    <div className="widget-content-right">
                                        <div className="widget-numbers text-white">
                                            <CountUp
                                                //start={75}
                                                end={`${homeDetail.counterDetails.live_absent_student}`}
                                                //separator=""
                                                //decimals={0}
                                                //decimal=","
                                                //prefix="$ "
                                                duration="10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>}

            {
                tStore.nextClass === 'null' ?
                    <LoadingOverlay tag="div" active={homeDetail.nextClass != [] ? true : false}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#fff',
                                opacity: 0.5
                            })
                        }}
                        spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>

                        <TimerCopy
                            label={'Your next session'}

                        />
                    </LoadingOverlay>

                    :
                    <Timer
                        classname={homeDetail.nextClass.class_name}
                        // date={homeDetail.nextClass.start_timer_till}
                        date={homeDetail.nextClass.timer}
                        weekly_class_id={homeDetail.nextClass.weekly_class_id}
                        class_id={homeDetail.nextClass.class_id}
                        classStatus2={homeDetail.nextClass.status}
                        joinClass={joinClass}
                        active={true}
                        loader={tStore.timerLoader}
                        disablity={homeDetail.nextClass.length <= 0 ? true : false}
                        classStatus={classStatus}
                        role={tokenData.role ? tokenData.role:'admin'}
                        label={'Your next session'}
                        checkHost={checkHost}
                    // date={nextC}
                    //timeFormat="MM DD YYYY, h:mm a"
                    />

            }

            <Card className="main-card mb-3">
                <CardHeader>
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                        Live Sessions
                    </div>
                    <div className="btn-actions-pane-right">

                    </div>
                </CardHeader>
                <Table responsive borderless hover className="align-middle text-truncate mb-0">
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th className="text-center">Session</th>
                            <th className="text-center">Clinic</th>
                            {/* <th className="text-center">Subject</th> */}
                            <th className="text-center">Time Slot</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            homeDetail.liveClass ? homeDetail.liveClass.map((dt, index) => {

                                return (

                                    < tr >
                                        <td className="text-center text-muted" style={{ width: '80px' }}>{index + 1}</td>
                                        <td className="text-center" style={dt.class_type === "special" ? { width: '80px', color: 'red' } : { width: '80px' }}>
                                            {/* <img width={40} className="rounded-circle"
                                               src={avatar1}
                                                  alt=""/> */}

                                            {dt.class_type === "special" ?
                                                <div className="mb-2 mr-2 badge badge-dot badge-dot-md badge-danger">Danger  </div>
                                                : null}

                                            {dt.class_name}
                                        </td>
                                        <td className="text-center">
                                            {/* <a href="https://colorlib.com/" onClick={(e)=>e.preventDefault()}>
                                                  Juan C. Cargill
                                                </a> */}
                                            {dt.grade_name}


                                        </td>
                                        {/* <td className="text-center">
                                            {dt.subject_name}
                                        </td> */}

                                        <td className="text-center">
                                            <span className="pr-2 opacity-6">
                                                <FontAwesomeIcon icon={faBusinessTime} />
                                            </span>
                                            {`${dt.from_time} - ${dt.to_time}`}
                                        </td>
                                        <td className="text-center">
                                            <div className='badge badge-pill badge-warning' >
                                                {dt.status}
                                            </div>

                                        </td>

                                        <td className="text-center">
                                            {/* <ButtonGroup size="sm">
                                                        <Button className="btn-shadow" onClick={() => getClassTimes(dt.class_id)} color="primary">Timing</Button>
                                                       
                                                    </ButtonGroup> */}

                                            <Button className="mb-2 mr-2 btn-icon btn-icon-only btn-pill" outline
                                                onClick={() => joinClass(dt.weekly_class_id, dt.class_id)}
                                                color="primary">
                                                <i className="pe-7s-user btn-icon-wrapper"> </i>
                                            </Button>

                                            {/* <ButtonGroup size="sm">
                                                        <Button className="btn-shadow" onClick={() => handleOpenStudentListModal(dt.class_id, dt.grade_id)} color="primary">Students</Button>
                                                       
                                                    </ButtonGroup> */}


                                        </td>
                                    </tr>
                                )
                            }) : null
                        }


                    </tbody>
                </Table>
                <CardFooter className="d-block p-4 text-center">
                    <Link to="/dashboard/show-class">
                        <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                            <span className="mr-2 opacity-7">
                                <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                            </span>
                            <span className="mr-1">
                                View Full TimeTable
                            </span>
                        </Button>
                    </Link>
                </CardFooter>
            </Card>


            <Card className="main-card mb-3">
                <CardHeader>
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                        Current Sessions
                    </div>
                    <div className="btn-actions-pane-right">

                    </div>
                </CardHeader>
                <Table responsive borderless hover className="align-middle text-truncate mb-0">
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th className="text-center">Session</th>
                            <th className="text-center">Clinic</th>
                            {/* <th className="text-center">Subject</th> */}
                            <th className="text-center">Therapist</th>
                            <th className="text-center">Time Slot</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            homeDetail.classesNow ? homeDetail.classesNow.map((dt, index) => {

                                return (

                                    < tr >
                                        <td className="text-center text-muted" style={{ width: '80px' }}>{index + 1}</td>
                                        <td className="text-center" style={dt.class_type === "special" ? { width: '80px', color: 'red' } : { width: '80px' }}>
                                            {/* <img width={40} className="rounded-circle"
                                               src={avatar1}
                                                  alt=""/> */}

                                            {dt.class_type === "special" ?
                                                <div className="mb-2 mr-2 badge badge-dot badge-dot-md badge-danger">Danger  </div>
                                                : null}

                                            {dt.class_name}
                                        </td>
                                        <td className="text-center">
                                            {/* <a href="https://colorlib.com/" onClick={(e)=>e.preventDefault()}>
                                                  Juan C. Cargill
                                                </a> */}
                                            {dt.grade_name}


                                        </td>
                                        {/* <td className="text-center">
                                            {dt.subject_name}
                                        </td> */}
                                        <td className="text-center">
                                            {dt.teacher}
                                        </td>

                                        <td className="text-center">
                                            <span className="pr-2 opacity-6">
                                                <FontAwesomeIcon icon={faBusinessTime} />
                                            </span>
                                            {`${dt.from_time} - ${dt.to_time}`}
                                        </td>



                                    </tr>
                                )
                            }) : null
                        }


                    </tbody>
                </Table>
                <CardFooter className="d-block p-4 text-center">
                    <Link to="/dashboard/show-class">
                        <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                            <span className="mr-2 opacity-7">
                                <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                            </span>
                            <span className="mr-1">
                                View Full TimeTable
                            </span>
                        </Button>
                    </Link>
                </CardFooter>
            </Card>



        </>
    )
}

export default Principal
