import React, { useEffect,useState } from 'react';
import { Row, Col,  } from 'reactstrap'
import Table from '../common/DataTable';
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { listRecordings,  } from '../../redux/Actions/Recordings'
import { development } from '../../config.json'
import copy from 'copy-to-clipboard';
import {  toast } from 'react-toastify';
// import Modal from "../common/Modal/index"
import ReactPlayer from 'react-player'
// import spinner from '../../assets/animated/Spinner.gif'
// import ReactHtmlParser from 'react-html-parser';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';

var columns
columns = [
    { title: '#', field: 'tableData.id' },
    { title: 'Session', field: 'class_name' },
    { title: 'Clinic', field: 'grade_name' },
    // { title: 'Subject', field: 'subject_name' },
    { title: 'Date', field: 'created_at' },
    { title: 'Created at', field: 'created_at' },
]

const Recordings = () => {
    const dispatch = useDispatch()
    const recordStore = useSelector(({ Recordings }) => Recordings)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(listRecordings(tokenData.token))
        return () => {

        }
    }, [])


    if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal'))
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Therapist', field: 'teacher_name' },
            { title: 'Session', field: 'class_name' },
            { title: 'Clinic', field: 'grade_name' },
            // { title: 'Subject', field: 'subject_name' },
            { title: 'Date', field: 'created_at' },
            { title: 'Created at', field: 'created_at' },
        ]
    else
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Session', field: 'class_name' },
            { title: 'Clinic', field: 'grade_name' },
            // { title: 'Subject', field: 'subject_name' },
            { title: 'Date', field: 'created_at' },
            { title: 'Created at', field: 'created_at' },
        ]


    const onDownload = (rowData) => {
        var basreUrl = development.baseUrl2;
        var url = `${basreUrl}downloadMyRecordings?session_id=${rowData}`
        window.location.href = url;
        // dispatch(downloadRecording(tokenData.token, rowData))
    }
    const onCopy = (url) => {

        toast.configure();
        copy(url);
        toast.success("Copied", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }

    const [play,setPlay]=useState({open:false,url:''})
    const onPlay=(val)=>{
        // console.log('uuuuuurrrrlll',url)
        setPlay({open:true,url:val})

    
    }
    const CloseModel=()=>{
        setPlay({open:false,url:''})
    }
    return (

        <>

            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        title={'Recording'}
                        columns={columns}
                        actions={"recording_list"}
                        data={recordStore.records}
                        // loader={tStore.schoolSubLoader}
                        onDownload={onDownload}
                        onCopy={onCopy}
                        onPlay={onPlay}
                    />
                </Col>

            </Row>


            <Dialog
            open={play.open}
            onClose={CloseModel}
            maxWidth='sm'
            fullWidth={true}
            >

                 <DialogTitle  id="alert-dialog-title">
                     <Grid container>
                         <Grid item xs={11}>Play Video</Grid>
                         <Grid item xs={1}><CloseIcon  style={{cursor:'pointer'}} onClick={CloseModel} /></Grid>
                     </Grid>
                 </DialogTitle>
                        <Grid item xs={12}>
                            <Divider  />
                        </Grid>
                    <DialogContent style={{padding:'0px'}} >
                    <div style={{ width:'100%',display:'flex',justifyContent:'center' }}>
                        <ReactPlayer
                            url={play.url}
                            controls={true}
                            />
                    </div>
                    </DialogContent>
    

        </Dialog>


        </>
    )
}

export default Recordings;