import React, { useState, useEffect } from 'react'
import './videoCard.scss'
import { Button, Row, Col, Input } from 'reactstrap'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import { deletePlaylistVideos, updatePlaylistVideos } from '../../../redux/Actions/playlist'
const Index = (props) => {

    const { thumbnile, embed, openVideo, data, title, duration, video_id, deleteVideo, openEdit, type } = props
    // const [details, setDetails] = useState()
    // useEffect(() => {
    //     setDetails({
    //         title: title,
    //         duration: duration
    //     })

    // }, [props])
    const storeData = useSelector(({ playlist }) => playlist)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const param = useParams()
    // const dispatch = useDispatch();


    // const deleteVideo = (video_id) => {
    //     if (!video_id) {
    //         Swal.fire({
    //             icon: 'warning',
    //             title: 'Video under processing',
    //             text: 'please try later',

    //         })
    //         setEditMode(false)

    //     }
    //     else {
    //         dispatch(deletePlaylistVideos(tokenData.token, video_id, param.playlist_id))
    //     }
    // }
    // // const [videoName,setVideo]
    // const [editMode, setEditMode] = useState(false)
    // const [videoTitle, setVideoTitle] = useState('')
    // const handleChange = (e) => {
    //     setVideoTitle(e.target.value)
    // }

    // const [updating, setUpdating] = useState([])
    // const updateVideo = (video_id) => {

    //     if (!video_id) {
    //         Swal.fire({
    //             icon: 'warning',
    //             title: 'Video under processing',
    //             text: 'please try later',

    //         })
    //         setVideoTitle(title)
    //         setEditMode(false)

    //     } else {

    //         setUpdating([...updating, video_id])
    //         dispatch(updatePlaylistVideos(tokenData.token, video_id, param.playlist_id, videoTitle, updated))

    //     }
    // }
    // const cancelUpdate = (video_id) => {
    //     setEditMode(false)
    //     setVideoTitle(title)
    //     const removeId = updating.filter(id => id != video_id)
    //     setUpdating(removeId)

    // }
    // const updated = (title_name, video_id) => {
    //     setVideoTitle(title_name)
    //     setEditMode(false)
    //     const removeId = updating.filter(id => id != video_id)
    //     setUpdating(removeId)

    // }
    return (
        <>
            <div className="jms-choose-from-playlist-thumb-card" style={{ backgroundImage: `url(${thumbnile})`,cursor:'pointer',height:'164px' }}
                onClick={type === "playlist" ? () => openVideo(embed, thumbnile) : null}
            >
                {/* <img src={thumbnile} className="jms-choose-from-playlist-thumb-card" /> */}
                {/* <input
                                    style={{ marginLeft: 6 }}
                                    type="checkbox"
                                    id={`id-${id}`}
                                    checked={mutliSelect.includes(videoId)}
                                    value={videoId}
                                    className="jms-choose-from-playlist-thumb-checkbox"
                                    onChange={() => multiSelectVideo(videoId)}
                                    name="muliselect"
                                /> */}
                <div className="jms-choose-from-playlist-thumb-title" > {title}</div>
                <div className="jms-choose-from-playlist-thumb-size">{duration}</div>
                {thumbnile.includes('default') && <div className="jms-thumb-card-processing">{"Processing"}</div>}

                {/* {editMode ?
                <Row className="jms-choose-from-playlist-thumb-input">
                    <Col md={12}>
                        <Input style={{ height: 32 }} type="text" onChange={handleChange} name="title" value={videoTitle} />
                    </Col>
                </Row>
                : null
            } */}

                {/* <div className="jms-choose-from-playlist-thumb-format" >{format}</div> */}
                {/* <div className="jms-choose-from-playlist-thumb-format-choose" ><span style={{ float: 'right', fontSize: 11, }}>
                                    Sync  &nbsp;{` `}
                                    <input
                                        // style={{ float: 'right' }}
                                        onChange={() => handleClickSync(videoId)}
                                        value={isToggleOn}
                                        type="checkbox"
                                        id={`sync${videoId}`}
                                        name={`sync-${videoId}`}
                                        disabled={!mutliSelect.includes(videoId)}
                                        checked={syncVideo.includes(videoId)}

                                    />
                                </span></div> */}

            </div >
            <div className="jms-choose-from-playlist-thumb-actions" >
                {/* {
             editMode ?
                 <Row>

                     <Col md={6}>
                         <Button onClick={() => cancelUpdate(video_id)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="danger" >Cancel</Button>

                     </Col>
                     <Col md={6}>
                         <Button disabled={updating.includes(video_id)} onClick={() => updateVideo(video_id)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="success">{updating.includes(video_id) ? "Updating..." : "Save"}</Button>


                     </Col>
                 </Row>
                 :
                 <Row>

                     <Col md={6}>
                         <Button onClick={() => setEditMode(true)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="info" >Edit</Button>

                     </Col>
                     <Col md={6}>
                         <Button onClick={() => deleteVideo(video_id)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="danger">{storeData.deleteVideoLoader ? 'Deleting...' : 'Delete'}</Button>


                     </Col>
                 </Row>
         } */}
                {
                    type === "player" ?
                        <Row>

                            {/* <Col md={6}>
                                <p style={{ fontSize: '9px', marginBottom: '0px' }}>From</p>
                                <p style={{ fontSize: '10px', marginBottom: '0px' }}>{data.from_date}</p>
                                <p style={{ fontSize: '8px' }}>{data.from_time}</p>

                            </Col>
                            <Col md={6}>
                                <p style={{ fontSize: '9px', marginBottom: '0px' }}>To</p>
                                <p style={{ fontSize: '10px', marginBottom: '0px' }}> {data.to_date}</p>
                                <p style={{ fontSize: '8px' }}>{data.to_time}</p>


                            </Col> */}
                            <Col md={12}>
                                <p style={{ fontSize: 13, margin: 0, fontWeight: 500 }}>
                                    {`Video available from ${data.from_time} to ${data.to_time} `}
                                </p>
                            </Col>

                            <Col md={12}>
                                <p style={{ fontSize: 13, margin: 0, fontWeight: 500 }}>
                                    {` between date ${data.from_date} to ${data.to_date}`}
                                </p>
                            </Col>
                            {/* <p style={{ fontSize: 12, margin: 0 }}>
                                {`Video available from ${data.from_time} to ${data.to_time} 
                                between date ${data.from_date} to ${data.to_date}`}
                            </p> */}
                        </Row>
                        : <Row>

                            <Col md={6}>
                                <Button onClick={() => openEdit(video_id, title)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="info" >Edit</Button>

                            </Col>
                            <Col md={6}>
                                <Button onClick={() => deleteVideo(video_id)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="danger">{storeData.deleteVideoLoader ? 'Deleting...' : 'Delete'}</Button>


                            </Col>
                        </Row>
                }

            </div>
        </>
    )
}

export default Index
