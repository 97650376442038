import React, { Fragment,  } from 'react';
import { connect, useSelector, } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
// import Echo from 'laravel-echo';
import ResizeDetector from 'react-resize-detector';
import { development } from '../../config.json';

import AppMain from '../../Layout/AppMain';

// const tokenData = JSON.parse(localStorage.getItem('authDetails'));

const options = {

    broadcaster: 'pusher',
    key: "local",
    //  wsHost: window.location.hostname,
    wsHost: development.socketUrl,
    // wsHost: "backend.mymots.com",

    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],

    // broadcaster: 'pusher',
    // key: 'key',

    // cluster: 'mt1',    
    //authEndpoint is your apiUrl + /broadcasting/auth
    // authEndpoint: `http://${development.socketUrl}/api/broadcasting/auth`,
    // authEndpoint: `${development.socketUrl}/api/broadcasting/auth`,
    // host: "http://localhost:6001",
    // wsPort: 6001,
    // wssPort: 6001,

    // auth: {
    //   headers: {
    //     Authorization: `Bearer ${tokenData.token}`,
    //     Accept: 'application/json',
    //   },
    // },
};

const Main = (props) => {

    // const [state, setState] = useState({ closedSmallerSidebar: false })
    // const dispatch = useDispatch()
    // var mute = "mute";

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)


    // const store1 = useSelector(state => state)
   

    // useEffect(() => {
        // const echo = new Echo(options);
    //     console.log("echo in useEffect", echo)

    //     // echo.channel("audioSettings").listen('toggleStudentMike', (e) => {
    //     //     console.log("index page broadcast", e.message)

    //     //     // console.log("e.message", e.message)
    //     // });

    //     // if (typeof echo === 'undefined') {
    //     // const echo = new Echo(options);

    //     // if (tokenData) {
    //     //     if (tokenData.role == "student") {

        // echo.channel("DemoChannel").listen('WebsocketDemoEvent', (e) => {
        //     console.log("demo channel", e.message)
        //     if (e.message.mute_status !== mute) {
        //         console.log("e.message--", e.message)
        //         dispatch({ type: 'BROADCAST_INDEX_PAGE', payload: e.message.student_id })
        //         mute = e.message.mute_status
        //     }
        //     // console.log("e.message", e.message)

        // });


    //     echo.channel(`classInprogress`).listen('classStarted', (e) => {
    //         ai(e.message.class_id)
    //         console.error("tStore.startClass_id", tStore.startClass_id)
    //         console.warn("index page broadcast for start class-------", e.message)
    //         // dispatch({ type: 'BROADCAST_INDEX_PAGE', payload: e.message.student_id })

    //     });

    //     //     }
    //     // }


    //     // echo.channel("classInprogress1").listen('classStarted', (e) => {
    //     //     console.log("index page broadcast", e.message)
    //     //     if (e.message.mute_status !== mute) {
    //     //         console.log("e.message--", e.message)
    //     //         dispatch({ type: 'BROADCAST_INDEX_PAGE', payload: e.message.student_id })
    //     //         mute = e.message.mute_status
    //     //     }
    //     //     // console.log("e.message", e.message)

    //     // });




    //     return () => {
    //         console.log("unmount echooo")
    //         //echo = undefined
    //     };

    // }, [])
   

    const ai = (id) => {
        if (tStore.startClass_id === id) {
            alert("Same")
        }
        alert(id)
        alert(tStore.startClass_id)
    }


    let {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
    } = props;

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <div className={cx(
                        "app-container app-theme-" + colorScheme,
                        { 'fixed-header': enableFixedHeader },
                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                        { 'fixed-footer': enableFixedFooter },
                        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                        { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                        { 'sidebar-mobile-open': enableMobileMenu },
                        { 'body-tabs-shadow-btn': enablePageTabsAlt },
                    )}>
                        <AppMain />
                    </div>
                </Fragment>
            )}
        />
    )

}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(Main));

