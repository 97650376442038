import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getMyAccount } from '../../redux/Actions/homeTeacher'
import avatar1 from '../../assets/utils/images/avatars/1.jpg';
import bg1 from '../../assets/utils/images/dropdown-header/abstract1.jpg';
import copy from 'copy-to-clipboard';

import {
    Col,
    Button,
    Card,
    Progress,
    ListGroup,
    ListGroupItem,
    Input,
    Label

} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { uploadUserImage } from "../../redux/Actions/new-action"
import Snackbar from '@material-ui/core/Snackbar';

const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const dispatch = useDispatch();
    const accountInfo = useSelector(({ homeTeacher }) => homeTeacher)
    const emoji_loader = useSelector((state) => state.new_action.emoji_loader ? state.new_action.emoji_loader : false)


    const [emoji, setEmoji] = useState('')
    const [show, setShow] = useState(false)

    useEffect(() => {
        dispatch(getMyAccount(tokenData.token))
    }, [])



    const myfunction = () => {
        toast.configure();
        copy('https://mymots.com/#/teacher-register?referal=' + accountInfo.myAccountInfo.referral_code);
        toast.error("Copied", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }
    const upgradePlan = () => {
        dispatch({ type: 'PAYMENT_UPGRADE_POPUP_REQUEST' })

    }

    const HandleImage = (e) => {
        setEmoji(e.target.files[0])
    }

    const UploadEmoji = () => {
        let formData = new FormData();
        formData.append("image", emoji);
        dispatch(uploadUserImage(tokenData.token, formData, cb))
    }
    const cb = () => {
        document.getElementById("emoji").value = ''
        setEmoji('')
        setShow(true)
    }
    return (
        <Col md="12" lg="6" xl={{ span: 6, offset: 3 }}    >
            <Card className="card-shadow-primary profile-responsive card-border mb-3">
                <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-danger">
                        <div className="menu-header-image"
                            style={{
                                backgroundImage: 'url(' + bg1 + ')'
                            }}
                        />
                        <div className="menu-header-content btn-pane-right">
                            <div className="avatar-icon-wrapper mr-2 avatar-icon-xl">
                                <div className="avatar-icon">
                                    <img src={avatar1} alt="Avatar 5" />
                                </div>
                            </div>
                            <div>
                                <h5 className="menu-header-title">{accountInfo.myAccountInfo ? accountInfo.myAccountInfo.first_name + " " + accountInfo.myAccountInfo.last_name : " "} </h5>
                                <h6 className="menu-header-subtitle">{accountInfo.myAccountInfo ? accountInfo.myAccountInfo.role === 'Principal' ? 'Admin' :
                                    accountInfo.myAccountInfo.role === 'Teacher' ? 'Therapist' :
                                        accountInfo.myAccountInfo.role === 'Student' ? 'Client' :
                                            accountInfo.myAccountInfo.role === 'Staff' ? 'Staff' : ''
                                    : " "}</h6>
                            </div>
                            {/* <div className="menu-header-btn-pane">
                            <Button size="sm" className="btn-icon mr-2 btn-icon-only"
                                    color="warning">
                                <i className="pe-7s-config btn-icon-wrapper"> </i>
                            </Button>
                            <Button size="sm" className="btn-icon"
                                    color="success">
                                View Profile
                            </Button>
                        </div> */}
                        </div>
                    </div>
                </div>
                <ListGroup flush>
                    {tokenData.role === 'student' && !tokenData.enterpriseUser ?

                        <ListGroupItem>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left mr-3">
                                        <div className="icon-wrapper m-0">
                                            <div className="progress-circle-wrapper">
                                                <Progress
                                                    type="circle"
                                                    percent={43}
                                                    width="100%"
                                                    theme={
                                                        {
                                                            active: {
                                                                trailColor: '#e1e1e1',
                                                                color: '#28b0cd'
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {(accountInfo.myAccountInfo && accountInfo.myAccountInfo.grade) ?
                                        <>
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    Grade
                                                </div>
                                                <div className="widget-subheading">
                                                    {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.grade : " "}
                                                </div>
                                            </div>

                                        </> : " "}
                                </div>
                            </div>
                        </ListGroupItem> : null
                    }
                    <ListGroupItem>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left mr-3">
                                    <div className="icon-wrapper m-0">
                                        <div className="progress-circle-wrapper">
                                            <Progress
                                                type="circle"
                                                percent={82}
                                                width="100%"
                                                theme={
                                                    {
                                                        active: {
                                                            trailColor: '#e1e1e1',
                                                            color: '#3ac47d'
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Email
                                    </div>
                                    <div className="widget-subheading">
                                        {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.email : " "}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ListGroupItem>
                    <ListGroupItem>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left mr-3">
                                    <div className="icon-wrapper m-0">
                                        <div className="progress-circle-wrapper">
                                            <Progress
                                                type="circle"
                                                percent={47}
                                                width="100%"
                                                theme={
                                                    {
                                                        active: {
                                                            trailColor: '#e1e1e1',
                                                            color: '#f7b924'
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Mobile
                                    </div>
                                    <div className="widget-subheading">
                                        {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.mobile : " "}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ListGroupItem>

                    {
                        tokenData.role === 'teacher' ?
                            <ListGroupItem>
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-3">
                                            <div className="icon-wrapper m-0">
                                                <div className="progress-circle-wrapper">
                                                    <Progress
                                                        type="circle"
                                                        percent={47}
                                                        width="100%"
                                                        theme={
                                                            {
                                                                active: {
                                                                    trailColor: '#e1e1e1',
                                                                    color: '#f7b924'
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-content-left">
                                            <Label>
                                                Upload Image (200px x 200px)
                                            </Label>
                                            <Input
                                                type='file'
                                                onChange={HandleImage}
                                                id='emoji'
                                            />
                                        </div>
                                        <div className="widget-content-left">
                                            <LaddaButton className="mt-4 btn btn-primary"
                                                loading={emoji_loader}
                                                onClick={UploadEmoji}
                                                type="submit"
                                                data-style={ZOOM_IN}
                                                disabled={!emoji}
                                            >
                                                Submit
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </ListGroupItem>
                            : ''
                    }
                    {tokenData.role === 'teacher' && !tokenData.enterpriseUser ?

                        <ListGroupItem>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left mr-3">
                                        <div className="icon-wrapper m-0">
                                            <div className="progress-circle-wrapper">
                                                <Progress
                                                    type="circle"
                                                    percent={43}
                                                    width="100%"
                                                    theme={
                                                        {
                                                            active: {
                                                                trailColor: '#e1e1e1',
                                                                color: '#28b0cd'
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Referral Code
                                        </div>
                                        <div className="widget-subheading">
                                            {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.referral_code : " "}

                                        </div>

                                    </div>
                                    <div className="widget-content-right">
                                        <button onClick={myfunction}>copy</button>
                                    </div>
                                </div>
                            </div>

                        </ListGroupItem> : null
                    }
                    {
                        tokenData.role === "teacher" && !tokenData.enterpriseUser ?
                            <ListGroupItem>
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-3">
                                            <div className="icon-wrapper m-0">
                                                <div className="progress-circle-wrapper">
                                                    <Progress
                                                        type="circle"
                                                        percent={82}
                                                        width="100%"
                                                        theme={
                                                            {
                                                                active: {
                                                                    trailColor: '#e1e1e1',
                                                                    color: '#3ac47d'
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-content-left">
                                            <div className="widget-heading">
                                                Plan
                                            </div>
                                            <div className="widget-subheading">

                                                {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.plan_name : " "}
                                            </div>
                                        </div>
                                        <div className="widget-content-right">
                                            <Button onClick={upgradePlan} color="warning">Choose Plan</Button>
                                        </div>
                                    </div>
                                </div>
                            </ListGroupItem>
                            : null
                    }
                </ListGroup>
            </Card>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={show}
                onClose={() => { setShow(false) }}
                message="Image Uploaded Successfully"
                autoHideDuration={2500}
            />
        </Col>

    )

}
export default AnalyticsDashboard1