import React from 'react'
import { Col, Card, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock,

} from '@fortawesome/free-solid-svg-icons';
import Switchy from "react-switch";

const TimeSlotCard = (props) => {
    const { days, changeHolidays } = props
    return (
        <>

            <Col md={3}>
                <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary">
                    <div className="widget-chat-wrapper-outer">
                        <div className="widget-chart-content">
                            <Row>
                                <Col md={6}>
                                    <p>{days.day}</p>
                                </Col>
                                <Col md={6}>
                                    <p style={{ textAlign: 'right' }}>

                                        <Switchy

                                            onChange={() => changeHolidays(days.weekly_class_id)}
                                            checked={days.holiday_at}
                                        />
                                    </p>
                                </Col>
                            </Row>



                            <div className="widget-title opacity-5 text-uppercase">
                                <h6>
                                    {`${days ? days.time_from : null} - ${days ? days.time_to : null}`}
                                </h6>
                                <input
                                    value={days ? days.class_from_time : null}
                                    type="hidden"
                                    name={

                                        days.day === 'sun' ? "week[sun][class_from_time][]" :
                                            days.day === 'mon' ? "week[mon][class_from_time][]" :
                                                days.day === 'tue' ? "week[tue][class_from_time][]" :
                                                    days.day === 'wed' ? "week[wed][class_from_time][]" :
                                                        days.day === 'thu' ? "week[thu][class_from_time][]" :
                                                            days.day === 'fri' ? "week[fri][class_from_time][]" :
                                                                days.day === 'sat' ? "week[sat][class_from_time][]" :
                                                                    "name from"
                                    }
                                    id="from"
                                />
                                <input type="hidden"
                                    value={days ? days.class_to_time : null}
                                    id='to'
                                    name={
                                        days.day === 'sun' ? "week[sun][class_to_time][]" :
                                            days.day === 'mon' ? "week[mon][class_to_time][]" :
                                                days.day === 'tue' ? "week[tue][class_to_time][]" :
                                                    days.day === 'wed' ? "week[wed][class_to_time][]" :
                                                        days.day === 'thu' ? "week[thu][class_to_time][]" :
                                                            days.day === 'fri' ? "week[fri][class_to_time][]" :
                                                                days.day === 'sat' ? "week[sat][class_to_time][]" :
                                                                    "name to"
                                    }
                                />
                            </div>
                            <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                <div className="widget-chart-flex align-items-center">

                                    <div>
                                        <h5>
                                            {days.next_class_date}
                                            {/* {days.day} */}
                                            {days ? days.day === 'sun' ? 'Sunday'
                                                : days.day === 'mon' ? 'Monday'
                                                    : days.day === 'tue' ? 'Tuesday'
                                                        : days.day === 'wed' ? 'Wednesday'
                                                            : days.day === 'thu' ? 'Thursday'
                                                                : days.day === 'fri' ? 'Friday'
                                                                    : days.day === 'sat' ? 'Saturday' : null
                                                : null}
                                        </h5>

                                    </div>
                                    <div style={{ display: 'none' }}>

                                        <select
                                            id='day'
                                            name='day'
                                        >
                                            <option value={days ? days.day : null}>
                                                {days ? days.day === 'sun' ? 'Sunday'
                                                    : days.day === 'mon' ? 'Monday'
                                                        : days.day === 'tue' ? 'Tuesday'
                                                            : days.day === 'wed' ? 'Wednesday'
                                                                : days.day === 'thu' ? 'Thursday'
                                                                    : days.day === 'fri' ? 'Friday'
                                                                        : days.day === 'sat' ? 'Saturday' : null
                                                    : null}</option>
                                        </select>

                                    </div>
                                    <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                        <div className="ml-auto">
                                            <FontAwesomeIcon style={{ fontSize: '40px' }} color={days.class_type === "special" ? 'red' : '#545bd8'} icon={faClock} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>


        </>
    )
}

export default TimeSlotCard
