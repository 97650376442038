

import React, { Component } from 'react';
import PropTypes from 'prop-types'
import './timerStyle.scss'
class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            date: this.props.date,
            // date: '12 07 2020,11:01 am'
        }
    }
    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.state.date);
            date ? this.setState(date) : this.stop();
            if (this.state.hours === 0 && this.state.min === 0 && (this.state.sec === 0 || this.state.sec === 1) && this.props.type === "instruction") {

                this.props.finish()

            }
            if (this.state.hours === 0 && this.state.min === 0 && (this.state.sec === 1) && this.props.type === "examHall") {

                this.props.finish()

            }
        }, 1000);
    }
    componentWillUnmount() {
        this.stop();
    }
    preveProps = this.props.date
    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
        if (diff <= 0) return false;
        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0
        };
        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }
    stop() {
        clearInterval(this.interval);
    }
    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }
    render() {
        const countDown = this.state;
        var sc = this.addLeadingZeros(countDown.sec)
        var mn = this.addLeadingZeros(countDown.min)
        var hr = this.addLeadingZeros(countDown.hours)

        return (
            <>
                <div className="timer">
                    {/* {`${this.addLeadingZeros(countDown.days)}:${this.addLeadingZeros(countDown.hours)}:${this.addLeadingZeros(countDown.min)}:${this.addLeadingZeros(countDown.sec) === "NaN" ? "00" : this.addLeadingZeros(countDown.sec)}`} */}
                    {`${this.addLeadingZeros(countDown.hours)}:${this.addLeadingZeros(countDown.min)}:${this.addLeadingZeros(countDown.sec) === "NaN" ? "00" : this.addLeadingZeros(countDown.sec)}`}
                </div>
            </>
        );
    }
}
Countdown.propTypes = {
    date: PropTypes.string.isRequired
};
Countdown.defaultProps = {
    date: new Date()
};
export default Countdown;
