import React, { useState, useEffect, Fragment } from 'react';
import Modals from '../common/Modal'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getTodaysClass, getNextClass, getClassTiming, populateRegisteredStudents, getActivities } from '../../redux/Actions/homeTeacher'
import { logout, authCheck } from '../../redux/Actions/auth'
import Timer from '../common/Timer'
import TimerCopy from '../common/Timer/copy'
import RecentActivity from './recentActivity'
import Assignments from './assignments'
import LoadingOverlay from 'react-loading-overlay'
import Swal from 'sweetalert2'
import { Loader, Types } from 'react-loaders';
import BrowserNotSupport from '../Class/Utility/browserNotSupport'

import {
    isFirefox,
    isIE,
    isEdge,
    // isChrome,
    // isSafari
} from "react-device-detect";
import {
    Row, Col,
    Button,
    CardHeader,
    Container,
    Card,
    Alert,
    CardFooter,
    // CardBody,
    // Progress,
    // ListGroup,
    // ListGroupItem,
    // CustomInput, Input,
    // Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
    // UncontrolledButtonDropdown
} from 'reactstrap';
import { getAssignments, getTrialDays } from '../../redux/Actions/assignment'
// import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
// import noassignment from "../../assets/images/noassignment.png"
// import notimeline from "../../assets/images/notimeline.png"
// import CountUp from 'react-countup';

// import ReactTable from "react-table";

// import avatar1 from '../../../../assets/utils/images/avatars/1.jpg';
// import avatar2 from '../../../../assets/utils/images/avatars/2.jpg';

// import Ionicon from 'react-ionicons';
// import { IoIosAnalytics } from "react-icons/io";
// /
// import PerfectScrollbar from 'react-perfect-scrollbar';

// import Slider from "react-slick";

// import { makeData } from "../../../Tables/DataTables/Examples/utils";

// import {
//     ResponsiveContainer,
//     AreaChart,
//     Area,
// } from 'recharts';

// import {
//     Sparklines,
//     SparklinesCurve
// } from 'react-sparklines';

import {
    // faArchive,
    // faAngleUp,
    // faAngleDown,
    faCalendarAlt,
    faCloudSunRain,
    // faEllipsisH,
    // faCheck,
    // faTrashAlt,
    faBusinessTime,
    faCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateStartClass } from '../../redux/Actions/homeTeacher'

import { useHistory, Link } from 'react-router-dom'

// import classnames from 'classnames';
import TimeShedule from './timingModel'
import {

    Table,
    // ButtonGroup,
    // Nav,
    // NavItem,
    // NavLink,
    // Popover,
    // PopoverBody,
} from 'reactstrap';
import ViewStudent from './viewStudent'
import HomeTitle from '../HeaderTitle/HomeTitle'
import PageTitle from '../common/HeaderTitle/AdminPageTitle'
import Logo from '../../assets/utils/images/shamahlogosquare.png'
// import Timernew from "./Timer"
// import Countdown from 'react-countdown';
// import Timer from 'react-compound-timer'

// var options = {
//     lines: 13,
//     length: 4,
//     width: 2,
//     radius: 5,
//     scale: 1.00,
//     corners: 1,
//     color: '#000',
//     opacity: 0.25,
//     rotate: 0,
//     direction: 1,
//     speed: 1,
//     trail: 60,
//     fps: 20,
//     zIndex: 2e9,
//     top: '36%',
//     left: '60%',
//     shadow: false,
//     hwaccel: false,
//     position: 'absolute'
// };





// function boxMullerRandom() {
//     let phase = true,
//         x1, x2, w;

//     return (function () {

//         if (phase) {
//             do {
//                 x1 = 2.0 * Math.random() - 1.0;
//                 x2 = 2.0 * Math.random() - 1.0;
//                 w = x1 * x1 + x2 * x2;
//             } while (w >= 1.0);

//             w = Math.sqrt((-2.0 * Math.log(w)) / w);
//             return x1 * w;
//         } else {
//             return x2 * w;
//         }
//     })();
// }

// function randomData(n = 30) {
//     return Array.apply(0, Array(n)).map(boxMullerRandom);
// }

// const sampleData = randomData(10);
// const sampleData2 = randomData(15);
// const sampleData3 = randomData(8);
// const sampleData4 = randomData(12);
// import { Prompt } from 'react-router-dom'


const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    // const [state, setState] = useState({
    //     data: makeData(),
    //     dropdownOpen: false
    // });
    const [time, setTime] = useState()
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    // const nextclass = useSelector(({ homeTeacher }) => homeTeacher)
	const nextclass = useSelector((state) =>state.homeTeacher.nextClass ? state.homeTeacher.nextClass:'');

	const checkHost = useSelector((state) =>state.homeTeacher.checkHost ? state.homeTeacher.checkHost:0);


    const cbLogOut = () => {

        dispatch(logout())
        localStorage.removeItem("authDetails");
        history.push('/')
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
    }

    useEffect(() => {

        // if (isSafari) {
        //     console.log('safari browser')
        // } else {
        //     console.log('not safari browser')
        // }

        dispatch(authCheck(tokenData.token, cbLogOut))

        dispatch(getTodaysClass(tokenData.token))
        dispatch(getNextClass(tokenData.token))
        dispatch(getActivities(tokenData.token))
        var eventTime = new Date('Tue, 21 Apr 2020 13:00:00 GMT').getTime(); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
        var currentTime = new Date().getTime(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
        var diffTime = eventTime - currentTime;
        var duration = moment.duration(diffTime * 1000, 'milliseconds');
        var interval = 1000;
        var count = 0;
        setInterval(function () {
            duration = moment.duration(duration - interval, 'milliseconds');

            const hours = duration.hours()
            const minutes = duration.minutes()
            const seconds = duration.seconds()
            setTime({ hours, minutes, seconds });

        }, interval);

        if (tokenData.role === 'teacher') {

            dispatch(getAssignments(tokenData.token))
            dispatch(getTrialDays(tokenData.token))
        }
        return (() => {
            dispatch({ type: 'TIMER_RESET' })

        })

    }, [])

    const history = useHistory()

    const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)

    const assignment = useSelector(({ assignments }) => assignments)


    const joinClass = (weekId, classId) => {
        if (isFirefox || isIE || isEdge) {
            browserNotSupport()
        } else {
            dispatch(validateStartClass(cb, tokenData.token, weekId, classId, conference ? "conference" : "normal"))


        }


    }
    const [unSupportBrowser, setUnSupportBrowser] = useState(false)
    const browserNotSupport = () => {
        setUnSupportBrowser(true)
    }
    const closeUnsupportPopup = () => {
        setUnSupportBrowser(false)
        // history.push('/dashboard/overview')

    }
    const cb = (weekId, classId) => {

       
        if(checkHost===1){
            history.push(`/dashboard/startclass/${weekId}/${classId}/${conference ? "conference" : "normal"}`)
        }
        else{
            history.push(`/dashboard/join/${weekId}/${classId}`)
        }
    }
    //const cb2 = () => alert("presse")

    const [openModal, setOpenModal] = useState(false)

    const getClassTimes = (id) => {
        dispatch(getClassTiming(tokenData.token, id))
        setOpenModal(true)
    }

    const closeTimingModal = () => {
        setOpenModal(false)
    }


    const [studentListModal, setStudentListModal] = useState();
    const handleOpenStudentListModal = (class_id, grade_id) => {
        if (tokenData.enterpriseUser) {

            dispatch(populateRegisteredStudents(tokenData.token, null, class_id))


        } else {
            dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))


        }
        setStudentListModal(true)
    }
    const handleCloseStudentListModal = () => {
        setStudentListModal(false)
    }
    // setState({
    //     selectedOption: null,
    // });

    // const handleChange = (selectedOption) => {
    //     setState({ selectedOption });
    // };

    const toggle = () => {
        // setState(prevState => ({
        //     dropdownOpen: !prevState.dropdownOpen
        // }));
    }


    // const { selectedOption } = state;
    // const { data } = state;

    // const settings = {
    //     className: "",
    //     centerMode: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     speed: 500,
    //     dots: true,
    // };
    // const dateInFuture = '2029-12-31 23:59:59' 

    //console.log("tStore.specificClassTime+++", tStore.specificClassTime)

    const nextC = homeDetail.nextClass ? homeDetail.nextClass.start_timer_till : null;
    const [conference, setConference] = useState(false)
    const handleChange = (e) => {
        //const { name, value } = e.target
        // alert(value)
        setConference(!conference)
    }
    return (
        <Fragment>
            {
                nextclass ? 
                <>
                    <Container fluid>
                {/* <Card className="main-card mb-2">
                    <font ><div classNamnpme="card-header-title font-size-lg text-capitalize font-weight-normal"><h4>  {assignment.days} days trial left .</h4></div>
                    </font>
                </Card> */}

                {tokenData.enterpriseUser &&
                    // <HomeTitle
                    //     style={{ display: 'none' }}
                    //     heading={tokenData.enterprice_Name}
                    //     subheading={tokenData.description}
                    //     icon={tokenData.logo}
                    // />
                    <PageTitle
                          heading="Therapist Dashboard"
                          subheading="Monitor your account from here"
                         icon={Logo}
            />
                }
                {!tokenData.enterpriseUser ?

                    <Alert color="danger" isOpen={true}
                    //toggle={onDismiss}
                    //toggle={""}
                    > <strong>{`${assignment.days} days trial left.`}</strong>
                    </Alert>
                    : null}

                    
                {homeDetail.nextClass === 'null' ?
                    <LoadingOverlay tag="div" active={homeDetail.nextClass != [] ? true : false}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#fff',
                                opacity: 0.5
                            })
                        }}
                        spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>

                        <TimerCopy
                            label={'Your next session'}

                        />
                    </LoadingOverlay>

                    :
                    <Timer
                        classname={homeDetail.nextClass.class_name}
                        // date={homeDetail.nextClass.start_timer_till}
                        date={homeDetail.nextClass.timer}
                        weekly_class_id={homeDetail.nextClass.weekly_class_id}
                        class_id={homeDetail.nextClass.class_id}
                        joinClass={joinClass}
                        active={true}
                        handleChange={handleChange}
                        conference={conference}
                        loader={tStore.timerLoader}
                        disablity={homeDetail.nextClass.length <= 0 ? true : false}
                        classStatus2={homeDetail.nextClass.status}
                        role={'teacher'}
                        label={'Your next session'}
                        checkHost={checkHost}
                    />
                    // <Timer
                    //     initialTime={new Date(homeDetail.nextClass.start_timer_till).getTime() - new Date().getTime()}
                    //     direction="backward"
                    // >
                    //     {() => (
                    //         <React.Fragment>
                    //             <Timer.Days /> days
                    //             <Timer.Hours /> hours
                    //             <Timer.Minutes /> minutes
                    //             <Timer.Seconds /> seconds
                    //         </React.Fragment>
                    //     )}
                    // </Timer>
                }

                <LoadingOverlay tag="div" active={tStore.upcomingClassLoader}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: '#fff',
                            opacity: 0.5
                        })
                    }}
                    spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                    <Card className="main-card mb-3">
                        <CardHeader>
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                Upcoming Sessions
                            </div>
                            <div className="btn-actions-pane-right">

                            </div>
                        </CardHeader>
                        <Table responsive borderless hover className="align-middle text-truncate mb-0">
                            <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th className="text-center">Session</th>
                                    <th className="text-center">Clinic</th>
                                    {/* <th className="text-center">Subject</th> */}
                                    <th className="text-center">Date</th>
                                    <th className="text-center">Day</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Time Slot</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    homeDetail.todayClass ? homeDetail.todayClass.map((dt, index) => {

                                        return (

                                            < tr >
                                                <td className="text-center text-muted" style={{ width: '80px' }}>{index + 1}</td>
                                                <td className="text-center" style={dt.class_type === "special" ? { width: '80px', color: 'red' } : { width: '80px' }}>
                                                    {/* <img width={40} className="rounded-circle"
                                               src={avatar1}
                                                  alt=""/> */}

                                                    {dt.class_type === "special" ?
                                                        <div className="mb-2 mr-2 badge badge-dot badge-dot-md badge-danger">Danger  </div>
                                                        : null}

                                                    {dt.class_name}
                                                </td>
                                                <td className="text-center">
                                                    {/* <a href="https://colorlib.com/" onClick={(e)=>e.preventDefault()}>
                                                  Juan C. Cargill
                                                </a> */}
                                                    {dt.grade_name}


                                                </td>
                                                {/* <td className="text-center">
                                                    {dt.subject_name}
                                                </td> */}
                                                <td className="text-center">
                                                    <span className="pr-2 opacity-6">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </span>
                                                    {dt.date ? dt.date : "null"}
                                                </td>
                                                <td className="text-center">
                                                    <span className="pr-2 opacity-6">
                                                        <FontAwesomeIcon icon={faCloudSunRain} />
                                                    </span>
                                                    {dt.day ? dt.day : "null"}
                                                </td>
                                                <td className="text-center">
                                                    <div className={dt.status === 'inprogress' ? 'badge badge-pill badge-warning'
                                                        : dt.status === 'completed' ? 'badge badge-pill badge-success'
                                                            : dt.status === 'ongoing' ? 'badge badge-pill badge-danger'
                                                                : dt.status === 'pending' ? 'badge badge-pill badge-info' : null}>
                                                        {dt.status}
                                                    </div>


                                                </td>
                                                <td className="text-center">
                                                    <span className="pr-2 opacity-6">
                                                        <FontAwesomeIcon icon={faBusinessTime} />
                                                    </span>
                                                    {`${dt.class_from_time} - ${dt.class_to_time}`}
                                                </td>

                                                <td className="text-center">
                                                    {/* <ButtonGroup size="sm">
                                                        <Button className="btn-shadow" onClick={() => getClassTimes(dt.class_id)} color="primary">Timing</Button>
                                                       
                                                    </ButtonGroup> */}
                                                    <Button className="mb-2 mr-2 btn-icon btn-icon-only btn-pill" outline
                                                        onClick={() => getClassTimes(dt.class_id)}
                                                        color="primary">
                                                        <i className="pe-7s-clock btn-icon-wrapper"> </i>
                                                    </Button>
                                                    <Button className="mb-2 mr-2 btn-icon btn-icon-only btn-pill" outline
                                                        onClick={() => handleOpenStudentListModal(dt.class_id, dt.grade_id)}
                                                        color="primary">
                                                        <i className="pe-7s-user btn-icon-wrapper"> </i>
                                                    </Button>

                                                    {/* <ButtonGroup size="sm">
                                                        <Button className="btn-shadow" onClick={() => handleOpenStudentListModal(dt.class_id, dt.grade_id)} color="primary">Students</Button>
                                                       
                                                    </ButtonGroup> */}


                                                </td>
                                            </tr>
                                        )
                                    }) : null
                                }


                            </tbody>
                        </Table>
                        <CardFooter className="d-block p-4 text-center">
                            <Link to="/dashboard/show-class">
                                <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                                    <span className="mr-2 opacity-7">
                                        <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                                    </span>
                                    <span className="mr-1">
                                        View Full TimeTable
                                    </span>
                                </Button>
                            </Link>
                        </CardFooter>
                    </Card>



                </LoadingOverlay>
                <Row>
                    <Col sm="12" lg="6">
                        <LoadingOverlay tag="div" active={assignment.homePageAssignmentLoader}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                            <Assignments
                                assignments={assignment.assignments}
                            />
                        </LoadingOverlay>
                    </Col>

                    <Col sm="12" lg="6">
                        <LoadingOverlay tag="div" active={tStore.activityLoader}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                            <RecentActivity
                                activities={tStore.activities}
                            />
                        </LoadingOverlay>
                    </Col>


                </Row>



            </Container>


            <Modals
                open={openModal}
                handleClose={closeTimingModal}
                title={`Time shedule`}

            >
                <TimeShedule
                    data={tStore.specificClassTime}
                />
            </Modals>
            <Modals
                open={studentListModal}
                handleClose={handleCloseStudentListModal}
                title={`View Clients`}
            >
                <ViewStudent
                    data={tStore.specificStudent}
                />

            </Modals>
            <Modals
                open={unSupportBrowser}
                handleClose={closeUnsupportPopup}
                title={`Warning`}
            >
                <BrowserNotSupport />

            </Modals>
                </>
                :
                <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                    <span>Loading</span>
                </div>
            }
            


        </Fragment >
    )

}
export default AnalyticsDashboard1