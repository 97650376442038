

import axios from 'axios'
import { development } from '../../config.json'
// import moment from 'moment'
import Swal from 'sweetalert2'
// import React from 'react'



export const validateVideoClass = (token, video_id, class_id, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        //video_id: video_id,
        video_class_id: video_id,
        class_id: class_id
    }
    dispatch({ type: 'VALIDATE_VIDEO_REQUEST' })
    axios.post(`${development.baseUrl}validateVideoClass`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                cb(video_id)

            } else {
                Swal.fire({
                    icon: 'warning',
                    title: '',
                    text: 'Its not time to start video',

                })
            }

        })
        .catch(err => {
            dispatch({ type: 'VALIDATE_VIDEO_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                         
                        window.location.reload()
                        }
                    }
                }
                
            }

        })
}
export const updateTeacher = (token, data) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'UPDATE_USER_REQUEST' })
    axios.post(`${development.baseUrlEnte}updateUser`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                const edited = data
                edited.user_password = '********'
                dispatch({ type: 'UPDATE_TEACHER_SUCCESS', payload: edited })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Updated Successfully',

                })
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_USER_FAILED', payload: err })
        })
}
export const updateStudent = (token, data) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'UPDATE_USER_REQUEST' })
    axios.post(`${development.baseUrlEnte}updateUser`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                const edited = data
                edited.user_password = '********'
                dispatch({ type: 'UPDATE_STUDENT_SUCCESS', payload: edited })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Updated Successfully',

                })
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_USER_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                          
                        window.location.reload()
                        }
                    }
                }
                
            }

        })
}
export const getVideoList = (token, id, cb, empty) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }

        const body = {
            video_class_id: id
        }
        dispatch({ type: 'REQUEST_VIDEO' })
        axios.post(`${development.baseUrl}populateClassVideos`, body, { headers: head })


            .then(res => {
                //  debugger
                if (res.data.result) {

                    dispatch({ type: 'REQUEST_VIDEO_SUCCESS', payload: res.data })
                    if (res.data.videos && res.data.videos.length > 0) {
                        cb(res.data.videos[0].class_video_id, res.data)
                        //debugger

                    }


                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Empty',
                        text: 'There is no videos available in this class',

                    })
                    empty()
                }
            })
            .catch(err => {
                dispatch({ type: 'REQUEST_VIDEO_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                              
                            window.location.reload()
                            }
                        }
                    }
                   
                }
    
            })
    }


export const populatePermission = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        enterprise : true
    }
    dispatch({ type: 'MODULE_REQUEST' })
    axios.post(`${development.baseUrlente}populatePrivilege`,data, { headers: head })
        .then(res => {
            dispatch({ type: 'MODULE_SUCCESS', payload: res.data })
        })
        .catch(err => {
            dispatch({ type: 'MODULE_FAILED', payload: err })
        })
}

export const populateRole = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    dispatch({ type: 'ROLE_REQUEST' })
    axios.post(`${development.baseUrlente}populateRole`, { headers: head })
        .then(res => {
            dispatch({ type: 'ROLE_SUCCESS', payload: res.data })
        })
        .catch(err => {
            dispatch({ type: 'ROLE_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                         
                        window.location.reload()
                        }
                    }
                }
               
            }

        })
}

export const populateGroup = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    dispatch({ type: 'GROUP_REQUEST' })
    axios.post(`${development.baseUrlente}populateGroup`, null, { headers: head })
        .then(res => {
            dispatch({ type: 'GROUP_SUCCESS', payload: res.data })
            // console.log("res=====", res.data)
        })
        .catch(err => {
            dispatch({ type: 'GROUP_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                          
                        window.location.reload()
                        }
                    }
                }
               
            }

        })
}

export const createTeacher = (token, data, cb, cb2) => (dispatch) => {
    // console.log("inside f", data)
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }

    dispatch({ type: 'CREATE_TEACHER_REQUEST' })
    axios.post(`${development.baseUrlente}register`, data, { headers: head })
        .then(res => {
            // console.log("created class", res.data)
            if (res.data.result) {
                dispatch({ type: 'CREATE_TEACHER_SUCCESS', payload: res.data })

                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Created Successfully',

                })
            }
            if (!res.data.result) {
                dispatch({ type: 'CREATE_TEACHER_FALSE' })
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
            cb();
        })
        .catch(err => {
            if (err.response.status === 422) {

                // var keys = Object.keys(err.response.data.errors);
                var val = Object.values(err.response.data.errors)
                // // var myJSON = JSON.stringify(val);
                // Swal.fire({
                //     icon: 'warning',
                //     title: '',
                //     text: val.map(va => <p>{JSON.stringify(va)}</p>)
                // })
                cb();

                cb2(val)
            }
            if (err.response.status === 401) {
               
              window.location.reload()
              }
            dispatch({ type: 'CREATE_USER_FAILED', payload: err.response.data })
        })
}

export const showTeachers = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'TEACHER_REQUEST' })
    axios.post(`${development.baseUrlente}populateRegisteredTeachers`, null, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TEACHER_REQUEST_SUCCESS', payload: res.data })
                // console.log("response", res.data);
            }
        })
        .catch(err => {
            dispatch({ type: 'TEACHER_REQUEST_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                         
                        window.location.reload()
                        }
                    }
                }
               
            }
        })
}

export const showStudent = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STUDENT_REQUEST' })
    axios.post(`${development.baseUrlente}populateRegisteredStudents`, null, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_REQUEST_SUCCESS', payload: res.data })
                // console.log("response", res.data);
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_REQUEST_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                         
                        window.location.reload()
                        }
                    }
                }
               
            }
        })
}

export const showStaff = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STAFF_REQUEST' })
    axios.post(`${development.baseUrlente}populateRegisteredStaffs`, null, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STAFF_REQUEST_SUCCESS', payload: res.data })
                // console.log("response", res.data);
            }
        })
        .catch(err => {
            dispatch({ type: 'STAFF_REQUEST_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                          
                        window.location.reload()
                        }
                    }
                }
               
            }
        })
}

export const getTeacherData = (token, user_id) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
        // console.log("user--------", user_id)
        const body = {
            user_id: user_id
        }
        dispatch({ type: 'REQUEST_EDIT_USER_DEAILS' })
        axios.post(`${development.baseUrlente}editUser`, body, { headers: head })
            .then(res => {
                // if (res.data.result) {
                // console.log("response", res);
                dispatch({ type: 'SUCCESS_EDIT_USER_DEAILS', payload: res.data })
                // cb(res.data.class_details[0])
                // }
            })
            .catch(err => {
                dispatch({ type: 'REQUEST_VIDEO_FAILED', payload: err })
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                              
                            window.location.reload()
                            }
                        }
                    }
                    
                }
            })
    }

export const setEditData = (token, data, cb) => (dispatch) => {
    // console.log("inside f", data)
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }

    dispatch({ type: 'EDIT_TEACHER_REQUEST' })
    axios.post(`${development.baseUrlente}updateUser`, data, { headers: head })
        .then(res => {
            if (res.data.result) {

                dispatch({ type: 'EDIT_TEACHER_SUCCESS', payload: res.data })
                cb()
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Permission Changed Successfully',

                })
            }
            // console.log("created class", res.data)
        })
        .catch(err => {

            // console.log("eroorr=======", err)
            dispatch({ type: 'EDIT_TEACHER_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                          
                        window.location.reload()
                        }
                    }
                }
               
            }
        })
}

export const deleteUser = (token, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    const body = {
        user_id: user_id
    }
    dispatch({ type: 'DELETE_TEACHER_REQUEST' })
    axios.post(`${development.baseUrlente}deleteUser`, body, { headers: head })
        .then(res => {
            dispatch({ type: 'TEACHER_REQUEST_SUCCESS', payload: res.data })
            cb();
        })
        .catch(err => {
            dispatch({ type: 'DELETE_TEACHER_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                          
                        window.location.reload()
                        }
                    }
                }
               
            }
        })
}

export const populateStudentGroup = (token, user_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    const body = {
        student_id: user_id
    }
    dispatch({ type: 'STUDENT_GROUP_REQUEST' })
    axios.post(`${development.baseUrlente}populateStudentGroup`, body, { headers: head })
        .then(res => {
            dispatch({ type: 'STUDENT_GROUP_SUCCESS', payload: res.data })
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_GROUP_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                         
                        window.location.reload()
                        }
                    }
                }
                
            }
        })
}


export const updateUser = (token, data) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'UPDATE_USER_REQUEST' })
    axios.post(`${development.baseUrlEnte}updateUser`, data, { headers: head })
        .then(res => {
            if (res.data.result) {

                const edited = data
                edited.user_password = '********'
                dispatch({ type: 'UPDATE_USER_SUCCESS', payload: edited })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Updated Successfully',

                })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_USER_FAILED', payload: err })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                          
                        window.location.reload()
                        }
                    }
                }
               
            }
        })
}



