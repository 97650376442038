
// import axios from 'axios';
// import Dropzone from 'react-dropzone'
// import Table from './table'
import { Card, Input, CustomInput } from 'reactstrap';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { useDispatch, useSelector } from "react-redux";
import { downloadAssignment, getAssignments, addAssignment, getStudentAssignments } from '../../redux/Actions/assignment'
import { populateAllUserClass } from '../../redux/Actions/homeTeacher'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import SweetAlert from 'sweetalert-react';

// import Modals from '../common/Modal'
// import Success from './CreateSuccess'
// import StudentTable from './StudentList/table'
// import RespondToAssignment from './AssigmentRespond'
const formData = new FormData();

const AddAssignment = (props) => {
  console.log('assagin******' + window.location.href);
  var pageURL = window.location.href;
  var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
  console.log('assagin ' + lastURLSegment);
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  useEffect(() => {
    // if (!localStorage.getItem('authDetails')) {
    //     history.push('/')
    // }

    console.log('ROLE ' + tokenData.role);
    if (tokenData.role === 'teacher') {
      dispatch(populateAllUserClass(tokenData.token))
      dispatch(getAssignments(tokenData.token))
      console.log('class_id ' + props.class_id);
    }
    else if (tokenData) {
      console.log('ELSE  ROLE');
      dispatch(getStudentAssignments(tokenData.token))
    }

  }, [history]);
  const state = {
    file: []

  }
  const [loader, setLoader] = useState();

  // const columns = [
  //   { title: 'Assignment', field: 'title' },
  //   { title: ' Description', field: 'description' },
  //   { title: ' Subject', field: 'subject_name' },
  //   { title: 'Class', field: 'class_name' },
  //   { title: 'Grade', field: 'grade_name' },
  //   { title: 'Created On', field: 'created_at' },
  //   { title: 'Deadline', field: 'deadline' },

  // ]

  // const columns2 = [
  //   { title: 'Name', field: 'first_name' },
  //   { title: ' LastName', field: 'last_name' },
  //   { title: ' status', field: 'status' },

  // ]

  // const handleChangeGrade = e => {
  //   const { name, value } = e.target;
  //   formData.set('class_id', e.target.value);
  // };


  // const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)
  // const assignment = useSelector(({ assignments }) => assignments)
  const assignmentStudents = useSelector(({ assignments }) => assignments)
  console.log("class id s homeDetail")
  // const downloadFile = (rowData) => {

  //   dispatch(downloadAssignment(tokenData.token, rowData.assignment_id, rowData.class_id,reloadTbl));

  // }

  // const reloadTbl=()=>{
  //   console.log('ROLE ' + tokenData.role);
  //   if (tokenData.role === 'teacher') {
  //     dispatch(populateAllUserClass(tokenData.token))
  //     dispatch(getAssignments(tokenData.token))

  //   }
  //   else if (tokenData) {
  //     console.log('ELSE  ROLE');
  //     dispatch(getStudentAssignments(tokenData.token))
  //   }
  // }
  // const [stList, setList] = useState();
  // const populateStudents = (studs) => {
  //   console.log('download Students ');
  //   setStudents(assignmentStudents);
  //   openStudentsModel();
  // }
  const [successModal, setSuccessModal] = useState();
  // const [studModel, setStudModel] = useState();
  // const [students, setStudents] = useState();

  // const [respond, setRespond] = useState();
  // const [aAssignmentId, setaAssignmentId] = useState();
  // const [aAssignmentTitle, setTitle] = useState();
  // const [aAssignmentDescription, setDescription] = useState();

  // const respondToAssignment = (id, title, desc) => {
  //   console.log('respond Students ' + id + ' ' + title + ' ' + desc);
  //   setTitle(title);
  //   setDescription(desc);
  //   setRespond(true);
  //   setaAssignmentId(id);
  // }

  // const openStudentsModel = () => {
  //   setStudModel(true);

  // }
  // const closeRespondModel = () => {
  //   setRespond(false);

  // }

  // const openRespondModel = () => {
  //   setStudModel(true);

  // }
  // const closeStudentsModel = () => {
  //   setStudModel(false);

  // }

  const handleOpenSuccessModal = () => {
    console.log('callback')
    setLoader(false);
    setSuccessModal(true)
    dispatch(getAssignments(tokenData.token))
    document.getElementById("title").value = "";
    document.getElementById("description").value = "";
    document.getElementById("deadline").value = "";
    document.getElementById("assingment").value = "";

  }
  const handleCloseSuccesstModal = () => {
    setSuccessModal(false)
  }


  const onSubmit = (e) => {
    e.preventDefault()
    setLoader(true);
    // var imagefile = document.querySelector('#assignment');

    // this.props.onSubmitForm(this.formData);
    dispatch(addAssignment(tokenData.token, formData, handleOpenSuccessModal))
  }
  const onChangeTitle = (e) => {

    formData.set('title', e.target.value)
    console.log('bsbsz')
  }

  const onChangeDescription = (e) => {
    formData.set('description', e.target.value)
  }
  const onChangeDeadline = (e) => {
    formData.set('deadline', e.target.value)
  }
  // const onChangeClassId = (e) => {
  //   formData.set('class_id', e.target.value)
  // }
  const onChange = (e) => {

    for (let i = 0; i < e.target.files.length; i++) {

      formData.append('assignment[]', e.target.files[i])
    }


  }
  // const handleStudentList = () => {
  //   setSuccessModal(false)
  // }

  // const handleFileList = (event) => {

  // }



  return (

    <div>





      {tokenData.role === 'teacher' ?
        <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary">
          <div className="widget-chat-wrapper-outer">
            <form onSubmit={onSubmit} enctype="multipart/form-data" >

              <table width="100%" padding="5px" align="center">
                <tr>



                  <td align="left" colSpan="2">
                    <label>Title</label>
                  </td>
                  <td align="left" colSpan="2">Deadline</td>
                </tr>

                <tr>




                  <td></td>

                  <td>
                    <Input type="text" style={{ "width": "100%" }} required onBlur={onChangeTitle} id="title" name="title" />
                  </td>
                  <td></td>

                  <td>
                    <Input type="date" style={{ "width": "100%" }} required onBlur={onChangeDeadline} id="deadline" name="deadline" />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="6" align="left">Description</td>

                </tr>
                <tr>
                  <td colSpan="6">
                    <Input type="textarea" rows="4" style={{ "width": "100%" }} required onBlur={onChangeDescription} id="description" name="description" />

                  </td>


                  <td></td><td></td>
                </tr>
                <tr>
                  <td colSpan="3" align="left">
                    Attach Your Quiz Files
      </td>
                  <td colSpan="2" align="left">

                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="4">
                    {/* <Input type="file" style={{ "width": "100%" }} onBlur={onChange} multiple id="assignment" name="assignment" /> */}
                    <CustomInput type="file" id="assignment" onBlur={onChange} name="assignment" multiple />
                  </td>

                  <td colSpan="2" align="right" >

                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                      loading={loader}
                      type="submit"
                      data-style={ZOOM_IN}   >
                      Add New Assignment
</LaddaButton>

                  </td>

                </tr>
                <tr>

                </tr>


              </table>
              <br />
            </form>
          </div>

        </Card>
        : <div></div>

      }

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />



    </div>

  )


}

export default AddAssignment;