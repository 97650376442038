import React from 'react'
import serialize from 'form-serialize'
import { useDispatch } from "react-redux";
import {SetEmailIds} from '../../../redux/Actions/exam'
import {useState} from 'react'
import swal from 'sweetalert';



import {
    Row, Col,Badge, Form, Button, Input,Label,InputGroupText      
  } from 'reactstrap';
import LaddaButton, { ZOOM_IN } from 'react-ladda';


 const tokenData = JSON.parse(localStorage.getItem("authDetails"));
 const mailid = [] 



 function SendEmail(props) {
    const dispatch = useDispatch();
   const [Email, setEmail] = useState('')
   //const [EmailStore, setEmailStore] = useState([])

    function handleChange(e){
         setEmail(e.target.value)
             }


    function handleSubmit(e){
        e.preventDefault()
       let body={email:mailid,
                exam_id:props.exam_id} 
       dispatch(SetEmailIds(tokenData.token, body,props.handleClose))
       console.log('details',body)  
    }         

const AddEmail = (e) =>{
    e.preventDefault()
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if(Email.match(mailformat))
    {
    mailid.push(Email)
    setEmail("")
    console.log('estore',mailid)
    }
    else{
    setEmail("")
    swal("Enter a valid email!");
    }
}
    return (
        <div className="container" alignItems='center'>
        
        <Form onSubmit={handleSubmit}>
        <Row>
        <Col md={8}>
            <label><strong>Email:</strong></label>
            <Input  type='email' name='email' onChange={handleChange} value={Email}></Input>
        </Col>
        <Col md={4}>
        <Row>
         <Col>
         </Col>
         <Col>
         <Row>
          <Button className='btn mr-2 mt-4' onClick={AddEmail}>Add</Button>
          <Button className=" btn  mt-4" color='primary' type="submit">Finish</Button>        
         </Row>
         </Col>
        </Row>       
        </Col>
        </Row>
        <strong>Added emails :</strong>
        {mailid.map(mail =>(<Badge className='m-1 text-lowercase'>{mail}</Badge>) )}
           
       </Form>
       </div>
    )
}

export default SendEmail
