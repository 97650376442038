import React from "react";
import serialize from "form-serialize";
import { useDispatch, useSelector } from "react-redux";
import { UpdateExamTiming } from "../../../redux/Actions/exam";
import { useState } from "react";
import { format } from "date-fns";
import moment from "moment";

import { Row, Col, Form, Input, Label } from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";

function UpdateExamTime(props) {
  const eStore = useSelector(({ exam }) => exam);

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const dispatch = useDispatch();

  const [ExamTime, setExamTime] = useState(props.rowData.time);
  const [ExamDate, setExamDate] = useState(props.rowData.date);
  const [Title, setTitle] = useState(props.rowData.exam_title);
  console.log("dt", props.rowData.date);
  //const Date = props.rowData.date;
  //const Time = props.rowData.time;
  const ExamID = props.rowData.exam_id;
  const GradeName = props.rowData.grade_name;
  const SubjectName = props.rowData.subject_name;
  const formattedDate = ExamDate.split("-")
    .reverse()
    .join("-");
  const formattedTime = moment(ExamTime, "h:mm a").format("HH:mm");
  const handleClose = props.handleCloseUpdateModal;

  function handleDateChange(e) {
    const dt = e.target.value;
    let fdate = dt
      .split("-")
      .reverse()
      .join("-");
    setExamDate(fdate);
    console.log("date", e.target.value);
  }
  function handleTimeChange(e) {
    setExamTime(e.target.value);
  }
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    // console.log("tokennn",tokenData.token)
    dispatch(UpdateExamTiming(tokenData.token, body, handleClose));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>Grade</Col>
          <Col md={6}>Subject</Col>

          <Col md={6}>
            <strong style={{ textTransform: "capitalize" }}>{GradeName}</strong>
            <hr />
          </Col>

          <Col md={6}>
            <strong>{SubjectName}</strong>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span> Exam Date
            </Label>
            <Input type="hidden" name="exam_id" value={ExamID} />
            <Input
              type="date"
              required
              name="exam_date"
              value={formattedDate}
              onChange={handleDateChange}
            />
          </Col>
          <Col md={6}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span> Exam Time
            </Label>
            <Input
              type="time"
              required
              name="exam_time"
              value={formattedTime}
              onChange={handleTimeChange}
            />
          </Col>
          <Col md={12}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span> Exam name
            </Label>
            <Input
              type="text"
              required
              name="exam_title"
              value={Title}
              onChange={handleTitleChange}
            />
          </Col>
          <Col md={6}>
            <hr />
            {/* <Button type="submit" color="danger">UPDATE</Button> */}
            <LaddaButton
              className="mb-2 mr-2 btn btn-primary"
              loading={eStore.updateExamLoader}
              type="submit"
              data-style={ZOOM_IN}
            >
              UPDATE EXAM
            </LaddaButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default UpdateExamTime;
