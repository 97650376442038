import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Input, Form, FormFeedback } from 'reactstrap'
import Modals from '../../common/Modal'
// import RespondToAssignment from '../AssigmentRespond'
import pdfIcon from './pdf.png'
import docIcon from './document.png'
import '../assign.scss'
import Viewer from 'viewerjs';
import { requiredValidator, markOrGradeValidator } from '../../../utilities/Validator'
import { useParams, Route, Link } from 'react-router-dom'
import Header from './header'
import Profile from './profile';
import { getStudentAssignmentData, getAssignmnetMark, submitAssignmnetMark, downloadStudentAssignments } from '../../../redux/Actions/assignment'
import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay'
// import Breadcrumbs from '../../common/Breadcrumbs'
import Swal from 'sweetalert2'
// import PDFViewer from './PDFViewer';
import ImageView from './imageViewer'
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
// import 'bulma/css/bulma.css'
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import {
    faHome

} from '@fortawesome/free-solid-svg-icons';
// import 'bulma-helpers/css/bulma-helpers.min.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import PDFViewer from 'pdf-viewer-reactjs'





const SpecificStudentAssignment = () => {
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"))
    const params = useParams()
    const assignmentStore = useSelector(({ assignments }) => assignments)
    const ims = useRef(null)

    const [viewAddMark, setViewAddMark] = useState(false);
    const [inputField, setInputField] = useState({
        assignment_id: '',
        student_id: '',
        comment: '',
        mark: ''
    })

    // const file = 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png'
    // const type = 'png'

    //  const history = useHistory();
    useEffect(() => {
        dispatch(getStudentAssignmentData(tokenData.token, params.assig_id, params.class_id, params.student_id, params.assesment_id))
        return () => {
            dispatch({ type: 'CLEANUP_SPECIFIC_STUDENT' })
        }
    }, [])
    const addMarks = () => {

        dispatch(getAssignmnetMark(tokenData.token, params.assig_id, params.student_id, loadStudentMark, cb));
    }
    const cb = (data, ids) => {
        console.log("dataaa----", data)
        setInputField({ ...inputField, comment: data.teacher_remark, mark: data.mark, assignment_id: ids.assignment_id, student_id: ids.student_id, grading_type: data.grading_type, total: data.total })
    }
    const loadStudentMark = () => {

        setViewAddMark(true);
    }
    const handleCloseSuccesstModal = () => {
        setViewAddMark(false)
        setError({})

    }
    const handleChange = (e) => {
        const { name, value } = e.target
        console.log("inputField..", inputField)
        //dispatch({ type: 'EDIT_MARKS_REMARKS', payload: e.target })
        setInputField({ ...inputField, [name]: value })
    }
    const [error, setError] = useState({})
    const onSubmit = (e) => {
        e.preventDefault()
        // console.log("assignmentStore.specificStudentAssignmentData.grading_type", assignmentStore.specificStudentAssignmentData.grading_type);
        // console.log("typeof", typeof assignmentStore.specificStudentAssignmentData.grading_type);
        const err = {
            mark: markOrGradeValidator(inputField.mark, assignmentStore.specificStudentAssignmentData.grading_type, inputField.total),
            comment: requiredValidator(inputField.comment)
        }
        if (!err.mark && !err.comment) {
            setError(err)
            dispatch(submitAssignmnetMark(tokenData.token, inputField, hideStudentMark));

        } else {
            setError(err)
        }

    }
    const hideStudentMark = () => {

        setViewAddMark(false);
    }

    const downloadStudentFile = () => {
        dispatch(downloadStudentAssignments(tokenData.token, params.assig_id, params.class_id, params.student_id, reloadTbl));


    }

    const props = {
        allowFullScreen: true,
        src: "http://projects.itsasbreuk.nl/react-components/itsa-docviewer/example.pdf"
    };
    // const [UL, setUL] = useState("https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf")
    // const uri = () => {
    //     setUL("https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf")
    // }
    const reloadTbl = () => {
        // Swal.fire({
        //     icon: 'success',
        //     title: '',
        //     text: 'Done',
        // })
    }
    // const [img, setImg] = useState()
    const [currentSelected, setCurrentSelected] = useState(0)
    const [imageboxIsOpen, setImageboxIsOpen] = useState(false)
    const [pdfboxIsOpen, setPdfboxIsOpen] = useState(false)
    // const [pdfFile, setPdfFile] = useState(null)
    const openImage = (image, index) => {
        if (image.ext === "pdf") {
            // setPdfboxIsOpen(true)
            // setPdfFile(`https://cors-anywhere.herokuapp.com/${image.src}`)
            // setPdfFile(image.src)
            var link = document.createElement("a");
            link.href = image.src;
            link.target = "_blank"
            link.click();
            link.remove();
        } else if (image.ext === "png" || image.ext === "jpg" || image.ext === "jpeg") {
            //debugger
            // setImg(assignmentStore.specificStudentAssignmentData.files.filter(row => row.ext == "png"))
            setCurrentSelected(index)
            setImageboxIsOpen(true)
        } else {
            Swal.fire({
                icon: 'warning',
                //title: "Format not supported",
                text: "Do you want download file ?",
                confirmButtonText: 'Download',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            }).then((result) => {
                if (result.value) {
                    var link = document.createElement("a");
                    link.href = image.src;
                    // link.target = "_blank"
                    link.click();
                    link.remove();
                }
            })
        }
    }
    const toggleModal = () => {
        setImageboxIsOpen(!imageboxIsOpen);
    }
    let gallery = null
    const showImage = () => {
       // debugger;
        const ele2 = ims.current;
        if (!gallery) {
            gallery = new Viewer(ele2);
            gallery.show()
        } else {
            gallery.show()
        }
    }
    return (
        <>
            <LoadingOverlay tag="div" active={assignmentStore.specificStudentAssignmentData ? false : true}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: '#fff',
                        opacity: 0.5
                    })
                }}
                spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/dashboard" ><FontAwesomeIcon icon={faHome} /></Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to="/dashboard/assignments" >Quizzes</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={`/dashboard/assignments/${params.assig_id}/${params.class_id}`} >Details </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Evaluation</BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col md={12}>
                        <Header
                            button={assignmentStore.specificStudentAssignmentData.grading_type === "marks" ? 'Add Mark' : 'Add Grade'}
                            background={'#00458B'}
                            icon="pe-7s-note2 icon-gradient bg-sunny-morning"
                            heading={assignmentStore.specificStudentAssignmentData.Title}
                            subject={assignmentStore.specificStudentAssignmentData.Subject}
                            deadline={assignmentStore.specificStudentAssignmentData.Deadline}
                            color={'#99DDFF'}
                            lastDataColor={'rgb(180, 193, 189)'}
                            buttonAction={addMarks}
                            loader={assignmentStore.getAssigmentMarkLoader}
                        />
                    </Col>
                    <Col md={8} className="jms-assign-specific-pad">
                        <Card className="assign-details-card" style={{ height: '347px' }}>
                            <h6><strong>REMARKS</strong></h6>
                            <hr />
                            <div>
                                <strong>Client  : </strong>
                                <p>{assignmentStore.specificStudentAssignmentData.Student_remark} </p>
                            </div>
                            <hr />
                            <div>
                                <strong> Therapist  :</strong>
                                <p>{assignmentStore.specificStudentAssignmentData.Teacher_remark} </p>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4} className="jms-assign-specific-pad">
                        <Profile
                            markType={assignmentStore.specificStudentAssignmentData.grading_type}
                            background={'white'}
                            name={assignmentStore.specificStudentAssignmentData.Student_name}
                            grade={assignmentStore.specificStudentAssignmentData.Grade}
                            email={assignmentStore.specificStudentAssignmentData.email}
                            handleClick={downloadStudentFile}
                            submittedOn={assignmentStore.specificStudentAssignmentData.Submitted_on}
                            mark={assignmentStore.specificStudentAssignmentData.Mark}
                            Total_mark={assignmentStore.specificStudentAssignmentData.Total_mark}
                        />
                    </Col>
                </Row>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col md={12}>
                            <h6 style={{ padding: '10px 0px 0px 10px' }}><strong>Files</strong></h6>
                            <p>Documents</p>

                            <hr />
                        </Col>
                        <Col md={12}>
                            <Row style={{ padding: 10 }}>
                                {assignmentStore.assignmentDoc ?
                                    assignmentStore.assignmentDoc.map((image, index) => {
                                        return (
                                            <Col md={2} style={{ padding: 5 }}>
                                                <div onClick={() => openImage(image, index)}
                                                    style={{
                                                        backgroundSize: 'cover',
                                                        //backgroundImage: `url(${image.src})`, 
                                                        // backgroundImage: `url(${image.ext == "pdf" ? pdfIcon : docIcon})`,

                                                        width: '-webkit-fill-available',
                                                        borderRadius: '5px',
                                                        border: '3px solid #e8e7e7',
                                                        height: 130,
                                                        display: 'flex'
                                                    }}>
                                                    <img src={image.ext == "pdf" ? pdfIcon : docIcon} width={70} height={70} style={{ display: 'block', margin: 'auto' }} />
                                                    {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                </div>
                                            </Col>


                                        )
                                    })
                                    : null
                                }
                            </Row>
                            <p>Images</p>

                            <hr />
                            <div ref={ims}>
                                <Row style={{ padding: 10 }}>
                                    {assignmentStore.assignmentImage ?
                                        assignmentStore.assignmentImage.map((image, index) => {
                                            return (
                                                // <Col md={2} style={{ padding: 5 }}>
                                                //     <div onClick={() => openImage(image, index)}
                                                //         style={{
                                                //             backgroundSize: 'cover',
                                                //             backgroundImage: `url(${image.ext != "png" && image.ext != "jpg" && image.ext != "jpeg" ? pdfIcon : image.src})`,
                                                //             width: '-webkit-fill-available',
                                                //             borderRadius: '5px',
                                                //             border: '3px solid #e8e7e7',
                                                //             height: 130
                                                //         }}>
                                                //         {/* <img src={image.src} height={148} width={148} style={{ borderRadius: '5px', border: '3px solid #e8e7e7' }} /> */}
                                                //     </div>
                                                // </Col>
                                                <Col md={2} style={{ padding: 5 }} >
                                                    <div
                                                        //  onClick={() => openImage(image, index)}
                                                        onClick={showImage}
                                                        // className="jms-image-view"
                                                        style={{
                                                            backgroundSize: 'cover',
                                                            backgroundImage: `url(${image.ext != "png" && image.ext != "jpg" && image.ext != "jpeg" ? pdfIcon : image.src})`,
                                                            width: '-webkit-fill-available',
                                                            borderRadius: '5px',
                                                            border: '3px solid #e8e7e7',
                                                            height: 130,
                                                            width: 130
                                                        }}

                                                    >
                                                        <img src={image.src} width={70} height={70} style={{ display: 'block', margin: 'auto' }} id={index} />


                                                    </div>

                                                </Col>
                                            )
                                        })
                                        : null
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card>
                {/* {assignmentStore.assignmentImage ?
                    <ImageView
                        modalIsOpen={imageboxIsOpen}
                        toggleModal={toggleModal}
                        current={currentSelected}
                        images={assignmentStore.assignmentImage}
                    />
                    : null
                } */}
                <Modals
                    open={pdfboxIsOpen}
                    handleClose={() => setPdfboxIsOpen(false)}
                    // title={"Add Mark"}
                    size="lg"
                >
                    {/* <iframe src={`https://docs.google.com/viewer?url=${pdfFile}&embedded=true`} frameborder="0" height="500px" width="100%"></iframe> */}
                    {/* <PDFViewer
                        file={pdfFile}
                    // file={'https://backend.mymots.com/assignment/H7gbZFaNjYRCA71BOlqtXLf0rAmCIjC7qbhGQA42.pdf' }
                    /> */}
                </Modals>
            </LoadingOverlay>
            <Modals
                open={viewAddMark}
                handleClose={handleCloseSuccesstModal}
                title={assignmentStore.specificStudentAssignmentData.grading_type === "marks" ? 'Add Mark' : 'Add Grade'}
            >
                <Form onSubmit={onSubmit} >
                    <Row>
                        <Col md={3} className="assign-form-grid">
                            {assignmentStore.specificStudentAssignmentData.grading_type === "marks" ? 'Mark' : 'Grade'}
                        </Col>
                        <Col md={6} className="assign-form-grid">
                            <Input type="text" id="grade" name="mark"
                                value={inputField.mark}
                                onChange={handleChange}
                                invalid={error.mark}
                            />
                            <FormFeedback >{error.mark}</FormFeedback>

                        </Col>
                        <Col md={3} className="assign-form-grid"
                            style={{ position: 'relative', height: '14px' }}
                        >
                            <div
                                style={{ position: 'absolute', top: '50%', color: 'gray' }}
                            ><h6><strong> {inputField.grading_type == "marks" ? ` / ${inputField.total}` : null}</strong></h6></div>
                        </Col>
                        <Col md={3} className="assign-form-grid">
                            Remark
  </Col>
                        <Col md={9} className="assign-form-grid">
                            <Input type="textarea" id="remark" name="comment" rows="4" cols="45"
                                value={inputField.comment}
                                onChange={handleChange}
                                invalid={error.comment}
                            />
                            <FormFeedback >{error.comment}</FormFeedback>

                        </Col>
                        <Col md={12} className="assign-form-grid">
                            <button type="submit" className="btn btn-success btn-block" >Submit</button>

                        </Col>
                    </Row>

                </Form>

            </Modals>



        </>
    )
}

export default SpecificStudentAssignment
