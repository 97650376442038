import { validate } from 'email-validator'



export const excelValidator = (value) => {
    if (!value) return '* Required'

    const extensions = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xml']
    const currentFileExtension = value.split('.').pop();
    if (!extensions.includes(currentFileExtension)) {
        return '*Invalid, Excel file only allowed '
    }
}

export const videoValidator = (value) => {
    if (!value) return '* Required'
    console.log({ value });

    const extensions = ['mp4', 'm4a', 'm4v', 'f4v', 'f4a', 'm4b', 'm4r', 'f4b', 'mov', '3gp', '3gp2', '3g2', '3gpp', '3gpp2', 'ogg', 'oga', 'ogv', 'ogx', 'mkv', 'avi']
    const currentFileExtension = value.split('.').pop();
    if (!extensions.includes(currentFileExtension)) {
        return '*Invalid, video file only allowed '
    }
}
export const numberValidator = (value) => {
    let isnum = /^\d+$/.test(value);
    if (!value) return '* Required'

    if (!isnum) {
        return '*Invalid, you to have enter numbers '
    }
}
export const markOrGradeValidator = (value, type, totalMark) => {
    let isnum = /^\d+$/.test(value);
    let isString = /^[a-zA-Z]+$/.test(value);
    let isSpecialAndApha = /^[ A-Za-z+-]*$/.test(value)
    if (!value) return '* Required'
    if (type === null || type === "grading") {
        console.log("typeof value--is num", isNaN(value))

        if (isnum || !isSpecialAndApha) {
            return '*Invalid, you to have enter grade'
        }

    } else {
        console.log("total......", totalMark)

        if (!isnum || isString) {
            return '*Invalid, you to have enter mark'
        }
        if (totalMark < parseInt(value)) {
            console.log(`total......"${totalMark} ===value-${value}`)

            return '*Invalid, mark should be less than total mark'

        }
    }

}
export const requiredValidator = (value) => {
    if (!value) return '* Required'
}

export const nameValid = (value) => {
    var letters = /^[0-9 a-zA-Z]+$/;
    // console.log("val-----", value);

    if (!value) {
        return '* Required'
    } else if (!value.match(letters)) {
        return 'Special symbol not acceptable'

    }
}

export const emailOrMobileValidator = value => {
    let isnum = /^\d+$/.test(value);

    if (!value) return '*Required'
    if (!validate(value)) {
        if (!isnum) {
            return '*Enter valid email or mobile'
        } else if (isnum) {
            return false
        }
        else {
            return '*Enter valid email or mobile'

        }
    }

}
export const emailValidator = email => {
    if (!email) return '*Required'
    if (!validate(email)) return '*Enter valid email'
}
export const passwordValidator = (password) => {
    if (!password) return '*Required'
    //if (password !== cpassword) return '*password not matching'

}
export const cpasswordValidator = (password, cpassword) => {

    if (password !== cpassword) return '*password not matching'

}

export const nameValidator = name => {
    if (!name) return '*Required'
}
export const mobileValidator = mobile => {
    var numbers = /^[0-9]+$/.test(mobile)
    if (!mobile) return '*Required'
    if (mobile.length < 8) return '* mobile number is invalid'
    if (!numbers) return '* mobile number is invalid '
}

export const checkBoxValidtor = (checkbox) => {
    //return;
    if (checkbox === false) return '*you should accept terms and condition to continue';
}