import React, { useEffect, useState } from 'react'
import PageTitle from '../common/HeaderTitle/PageTitle'
// import { Card, Col, Row, Input, Label } from 'reactstrap'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Table from '../common/DataTable'
import { populateGroup, deleteGroup, editGroup, getGroupAssignedTeachers, populateGroupStudents } from '../../redux/Actions/grouping'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
// import AssignTeacher from './assignTeacher'
// import Modals from '../common/Modal'
import Swal from 'sweetalert2'
// import ListStudent from './ListStudent'

const Index = () => {


    const columns = [{ title: 'Group ', field: 'group_name', editable: 'onUpdate' },];
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const groupStore = useSelector(({ grouping }) => grouping)
    useEffect(() => {

        dispatch(populateGroup(tokenData.token))

        return () => {

        }
    }, [])

    console.log("groupStore", groupStore)
    const onEdit = (newData) => {

        console.log("newData", newData)
        if (newData.group_name == "") {
            Swal.fire(
                'Required',
                'Group name is required',
                'warning'
            )
        } else {
            dispatch(editGroup(tokenData.token, newData))

        }
    }
    const onDelete = (id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteGroup(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }
    // const [assignStudent, setAssignStudent] = useState(false)


    const handleAssignStudent = (id) => {
        //  alert("Group-ID", id)
        console.log("group_id", id)
        // setGroupID(id)
        dispatch(populateGroupStudents(tokenData.token, id, 'test'))
        // setAssignStudent(true)

    }

    // const [assignPopup, setAssignPopup] = useState(false)
    // const [groupID, setGroupID] = useState()
    const handleAssignTeacher = (id) => {
        //  alert("Group-ID", id)
        console.log("group_id", id)
        // setGroupID(id)
        dispatch(getGroupAssignedTeachers(tokenData.token, id))
        // setAssignPopup(true)

    }

    return (
        <>
            <PageTitle

                heading="Show Group"
               subheading=" "
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
            />

            <Table
                data={groupStore.groupList}
                loader={groupStore.groupListLoader}
                columns={columns}
                onEdit={onEdit}
                onDelete={onDelete}
                handleAssignTeacher={handleAssignTeacher}
                title={'Groups'}
                handleAssignStudent={handleAssignStudent}
                // handleSelectedRow={handleSelectedRow}
                actions={"create_group"}
            />


        </>
    )
}

export default Index
