import React, { useState, useEffect } from 'react'
// import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, Chip, Checkbox, TextField, InputAdornment } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { Row, Col } from 'reactstrap'
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux'
import { getAssignedGroups, assignGroupParticipantsToClassRoom } from '../../../redux/Actions/new-action'

function AssignGroupToClass({ assignedlist, full_userlist, closeGroupAssignModal, class_id, teacher_id }) {

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const teacher_group_loader = useSelector(state => state.new_action.teacher_group_loader ? state.new_action.teacher_group_loader : false)
    const assign_loader = useSelector(state => state.new_action.assign_loader ? state.new_action.assign_loader : false)

    useEffect(() => {
        dispatch(getAssignedGroups(tokenData.token, class_id, cb))
    }, [])

    const [SelectedGroup, setSelectedGroup] = useState([])
    const [SelectedGroupCopy, setSelectedGroupCopy] = useState([])
    const [AllGroups, setAllGroups] = useState([])

    const [NewGroups, setNewGroups] = useState([])
    const [GroupUnSelected, setGroupUnSelected] = useState([])
    const [AllGroupsCopy, setAllGroupsCopy] = useState([])
   // const [assign_loader, setassign_loader] = useState(false)


    const AddGroupsToClass = () => {
        dispatch(assignGroupParticipantsToClassRoom(tokenData.token,class_id, NewGroups, GroupUnSelected,closeGroupAssignModal2))
    }

    const cb = (selected, all) => {
        setSelectedGroup(selected)
        setSelectedGroupCopy(selected)
        setAllGroups(all)
        setAllGroupsCopy(all)
       
    }

    const SearchUsers = (e) => {
        if (e.target.value) {
            let data = [...AllGroupsCopy]
            let filter = data.filter(i => i.group_name.toUpperCase().includes(e.target.value.toUpperCase()))
            setAllGroups(filter)
        } else {
            setAllGroups(AllGroupsCopy)
        }
    }

    const handleRemove = (item) => {
        let filter = SelectedGroup.filter(val => val.group_id !== item.group_id)
        setSelectedGroup(filter)
        if (SelectedGroupCopy.includes(item)) {
            setGroupUnSelected([...GroupUnSelected, item.group_id])
        }

        if(NewGroups.includes(item.group_id)){
            let filter = NewGroups.filter(val => val !== item.group_id)
            setNewGroups(filter)
        }
    }

    const closeGroupAssignModal2 = () => {
        setNewGroups([])
        setGroupUnSelected([])
        setAllGroups([])
        setAllGroupsCopy([])
        closeGroupAssignModal()
    }

    const HandleUser = (val, e) => {
        if (e.target.checked) {
            let assigned = [...SelectedGroup, val]
            setSelectedGroup(assigned)
            if (!SelectedGroupCopy.includes(val)) {
                setNewGroups([...NewGroups, val.group_id])
            }
            if(GroupUnSelected.includes( val.group_id)){
                let filter = GroupUnSelected.filter(i => i !== val.group_id)
                setGroupUnSelected(filter)
            }
        } else {
            let filter = SelectedGroup.filter(i => i.group_id !== val.group_id)
            setSelectedGroup(filter)
            if (SelectedGroupCopy.includes(val)) {
                setGroupUnSelected([...GroupUnSelected, val.group_id])
            }
            if(NewGroups.includes(val.group_id)){
                let filternew = NewGroups.filter(i => i !== val.group_id)
                setNewGroups(filternew)
            }

        }
    }

    return (
        <>
            <DialogTitle style={{ textAlign: 'center', padding: '10px 20px' }}>
                Assign Groups
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Row>
                    <Col xs={12}>
                        <div className='wrap-chat-main' style={{ height: '100px', overflowY: 'auto', width: '100%', border: '1px solid #777', borderRadius: '8px', padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word', margin: '5px 0px' }}>
                            {
                                SelectedGroup.map((val, ky) => {
                                    return (
                                        <Chip
                                            key={ky}
                                            label={val.group_name}
                                            onDelete={() => { handleRemove(val) }}
                                            color='primary'
                                            style={{ marginLeft: '3px', marginTop: '3px' }}
                                            size='small'
                                        />
                                    )
                                })
                            }

                        </div>
                    </Col>
                    <Col xs={6} style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
                        <span style={{ fontSize: '15px', fontWeight: '700' }}>Name</span>
                    </Col>
                    <Col xs={6} className='text-center'>
                        <TextField
                            size='small'
                            label='search'
                            // variant=''
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            onChange={SearchUsers}
                        />
                    </Col>
                    <Col xs={12}>


                        <div className='wrap-chat-main' style={{ height: '200px', overflowY: 'auto', width: '100%', wordWrap: 'break-word' }}>

                            {
                                teacher_group_loader ? <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>Loading...</div> : ''

                            }
                            {
                                AllGroups?.length > 0 ? AllGroups.map((val, ky) => {
                                    return (
                                        <div key={ky} style={{ padding: '0 1.5rem' }}>
                                            <Checkbox
                                                checked={SelectedGroup.includes(val)}
                                                color='primary'
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                onChange={(e) => { HandleUser(val, e) }}
                                                size='small'
                                            />
                                            <span style={{ fontWeight: '500', color: 'black' }}>{val.group_name}</span>
                                            {/* <span style={{ fontSize: '13px' }}> ({val.role_name === 'student' ? 'client' : val.role_name === 'teacher' ? 'therapist' : val.role_name === 'principal' ? "admin" : val.role_name})</span> */}
                                        </div>
                                    )
                                })
                                    :
                                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                                        {/* {!userlist_loader && "No users to list"} */}
                                    </div>
                            }


                        </div>


                    </Col>
                    <Col xs={12} style={{ textAlign: 'right' }}>
                        <LaddaButton
                            data-style={ZOOM_IN}
                            className='btn btn-primary mr-1'
                            onClick={AddGroupsToClass}
                            loading={assign_loader}
                            disabled={(GroupUnSelected.length === 0 && NewGroups.length===0 ) ? true : false}
                        >
                            Assign
                        </LaddaButton>

                        <LaddaButton className='btn btn-danger'
                            onClick={closeGroupAssignModal2}
                        >
                            Close
                        </LaddaButton>
                    </Col>
                </Row>

            </DialogContent>
        </>
    )
}

export default AssignGroupToClass
