import React, { useEffect } from "react";
import serialize from "form-serialize";
import { useDispatch, useSelector } from "react-redux";
import { SetResultPublish, examDetails } from "../../../redux/Actions/exam";
import { useState } from "react";
import moment from "moment";
import { Row, Col, Form, Input, Label } from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";

function PublishResult(props) {
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const examStore = useSelector(({ exam }) => exam);
  var date = examStore?.examDetails.publish_at_date;
  var time = examStore?.examDetails.publish_at_time;
  const { exam_id, handleClose } = props;

  const formattedTime = moment(time, "h:mm a").format("HH:mm");
  const dispatch = useDispatch();

  const [ResultTime, setResultTime] = useState(formattedTime);
  const [ResultDate, setResultDate] = useState(date);

  function handleDateChange(e) {
    setResultDate(e.target.value);
  }
  function handleTimeChange(e) {
    setResultTime(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    dispatch(SetResultPublish(tokenData.token, body, handleClose));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>Subject</Col>
          <Col md={6}>Exam title</Col>

          <Col md={6}>
            <strong style={{ textTransform: "capitalize" }}>
              {examStore.examDetails?.subject}
            </strong>
            <hr />
          </Col>

          <Col md={6}>
            <strong>{examStore.examDetails?.exam_title}</strong>
            <hr />
          </Col>

          <Col md={6}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span> Result Date
            </Label>
            <Input type="hidden" name="exam_id" value={exam_id} />
            <Input
              type="date"
              required
              name="result_date"
              value={ResultDate}
              onChange={handleDateChange}
            />
          </Col>
          <Col md={6}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span> Result Time
            </Label>
            <Input
              type="time"
              required
              name="result_time"
              value={ResultTime}
              onChange={handleTimeChange}
            />
          </Col>
          <Col md={6}>
            <hr />
            {/* <Button type="submit" color="danger">UPDATE</Button> */}
            <LaddaButton
              className="mb-2 mr-2 btn btn-primary"
              //loading={assignment.updateAssignLoader}
              type="submit"
              data-style={ZOOM_IN}
            >
              Set publish date
            </LaddaButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default PublishResult;
