import { endpoints } from "../../helpers/endpoints";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

export const mailStudentExamResult = (token, details, cb) => (dispatch) => {
  const data = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "MAIL_RESULT_REQUEST" });
  axios
    .post(`${endpoints.hostUrl}mailStudentExamResult`, details, {
      headers: data,
    })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "MAIL_RESULT_SUCCESS" });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        cb();
      }
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_EXAMS_FAILED", payload: err });
    });
};

export const deleteQuestionPaper = (token, question_paper_id, cb) => (
  dispatch
) => {
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this question paper!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const data = {
        question_paper_id: question_paper_id,
      };
      axios
        .post(endpoints.deleteQuestionPaper, data, { headers: head })
        .then((res) => {
          if (res.data.result) {
            dispatch({
              type: "QUESTION_PAPER_DELETE_SUCESS",
              payload: res.data.details,
            });
            cb();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.message,
            });
            //resetForm()
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: res.data.message,
            });
          }
        });
    } else {
      swal("QuestionPaper delete aborted!");
    }
  });

  dispatch({ type: "DELETE_QUESTION" });
};

export const addQuestionid = (question_id) => (dispatch) => {
  dispatch({ type: "ADD_QUESTION_ID", payload: question_id });
};

export const submitStudentEvaluation = (token, body, student_id, exam_id) => (
  dispatch
) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "EVALUATION_REQUEST" });

  const data = {
    exam_id: exam_id,
    student_id: student_id,
    mark: body,
  };
  axios
    .post(endpoints.submitStudentEvaluation, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "EVALUATION_SUCCESS", payload: res.data.details });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        window.history.back();
        //resetForm()
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "EVALUATION_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Check your network connection",
        });
      }
    });
};
export const addQuestions = (token, question, mid, resetForm) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "ADD_QUESTION_REQUEST" });
  question.option_A
    ? (question.type = "objective")
    : (question.type = "subjective");
  question.markStructureId = mid;
  const data = {
    question: question,
  };
  axios
    .post(endpoints.addQuestions, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "ADD_QUESTION_SUCCESS", payload: res.data.details });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        resetForm();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_MARK_STRUCTURE_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};
export const createExam = (token, body, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "CREATE_EXAM_REQUEST" });
  // const data = {
  //     exam_id: exam_id
  // }
  axios
    .post(endpoints.createExam, body, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "CREATE_EXAM_SUCCESS", payload: res.data });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        cb();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "CREATE_EXAM_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};
export const examDetails = (cb, token, exam_id) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_EXAM_DETAILS_REQUEST" });
  const data = {
    exam_id: exam_id,
  };
  axios
    .post(endpoints.getExamDetails, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "GET_EXAM_DETAILS_SUCCESS", payload: res.data });
        cb(res.data.question_paper_id);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_EXAM_DETAILS_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};

export const deleteQuestion = (
  token,
  question_id,
  question_paper_id,
  mark_structure_id
) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "DELETE_QUESTION_REQUEST" });
  const data = {
    question_id: question_id,
    question_paper_id: question_paper_id,
    mark_structure_id: mark_structure_id,
  };
  axios
    .post(endpoints.deleteQuestion, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        Swal.fire({
          icon: "success",
          title: "Deleted",
          text: res.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "DELETE_QUESTION_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              //  title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        }
      }
    });
};
export const editQuestion = (
  token,
  question_id,
  question_paper_id,
  question_type,
  cb
) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "EDIT_QUESTION_GET_REQUEST" });
  const data = {
    question_id: question_id,
    question_paper_id: question_paper_id,
    question_type: question_type,
  };
  axios
    .post(endpoints.editQuestion, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "QUESTION_PREVIEW_SUCCESS", payload: res.data });
        cb(res.data);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "EDIT_QUESTION_GET_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              //  title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Check your network connection",
          });
        }
      }
    });
};

export const updateQuestion = (
  token,
  body,
  question_id,
  question_type,
  question_paper_id,
  cb
) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "EDIT_QUESTION_REQUEST" });
  body.question_id = question_id;
  body.type = question_type;
  body.question_paper_id = question_paper_id;
  const data = {
    question: body,
  };
  axios
    .post(endpoints.updateQuestion, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "EDIT_QUESTION_SUCCESS", payload: res.data });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        cb();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "EDIT_QUESTION_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              //  title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Check your network connection",
          });
        }
      }
    });
};

export const filterQuestionPaper = (token, grade_id, subject_id) => (
  dispatch
) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_FILTER_QUESTION_PAPER_REQUEST" });
  const data = {
    grade_id: grade_id,
    subject_id: subject_id,
  };
  axios
    .post(endpoints.filterQuestionPaper, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "GET_FILTER_QUESTION_PAPER_SUCCESS",
          payload: res.data.details,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_FILTER_QUESTION_PAPER_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              // title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        }
      }
    });
};
export const getQuestionPaperPreview = (token, id) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "QUESTION_PAPER_PREVIEW_REQUEST" });
  const data = {
    question_paper_id: id,
  };
  axios
    .post(endpoints.getQuestionPaperPreview, data, { headers: head })
    .then((res) => {
      if (res.data.details.length < 1) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Question empty",
        });
        window.history.back();
      } else if (res.data.result) {
        dispatch({
          type: "QUESTION_PAPER_PREVIEW_SUCCESS",
          payload: res.data.details,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "QUESTION_PAPER_PREVIEW_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              // title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        }
      }
    });
};
export const getTeacherQuestions = (token, id, name) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_TEACHER_QUESTION_PAPER_REQUEST" });

  axios
    .get(endpoints.getTeacherQuestions, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "GET_TEACHER_QUESTION_PAPER_SUCCESS",
          payload: res.data.details,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_TEACHER_QUESTION_PAPER_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              // title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        }
      }
    });
};
export const getMarkStucture = (token, id) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_MARK_STRUCTURE_REQUEST" });
  const data = {
    question_paper_id: id,
  };
  axios
    .post(endpoints.getMarkStucture, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "GET_MARK_STRUCTURE_SUCCESS",
          payload: res.data.details,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_MARK_STRUCTURE_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              // title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        }
      }
    });
};
export const createQuestionPaper = (token, data, resetForm) => (dispatch) => {
  data.shuffle_type = "all";
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "CREATE_QUESTION_PAPER_REQUEST" });
  // const data = {
  //     class_id: class_id,
  //     exam_id: exam_id
  // }

  // fs.writeFile("createExam.json", data, function(err) {
  // if (err) {
  //     console.log(err);
  // }
  // });

  axios
    .post(endpoints.createQuestions, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "CREATE_QUESTION_PAPER_SUCCESS",
          payload: res.data.details,
        });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        resetForm();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "CREATE_QUESTION_PAPER_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              // title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        }
      }
    });
};
export const getAnswerPaperDetails = (token, exam_id, student_id) => (
  dispatch
) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "STUDENT_ANSWER_REQUEST" });
  const data = {
    exam_id: exam_id,
    student_id: student_id,
  };
  axios
    .post(endpoints.getAnswerPaperDetails, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "STUDENT_ANSWER_SUCCESS", payload: res.data.details });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "STUDENT_ANSWER_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};

export const getTeacherExams = (token) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_TEACHER_EXAMS_REQUEST" });
  axios
    .get(endpoints.getTeacherExams, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "GET_TEACHER_EXAMS_SUCCESS",
          payload: res.data.details,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_TEACHER_EXAMS_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};
export const getStudentExams = (token) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_EXAMS_REQUEST" });
  axios
    .get(endpoints.getStudentExams, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "GET_EXAMS_SUCCESS", payload: res.data.details });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_EXAMS_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};
export const saveAnswers = (token, data, questionNumber) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "SAVE_ANSWER_REQUEST" });
  axios
    .post(endpoints.saveAnswers, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "SAVE_ANSWER_SUCCESS",
          payload: { questionNumber: questionNumber },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "SAVE_ANSWER_FAILED" });
      console.log("err from", err);
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};
export const getQuestionPaperDetails = (token, examId, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_QUESTION_PAPER_REQUEST" });
  const data = { exam_id: examId };
  axios
    .post(endpoints.getQuestionPaperDetails, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "GET_QUESTION_PAPER_SUCCESS",
          payload: res.data.details,
        });
        cb(res.data.details.Sections);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_QUESTION_PAPER_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              text: "You are logged in to another system",
              // title: 'Token Expired',
            });
          }
        }
      }
    });
};

export const validateStartExam = (cb, token, examId, source) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "VALIDATE_START_EXAM_REQUEST" });
  const data = { exam_id: examId, source: source };
  axios
    .post(endpoints.validateStartExam, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        console.log("called", res.data);
        dispatch({
          type: "VALIDATE_START_EXAM_SUCCESS",
          payload: res.data.instructions,
        });
        cb(examId);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
          confirmButtonText: "Okay",
        }).then((result) => {
          // props.history.push('/dashboard/')
          // return <Redirect to='/dashboard' />
          dispatch({ type: "VALIDATE_START_EXAM_FAILED" });
          window.history.back();
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "VALIDATE_START_EXAM_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            //  cb()
          }
        }
      }
    });
};

export const getExamDetails = (token) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_UPCOMING_EXAM_DETAILS_REQUEST" });
  axios
    .get(endpoints.getExamDetails, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "GET_UPCOMING_EXAM_DETAILS_SUCCESS",
          payload: res.data,
        });
      } else {
        Swal.fire({
          icon: "Error",
          title: "Warning",
          text: "Something went wrong",
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_UPCOMING_EXAM_DETAILS_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            //  cb()
          }
        }
      }
    });
};

export const getDuration = (token) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //dispatch({ type: 'GET_UPCOMING_EXAM_DETAILS_REQUEST' })
  axios
    .get(endpoints.getExamDetails, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "GET_EXAM_DURATION_SUCCESS", payload: res.data });
      } else {
        Swal.fire({
          icon: "Error",
          title: "Warning",
          text: "Something went wrong",
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_UPCOMING_EXAM_DETAILS_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            //  cb()
          }
        }
      }
    });
};

export const assignStudentToExam = (token, data, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  dispatch({ type: "ADD_STUDENTS_TO_EXAM_REQUEST" });

  axios
    .post(`${endpoints.hostUrl}addStudentToExam`, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "ADD_STUDENTS_TO_EXAM_SUCCESS",
          payload: res.data.class_details,
        });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        cb();
        //console.log('called')
      } else {
        Swal.fire({
          icon: "Warning",
          title: "Warning",
          text: res.data.message,
        });
      }

      //console.log("grade", res.data)
    })
    .catch((err) => {
      dispatch({ type: "ADD_STUDENTS_TO_EXAM_FAILED", payload: err });
    });
};

export const assignStudentToExamEnte = (token, data, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  dispatch({ type: "ADD_STUDENTS_TO_EXAM_REQUEST" });

  axios
    .post(`${endpoints.addStudentsExamEnte}`, data, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "ADD_STUDENTS_TO_EXAM_SUCCESS",
          payload: res.data.class_details,
        });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        cb();
      }
      //console.log("grade", res.data)
    })
    .catch((err) => {
      dispatch({ type: "ADD_STUDENTS_TO_EXAM_FAILED", payload: err });
    });
};

export const populateExamStudents = (token, grade_id, exam_id, cb = null) => (
  dispatch
) => {
  const data = {
    grade_id: grade_id,
    exam_id: exam_id,
  };
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //console.log(`head ${head.Authorization} data ${data.class_name}`)
  dispatch({ type: "POPULATE_STUDENT_REQUEST" });
  axios
    .post(`${endpoints.hostUrl}populateExamStudents`, data, { headers: head })

    .then((res) => {
      //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
      if (res.data.result) {
        dispatch({ type: "POPULATE_STUDENT_SUCCESS", payload: res.data });
        dispatch({ type: "POPULATE_EXAM_STUDENT_SUCCESS", payload: res.data });

        cb(res.data.students);
        // res.data.students.map((row) => {
        //   console.log("selected red");
        //   if (row.selected === true) {
        //     co
        //   }
        // });
      }
      // console.log("populateRegisteredStudents", res.data)
    })
    .catch((err) => {
      dispatch({ type: "POPULATE_STUDENT_FAILED", payload: err });
    });
};

export const populateExamGroups = (token, exam_id, teacher_id, cb) => (
  dispatch
) => {
  const data = {
    teacher_id: teacher_id,
    exam_id: exam_id,
    type: "exam",
  };
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  dispatch({ type: "POPULATE_TEACHER_GROUP_REQUEST" });
  axios
    .post(`${endpoints.getTeacherAssignedGroups}`, data, { headers: head })

    .then((res) => {
      dispatch({ type: "POPULATE_TEACHER_GROUP_SUCCESS", payload: res.data });
      cb(res.data.details);
    })
    .catch((err) => {
      dispatch({ type: "POPULATE_TEACHER_GROUP_FAILED", payload: err });
    });
};

export const UpdateExamTiming = (token, details, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "UPDATE_EXAM_REQUEST" });
  axios
    .post(`${endpoints.hostUrl}extendWholeExamSchedule`, details, {
      headers: head,
    })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "UPDATE_EXAM_SUCCESS", payload: res.data.details });

        Swal.fire({
          icon: "success",
          title: "Updated",
          text: "Exams updated successfully",
        });
        cb();
      }
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_EXAMS_FAILED", payload: err });
    });
};

export const UpdateExamIndividual = (token, details, cb) => (dispatch) => {
  const data = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "UPDATE_EXAM_INDIVIDUAL_REQUEST" });
  axios
    .post(`${endpoints.hostUrl}extendExamScheduleIndividually`, details, {
      headers: data,
    })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "UPDATE_EXAM_INDIVIDUAL_SUCCESS", payload: res.data });
        console.log("res", res.data);
        Swal.fire({
          icon: "success",
          title: "Updated",
          text: "Exams updated successfully",
        });

        cb();
      }
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_EXAM_INDIVIDUAL_FAILED", payload: err });
    });
};

export const SetResultPublish = (token, details, cb) => (dispatch) => {
  const data = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "UPDATE_EXAM_REQUEST" });
  axios
    .post(`${endpoints.hostUrl}publishExamResult`, details, { headers: data })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "UPDATE_EXAM_SUCCESS" });
        Swal.fire({
          icon: "success",
          title: "Updated",
          text: res.data.message,
        });
        cb();
      }
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_EXAMS_FAILED", payload: err });
    });
};

export const SetEmailIds = (token, details, cb) => (dispatch) => {
  const data = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "EXAM_RESULT_EMAIL_REQUEST" });
  axios
    .post(`${endpoints.hostUrl}mailWholeExamResult`, details, { headers: data })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "UPDATE_EXAM_SUCCESS" });
        Swal.fire({
          icon: "success",
          title: "Updated",
          text: "Result publish date set successfully",
        });
        cb();
      }
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_EXAMS_FAILED", payload: err });
    });
};
export const setApprovalExam = (token, approve, exam_id, teacher_id, cb) => (
  dispatch
) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const data = {
    exam_id: exam_id,
    teacher_id: teacher_id,
    approve: approve,
  };
  dispatch({ type: "REQUEST_VIDEOCLASS_STATUS" });

  axios
    .post(`${endpoints.hostUrl}changeApprovalForExam`, data, { headers: head })
    .then((res) => {
      dispatch({ type: "SUCCESS_EXAM_APPROVAL_TOGGLE" });
      if (res.data.result) {
        Swal.fire({
          icon: "success",
          title: "Updated",
          text: res.data.message,
        });
        cb();
      }
    })
    .catch((err) => {
      dispatch({ type: "FAILED_EXAM_APPROVAL_STATUS", payload: err });
    });
};

export const finishExam = (token, finish, exam_id) => (dispatch) => {
  const details = {
    finishExam: finish,
    exam_id: exam_id,
  };
  const data = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .post(`${endpoints.hostUrl}finishExam`, details, { headers: data })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "RESET_ANSWER" }); //To answer reducer
      }
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_EXAMS_FAILED", payload: err });
    });
};

export const getInstructions = (cb, token) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  dispatch({ type: "GET_INSTRUCTION_REQUEST" });

  axios
    .get(endpoints.getInstructions, { headers: head })
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "GET_INSTRUCTIONS_SUCCESS",
          payload: res.data,
        });
        cb(res.data.details);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "GET_INSTRUCTIONS_FAILED" });
      if (err) {
        if (err.response) {
          if (err.response.status === 401) {
            Swal.fire({
              icon: "error",
              // title: 'Token Expired',
              text: "You are logged in to another system",
            });
          }
        }
      }
    });
};

export const addInstructions = (token, instructions, exam_id) => (dispatch) => {
  const details = {
    instructions: instructions,
    exam_id: exam_id,
  };
  const data = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .post(`${endpoints.hostUrl}addInstructions`, details, { headers: data })
    .then((res) => {
      if (res.data.result) {
        dispatch({ type: "INSTRUCTION_ADD_SUCCESS" });
        Swal.fire({
          icon: "success",
          title: "Added",
          text: "Instructions added successfully",
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_EXAMS_FAILED", payload: err });
    });
};

export const setLocalAnswer = (answer, question_no) => (dispatch) => {
  // var arr = {};
  //arr[question_id] =    answer ;
  //console.log("showing the array",arr)
  dispatch({
    type: "SET_LOCAL_ANSWER",
    payload: { answer: answer, question_no: question_no },
  });
};
