import { combineReducers } from "redux";
import auth from './Reducers/auth'
import signUp from './Reducers/signUp'
import homeStudent from './Reducers/homeStudent'
import homeTeacher from './Reducers/homeTeacher'
import Reducers from '../reducers/index'
import assignments from './Reducers/assignments'
import playlist from './Reducers/playlist'
import Recordings from './Reducers/Recordings'
import grouping from './Reducers/grouping'
import videolist from './Reducers/videolist'
import feedback from './Reducers/feedback'
import exam from './Reducers/exam'
import answers from './Reducers/answers'
import new_action from "./Reducers/new-action-reducer"
const rootReducer = combineReducers({ answers, auth, signUp, homeStudent, homeTeacher, playlist, videolist, assignments, Recordings, grouping, exam, feedback,new_action, ...Reducers })

export default rootReducer